import React, { ReactNode, useEffect, useRef } from 'react';
import { Footer } from './components/Footer';
import { SortParameters } from './hooks';
import { ChevronsUpDownIcon } from 'lucide-react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { SimpleCheckbox } from '../SimpleCheckbox';
import { SubRow, TableRow } from './components/TableRow';
import { NoContent } from './components/NoContent';
import { Header } from '.';
import { getNestedProperty } from './util';

export type Column<T> = {
  property?: keyof T;
  expanded?: boolean;
  rightAlign?: boolean;
  sortableHeader?: boolean | string;
  heading?: string | React.ReactNode;
  width?: number;
  render?: (
    item: T,
    index: number,
    state: {
      toggleSubRows: () => void;
      isSubrowsOpen: boolean;
      hasSubrows: boolean;
      subrowCount: number;
    },
    rowIndex: number,
  ) => ReactNode;
};

export type Meta = {
  current_page: number;
  from: number | null;
  last_page: number;
  path: string | null;
  per_page: number;
  to: number | null;
  total: number;
  has_more: boolean;
  has_less: boolean;
  next_page: number | null;
  previous_page: number | null;
};

type Props<T, SR> = {
  disableFrontendSorting?: boolean;
  disabled?: boolean;
  sorting?: SortParameters<any>;
  onSelectionChange?: (added: T[], removed: T[]) => void;
  selection?: T[];
  selectionIdentifyBy?: string;
  onSortChange?: (sorting: SortParameters<any>) => void;
  items: (T & { tableRowClassNames?: string })[] | undefined;
  skeletonLoaders?: number;
  columns: Column<T>[];
  isLoading?: boolean;
  dense?: boolean;
  transparent?: boolean;
  renderRow?: (args: {
    item: T;
    cells: JSX.Element[];
    index: number;
    classNames: string;
  }) => JSX.Element;
  subRows?: SubRow<T, SR>;
  children?: JSX.Element | JSX.Element[];
  overlay?: React.ReactNode;
};

export const Table = <T, SR>({
  disableFrontendSorting,
  columns,
  dense = false,
  skeletonLoaders = 25,
  isLoading,
  items = [],
  sorting,
  onSelectionChange,
  selection,
  selectionIdentifyBy = 'id',
  onSortChange,
  subRows,
  renderRow,
  transparent,
  children,
  overlay,
  disabled,
}: Props<T, SR>) => {
  const selectionEnabled = !!onSelectionChange && !!selection;
  const dummyContentWrapperRef = useRef<HTMLDivElement>(null);
  const dummyContentRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const renderHeading = (column: Column<T>) => {
    if (column.heading) {
      if (typeof column.heading === 'string') {
        return column.heading?.toUpperCase();
      } else {
        return column.heading;
      }
    }
    return '' + ((column?.property ?? '') as string).toUpperCase();
  };

  const sortFunction = (a: any, b: any) => {
    if (!sorting) {
      return;
    }
    if (typeof a[sorting.sortBy] === 'string') {
      if (sorting.sortDirection === 'ASC') {
        return a[sorting.sortBy].localeCompare(b[sorting.sortBy]);
      } else {
        return b[sorting.sortBy].localeCompare(a[sorting.sortBy]);
      }
    }
    if (typeof a[sorting.sortBy] === 'number') {
      if (sorting.sortDirection === 'ASC') {
        return a[sorting.sortBy] - b[sorting.sortBy];
      } else {
        return b[sorting.sortBy] - a[sorting.sortBy];
      }
    }
  };

  const sortedItems =
    sorting && !disableFrontendSorting ? [...items].sort(sortFunction) : items;
  const allItemsAreSelected =
    sortedItems.length > 0 &&
    sortedItems.every((item) =>
      selection?.find(
        (selectedItem) =>
          getNestedProperty(selectedItem, selectionIdentifyBy) ===
          getNestedProperty(item, selectionIdentifyBy),
      ),
    );

  const handleSortChange = (column: Column<any>) => {
    if (!onSortChange) {
      return;
    }
    const sortBy =
      typeof column.sortableHeader === 'string'
        ? column.sortableHeader
        : column.property;
    if (sorting?.sortBy !== sortBy) {
      return onSortChange({
        sortBy,
        sortName: '' + (column.property as string) + ' (ASC)',
        sortDirection: 'ASC',
      });
    }
    if (sorting?.sortDirection === 'ASC') {
      return onSortChange({
        ...sorting,
        sortName: '' + (column.property as string) + ' (DESC)',
        sortDirection: 'DESC',
      });
    }
    if (sorting?.sortDirection === 'DESC') {
      return onSortChange({
        sortBy: undefined,
        sortName: undefined,
        sortDirection: undefined,
      });
    }
  };

  const handleSelectAll = (_: boolean) => {
    if (!onSelectionChange) return;

    if (allItemsAreSelected) {
      onSelectionChange([], sortedItems);
    } else {
      onSelectionChange(sortedItems, []);
    }
  };

  const handleSelect = (value: boolean, item: T) => {
    if (!onSelectionChange) return;
    if (value) {
      onSelectionChange([item], []);
    } else {
      onSelectionChange([], [item]);
    }
  };

  useEffect(() => {
    if (
      dummyContentWrapperRef.current &&
      dummyContentRef.current &&
      tableRef.current &&
      tableWrapperRef.current
    ) {
      dummyContentRef.current.style.width = tableRef.current.scrollWidth + 'px';
    }

    const resizeCallback = () => {
      if (
        dummyContentWrapperRef.current &&
        dummyContentRef.current &&
        tableRef.current &&
        tableWrapperRef.current
      ) {
        dummyContentRef.current.style.width =
          tableRef.current.scrollWidth + 'px';
      }
    };

    window.addEventListener('resize', resizeCallback);

    return () => window.removeEventListener('resize', resizeCallback);
  }, [
    tableRef.current,
    dummyContentWrapperRef.current,
    dummyContentRef.current,
  ]);

  return (
    <div
      className={`relative w-full rounded-lg ${transparent ? '' : 'bg-white'}`}
    >
      {overlay && (
        <div className="pointer-events-none absolute inset-0 z-10">
          <div className="pointer-events-auto">{overlay}</div>
        </div>
      )}
      {Array.isArray(children)
        ? children.filter((child) => child.type === (<Header />).type)
        : children?.type === (<Header />).type
          ? children
          : null}
      <div
        ref={dummyContentWrapperRef}
        onScroll={(e) =>
          (tableWrapperRef.current!.scrollLeft = e.currentTarget.scrollLeft)
        }
        className={`editor-scroll-bar h-4 w-full overflow-x-auto text-xs`}
      >
        <div ref={dummyContentRef}>&nbsp;</div>
      </div>

      <div
        ref={tableWrapperRef}
        className={`editor-scroll-bar overflow-x-auto pb-8 ${/*enableScroll*/ ''}`}
        onScroll={(e) =>
          (dummyContentWrapperRef.current!.scrollLeft =
            e.currentTarget.scrollLeft)
        }
      >
        <table ref={tableRef} className="w-full">
          <thead
            className={`${/*posSticky*/ ''} ${transparent ? '' : 'border-t'}`}
          >
            {selectionEnabled && (
              <th
                className={`border-b border-primary-200 py-2 text-left text-sm text-primary-800`}
                style={{ width: '1rem' }}
              >
                <SimpleCheckbox
                  checked={allItemsAreSelected}
                  onChange={handleSelectAll}
                  disabled={isLoading}
                />
              </th>
            )}
            {columns.map((column, i) => (
              <th
                key={'header' + i}
                className={`border-b border-primary-200 py-2 pl-3 pr-3 text-left text-xs text-primary-800 ${
                  column.sortableHeader && onSortChange && !disabled
                    ? 'cursor-pointer'
                    : ''
                } ${disabled ? 'cursor-not-allowed opacity-50' : ''} ${column.expanded ? 'w-full' : ''}`}
                onClick={
                  column.sortableHeader && onSortChange && !disabled
                    ? () => handleSortChange(column)
                    : undefined
                }
              >
                <div
                  className={`flex min-h-[2.5rem] items-center gap-0.5 whitespace-nowrap ${
                    column.rightAlign ? `justify-end` : ''
                  }`}
                >
                  {renderHeading(column)}
                  {column.sortableHeader &&
                    ((column.property !== sorting?.sortBy &&
                      column.sortableHeader !== sorting?.sortBy) ||
                    sorting?.sortBy ===
                      undefined ? null : sorting?.sortDirection === 'DESC' ? (
                      <ChevronDown size={14} />
                    ) : (
                      <ChevronUp size={14} />
                    ))}
                </div>
              </th>
            ))}
            {subRows && <th className="border-b border-primary-200" />}
          </thead>
          <tbody className="relative">
            {isLoading &&
              Array.from({ length: skeletonLoaders! }).map((v, key) => (
                <tr key={'loader' + key}>
                  <td
                    colSpan={columns.length + (selectionEnabled ? 1 : 0)}
                    className="w-full"
                  >
                    <div className="mt-1 h-12 animate-pulse rounded-lg bg-primary-100" />
                  </td>
                </tr>
              ))}
            {!isLoading && sortedItems?.length === 0 && (
              <tr>
                {' '}
                <td
                  colSpan={columns.length}
                  className="pt-6 text-center text-primary-400"
                >
                  {(Array.isArray(children)
                    ? children.find(
                        (child) => child.type === (<NoContent />).type,
                      )
                    : children?.type === (<NoContent />).type) ?? 'No results'}
                </td>
              </tr>
            )}

            {!isLoading &&
              sortedItems?.map((item, rowIndex) => (
                <TableRow
                  key={getNestedProperty(item, selectionIdentifyBy ?? 'id')}
                  item={item}
                  columns={columns}
                  selectionEnabled={selectionEnabled}
                  selection={selection}
                  selectionIdentifyBy={selectionIdentifyBy}
                  handleSelect={handleSelect}
                  renderRow={renderRow}
                  subRows={subRows}
                  rowIndex={rowIndex}
                  dense={dense}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div className="mb-6">
        {Array.isArray(children)
          ? children.filter((child) => child.type === (<Footer />).type)
          : children?.type === (<Footer />).type
            ? children
            : null}
      </div>
    </div>
  );
};
