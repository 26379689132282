import { Button } from '@/Components/v2/Button';
import { ChevronRight } from 'lucide-react';

type Props = {
  onChooseMessage: (message: string) => void;
};

export const InitialMessageSuggestions = ({ onChooseMessage }: Props) => {
  const messages = [
    'optimize my page https://www.wine-searcher.com/regions-gaillac',
    'create an article for the keyword “Sicilian wines”',
    'find long-tail keywords related to “Sicilian wines” and provide me with the search volume',
  ];

  return (
    <div className="flex flex-grow flex-col items-center justify-center px-6">
      <div className="mb-8 text-2xl font-bold text-primary">
        Get started with prompts like...
      </div>
      <div className="flex flex-col items-center gap-8">
        {messages.map((message) => (
          <div className="flex w-full items-center gap-4 rounded-lg bg-white px-4 py-6 text-primary shadow-lg">
            <div className="flex-grow">{message}</div>
            <div className="flex-shrink-0">
              <Button
                text="Execute"
                variant="ghost"
                appendIcon={ChevronRight}
                onClick={() => onChooseMessage(message)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
