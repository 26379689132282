import React, { useEffect, useRef } from 'react';
export function Face() {
  const faceContainerRef = useRef(null);
  const eyesRef = useRef([]);
  const isRandomMovementActiveRef = useRef(false);
  const lastMouseMoveRef = useRef(Date.now());
  // Function to play a programmed laughing sound (unchanged)
  function playLaughSound() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioCtx = new AudioContext();
    const now = audioCtx.currentTime;
    function playBurst(startTime, baseFrequency) {
      const oscillator1 = audioCtx.createOscillator();
      const oscillator2 = audioCtx.createOscillator();
      oscillator1.type = 'sine';
      oscillator2.type = 'sine';
      const frequencyOffset = Math.random() * 30 + 10;
      oscillator1.frequency.setValueAtTime(
        baseFrequency + frequencyOffset,
        startTime,
      );
      oscillator2.frequency.setValueAtTime(
        baseFrequency + frequencyOffset * 1.02,
        startTime,
      );
      const gainNode = audioCtx.createGain();
      gainNode.gain.setValueAtTime(0, startTime);
      gainNode.gain.linearRampToValueAtTime(0.4, startTime + 0.03);
      gainNode.gain.linearRampToValueAtTime(0, startTime + 0.15);
      oscillator1.connect(gainNode);
      oscillator2.connect(gainNode);
      gainNode.connect(audioCtx.destination);
      oscillator1.start(startTime);
      oscillator2.start(startTime);
      oscillator1.stop(startTime + 0.15);
      oscillator2.stop(startTime + 0.15);
    }
    const baseFrequencies = [900, 850, 880, 910, 860, 920, 870];
    let delay = 0;
    for (let i = 0; i < baseFrequencies.length; i++) {
      playBurst(now + delay, baseFrequencies[i]);
      delay += Math.random() * 0.15 + 0.08;
    }
    setTimeout(() => {
      audioCtx.close();
    }, 1200);
  }
  // Laughing animation on click (unchanged)
  function runLaugh() {
    if (isRandomMovementActiveRef.current) return;
    isRandomMovementActiveRef.current = true;
    playLaughSound();
    eyesRef.current.forEach((eye) => {
      const inner = eye.querySelector('.eye-inner');
      if (inner) {
        inner.classList.add('laughing');
      }
    });
    setTimeout(() => {
      eyesRef.current.forEach((eye) => {
        const inner = eye.querySelector('.eye-inner');
        if (inner) {
          inner.classList.remove('laughing');
        }
      });
      isRandomMovementActiveRef.current = false;
    }, 2000);
  }
  // 1) Up/Down Expression (scaled)
  function randomUpDownExpression() {
    isRandomMovementActiveRef.current = true;
    const direction = Math.random() < 0.5 ? -1 : 1;
    // Original offset 15px becomes 15*0.7 = 10.5px
    const upPos = { x: 15 * 0.7 * direction, y: -15 * 0.7 };
    const downPos = { x: 15 * 0.7 * direction, y: 15 * 0.7 };
    const centerPos = { x: 0, y: 0 };
    eyesRef.current.forEach((eye) => {
      eye.style.transform = `translate(${upPos.x}px, ${upPos.y}px)`;
    });
    setTimeout(() => {
      eyesRef.current.forEach((eye) => {
        eye.style.transform = `translate(${downPos.x}px, ${downPos.y}px)`;
      });
      setTimeout(() => {
        eyesRef.current.forEach((eye) => {
          eye.style.transform = `translate(${centerPos.x}px, ${centerPos.y}px)`;
        });
        isRandomMovementActiveRef.current = false;
      }, 800);
    }, 800);
  }
  // 2) Curious Expression (scaled)
  function randomCuriousExpression() {
    isRandomMovementActiveRef.current = true;
    const side = Math.random() < 0.5 ? 'left' : 'right';
    // Original side offset 25px becomes 25*0.7 = 17.5px
    const sideOffset = 25 * 0.7 * (side === 'left' ? -1 : 1);
    // STEP A
    eyesRef.current.forEach((eye) => {
      if (eye.classList.contains(side)) {
        eye.style.transformOrigin = 'bottom center';
        eye.style.transform = `translate(${sideOffset}px, 0) scale(1,1.1)`;
      } else {
        eye.style.transformOrigin = 'center';
        eye.style.transform = `translate(${sideOffset}px, 0) scale(1)`;
      }
    });
    // STEP B: translate Y -10px becomes -10*0.7 = -7px
    setTimeout(() => {
      eyesRef.current.forEach((eye) => {
        if (eye.classList.contains(side)) {
          eye.style.transform = `translate(${sideOffset}px, -${10 * 0.7}px) scale(1,1.3)`;
        } else {
          eye.style.transform = `translate(${sideOffset}px, -${10 * 0.7}px) scale(1)`;
        }
      });
    }, 400);
    // STEP C: translate Y +10px becomes +10*0.7 = 7px
    setTimeout(() => {
      eyesRef.current.forEach((eye) => {
        if (eye.classList.contains(side)) {
          eye.style.transform = `translate(${sideOffset}px, ${10 * 0.7}px) scale(1,1.3)`;
        } else {
          eye.style.transform = `translate(${sideOffset}px, ${10 * 0.7}px) scale(1)`;
        }
      });
    }, 800);
    // STEP D
    setTimeout(() => {
      eyesRef.current.forEach((eye) => {
        if (eye.classList.contains(side)) {
          eye.style.transform = `translate(${sideOffset}px, 0) scale(1,1.3)`;
        } else {
          eye.style.transform = `translate(${sideOffset}px, 0) scale(1)`;
        }
      });
    }, 1200);
    // STEP E
    setTimeout(() => {
      eyesRef.current.forEach((eye) => {
        eye.style.transform = `translate(0, 0) scale(1)`;
      });
    }, 1600);
    setTimeout(() => {
      isRandomMovementActiveRef.current = false;
    }, 2000);
  }
  // 3) Look Down Sides Expression (scaled)
  function randomLookDownSidesExpression() {
    isRandomMovementActiveRef.current = true;
    const startSide = Math.random() < 0.5 ? 'left' : 'right';
    // Original 20px becomes 20*0.7 = 14px
    const leftTransform = 'translate(-14px, 14px)';
    const rightTransform = 'translate(14px, 14px)';
    const centerTransform = 'translate(0, 0)';
    if (startSide === 'left') {
      eyesRef.current.forEach((eye) => {
        eye.style.transform = leftTransform;
      });
      setTimeout(() => {
        eyesRef.current.forEach((eye) => {
          eye.style.transform = rightTransform;
        });
        setTimeout(() => {
          eyesRef.current.forEach((eye) => {
            eye.style.transform = centerTransform;
          });
          setTimeout(() => {
            isRandomMovementActiveRef.current = false;
          }, 400);
        }, 600);
      }, 600);
    } else {
      eyesRef.current.forEach((eye) => {
        eye.style.transform = rightTransform;
      });
      setTimeout(() => {
        eyesRef.current.forEach((eye) => {
          eye.style.transform = leftTransform;
        });
        setTimeout(() => {
          eyesRef.current.forEach((eye) => {
            eye.style.transform = centerTransform;
          });
          setTimeout(() => {
            isRandomMovementActiveRef.current = false;
          }, 400);
        }, 600);
      }, 600);
    }
  }
  function doRandomExpression() {
    const expressions = [
      randomUpDownExpression,
      randomCuriousExpression,
      randomLookDownSidesExpression,
    ];
    const randomIndex = Math.floor(Math.random() * expressions.length);
    expressions[randomIndex]();
  }
  useEffect(() => {
    let expressionTimeoutId;
    function scheduleExpressions() {
      const randomInterval = Math.floor(Math.random() * 4000) + 6000;
      expressionTimeoutId = setTimeout(() => {
        if (Date.now() - lastMouseMoveRef.current >= 1000) {
          doRandomExpression();
        }
        scheduleExpressions();
      }, randomInterval);
    }
    scheduleExpressions();
    return () => clearTimeout(expressionTimeoutId);
  }, []);
  // Mouse tracking for eyes (scaled)
  useEffect(() => {
    eyesRef.current = faceContainerRef.current
      ? Array.from(faceContainerRef.current.querySelectorAll('.eye'))
      : [];
    function handleMouseMove(e) {
      lastMouseMoveRef.current = Date.now();
      if (isRandomMovementActiveRef.current) return;
      if (!faceContainerRef.current) return;
      const rect = faceContainerRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const angle = Math.atan2(e.clientY - centerY, e.clientX - centerX);
      // Original moveDistance 12px becomes 12*0.7 = 8.4px
      const moveDistance = 12 * 0.7;
      const offsetX = moveDistance * Math.cos(angle);
      const offsetY = moveDistance * Math.sin(angle);
      eyesRef.current.forEach((eye) => {
        eye.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
        eye.style.transformOrigin = 'center';
      });
    }
    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, []);
  // Blinking (scaled keyframe values)
  useEffect(() => {
    let blinkTimeoutId;
    function blink() {
      const asyncBlink = Math.random() < 0.5;
      const delayMs = asyncBlink ? Math.floor(Math.random() * 5) + 20 : 0;
      const delayEyeIsLeft = Math.random() < 0.5;
      eyesRef.current.forEach((eye) => {
        const inner = eye.querySelector('.eye-inner');
        if (inner) {
          if (
            asyncBlink &&
            ((eye.classList.contains('left') && delayEyeIsLeft) ||
              (eye.classList.contains('right') && !delayEyeIsLeft))
          ) {
            setTimeout(() => {
              inner.classList.add('blinking');
              setTimeout(() => {
                inner.classList.remove('blinking');
              }, 300);
            }, delayMs);
          } else {
            inner.classList.add('blinking');
            setTimeout(() => {
              inner.classList.remove('blinking');
            }, 300);
          }
        }
      });
    }
    function scheduleBlink() {
      const randomDelay = Math.floor(Math.random() * 3000) + 2000;
      blinkTimeoutId = setTimeout(() => {
        blink();
        scheduleBlink();
      }, randomDelay);
    }
    scheduleBlink();
    return () => clearTimeout(blinkTimeoutId);
  }, []);
  return (
    <div style={{ width: '140px', height: '140px', position: 'relative' }}>
      <style>{`
        .face-floating {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 140px; /* 200px * 0.7 */
          height: 140px; /* 200px * 0.7 */
        }
        .floatUpDown {
          animation: floatUpDown 4s ease-in-out infinite;
        }
        @keyframes floatUpDown {
          0%, 100% { transform: translate(-50%, -50%); }
          50% { transform: translate(-50%, -54%); }
        }
        @keyframes organicShift {
          0%, 100% { border-radius: 50% 45% 52% 47% / 47% 53% 50% 50%; }
          33% { border-radius: 55% 42% 50% 45% / 50% 55% 45% 52%; }
          66% { border-radius: 48% 50% 55% 43% / 45% 50% 55% 48%; }
        }
        .face {
          position: relative;
          width: 100%;
          height: 100%;
          background: #86EFAC;
          box-shadow: 0 0 0px rgba(0, 255, 100, 0.5);
          border-radius: 50% 45% 52% 47% / 47% 53% 50% 50%;
          animation: organicShift 4.5s ease-in-out infinite alternate;
        }
        @keyframes pupilDilation {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.1); }
        }
        .eye-inner {
          animation: pupilDilation 6s ease-in-out infinite alternate;
        }
        .eye {
          position: absolute;
          width: 15px; /* 22px * 0.7 */
          height: 39px; /* 55px * 0.7 */
          background: none;
          transform-origin: center;
          transition: transform 0.3s ease-out;
        }
        .eye.left {
          top: 42px; /* 60px * 0.7 */
          left: 43px; /* 62px * 0.7 */
        }
        .eye.right {
          top: 42px; /* 60px * 0.7 */
          right: 43px; /* 62px * 0.7 */
        }
        .eye-inner {
          width: 100%;
          height: 100%;
          background: #fff;
          border-radius: 7px; /* 10px * 0.7 */
          transition: all 0.3s ease-out;
        }
        @keyframes blink {
          0%   { height: 28px; top: 6px; }    
          10%  { height: 28px; top: 6px; }
          30%  { height: 1px;  top: 27px; }    
          70%  { height: 1px;  top: 27px; }
          90%  { height: 28px; top: 6px; }
          100% { height: 28px; top: 6px; }
        }
        .blinking {
          position: relative;
          animation: blink 0.3s ease-in-out forwards;
        }
        /* Laughing shape */
        .laughing {
          clip-path: path("M 0,0 H 15 V 32 Q 8 39 0 32 Z"); /* scaled from original */
          animation: laughUpDown 0.15s ease-in-out infinite;
        }
        @keyframes laughUpDown {
          0%   { transform: translateY(0); }
          50%  { transform: translateY(-4px); } /* -5px scaled */
          100% { transform: translateY(0); }
        }
      `}</style>
      <div
        className="face-floating floatUpDown"
        ref={faceContainerRef}
        onClick={runLaugh}
      >
        <div className="face">
          <div className="eye left">
            <div className="eye-inner"></div>
          </div>
          <div className="eye right">
            <div className="eye-inner"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
