import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { PopoverMenu } from '@/Components/v2/Menu/ButtonMenu';
import { prompts } from '@/data/prompts';
import { tracking } from '@/Services/tracking/Tracking';
import { Album } from 'lucide-react';

type Props = {
  disabled?: boolean;
  onSendMessage: (message: string) => void;
};

export const PredefinedMessages = ({ disabled, onSendMessage }: Props) => {
  const handlePrompt = (prompt: { title: string; prompt: string }) => {
    tracking.event('document_chat_quick_prompt', {
      title: prompt.title,
      prompt: prompt.prompt,
    });
    onSendMessage(prompt.prompt ?? '');
  };

  return (
    <PopoverMenu
      type="hover"
      items={prompts.map((prompt) => ({
        prependIcon: prompt.Icon,
        title: prompt.title,
        disabled,
        onClick: () => handlePrompt(prompt),
      }))}
      trigger={
        <IconButton
          onClick={(e) => {
            e.preventDefault();
          }}
          disabled={disabled}
          icon={Album}
        />
      }
    />
  );
};
