import { withRef, withVariants } from '@udecode/cn';
import { cva } from 'class-variance-authority';

import { PlateElement } from './plate-element';
import { DraggableElement } from './draggable-element';

const headingVariants = cva('relative mb-1', {
  variants: {
    variant: {
      h1: 'mb-1 font-heading text-2xl font-extrabold',
      h2: 'mb-3 font-heading text-2xl font-extrabold tracking-tight',
      h3: 'mb-3 font-heading text-xl font-extrabold tracking-tight',
      h4: 'mb-3 font-heading text-lg font-extrabold tracking-tight',
      h5: 'text-lg font-semibold tracking-tight',
      h6: 'font-semibold tracking-tight',
    },
  },
});

const HeadingElementVariants = withVariants(PlateElement, headingVariants, [
  'variant',
]);

export const HeadingElement = withRef<typeof HeadingElementVariants>(
  ({ children, variant = 'h1', ...props }, ref) => {
    return (
      <HeadingElementVariants
        ref={ref}
        as={variant!}
        variant={variant}
        {...props}
      >
        <DraggableElement element={props.element}>{children}</DraggableElement>
      </HeadingElementVariants>
    );
  },
);
