import {
  fetchGetGoogleMerchantCenter,
  useMerchantFeedSingle,
} from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { FeedSeoNotConfigured } from './FeedSeoNotConfigured';
import { ManageOptimizationGroups } from './ManageOptimizationGroups';
import { useEffect, useState } from 'react';
import { ProjectConnectionGoogleMerchantCenterResource } from '@/api/openapiSchemas';

export const FeedSeoLoader = () => {
  const appState = useAppStore();

  const [merchantCenterData, setMerchantCenterData] =
    useState<ProjectConnectionGoogleMerchantCenterResource>();

  const feedQuery = useMerchantFeedSingle(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      retry: false,
    },
  );

  useEffect(() => {
    fetchGetGoogleMerchantCenter({
      pathParams: {
        project: appState.currentProject!.id,
      },
    })
      .then((response) => {
        setMerchantCenterData(response.data ?? {});
      })
      .catch(() => {
        setMerchantCenterData({});
      });
  }, []);

  if (feedQuery.isLoading || !merchantCenterData) {
    return (
      <Menus>
        <PageContainer size="lg">
          <div className="flex h-screen flex-col gap-12 pt-96">
            <SkeletonLoader height="md" />
            <SkeletonLoader height="md" />
            <SkeletonLoader height="md" />
          </div>
        </PageContainer>
      </Menus>
    );
  }

  if (
    merchantCenterData?.state !== 'active' ||
    !(
      feedQuery.data?.data?.status === 'completed' ||
      feedQuery.data?.data?.status === 'deployed'
    )
  ) {
    return <FeedSeoNotConfigured feed={feedQuery.data?.data} />;
  }

  return <ManageOptimizationGroups feedId={feedQuery.data.data.id} />;
};
