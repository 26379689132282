import { cn, withRef } from '@udecode/cn';

import { PlateElement } from './plate-element';
import { DraggableElement } from './draggable-element';

export const BlockquoteElement = withRef<typeof PlateElement>(
  ({ children, className, ...props }, ref) => {
    return (
      <PlateElement
        ref={ref}
        as="blockquote"
        className={cn(className, 'my-1 w-full italic')}
        {...props}
      >
        <DraggableElement element={props.element}>
          <blockquote className="my-2 w-full border-l-2 border-secondary-600 bg-gray-100 py-5 pl-6 pr-2 text-lg leading-8">
            {children}
          </blockquote>
        </DraggableElement>
      </PlateElement>
    );
  },
);
