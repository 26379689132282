import React, { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ThumbsUp, X } from 'lucide-react';
import { SlideOverColors } from './utils';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';
import { Button } from '../v2/Button';
import { IconButton } from '../v2/IconButton/IconButton';

type Props = {
  title?: ReactNode;
  titleColor?: string;
  subHeading?: string;
  color?: SlideOverColors;
  dense?: boolean;
  onClose?: () => void;
  onClickOutside?: () => void;
  isOpen: boolean;
  handleButton?: {
    onClick: () => void;
    text: string;
    icon: React.ReactNode;
    isLoading?: boolean;
  };
  size?: 'xs' | 'sm' | 'md' | 'lg';
  side?: 'left' | 'right';
  children: React.ReactNode[] | React.ReactNode;
  readMoreUrl?: string;
  actions?: React.ReactNode;
};

export function SlideOver({
  title,
  subHeading,
  color,
  dense,
  handleButton,
  isOpen,
  size = 'sm',
  side = 'right',
  onClickOutside,
  onClose,
  titleColor = 'text-white',
  children,
  readMoreUrl,
  actions,
}: Props) {
  const [display, setDisplay] = useState(false);
  const [animationXOffset, setAnimationXOffset] = useState(448);

  useEffect(() => {
    if (isOpen) {
      setDisplay(true);
      setAnimationXOffset(0);
    } else {
      setAnimationXOffset(
        side === 'right' ? getSize().width : -getSize().width,
      );
    }
  }, [isOpen]);

  const handleAnimationEnd = () => {
    if (animationXOffset > 0 || animationXOffset < 0) {
      setDisplay(false);
    }
  };

  const getSize = () => {
    switch (size) {
      case 'xs':
        return { className: 'max-w-[288px]', width: 288 };
      case 'sm':
        return { className: 'max-w-md', width: 448 };
      case 'md':
        return { className: 'max-w-xl', width: 576 };
      case 'lg':
        return { className: 'max-w-3xl', width: 768 };
      default:
        return { className: 'max-w-md', width: 448 };
    }
  };

  return (
    <>
      {display
        ? createPortal(
            <>
              {onClickOutside && (
                <div
                  className={`fixed inset-0 z-50 bg-primary opacity-20 ${animationXOffset === 0 ? 'animate-fade-slow' : 'animate-fade-out'}`}
                  onClick={onClickOutside}
                ></div>
              )}
              <div
                className={`max space-between fixed bottom-0 ${side === 'right' ? 'right-0' : 'left-0'} top-0 z-50 flex h-[100vh] w-screen flex-col justify-between ${
                  getSize().className
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                <motion.div
                  className="relative flex h-full flex-col bg-white shadow-xl"
                  initial={{
                    x: side === 'right' ? getSize().width : -getSize().width,
                  }}
                  animate={{
                    x: animationXOffset,
                  }}
                  onAnimationComplete={handleAnimationEnd}
                  transition={{ type: 'easeout' }}
                >
                  {actions && (
                    <div className="absolute right-full top-0">{actions}</div>
                  )}
                  <div className="flex h-full flex-col overflow-hidden ">
                    {(title || subHeading) && (
                      <div
                        className={twMerge([
                          `${color} px-4 py-4 sm:px-6 ${titleColor}`,
                        ])}
                      >
                        <div className="flex items-center justify-between">
                          <h4 className="font-semibold uppercase leading-6">
                            {title}
                          </h4>
                          {onClose && (
                            <IconButton
                              onClick={onClose}
                              icon={X}
                              size="base"
                              color={color ? 'white' : 'gray'}
                            />
                          )}
                        </div>
                        <div className="mt-1">
                          <p>
                            {subHeading}{' '}
                            {readMoreUrl && (
                              <a
                                className="cursor-pointer text-white underline hover:opacity-60"
                                href={readMoreUrl}
                                target="_blank"
                              >
                                READ MORE
                              </a>
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                    <div
                      className={`flex-grow overflow-y-auto ${dense ? '' : 'px-6 py-4'}`}
                    >
                      {children}
                    </div>
                  </div>
                  {onClose && (
                    <div className="flex flex-shrink-0 justify-center gap-2 border-t-[1px] p-1.5">
                      <Button
                        text="Close"
                        variant="ghost"
                        onClick={onClose}
                        size={'xs'}
                      />
                      {handleButton && (
                        <Button
                          isLoading={handleButton.isLoading}
                          onClick={handleButton.onClick}
                          prependIcon={ThumbsUp}
                          text={handleButton.text}
                          color="secondary"
                          variant="outline"
                          size={'xs'}
                        />
                      )}
                    </div>
                  )}
                </motion.div>
              </div>
            </>,
            document.body,
          )
        : actions
          ? createPortal(
              <div className="absolute right-0 top-0">{actions}</div>,
              document.body,
            )
          : null}
    </>
  );
}
