import { AnimatedEllipsis } from '@/Components/AnimatedEllipsis';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';
import { ReactNode } from 'react';

type Props = {
  header?: string;
  before?: ReactNode;
  after?: ReactNode;
  hideBefore?: boolean;
  isLoading?: boolean;
  isFailed?: boolean;
};

export const DiffRow = ({
  isLoading,
  hideBefore,
  after,
  before,
  header,
  isFailed,
}: Props) => {
  return (
    <div className="flex border-b border-primary">
      <div className="flex-1 p-2">{header}</div>
      {!hideBefore && <div className="flex-[4] p-2">{before}</div>}
      <div className={`${hideBefore ? 'flex-[8]' : 'bor flex-[4]'} p-2`}>
        {isLoading ? (
          isFailed ? (
            <div className="mt-12 flex flex-col items-center gap-2">
              <div className="text-red">Something went wrong</div>
            </div>
          ) : (
            <div className="mt-12 flex flex-col items-center gap-2">
              <LoadingSpinner color="secondary" size="xl" />
              <div className="flex w-16">
                Thinking
                <AnimatedEllipsis />
              </div>
            </div>
          )
        ) : (
          after
        )}
      </div>
    </div>
  );
};
