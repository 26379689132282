import { create } from 'zustand';
import { InitialMessage } from './ChatPage';

type ChatStore = {
  initialMessage?: InitialMessage;
  setInitialMessage: (initialMessage?: InitialMessage) => void;
};

export const useChatStore = create<ChatStore>((set) => ({
  setInitialMessage: (initialMessage) => set({ initialMessage }),
}));
