'use client';

import { withCn } from '@udecode/cn';

import { Toolbar } from './toolbar';

export const FixedToolbar = withCn(
  Toolbar,
  'supports-backdrop-blur:bg-background/60 justify-between overflow-x-auto rounded-t-lg bg-background/95 p-1 backdrop-blur scrollbar-hide',
);
