import { useState } from 'react';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import DifficultyLabel from '@/Components/Labels/DifficultyLabel';
import { KeywordResearchHeader } from './components/v2/KeywordResearchHeader';
import { useTable } from '@/Components/Table/hooks';
import { CheckIcon, SearchIcon } from 'lucide-react';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { formatThousandSeperator, handleSplitOnPaste } from '@/utils';
import { Button } from '@/Components/v2/Button';
import {
  fetchProjectKeywordResearchSearchVolume,
} from '@/api/openapiComponents';
import { SlimKeywordResource } from '@/api/openapiSchemas';
import { KeywordResearchTableHeaderActions } from './components/KeywordResearchTableHeaderActions.tsx';
import { useKeywordExport } from './hooks';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import PageContainer from '@/Components/PageContainer';
import { useQuery } from '@tanstack/react-query';
import MonthlySearchesGraph from '@/Pages/Keywords/components/MonthlySearchesGraph.tsx';

export default function SearchVolume() {
  const appState = useAppStore();

  const [showTable, setShowTable] = useState(false);
  const [keywordsInput, setKeywordsInput] = useState('');

  const {
    tableState,
    setFilters,
    setSorting,
    selection,
    setSelection,
    resetSelection,
  } = useTable<
    'id' | 'name' | 'search_volume' | 'difficulty' | undefined,
    {
      keywords: string[];
    },
    SlimKeywordResource
  >({
    sortBy: 'search_volume',
    sortDirection: 'DESC',
  });

  const keywordQuery = useQuery({
    queryFn: () =>
      fetchProjectKeywordResearchSearchVolume({
        pathParams: { project: appState.currentProject!.id },
        body: {
          ...tableState,
          limit: 100,
          filters: {
            keywords:
              tableState?.filters?.keywords?.filter(
                (keyword) => keyword.trim() !== '',
              ) ?? [],
          },
        },
      }),
    queryKey: ['search_volume_keywords'],
    enabled: showTable,
  });

  const onExportCSV = useKeywordExport(
    {
      sort_by: tableState.sortBy,
      sort_direction: tableState.sortDirection,
      ids:
        keywordQuery.data?.data.map((keyword) => keyword.id.toString()) ?? [],
    },
    'search_volume_keywords',
  );

  const handleSubmit = () => {
    if (!tableState.filters) return;
    resetSelection();
    setShowTable(true);
    keywordQuery.refetch();
  };

  const isLoading = keywordQuery.isFetching;

  const errorHelper = new ErrorHelper(keywordQuery.error as any);

  appState.pageTitle('Search Volume');

  return (
    <Menus>
      <KeywordResearchHeader
        title="Search Volume"
        subtitle="Find search volume for your keywords"
        bgColor="bg-[#FAF5FF]"
        backgroundImage="/svg/keyword-research-search-volume.png"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="flex flex-col">
            <div className="flex-grow">
              <TextArea
                fullWidth
                rows={3}
                resize={true}
                counterMax={500}
                counter={
                  keywordsInput
                    .split('\n')
                    .filter((keyword) => keyword.trim() !== '').length
                }
                value={keywordsInput}
                onChange={(value) => {
                  setFilters({ keywords: value.split('\n') });
                  setKeywordsInput(value);
                }}
                onPaste={async (e) => {
                  const value = await handleSplitOnPaste(e, keywordsInput);
                  setKeywordsInput(value);
                }}
                placeholder="Keywords to search volume for..."
                hint="The keywords you want search volume for. One per line."
                error={
                  errorHelper.isError() ? errorHelper.message() : undefined
                }
              />
            </div>
            <div className="flex justify-end">
              <Button
                type="submit"
                text="Search"
                size="lg"
                color="green"
                isLoading={isLoading}
                prependIcon={SearchIcon}
              />
            </div>
          </div>
        </form>
      </KeywordResearchHeader>
      <div className={'flex-grow overflow-y-auto'}>
        <PageContainer size="lg">
          {/* Table */}
          {showTable && (
            <>
              <Table.Root
                selection={selection}
                onSelectionChange={setSelection}
                onSortChange={setSorting}
                sorting={tableState}
                isLoading={isLoading}
                skeletonLoaders={7}
                items={keywordQuery.data?.data}
                columns={[
                  {
                    sortableHeader: false,
                    rightAlign: false,
                    heading: '',
                    width: 1,
                    render: (item) => (
                      <>
                        {item.is_saved && (
                          <span className="text-secondary">
                            <CheckIcon></CheckIcon>
                          </span>
                        )}
                      </>
                    )
                  },
                  {
                    property: 'name',
                    sortableHeader: true,
                    heading: 'TARGET KEYWORD',
                  },
                  {
                    property: 'search_volume',
                    sortableHeader: true,
                    rightAlign: true,
                    heading: 'SEARCH VOLUME',
                    render: (item) => (
                      <>
                        {item.search_volume === 0
                          ? '< 10'
                          : formatThousandSeperator(item.search_volume)}
                      </>
                    ),
                  },
                  {
                    property: 'difficulty',
                    sortableHeader: true,
                    rightAlign: true,
                    heading: 'RANK DIFFICULTY',
                    render: (item) => (
                      <DifficultyLabel difficulty={item.difficulty} />
                    ),
                  },
                  {
                    property: 'monthly_searches',
                    sortableHeader: false,
                    heading: 'TREND',
                    rightAlign: true,
                    render: (item) => <MonthlySearchesGraph data={item.monthly_searches} />,
                  },
                ]}
              >
                <Table.Header csvExportOptions={onExportCSV}>
                  <KeywordResearchTableHeaderActions
                    selection={selection}
                    resetSelection={resetSelection}
                    setSelection={setSelection}
                    operationId="search_volume_keywords"
                  />
                  <Table.ResultsTotal
                    title="Keywords"
                    isLoading={isLoading}
                    total={keywordQuery.data?.data.length}
                  />
                </Table.Header>
                <Table.NoContent>
                  No keywords found. Try a different seed keyword.
                </Table.NoContent>
              </Table.Root>
            </>
          )}
        </PageContainer>
      </div>
    </Menus>
  );
}
