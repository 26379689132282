import { formatThousandSeperator } from '@/utils';
import { ReactNode } from 'react';

type Props = {
  feature: ReactNode;
  disabled?: boolean;
  isUnlimitedAfter?: number;
};

export const FeatureItem = ({ feature, disabled, isUnlimitedAfter }: Props) => {
  const processWord = (word: string) => {
    if (!Number.isNaN(Number(word)) || word.toLowerCase() === 'unlimited') {
      return (
        <>
          <b>
            {isUnlimitedAfter !== undefined && Number(word) > isUnlimitedAfter
              ? 'Unlimited'
              : !Number.isNaN(Number(word))
                ? formatThousandSeperator(Number(word))
                : word}
          </b>{' '}
        </>
      );
    }

    return <>{word} </>;
  };

  return (
    <p className={`${disabled ? 'text-primary-400 line-through' : ''}`}>
      {typeof feature === 'string'
        ? feature.split(' ').map(processWord)
        : feature}
    </p>
  );
};
