import { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Fragment } from 'react';

type Props = {
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  lastPage?: number;
  disabled?: boolean;
};

export default function Pagination({
  currentPage,
  setCurrentPage,
  lastPage,
  disabled,
}: Props) {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (lastPage) {
      setTotalPages(lastPage);
    }
  }, [lastPage]);

  const getMiddlePages = () => {
    let inner = Array.from({ length: 5 }).map(
      (_, index) => index + currentPage - 2,
    );

    while (inner[0] <= 1) {
      inner = inner.map((page) => page + 1);
    }
    while (inner[inner.length - 1] >= totalPages) {
      inner = inner.map((page) => page - 1);
    }

    return inner.filter((page) => page > 1 && page < totalPages);
  };

  const pages = [1, ...getMiddlePages()];

  if (totalPages > 1) {
    pages.push(totalPages);
  }

  return (
    <div className="unselectable flex w-full justify-center">
      <div className="flex w-max justify-center">
        {pages.includes(currentPage - 1) && (
          <button
            disabled={disabled}
            className="flex cursor-pointer items-center rounded-l-md border-b border-l border-t px-1 hover:bg-gray-50"
            onClick={() => setCurrentPage(currentPage - 1)}
            type="button"
          >
            <ChevronLeft color="#5B5B66" size={20} />
          </button>
        )}

        {pages.map((page, index) => (
          <Fragment key={page}>
            {page > 1 && pages[index - 1] !== page - 1 && (
              <div
                className={`px-2 ${
                  currentPage > page ? 'border-l' : 'border-r'
                }`}
              >
                ...
              </div>
            )}
            <button
              disabled={disabled}
              onClick={() => setCurrentPage(page)}
              key={index}
              type="button"
              className={`flex w-8 items-center justify-center px-2 py-0.5 ${
                currentPage === 1 && page === 1 ? 'rounded-l-md' : ''
              } ${
                currentPage === lastPage && page === lastPage
                  ? 'rounded-r-md'
                  : ''
              } ${
                page === currentPage
                  ? 'border border-emerald-400 bg-emerald-50 text-emerald-400 '
                  : `cursor-pointer border-b border-t hover:bg-gray-50 ${
                      currentPage > page ? 'border-l' : 'border-r'
                    }`
              } `}
            >
              {page}
            </button>
          </Fragment>
        ))}

        {pages.includes(currentPage + 1) && (
          <button
            disabled={disabled}
            className="flex cursor-pointer items-center rounded-r-md border-b  border-r border-t px-1 hover:bg-gray-50"
            onClick={() => setCurrentPage(currentPage + 1)}
            type="button"
          >
            <ChevronRight color="#5B5B66" size={20} />
          </button>
        )}
      </div>
    </div>
  );
}
