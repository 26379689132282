import {
  AnalyticsGranularity,
  AnalyticsMerchantCenterProductGroupResource,
  MerchantCenterQueryDimension,
  MerchantCenterQueryFilter,
  MerchantCenterQueryResource,
  ProjectResource
} from '@/api/openapiSchemas';
import { useState } from 'react';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useQuery } from '@tanstack/react-query';
import {
  fetchAnalyticsMerchantCenterQuery,
  fetchProjectsAnalyticsGoogleMerchantCenterFeedGroupsShow,
  useAnalyticsMerchantCenterQuery,
} from '@/api/openapiComponents';
import { AnalyticsRange, getDateParams } from '@/Pages/Analytics/utils';

export type GraphState = {
  isGMCEnabled: boolean;
  range: AnalyticsRange;
  clicks: boolean;
  impressions: boolean;
  ctr: boolean;
  position: boolean;
  granularity: AnalyticsGranularity;
  country: {
    iso_3166_3: string;
    iso_3166_2: string;
  };
};

export const getInitialGraphState = (project: ProjectResource) => {
  const data = localStorage.getItem(`product_analytics_graph_state_for_project_${project.id!}`);
  if (data) {
    return JSON.parse(data) as GraphState;
  } else {
    return {
      isGMCEnabled: false,
      range: 'P3M' as AnalyticsRange,
      clicks: true,
      impressions: true,
      ctr: false,
      position: false,
      granularity: 'week' as const,
      country: {
        iso_3166_2: 'xx',
        iso_3166_3: 'xxx',
      },
    };
  }
};

export const useGraphState = () => {
  const appState = useAppStore();

  const [graphState, setGraphState] = useState(
    getInitialGraphState(appState.currentProject!),
  );

  const handleSetState = (newState: GraphState) => {
    localStorage.setItem(`product_analytics_graph_state_for_project_${appState.currentProject!.id}`, JSON.stringify(newState));

    setGraphState((prevState: GraphState) => ({
      ...prevState,
      ...newState,
    }));
  };

  return [graphState, handleSetState] as const;
};

export const useAnalyticsGroups = (
  projectId: number,
  state: GraphState,
  groups: AnalyticsMerchantCenterProductGroupResource[],
  isLoaded: boolean,
) => {
  return useQuery({
    queryKey: ['product_analytics_page_groups', projectId, state.country, state.range],
    enabled: isLoaded,
    queryFn: async () => {
      const returnData: any[] = [];

      const func = async (item: AnalyticsMerchantCenterProductGroupResource) => {

      const conditions = item.conditions?.map(cond => (
          { dimension: cond.field as MerchantCenterQueryDimension,
            operator: cond.operator as MerchantCenterQueryFilter, expression: cond.value })) ?? [];

      const filters = [
        ...conditions,
      ];

      if ((state.country?.iso_3166_2 ?? 'xx') !== 'xx') {
        filters.push({
          dimension: 'customer_country_code',
          operator: 'equals',
          expression: state.country.iso_3166_2,
        });
      }


        const current = await fetchAnalyticsMerchantCenterQuery({
          pathParams: {
            project: projectId,
          },
          queryParams: {
            ...getDateParams(state.range),
            dimensions: ['title'],
            dimension_filter_groups: filters,
          },
        });

        const previous = await fetchAnalyticsMerchantCenterQuery({
          pathParams: {
            project: projectId,
          },
          queryParams: {
            ...getDateParams(state.range, true),
            dimensions: ['title'],
            dimension_filter_groups: filters,
          },
        });

        return {
          name: item.name,
          slug: item.slug,
          current: current.data[0] ?? {
            clicks: 0,
            impressions: 0,
            ctr: 0,
            keys: []
          } as unknown as MerchantCenterQueryResource,
          previous: previous.data[0],
        };
      };

      await Promise.all(
        groups.map((group) =>
          func(group).then((data) => returnData.push(data)),
        ),
      );

      return returnData;
    },
  });
};

export const useAnalyticsFeedSeoGroups = (
  projectId: number,
  state: GraphState,
  groups: number[] | string[],
  isLoaded: boolean,
) => {
  return useQuery({
    queryKey: ['product_analytics_feed_groups', projectId, state.country, state.range],
    enabled: isLoaded,
    queryFn: async () => {
      const returnData: any[] = [];

      const func = async (item: number | string) => {

        const itemsResponse = await fetchProjectsAnalyticsGoogleMerchantCenterFeedGroupsShow({
          pathParams: {
            project: projectId,
            merchantFeedGroup: Number(item),
          }
        })

        const filters: {
          dimension: MerchantCenterQueryDimension;
          operator: MerchantCenterQueryFilter;
          expression: string;
        }[] = [
            {
              dimension: 'offer_id' as MerchantCenterQueryDimension,
              operator: 'in' as MerchantCenterQueryFilter,
              expression: itemsResponse.data.ids!.join(',')
            }
        ];

        if ((state.country?.iso_3166_2 ?? 'xx') !== 'xx') {
          filters.push({
            dimension: 'customer_country_code',
            operator: 'equals',
            expression: state.country.iso_3166_2,
          });
        }

        const current = await fetchAnalyticsMerchantCenterQuery({
          pathParams: {
            project: projectId,
          },
          queryParams: {
            ...getDateParams(state.range),
            dimension_filter_groups: filters,
          },
        });

        const previous = await fetchAnalyticsMerchantCenterQuery({
          pathParams: {
            project: projectId,
          },
          queryParams: {
            ...getDateParams(state.range, true),
            dimension_filter_groups: filters,
          },
        });

        return {
          name: itemsResponse.data.name,
          slug: itemsResponse.data.id.toString(),
          current: current.data[0] ?? {
            clicks: 0,
            impressions: 0,
            ctr: 0,
            keys: []
          } as unknown as MerchantCenterQueryResource,
          previous: previous.data[0],
        };
      };

      await Promise.all(
        groups.map((group) =>
          func(group).then((data) => returnData.push(data)),
        ),
      );

      return returnData;
    },
  });
};

export const useGrowthSorting = (key: string) => {
  const [growthSorting, setGrowthSorting] = useState<
    'popular' | 'rising' | 'falling'
  >((localStorage.getItem(key + 'product_analytics_growth_sorting') ?? 'popular') as 'popular' | 'rising' | 'falling');

  const handleGrowthSorting = (newState: 'popular' | 'rising' | 'falling') => {
    localStorage.setItem(key + 'product_analytics_growth_sorting', newState);
    setGrowthSorting(newState);
  };

  return [growthSorting, handleGrowthSorting] as const;
};

export type AnalyticsTotalsData = {
  isLoading: boolean;
  data?: MerchantCenterQueryResource;
  previousData?: MerchantCenterQueryResource;
}

export const useAnalyticsTotals = (state: GraphState, options?: {
  groupFilters?: {
    dimension: MerchantCenterQueryDimension;
    operator: MerchantCenterQueryFilter;
    expression: string;
  }[],
  page?: {
    type: 'brand' | 'title' | 'offer_id';
    dimension?: string;
    id: string;
  },
  loaded?: boolean;
}) => {
  const appState = useAppStore();

  const filters = [];

  if ((state.country?.iso_3166_2 ?? 'xx') !== 'xx') {
    filters.push({
      dimension: 'customer_country_code' as MerchantCenterQueryDimension,
      operator: 'equals' as MerchantCenterQueryFilter,
      expression: state.country?.iso_3166_2,
    });
  }

  if (options?.page) {
    filters.push({
      dimension: options.page.type as MerchantCenterQueryDimension,
      operator: 'equals' as MerchantCenterQueryFilter,
      expression: options.page.dimension ?? options.page.id,
    });
  }

  if (options?.groupFilters) {
    filters.push(...options.groupFilters);
  }

  const currentQuery = useAnalyticsMerchantCenterQuery({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      ...getDateParams(state.range),
      dimension_filter_groups: filters,
    },
  }, {
    enabled: state.isGMCEnabled && options?.loaded === true,
  });

  const beforeQuery = useAnalyticsMerchantCenterQuery({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      ...getDateParams(state.range, true),
      dimension_filter_groups: filters,
    },
  }, {
    enabled: state.isGMCEnabled && options?.loaded === true,
  });

  return {
    isLoading: currentQuery.isLoading || beforeQuery.isLoading,
    data: currentQuery.data?.data[0],
    previousData: beforeQuery.data?.data[0],
  } as AnalyticsTotalsData;
}

export const MapProductData = (data1: MerchantCenterQueryResource[], data2: MerchantCenterQueryResource[]): MerchantCenterQueryResource[] => {
  if (data1.length === 0 || data2.length === 0) {
    return [];
  }

  const map = new Map<string, MerchantCenterQueryResource>();

  data1.forEach((item1) => {
    data2.forEach((item2) => {
      if (!map.has(item1.offer_id) && item1.offer_id == item2.offer_id) {
        map.set(item1.offer_id, {
          ...item1,
          keys: item2.keys,
        });
      }
    })
  });

  return Array.from(map.values());
}
