import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import * as Table from '@/Components/Table';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { Button } from '@/Components/v2/Button';
import { useEffect, useState } from 'react';
import { AnalyticsGroupResource } from '@/api/openapiSchemas';
import { EditGroupDialog } from './components/EditGroupDialog';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Group, Pencil, Trash2 } from 'lucide-react';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useDeletePageGroup, useListPageGroups } from '@/api/openapiComponents';
import { Event, events } from '@/events';
import { useNavigate } from '@tanstack/react-router';
import Pagination from '@/Components/ProjectKeywords/Pagination';

export const ManageGroups = () => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [groupToBeDeleted, setGroupToBeDeleted] =
    useState<AnalyticsGroupResource>();
  const [createNewGroup, setCreateNewGroup] = useState(false);
  const [groupToBeEdited, setGroupToBeEdited] =
    useState<AnalyticsGroupResource>();

  useEffect(() => {
    const handler = () => {
      navigate({ to: '/page-analytics', search: { page: 1, keywordPage: 1 } });
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  const deletePageGroupMutation = useDeletePageGroup();
  const pageGroupsQuery = useListPageGroups({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      page,
    },
  });

  return (
    <>
      <ConfirmDialog
        isOpen={!!groupToBeDeleted}
        title={`Delete group`}
        content={[
          'Are you sure you want to delete the group "',
          groupToBeDeleted?.name,
          '"?',
        ]}
        isLoading={deletePageGroupMutation.isPending}
        onClose={() => setGroupToBeDeleted(undefined)}
        onConfirm={() => {
          deletePageGroupMutation.mutate(
            {
              pathParams: {
                project: appState.currentProject!.id,
                analyticsPageGroup: groupToBeDeleted!.slug,
              },
            },
            {
              onSuccess: () => {
                pageGroupsQuery.refetch();
                setGroupToBeDeleted(undefined);
              },
            },
          );
        }}
      />
      <EditGroupDialog
        isOpen={createNewGroup}
        existingGroup={groupToBeEdited}
        onSuccess={() => pageGroupsQuery.refetch()}
        onClose={() => setCreateNewGroup(false)}
      />
      <Menus>
        <PageContainer
          canGoBack
          title="Manage groups"
          actions={
            <Button
              text="Add new group"
              color="secondary"
              onClick={() => {
                setCreateNewGroup(true);
                setGroupToBeEdited(undefined);
              }}
            />
          }
        >
          <Table.Root
            isLoading={pageGroupsQuery.isLoading}
            items={pageGroupsQuery.data?.data ?? []}
            columns={[
              {
                heading: 'Group',
                render: (item) => <div className="min-w-32">{item.name}</div>,
              },
              {
                heading: 'Rules',
                expanded: true,
                render: (item) =>
                  item.conditions
                    ?.map((c) => c.operator + ': ' + c.expression)
                    .join(', '),
              },
              {
                render: (item) => (
                  <div className="flex gap-1">
                    <IconButton
                      icon={Pencil}
                      onClick={() => {
                        setGroupToBeEdited(item);
                        setCreateNewGroup(true);
                      }}
                    />
                    <IconButton
                      icon={Trash2}
                      onClick={() => setGroupToBeDeleted(item)}
                    />
                  </div>
                ),
              },
            ]}
          >
            <Table.Header></Table.Header>
            <Table.NoContent>
              <div className="flex flex-col items-center gap-4">
                <Group size={128} className="text-primary-200" />
                <p className="text-lg font-bold text-primary">No page groups</p>
              </div>
            </Table.NoContent>
            <Table.Footer>
              <Pagination
                currentPage={page}
                setCurrentPage={setPage}
                lastPage={pageGroupsQuery.data?.meta.last_page ?? 1}
              />
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
};
