import * as Table from '@/Components/Table';
import { ValueWithGrowth } from '@/Pages/Analytics/components/ValueWithGrowth';
import { GrowthValueHeader } from '@/Pages/Analytics/components/GrowthValueHeader';
import { useAnalyticsMerchantCenterQuery } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import {
  getDateParams,
  calculateGrowth,
  convertData,
  convertKey,
  sortAnalyticsData,
} from '@/Pages/Analytics/utils';
import { CellWithBar } from '@/Pages/Analytics/components/CellWithBar';
import PageContainer from '@/Components/PageContainer';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { Menus } from '@/Components/Menus';
import {
  useAnalyticsTotals,
  useGraphState,
  useGrowthSorting,
  MapProductData,
} from './hooks';
import ToggleButton from '@/Components/ToggleButton';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { AnalyticsFilters } from './components/AnalyticsFilters';
import { AnalyticsUrlActions } from '@/Pages/Analytics/components/AnalyticsUrlActions';
import {
  MerchantCenterQueryDimension,
  MerchantCenterQueryFilter,
} from '@/api/openapiSchemas.ts';
import { useConnectToGoogleMerchantCenter } from '@/Pages/Settings/hooks.ts';
import { useEffect, useState } from 'react';
import { GoogleMerchantCenterConnect } from '@/Components/Table/components/GoogleMerchantCenterConnect.tsx';
import { Event, events } from '@/events';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';

export const ProductDetails = () => {
  const appState = useAppStore();

  const navigate = useNavigate({ from: '/product-analytics/page/$pageId' });
  const params = useParams({ from: '/product-analytics/page/$pageId' });
  const search = useSearch({ from: '/product-analytics/page/$pageId' });

  const [graphState, setGraphState] = useGraphState();
  const [productColumnSorting, setProductColumnSorting] = useState('clicks');
  const [growthSorting, setGrowthSorting] = useGrowthSorting('pagedetails');

  useEffect(() => {
    const handler = () => {
      navigate({
        to: '/product-analytics',
        search: { page: 1, keywordPage: 1 },
      });
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  const gscHookProps = useConnectToGoogleMerchantCenter(
    appState.currentProject!.id,
  );

  useEffect(() => {
    const handler = () => {
      navigate({
        to: '/product-analytics',
        search: { page: 1, keywordPage: 1 },
      });
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  useEffect(() => {
    setGraphState({
      ...graphState,
      isGMCEnabled:
        gscHookProps.googleMerchantCenterData?.data.settings.account !== null &&
        !gscHookProps.googleMerchantCenterError &&
        !gscHookProps.isGettingGoogleMerchantCenterData,
    });
  }, [
    gscHookProps.googleMerchantCenterError,
    gscHookProps.googleMerchantCenterData,
    gscHookProps.isGettingGoogleMerchantCenterData,
  ]);

  const filters = [];

  if ((graphState.country?.iso_3166_2 ?? 'xx') !== 'xx') {
    filters.push({
      dimension: 'customer_country_code' as MerchantCenterQueryDimension,
      operator: 'equals' as MerchantCenterQueryFilter,
      expression: graphState.country?.iso_3166_2,
    });
  }

  if (params.pageId) {
    filters.push({
      dimension: search.type as MerchantCenterQueryDimension,
      operator: 'equals' as MerchantCenterQueryFilter,
      expression: search.dimension ?? params.pageId,
    });
  }

  const totals = useAnalyticsTotals(graphState, {
    page: { type: search.type, id: params.pageId, dimension: search.dimension },
    loaded: true,
  });

  const currentQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range),
        dimensions: ['offer_id'],
        dimension_filter_groups: filters,
      },
    },
    {
      enabled: search.type === 'brand',
    },
  );

  const previousQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['offer_id'],
        dimension_filter_groups: filters,
      },
    },
    {
      enabled: search.type === 'brand',
    },
  );

  const currentWithTitleQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range),
        dimensions: ['title', 'offer_id'],
        dimension_filter_groups: filters,
      },
    },
    {
      enabled: search.type === 'brand',
    },
  );

  const previousWithTitleQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['title', 'offer_id'],
        dimension_filter_groups: filters,
      },
    },
    {
      enabled: search.type === 'brand',
    },
  );

  if (gscHookProps.isGettingGoogleMerchantCenterData) {
    return (
      <Menus>
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner color="secondary" size="2xl" />
        </div>
      </Menus>
    );
  }

  if (!graphState.isGMCEnabled) {
    return (
      <Menus>
        <PageContainer
          title={`${['title', 'offer_id'].includes(search.type ?? '') ? 'Product' : 'Brand'}: ${params.pageId}`}
          canScroll={false}
        >
          <div className="relative h-screen w-full overflow-hidden">
            <img
              src="/svg/analytics_placeholder.png"
              className="aspect-square w-full blur-sm"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="-mt-[420px] w-full">
                {gscHookProps && (
                  <GoogleMerchantCenterConnect
                    {...gscHookProps}
                    page="Product analytics"
                    message="Connect to Google Merchant Center to see your shop's product feed performance."
                  />
                )}
              </div>
            </div>
          </div>
        </PageContainer>
      </Menus>
    );
  }

  const convertedPreviousQuery = MapProductData(
    convertKey('offer_id', previousQuery.data?.data ?? [], 0),
    convertKey('offer_id', previousWithTitleQuery.data?.data ?? [], 1),
  );

  const convertedCurrentQuery = MapProductData(
    convertKey('offer_id', currentQuery.data?.data ?? [], 0),
    convertKey('offer_id', currentWithTitleQuery.data?.data ?? [], 1),
  );

  const data = convertData(
    {
      current: convertKey('title', convertedCurrentQuery, 0),
      previous: convertKey('title', convertedPreviousQuery, 0),
    },
    'title',
  );

  const mostClicks =
    data?.toSorted(
      (a, b) => (b.current?.clicks ?? 0) - (a.current?.clicks ?? 0),
    )[0]?.current.clicks ?? 0;

  const handleOpenPage = (page: string, dimension?: string) => {
    navigate({
      to: `/product-analytics/page/$pageId`,
      params: { pageId: page },
      // @ts-ignore
      search: { page: 1, type: 'offer_id', dimension },
    });
  };

  return (
    <Menus>
      <PageContainer
        title={`${['title', 'offer_id'].includes(search.type ?? '') ? 'Product' : 'Brand'}: ${params.pageId}`}
        canGoBack
      >
        <AnalyticsFilters
          state={graphState}
          onStateChange={setGraphState}
          totals={totals}
        />
        <AnalyticsGraph
          state={graphState}
          isLoading={false}
          page={{
            type: search.type,
            dimension: search.dimension,
            id: params.pageId,
          }}
        />

        {search.type === 'brand' && (
          <Table.Root
            isLoading={currentQuery.isLoading}
            skeletonLoaders={20}
            items={sortAnalyticsData(
              data ?? [],
              growthSorting,
              productColumnSorting,
            )?.slice((search.page - 1) * 20, search.page * 20)}
            columns={[
              {
                heading: 'title',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    value={item.current.clicks}
                    topValue={mostClicks}
                    name={item.current.title}
                    onClick={() =>
                      handleOpenPage(item.current.title, item.current.offer_id)
                    }
                    actions={
                      <AnalyticsUrlActions
                        onOpen={() =>
                          handleOpenPage(
                            item.current.title,
                            item.current.offer_id,
                          )
                        }
                      />
                    }
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Clicks"
                    onClick={() => setProductColumnSorting('clicks')}
                    selected={productColumnSorting === 'clicks'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Impressions"
                    onClick={() => setProductColumnSorting('impressions')}
                    selected={productColumnSorting === 'impressions'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="CTR"
                    onClick={() => setProductColumnSorting('ctr')}
                    selected={productColumnSorting === 'ctr'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <div className="mt-10 flex items-center gap-4">
                <h1 className="mb-1 text-xl font-semibold">Products</h1>
                <ToggleButton
                  value={growthSorting}
                  onChange={setGrowthSorting}
                  options={[
                    {
                      displayName: 'Popular',
                      value: 'popular',
                    },
                    {
                      displayName: 'Rising',
                      value: 'rising',
                    },
                    {
                      displayName: 'Falling',
                      value: 'falling',
                    },
                  ]}
                />
              </div>
            </Table.Header>
            <Table.Footer>
              <Pagination
                currentPage={search.page ?? 1}
                lastPage={Math.floor(data.length / 20)}
                setCurrentPage={(pageNumber) =>
                  navigate({
                    search: { ...search, page: pageNumber },
                    replace: true,
                  })
                }
              />
            </Table.Footer>
          </Table.Root>
        )}
      </PageContainer>
    </Menus>
  );
};
