import { Destination } from '../Destinations';
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';

let customerIo: AnalyticsBrowser;

export const customerIoDestination: Destination = {
  name: 'customerio',
  enabledByEnvKey: 'VITE_CUSTOMER_IO_WRITE_KEY',
  initialize: () => {
    customerIo = AnalyticsBrowser.load(
      {
        writeKey: import.meta.env.VITE_CUSTOMER_IO_WRITE_KEY,
      },
      {
        integrations: {
          'Customer.io In-App Plugin': {
            siteId: import.meta.env.VITE_CUSTOMER_IO_SITE_ID,
          },
        },
      },
    );
  },
  identify: (user) => {
    customerIo.identify('' + user.id, { email: user.email });
  },
  reset: () => {
    customerIo.reset();
  },
  resetGroup: () => {},
  group: (group, payload) => {},
  trackEvent: (event) => {
    if (event.name === 'page') {
      customerIo.page();
    } else {
      customerIo.track(event.name, event.payload);
    }
  },
};
