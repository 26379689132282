import {
  MarkToolbarButton,
  TurnIntoDropdownMenu,
  LinkToolbarButton,
  ToolbarGroup,
  ListToolbarButton,
  ToolbarButton,
} from '../components';
import { BoldPlugin, ItalicPlugin } from '@udecode/plate-basic-marks/react';
import { useQuery } from '@tanstack/react-query';
import { Generating } from '@/types';
import {
  ParagraphPlugin,
  useEditorReadOnly,
  useEditorState,
} from '@udecode/plate/react';
import {
  BoldIcon,
  ItalicIcon,
  QuoteIcon,
  WandSparklesIcon,
} from 'lucide-react';
import { AIToolbarButton } from './ai-toolbar-button';
import {
  BulletedListPlugin,
  NumberedListPlugin,
} from '@udecode/plate-list/react';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { setBlockType } from '../lib/transforms';

export function FloatingToolbarButtons() {
  const readOnly = useEditorReadOnly();
  const { hasFeature } = useFeatureFlagging();

  const editor = useEditorState();

  const { data: isGenerating } = useQuery<Generating>({
    queryKey: ['autoGenerationStream'],
  });

  const toggleBlockQuote = () => {
    const block = editor.api.block();

    setBlockType(
      editor,
      block?.[0].type === BlockquotePlugin.key
        ? ParagraphPlugin.key
        : BlockquotePlugin.key,
    );
  };

  return (
    <>
      {!readOnly && (
        <>
          {hasFeature('ask-ai') && (
            <ToolbarGroup>
              <AIToolbarButton tooltip="AI commands">
                <WandSparklesIcon />
                Ask AI
              </AIToolbarButton>
            </ToolbarGroup>
          )}

          <ToolbarGroup>
            <TurnIntoDropdownMenu />

            <MarkToolbarButton nodeType={BoldPlugin.key} tooltip="Bold (⌘+B)">
              <BoldIcon />
            </MarkToolbarButton>

            <MarkToolbarButton
              nodeType={ItalicPlugin.key}
              tooltip="Italic (⌘+I)"
            >
              <ItalicIcon />
            </MarkToolbarButton>
          </ToolbarGroup>

          <ToolbarGroup>
            {' '}
            <ListToolbarButton nodeType={BulletedListPlugin.key} />
            <ListToolbarButton nodeType={NumberedListPlugin.key} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarButton onClick={toggleBlockQuote}>
              <QuoteIcon />
            </ToolbarButton>
          </ToolbarGroup>
        </>
      )}
      <ToolbarGroup>
        <LinkToolbarButton disabled={!!isGenerating} />
      </ToolbarGroup>
    </>
  );
}
