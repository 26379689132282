import {
  useCreateMerchantCenterProductGroup,
  useTestMerchantCenterGroupFilters,
  useUpdateMerchantCenterProductGroup,
} from '@/api/openapiComponents';
import {
  AnalyticsMerchantCenterProductGroupResource,
  MerchantCenterQueryDimension,
  MerchantCenterQueryFilter,
} from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import * as Table from '@/Components/Table';
import { ErrorAlert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { useDebounce } from '@/Hooks/useDebounce';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { Plus, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  existingGroup?: AnalyticsMerchantCenterProductGroupResource;

  onClose: () => void;
  onSuccess: () => void;
};

type InternalRule = {
  value: string;
  field: 'brand' | 'title' | null;
  operator: string | null;
};

export const EditGroupDialog = ({
  isOpen,
  onClose,
  existingGroup,
  onSuccess,
}: Props) => {
  const appState = useAppStore();

  const [rules, setRules] = useState<InternalRule[]>([]);
  const [nameInput, setNameInput] = useState('');

  const createGroupMutation = useCreateMerchantCenterProductGroup();
  const updateGroupMutation = useUpdateMerchantCenterProductGroup();
  const testMutation = useTestMerchantCenterGroupFilters();

  const handleTestFilters = () => {
    if (
      rules.some(
        (rule) =>
          rule.value === '' && rule.field === null && rule.operator === null,
      )
    ) {
      return;
    }

    testMutation.mutate({
      pathParams: {
        project: appState.currentProject!.id,
      },
      body: {
        filters: [
          ...rules.map((rule) => ({
            dimension: rule.field as MerchantCenterQueryDimension,
            operator: rule.operator as MerchantCenterQueryFilter,
            expression: rule.value as string,
          })),
        ],
      },
    });
  };

  useEffect(() => {
    testMutation.reset();
    updateGroupMutation.reset();
    if (existingGroup?.conditions) {
      setNameInput(existingGroup.name);
      setRules(
        existingGroup.conditions.map((condition) => condition as InternalRule),
      );
    } else {
      setNameInput('');
      setRules([{ value: '', field: null, operator: null }]);
    }
  }, [existingGroup]);

  useDebounce(handleTestFilters, [rules]);

  const handleClose = () => {
    testMutation.reset();
    updateGroupMutation.reset();
    createGroupMutation.reset();
    setNameInput('');
    setRules([]);
    onClose();
  };

  const handleSave = () => {
    const body = {
      name: nameInput,
      conditions: rules.map((rule) => ({
        field: rule.field as 'title' | 'brand',
        operator: rule.operator as MerchantCenterQueryFilter,
        expression: rule.value,
      })),
    };

    if (existingGroup) {
      updateGroupMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
            merchantCenterProductGroup: existingGroup.slug,
          },
          body,
        },
        {
          onSuccess: () => {
            onSuccess();
            handleClose();
          },
        },
      );
    } else {
      createGroupMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
          },
          body,
        },
        {
          onSuccess: () => {
            onSuccess();
            handleClose();
          },
        },
      );
    }
  };

  const errorHelper = new ErrorHelper(
    createGroupMutation.error ?? updateGroupMutation.error,
  );

  return (
    <Dialog
      size="lg"
      title={existingGroup ? `Edit ${existingGroup.name}` : 'New page group'}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <InputDecoration label="Name" required>
        <Input
          value={nameInput}
          onChange={setNameInput}
          placeholder="Name of the group..."
        />
      </InputDecoration>
      <Table.Root
        items={rules}
        columns={[
          {
            heading: 'Field',
            render: (rule, __, ___, index) => (
              <div className="relative flex h-full items-center gap-2">
                <Select
                  onChange={(value) => {
                    setRules((prev) =>
                      prev.map((r, i) =>
                        i === index
                          ? { ...r, field: value as InternalRule['field'] }
                          : r,
                      ),
                    );
                  }}
                  placeholder={'Select field'}
                  value={rule.field}
                  options={[
                    {
                      title: 'Title',
                      value: 'title',
                    },
                    {
                      title: 'Brand',
                      value: 'brand',
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            heading: 'Condition',
            render: (rule, __, _, index) => (
              <div className="relative flex h-full items-center gap-2">
                <Select
                  onChange={(value) => {
                    setRules((prev) =>
                      prev.map((r, i) =>
                        i === index ? { ...r, operator: value as string } : r,
                      ),
                    );
                  }}
                  placeholder={'Select operator'}
                  value={rule.operator}
                  options={[
                    {
                      title: 'Contains',
                      value: 'contains',
                    },
                    {
                      title: 'Does not contain',
                      value: 'notContains',
                    },
                    {
                      title: 'Equals',
                      value: 'equals',
                    },
                    {
                      title: 'Does not equal',
                      value: 'notEquals',
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            heading: 'Value',
            render: (rule, __, _, index) =>
              rule.operator === 'includingRegex' ? (
                <TextArea
                  placeholder="URLs to match..."
                  onChange={(value) => {
                    setRules((prev) =>
                      prev.map((r, i) =>
                        i === index ? { ...r, value: value } : r,
                      ),
                    );
                  }}
                  counter
                  counterMax={3000}
                  error={errorHelper.has(`conditions.${index}.expression`)}
                  value={rule.value}
                />
              ) : (
                <Input
                  placeholder="Values of the condition..."
                  error={errorHelper.has(`conditions.${index}.expression`)}
                  onChange={(value) => {
                    setRules((prev) =>
                      prev.map((r, i) =>
                        i === index ? { ...r, value: value } : r,
                      ),
                    );
                  }}
                  value={rule.value}
                />
              ),
          },
          {
            render: (_, __, ___, index) => (
              <IconButton
                icon={Trash2}
                disabled={rules.length === 1}
                onClick={() =>
                  setRules((prev) => prev.filter((_, i) => index !== i))
                }
              />
            ),
          },
        ]}
      >
        <Table.Footer>
          <div className="-mt-4 flex w-full justify-between">
            <Button
              text="Add condition"
              prependIcon={Plus}
              variant="ghost"
              onClick={() =>
                setRules((prev) => [
                  ...prev,
                  { value: '', operator: null, field: 'title' },
                ])
              }
            />
            <Button
              text="Save"
              onClick={handleSave}
              color="secondary"
              isLoading={
                createGroupMutation.isPending || updateGroupMutation.isPending
              }
            />
          </div>
        </Table.Footer>
      </Table.Root>
      <div className="my-2 flex w-full">
        {errorHelper.isError() && <ErrorAlert title={errorHelper.message()} />}
      </div>
      <div className="min-h-[450px]">
        {testMutation.data?.data ? (
          <div className="flex flex-col">
            <h1 className="font-bold">
              Matches (
              {testMutation.data.data.length >= 100
                ? `100+`
                : testMutation.data.data.length}
              )
            </h1>
            {testMutation.data.data.slice(0, 20).map((result) => (
              <span
                rel="noreferrer"
                className="hover:opacity-60"
                key={result.keys[0]}
              >
                {result.keys[0]}
              </span>
            ))}
            {testMutation.data.data.length > 20 && (
              <div>
                And{' '}
                {testMutation.data.data.length >= 100
                  ? '80+'
                  : testMutation.data.data.length - 20}{' '}
                more...
              </div>
            )}
          </div>
        ) : rules.length > 0 && rules.some((rule) => rule.value?.length > 0) ? (
          <div className="flex flex-col gap-1">
            <h1 className="font-bold">Matches</h1>
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
            <SkeletonLoader height="2xs" />
          </div>
        ) : (
          <div className="flex flex-col">
            <h1 className="font-bold">Matches</h1>
          </div>
        )}
      </div>
    </Dialog>
  );
};
