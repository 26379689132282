import { CurrentSlideOver } from '@/types';
import { useState } from 'react';
import { Copy, Stars } from 'lucide-react';
import { UrlInput } from './PlateEditor/components/url-input';
import { Input } from '@/Components/v2/Input/Input';
import { useMetaDescriptionState, useMetaTitleState } from '../hooks';
import { Button } from '@/Components/v2/Button';
import { useDocumentStore } from '../stores';
import {
  useAnalyzePage,
  useDocumentShopifyImport,
  useGetCmsConnection,
} from '@/api/openapiComponents';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useEditor } from '@/Components/Utils/v2/api';
import { getInitialPlateJSValue } from '../utils';
import { useShallow } from 'zustand/react/shallow';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { capitalize } from '@/utils';
import { DocumentResource } from '@/api/openapiSchemas';
import { useQueryClient } from '@tanstack/react-query';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { MetadataWidth } from './MetadataWidth';
import { deepClone } from './PlateEditor/lib/streaming-plugin';

type Props = {
  includeReportElements?: boolean;
  document: DocumentResource;
  onOpenMetaTitle: () => void;
  onOpenMetaDescription: () => void;
  onCloseSlideOver: () => void;
  currentSlideOver?: CurrentSlideOver;
};

export const DocumentMetadata = ({
  currentSlideOver,
  document,
  onCloseSlideOver,
  onOpenMetaDescription,
  onOpenMetaTitle,
}: Props) => {
  const appState = useAppStore();
  const { hasFeature } = useFeatureFlagging();
  const editor = useEditor();
  const client = useQueryClient();

  const { setHeading } = useDocumentStore(
    useShallow(({ setHeading }) => ({
      setHeading,
    })),
  );

  const [metaTitle, setMetaTitle] = useMetaTitleState();
  const [metaDescription, setMetaDescription] = useMetaDescriptionState();

  const [showImportContentDialog, setShowImportContentDialog] = useState(false);

  const projectUrl = useDocumentStore((state) => state.projectUrl);
  const importFromShopifyMutation = useDocumentShopifyImport();
  const analyzeMutation = useAnalyzePage();

  const cmsConnectionQuery = useGetCmsConnection(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      retry: false,
    },
  );

  const handleImport = () => {
    if (!shouldImportFromCms) {
      return analyzeMutation.mutate(
        {
          body: {
            url: projectUrl!.url,
          },
        },
        {
          onSuccess: (data) => {
            setShowImportContentDialog(false);
            setMetaTitle(data.data.meta_title);
            setMetaDescription(data.data.meta_description);
            editor?.children.map(() => editor?.tf.delete({ at: [0] }));
            editor?.tf.insertNodes(
              deepClone(getInitialPlateJSValue(data.data.content)),
            );
          },
        },
      );
    }
    if (shouldImportFromCms) {
      return importFromShopifyMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
            document: document.id,
          },
        },
        {
          onSuccess: (data) => {
            setShowImportContentDialog(false);
            setHeading(data.data.title);
            setMetaDescription(data.data.meta_description);
            setMetaTitle(data.data.meta_title ?? '');
            editor?.children.map(() => editor?.tf.delete({ at: [0] }));
            editor?.tf.insertNodes(
              deepClone(getInitialPlateJSValue(data.data.text)),
            );
            client.setQueriesData(
              {
                predicate: (query) => query.queryKey.includes('getDocument'),
              },
              () => data,
            );
          },
        },
      );
    }
  };

  const hasCmsConnection = cmsConnectionQuery.data?.data.state === 'active';
  const shouldImportFromCms =
    hasCmsConnection &&
    (projectUrl?.cms.has_reference || !projectUrl) &&
    hasFeature('shopify-cms-integration');

  return (
    <div>
      <ConfirmDialog
        isOpen={!!showImportContentDialog}
        onClose={() => setShowImportContentDialog(false)}
        title={`Import content from URL`}
        description={[
          `Are you sure you want to import and overwrite content from ${capitalize(shouldImportFromCms ? (cmsConnectionQuery.data?.data?.type ?? 'URL') : 'URL')}?`,
        ]}
        confirmText="Import"
        onConfirm={() => handleImport()}
        isLoading={
          analyzeMutation.isPending || importFromShopifyMutation.isPending
        }
      />
      <div className="flex flex-col gap-6 pt-6">
        <InputDecoration label="URL" variant="overlay">
          <UrlInput document={document} />
        </InputDecoration>
        <InputDecoration
          label="TITLE"
          variant="overlay"
          actions={
            <MetadataWidth
              content={metaTitle}
              configs={[
                {
                  name: 'Desktop',
                  maxWidth: 580,
                  fontClassnames: 'text-[22px] font-normal font-google-desktop',
                },
                {
                  name: 'Mobile',
                  maxWidth: 480,
                  fontClassnames: 'text-[20px] font-normal font-google-mobile',
                },
              ]}
            />
          }
        >
          <Input
            fullWidth
            value={metaTitle}
            onChange={(value) => setMetaTitle(value)}
            disabled={
              analyzeMutation.isPending &&
              metaDescription.length === 0 &&
              metaTitle.length === 0
            }
            onFocus={() => {
              if (metaTitle.length === 0) {
                onOpenMetaTitle();
              }
            }}
            placeholder="Add title"
            appendIcon={
              <div className="flex">
                <IconButton
                  dense
                  icon={Copy}
                  onClick={() => navigator.clipboard.writeText(metaTitle)}
                />
                {document.keyword && (
                  <IconButton
                    dense
                    icon={Stars}
                    onClick={() => {
                      if (currentSlideOver === 'meta-title') {
                        onCloseSlideOver();
                      } else {
                        onOpenMetaTitle();
                      }
                    }}
                  />
                )}
              </div>
            }
          />
        </InputDecoration>
        <InputDecoration
          label="META DESCRIPTION"
          variant="overlay"
          actions={
            <MetadataWidth
              content={metaDescription}
              configs={[
                {
                  name: 'Desktop',
                  maxWidth: 920,
                  fontClassnames: 'text-[14px] font-normal font-google-desktop',
                },
                {
                  name: 'Mobile',
                  maxWidth: 680,
                  fontClassnames: 'text-[14px] font-normal font-google-mobile',
                },
              ]}
            />
          }
        >
          <TextArea
            fullWidth
            value={metaDescription}
            onChange={(value) => setMetaDescription(value)}
            disabled={
              analyzeMutation.isPending &&
              metaDescription.length === 0 &&
              metaTitle.length === 0
            }
            onFocus={() => {
              if (metaDescription.length === 0) {
                onOpenMetaDescription();
              }
            }}
            placeholder="Add meta description"
            appendIcon={
              <div className="flex">
                <IconButton
                  dense
                  icon={Copy}
                  onClick={() => navigator.clipboard.writeText(metaDescription)}
                />
                {document.keyword && (
                  <IconButton
                    dense
                    icon={Stars}
                    onClick={() => {
                      if (currentSlideOver === 'meta-description') {
                        onCloseSlideOver();
                      } else {
                        onOpenMetaDescription();
                      }
                    }}
                  />
                )}
              </div>
            }
          />
        </InputDecoration>
        <div className="-mt-4 flex items-center justify-end">
          <Button
            variant="ghost"
            text={`Import content from ${capitalize(shouldImportFromCms ? (cmsConnectionQuery.data?.data?.type ?? 'URL') : 'URL')}`}
            textCase="normal-case"
            onClick={() => setShowImportContentDialog(true)}
            disabled={!projectUrl}
          />
        </div>
      </div>
    </div>
  );
};
