import { ActiveDraggableState } from '@/types';
import { PlateEditor } from '@udecode/plate/react';
import { createContext } from 'react';

type DocumentActions = {
  handleInsertText: (markdownString: string) => void;
  handleReplaceText: (markdownString: string) => void;
};

export const DocumentActionContext = createContext<DocumentActions>(
  {} as DocumentActions,
);

export const EditorContext = createContext<null | PlateEditor>(null);

export const ActiveDraggableContext =
  createContext<null | ActiveDraggableState>(null);
export const EditorRefContext =
  createContext<null | React.RefObject<HTMLDivElement>>(null);
