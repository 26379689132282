import {
  createRoute,
  useNavigate,
  useParams,
  useSearch,
} from '@tanstack/react-router';
import { rootRoute } from '@/Pages/Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '@/Pages/Router/guards';
import { PageAnalytics } from './PageAnalytics';
import { PageAnalyticsGroup } from './PageAnalyticsGroup';
import { ManageGroups } from './ManageGroups';
import { PageDetails } from './PageDetails';
import { KeywordDetails } from './KeywordDetails';
import PageContainer from '@/Components/PageContainer';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ExternalLink } from 'lucide-react';
import { Menus } from '@/Components/Menus';
import { removeDomain } from '@/utils';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: (search: Record<string, string>) => ({
      page: Number(search.page ?? 1),
      keywordPage: Number(search.keywordPage ?? 1),
    }),
    path: '/page-analytics',
    component: () => <PageAnalytics />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: (search: Record<string, string>) => ({
      page: Number(search.page ?? 1),
      keywordPage: Number(search.keywordPage ?? 1),
    }),
    path: '/page-analytics/groups/$groupId',
    component: () => <PageAnalyticsGroup />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: (search: Record<string, string>) => ({
      page: Number(search.page ?? 1),
    }),
    path: '/page-analytics/page/$pageId',
    component: () => {
      const navigate = useNavigate({ from: '/page-analytics/page/$pageId' });
      const params = useParams({ from: '/page-analytics/page/$pageId' });
      const search = useSearch({ from: '/page-analytics/page/$pageId' });

      return (
        <Menus>
          <PageContainer
            title={`Page: ${removeDomain(params.pageId)}`}
            titleActions={
              <IconButton
                icon={ExternalLink}
                onClick={() => window.open(params.pageId, '_blank')}
              />
            }
            canGoBack
          >
            <PageDetails
              onKeywordClick={(keyword: string) =>
                navigate({
                  to: '/page-analytics/keyword/$keyword',
                  params: { keyword },
                  search: { page: 1 },
                })
              }
              onPageChange={(pageNumber: number) =>
                navigate({ search: { page: pageNumber } })
              }
              pageId={params.pageId}
              pageNumber={search.page}
            />
          </PageContainer>
        </Menus>
      );
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: (search: Record<string, string>) => ({
      page: Number(search.page ?? 1),
    }),
    path: '/page-analytics/keyword/$keyword',
    component: () => <KeywordDetails />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/page-analytics/groups',
    component: () => <ManageGroups />,
  }),
];
