import {
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  LucideProps,
} from 'lucide-react';
import { Alert } from './Alert';

type Props = {
  icon?: React.ComponentType<LucideProps> | React.ReactNode;
  title?: string;
  body?: string | (string[] | string)[] | undefined;
};
const ErrorAlert = ({ title = 'An error occured', body, icon }: Props) => (
  <Alert icon={icon} title={title} color="red" body={body} />
);

ErrorAlert.displayName = 'ErrorAlert';

export { ErrorAlert };
