import { Dialog } from '@/Components/Dialog';
import { Input } from '@/Components/v2/Input/Input';
import { LucideEarth, Search } from 'lucide-react';
import { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { CountryResource } from '@/api/openapiSchemas.ts';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader.tsx';

type Props = {
  isOpen: boolean;
  data: CountryResource[];
  isLoading: boolean;
  onClose: () => void;
  onChooseCountry: (country: {
    iso_3166_2: string;
    iso_3166_3: string;
  }) => void;
};

export const ChooseCountryDialog = ({
  isOpen,
  data,
  onChooseCountry,
  isLoading,
  onClose,
}: Props) => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <Dialog title="Choose country" isOpen={isOpen} handleClose={onClose}>
      {isLoading ? (
        <div className="flex flex-col gap-1">
          {Array.from({ length: 3 }).map((_, index) => (
            <SkeletonLoader height="2xs" key={index} />
          ))}
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <Input
            placeholder="Search country..."
            value={searchInput}
            onChange={setSearchInput}
            prependIcon={Search}
          />
          <div
            key={0}
            onClick={() => {
              onChooseCountry({
                iso_3166_2: 'xx',
                iso_3166_3: 'xxx',
              });
              onClose();
            }}
            className="flex cursor-pointer items-center gap-2 hover:opacity-60"
          >
            <div className="border border-gray-100">
              <LucideEarth width={23} height={18} />
            </div>
            <span>Global</span>
          </div>
          {data
            .filter((country) =>
              country.name?.toLowerCase()?.includes(searchInput.toLowerCase()),
            )
            .map((country) => {
              return (
                <div
                  key={country.id}
                  onClick={() => {
                    onChooseCountry({
                      iso_3166_2: country.iso_3166_2 ?? 'xx',
                      iso_3166_3: country.iso_3166_3 ?? 'xxx',
                    });
                    onClose();
                  }}
                  className="flex cursor-pointer items-center gap-2 hover:opacity-60"
                >
                  <ReactCountryFlag
                    key={country.id}
                    countryCode={country?.iso_3166_2 ?? ''}
                    svg
                    style={{
                      height: '18px',
                      width: '23px',
                    }}
                    className="border border-gray-100"
                  />
                  <span>{country.name}</span>
                </div>
              );
            })}
        </div>
      )}
    </Dialog>
  );
};
