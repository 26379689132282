import { CardSection } from '../../components/CardSection';
import { Subheading } from '../components/Subheading';
import { OctagonAlert, Pencil, RefreshCcw, Unlink } from 'lucide-react';
import { ConnectionCard } from './components/ConnectionCard';
import GoogleIcon from '../../../../../public/svg/google-icon.svg';
import ShopifyIcon from '../../../../../public/svg/shopify-logo.svg';
import {
  useConnectToGoogleMerchantCenter,
  useConnectToGoogleSearchConsole,
} from '../hooks';
import { GSCChooseSiteDialog } from './components/GSCChooseSiteDialog';
import { GMCChooseAccountDialog } from './components/GMCChooseAccountDialog';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ShopifySetupDialog } from './ShopifySetupDialog';
import { useState } from 'react';
import {
  useDeleteCmsConnection,
  useGetCmsConnection,
  useUpdateCmsConnection,
} from '@/api/openapiComponents';
import { ConnectionState } from '@/api/openapiSchemas';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { EditShopifyAPIKeyDialog } from './components/EditShopifyAPIKeyDialog';
import { Dialog } from '@/Components/Dialog';
import { AnimatedEllipsis } from '@/Components/AnimatedEllipsis';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { ErrorAlert } from '@/Components/v2/Alert';

export const Connections = () => {
  const projectId = useAppStore((state) => state.currentProject!.id);

  const { hasFeature } = useFeatureFlagging();

  const [
    showMerchantCenterDisconnectWarning,
    setShowMerchantCenterDisconnectWarning,
  ] = useState(false);
  const [showCreateShopifyDialog, setShowCreateShopifyDialog] = useState(false);
  const [showShopifyUpdateAPIKeyDialog, setShowShopifyUpdateAPIKeyDialog] =
    useState(false);

  const updateShopifyDataMutation = useUpdateCmsConnection();
  const deleteConnectionMutation = useDeleteCmsConnection();
  const cmsConnectionQuery = useGetCmsConnection(
    {
      pathParams: {
        project: projectId,
      },
    },
    {
      retry: false,
      enabled: hasFeature('shopify-cms-integration'),
    },
  );

  const {
    disconnect: disconnectSearchConsole,
    googleDialog: googleSearchConsoleDialog,
    googleSearchConsoleData,
    isGettingGoogleSearchConsoleData,
    isSelectingSiteForGoogleSearchConsole,
    isSettingUpGoogleSearchConsole,
    hasNoAvailableSites: hasNoAvailableSearchConsoleSites,
    selectSiteForGoogleSearchConsole,
    setGoogleDialog: setGoogleSearchConsoleDialog,
    initialSearchConsoleState,
    setupGoogleConsole,
  } = useConnectToGoogleSearchConsole(projectId);

  const {
    googleMerchantDialog,
    setGoogleMerchantDialog,
    isGettingGoogleMerchantCenterData,
    setupGoogleMerchantCenter,
    isSettingUpGoogleMerchantCenter,
    selectAccountForGoogleMerchantCenter,
    isSelectingAccountForGoogleMerchantCenter,
    googleMerchantCenterData,
    disconnectMerchantCenter,
    initialMerchantCenterState,
    hasNoAvailableAccounts,
  } = useConnectToGoogleMerchantCenter(projectId);

  const handleDeleteConnection = () => {
    deleteConnectionMutation.mutate(
      {
        pathParams: {
          project: projectId,
        },
      },
      {
        onSuccess: () => {
          cmsConnectionQuery.refetch();
        },
      },
    );
  };

  const handleUpdateShopifyData = () => {
    updateShopifyDataMutation.mutate(
      {
        pathParams: {
          project: projectId,
        },
      },
      {
        onSuccess: () => {
          cmsConnectionQuery.refetch();
        },
      },
    );
  };

  return (
    <>
      <ConfirmDialog
        title="Disconnect Google Merchant Center"
        isOpen={showMerchantCenterDisconnectWarning}
        onClose={() => setShowMerchantCenterDisconnectWarning(false)}
        content={
          <div>
            <p>Are you sure you want to disconnect Google Merchant Center?</p>
            <div className="mt-4">
              <ErrorAlert
                icon={OctagonAlert}
                title="This will remove all the imported feeds, optimization groups and
              the optimized changes that are deployed will be reverted. This is
              irreversible!"
              />
            </div>
          </div>
        }
        confirmText="Diconnect"
        confirmColor="red"
        onConfirm={() => {
          disconnectMerchantCenter({
            pathParams: {
              project: projectId,
              connection: googleMerchantCenterData?.data.id!,
            },
          });
          setShowMerchantCenterDisconnectWarning(false);
        }}
      />
      <Dialog isOpen={isSettingUpGoogleMerchantCenter} size="sm">
        <div className="flex flex-col items-center text-primary">
          <LoadingSpinner color="secondary" size="2xl" />
          <p className="flex">
            Connecting to Google Merchant Center
            <AnimatedEllipsis fixedWidth />
          </p>
          <p className="text-sm italic text-primary-400">
            This may take a couple minutes
          </p>
        </div>
      </Dialog>
      <EditShopifyAPIKeyDialog
        isOpen={showShopifyUpdateAPIKeyDialog}
        onClose={() => setShowShopifyUpdateAPIKeyDialog(false)}
      />
      <ShopifySetupDialog
        isOpen={showCreateShopifyDialog}
        onClose={() => setShowCreateShopifyDialog(false)}
        onSuccess={() => cmsConnectionQuery.refetch()}
      />
      <GMCChooseAccountDialog
        isLoading={isSelectingAccountForGoogleMerchantCenter}
        isOpen={googleMerchantDialog.isOpen}
        onConnect={() => {
          selectAccountForGoogleMerchantCenter({
            pathParams: {
              project: projectId,
            },
            body: {
              account_id: googleMerchantDialog.selectedAccount!,
            },
          });
          setGoogleMerchantDialog(initialMerchantCenterState);
        }}
        setGoogleDialog={setGoogleMerchantDialog}
        googleDialog={googleMerchantDialog}
        hasNoAvailableAccounts={hasNoAvailableAccounts}
      />
      <GSCChooseSiteDialog
        isLoading={isSelectingSiteForGoogleSearchConsole}
        isOpen={googleSearchConsoleDialog.isOpen}
        hasNoAvailableSites={hasNoAvailableSearchConsoleSites}
        onConnect={() => {
          selectSiteForGoogleSearchConsole({
            pathParams: {
              project: projectId,
            },
            body: {
              site: googleSearchConsoleDialog.selectedSite!,
            },
          });
          setGoogleSearchConsoleDialog(initialSearchConsoleState);
        }}
        setGoogleDialog={setGoogleSearchConsoleDialog}
        googleDialog={googleSearchConsoleDialog}
      />
      <Subheading text="Simplify your workflow with our different integrations. Connect and keep everything in sync." />
      <CardSection title="Data sources" gap={4}>
        <ConnectionCard
          key={'google-search-console'}
          connection={{
            isLoadingInitialConnection: isGettingGoogleSearchConsoleData,
            isFetchingConnection:
              isSettingUpGoogleSearchConsole ||
              isGettingGoogleSearchConsoleData,
            onClick: setupGoogleConsole,
            state: googleSearchConsoleData?.data?.state,
          }}
          items={[
            {
              title: 'Disconnect',
              onClick: () => {
                disconnectSearchConsole({
                  pathParams: {
                    project: projectId,
                    connection: googleSearchConsoleData?.data.id!,
                  },
                });
              },
              prependIcon: Unlink,
            },
            {
              title: 'Edit',
              onClick: () => {
                setGoogleSearchConsoleDialog({
                  isOpen: true,
                  sites:
                    googleSearchConsoleData?.data.settings.available_sites ??
                    [],
                  selectedSite: googleSearchConsoleData?.data.settings.site,
                });
              },
              prependIcon: Pencil,
            },
          ]}
          icon={GoogleIcon}
          title="Google Search Console"
          description="Identify keyword gaps in your content by connecting to Google
            Search Console."
        />
        {hasFeature('google-merchant-center') && (
          <ConnectionCard
            connection={{
              isLoadingInitialConnection: isGettingGoogleMerchantCenterData,
              isFetchingConnection:
                isSettingUpGoogleMerchantCenter ||
                isGettingGoogleMerchantCenterData,
              onClick: setupGoogleMerchantCenter,
              state: googleMerchantCenterData?.data?.state,
            }}
            items={[
              {
                title: 'Disconnect',
                onClick: () => {
                  setShowMerchantCenterDisconnectWarning(true);
                },
                prependIcon: Unlink,
              },
              {
                title: 'Edit',
                onClick: () => {
                  setGoogleMerchantDialog({
                    isOpen: true,
                    accounts:
                      googleMerchantCenterData?.data.settings.accounts ?? [],
                    selectedAccount:
                      googleMerchantCenterData?.data.settings.account
                        ?.accountId ?? undefined,
                  });
                },
                prependIcon: Pencil,
              },
            ]}
            icon={GoogleIcon}
            title="Google Merchant Center"
            description="Google merchant center"
          />
        )}
        {hasFeature('shopify-cms-integration') && (
          <ConnectionCard
            key={'shopify'}
            title="Shopify"
            description="Connect your shopify store to sync editor content to your store"
            icon={ShopifyIcon}
            items={[
              {
                title: 'Change credentials',
                prependIcon: Pencil,
                onClick: () => setShowShopifyUpdateAPIKeyDialog(true),
              },
              {
                title: 'Refresh',
                prependIcon: RefreshCcw,
                onClick: handleUpdateShopifyData,
                disabled: updateShopifyDataMutation.isPending,
              },
              {
                title: 'Disconnect',
                onClick: handleDeleteConnection,
                prependIcon: Unlink,
              },
            ]}
            connection={{
              state:
                cmsConnectionQuery.data?.data?.type === 'shopify' &&
                !cmsConnectionQuery.isError
                  ? (cmsConnectionQuery.data?.data.state as ConnectionState)
                  : 'disabled',
              isFetchingConnection: false,
              isLoadingInitialConnection: cmsConnectionQuery.isFetching,
              onClick: () => setShowCreateShopifyDialog(true),
            }}
          />
        )}
      </CardSection>
    </>
  );
};
