import {
  useDocumentChatClear,
  useDocumentsChatMessagesDestroy,
  useDocumentsChatMessagesIndex,
  useDocumentsChatMessagesStore,
} from '@/api/openapiComponents';
import { DocumentResource } from '@/api/openapiSchemas';
import { BlockSelection } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { useStartMessageStreamMutation } from '@/Components/Utils/v2/api';
import { queryKeyFn } from '@/api/openapiContext';
import { useScrollIntoView } from './hooks/useScrollIntoView';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { ReactNode, useState } from 'react';
import { ChatLayout } from '@/Pages/Chat/components/ChatLayout';
import { useSearch } from '@tanstack/react-router';
import { GetStartedChatBubbleText } from './components/GetStartedChatBubble';
import { InitialMessageSuggestions } from './components/InitialMessageSuggestions';

type Props = {
  document: DocumentResource;
  selection: BlockSelection | null;
  deselectSelection: () => void;
  disabled?: boolean;
  hideEditorActions?: boolean;
  initialMessage?: ReactNode;
};

export const Chat = ({
  document,
  deselectSelection,
  hideEditorActions,
  selection,
  disabled,
  initialMessage,
}: Props) => {
  const client = useQueryClient();
  const search = useSearch({ from: '/documents/$documentId' });

  const { showSnackbar } = useSnackbar();

  const [text, setText] = useState('');

  const variables = {
    pathParams: { document: document.id },
  };

  const queryKey = queryKeyFn({
    path: '/documents/{document}/chat-messages',
    operationId: 'documentsChatMessagesIndex',
    variables,
  });

  const { mutateAsync: sendMessageAsync, isPending: isSendingMessage } =
    useDocumentsChatMessagesStore();
  const { data: chatMessages, isLoading } =
    useDocumentsChatMessagesIndex(variables);
  const deleteChatMessageMutation = useDocumentsChatMessagesDestroy();
  const { mutate: clearChat, isPending: isClearingChat } = useDocumentChatClear(
    {
      onMutate: () => {
        client.setQueryData(queryKey, () => ({ data: [] }));
      },
      onSuccess: () => {
        client.invalidateQueries({ queryKey });
      },
    },
  );

  const {
    handleStartStream,
    data: { isLoadingMessage, content, isStreamingMessage, toolCall },
    handleSetLoadingMessage,
  } = useStartMessageStreamMutation({ documentId: document.id }, queryKey);

  const handleRefreshMessage = (id: number) => {
    handleSetLoadingMessage(true);
    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chatMessage: id,
          document: document.id,
        },
      },
      {
        onSuccess: () => {
          client.invalidateQueries({ queryKey: queryKey }).then(() => {
            handleStartStream();
          });
        },
      },
    );
  };

  const { handleScrollIntoView, isInView, scrollToDiv, scrollContainerDiv } =
    useScrollIntoView(true);

  const handleSendMessage = async (
    predefinedText?: string,
    images?: string[],
    insert = true,
  ) => {
    handleScrollIntoView();
    if (insert) {
      if (selection && selection.selected.showInChat) {
        client.setQueriesData({ queryKey }, (prev) => {
          return {
            ...prev,
            data: [
              {
                content: selection.selected.text,
                display_type: 'SELECTION',
                is_moderated: false,
                id: -1,
                role: 'user',
                images,
              },
              ...prev.data,
            ],
          };
        });
      }
      client.setQueriesData({ queryKey }, (prev) => {
        return {
          ...prev,
          data: [
            {
              content: predefinedText || text,
              display_type: 'MESSAGE',
              is_moderated: false,
              id: 0,
              role: 'user',
              images,
            },
            ...prev.data,
          ],
        };
      });
    }
    if (selection && selection.selected.showInChat) {
      try {
        await sendMessageAsync({
          pathParams: { document: document.id },
          body: {
            content: selection.selected.text,
            display_type: 'SELECTION',
            images,
          },
        });
        if (!predefinedText) {
          if (!text) return;
          setText('');
        }
        deselectSelection();
      } catch (error: any) {
        showSnackbar({
          alignment: 'bottom',
          message: error.message,
          color: 'red',
        });
        return;
      }
    }
    handleSetLoadingMessage(true);

    try {
      const message = await sendMessageAsync({
        pathParams: { document: document.id },
        body: {
          content: predefinedText || text,
          display_type: 'MESSAGE',
          images,
        },
      });
      if (!predefinedText) {
        if (!text) return;
        setText('');
      }
      if (!message.data.is_moderated) {
        handleStartStream();
      } else {
        handleSetLoadingMessage(false);
        client.setQueriesData({ queryKey }, (prev) => {
          return {
            ...prev,
            data: [
              ...prev.data.map((m, index) => {
                if (index === 0) {
                  return {
                    ...m,
                    id: message.data.id,
                    is_moderated: true,
                  };
                }
                return m;
              }),
            ],
          };
        });
      }
    } catch (error: any) {
      showSnackbar({
        alignment: 'top',
        message: error.message,
        color: 'red',
      });
      handleSetLoadingMessage(false);
    }
  };

  const handleSubmitEditedMessage = (
    id: number,
    text: string,
    images?: string[],
  ) => {
    client.setQueriesData({ queryKey }, (prev) => {
      const messageIndex = prev.data.findIndex((message) => message.id === id);

      return {
        ...prev,
        data: [
          {
            ...prev.data[messageIndex],
            content: text,
          },
          ...prev.data.slice(messageIndex + 1),
        ],
      };
    });

    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chatMessage: id,
          document: document.id,
        },
      },
      {
        onSuccess: () => {
          handleSendMessage(text, images, false);
        },
      },
    );
  };

  return (
    <ChatLayout
      input={{
        onChange: setText,
        value: text,
      }}
      placeholder={
        search.v2 ? (
          <InitialMessageSuggestions
            onChooseMessage={(message) => handleSendMessage(message)}
          />
        ) : (
          <GetStartedChatBubbleText />
        )
      }
      currentToolCall={toolCall!}
      isClearingChat={isClearingChat}
      isDeletingMessage={deleteChatMessageMutation.isPending}
      isLoading={isLoading}
      isLoadingMessage={isLoadingMessage}
      isSendingMessage={isSendingMessage}
      isStreamingMessage={isStreamingMessage}
      onEditMessage={handleSubmitEditedMessage}
      onRefreshMessage={handleRefreshMessage}
      onSendMessage={handleSendMessage}
      scroll={{
        handleScrollIntoView,
        isInView,
        scrollToDiv,
        scrollContainerDiv,
      }}
      selection={selection}
      hideEditorActions={hideEditorActions}
      chatMessages={chatMessages?.data}
      disabled={disabled}
      initialMessage={initialMessage}
      onClearChat={() => clearChat({ pathParams: { document: document.id } })}
      streamedMessage={content!}
    />
  );
};
