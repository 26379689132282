'use client';

import {
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  flip,
  offset,
  UseVirtualFloatingOptions,
} from '@udecode/plate-floating';
import {
  FloatingLinkUrlInput,
  LinkFloatingToolbarState,
  LinkOpenButton,
  LinkPlugin,
  submitFloatingLink,
  useFloatingLinkEdit,
  useFloatingLinkEditState,
  useFloatingLinkInsert,
  useFloatingLinkInsertState,
  useFloatingLinkUrlInputState,
} from '@udecode/plate-link/react';

import { cn } from '../lib/utils';
import { Icons } from './icons';

import {
  buttonVariants,
  inputVariants,
  popoverVariants,
  Separator,
} from '../components';
import { useDebounce } from '@/Hooks/useDebounce';
import LoadingTextAnimation from '@/Components/LoadingTextAnimation';
import SimpleButton from '@/Components/SimpleButton';
import { ExternalLink, PlusSquare } from 'lucide-react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Dot } from 'lucide-react';
import { ProjectDomainUrlResource } from '@/api/openapiSchemas';
import { fetchProjectsDomainUrlsIndex } from '@/api/openapiComponents';
import { Button } from '@/Components/v2/Button';
import { useEditorRef } from '@udecode/plate/react';

const floatingOptions: UseVirtualFloatingOptions = {
  placement: 'bottom-start',
  middleware: [
    offset(12),
    flip({
      padding: 12,
      fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
    }),
  ],
};

export type LinkFloatingToolbarProps = {
  state?: LinkFloatingToolbarState;
};

export function LinkFloatingToolbar({ state }: LinkFloatingToolbarProps) {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  useDebounce(() => setDebouncedSearch(search), [search], 500);

  const insertState = useFloatingLinkInsertState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });

  const {
    props: insertProps,
    ref: insertRef,
    hidden,
    textInputProps,
  } = useFloatingLinkInsert(insertState);

  const editor = useEditorRef();
  const initialLoadRef = useRef(false);

  const { data: projectId } = useQuery<number>({
    queryKey: ['document-project-id'],
  });

  const domainQuery = useInfiniteQuery({
    queryKey: ['Floating-linksearch', debouncedSearch, projectId],
    queryFn: ({ pageParam }) =>
      projectId !== undefined
        ? fetchProjectsDomainUrlsIndex({
            pathParams: {
              project: projectId,
            },
            queryParams: {
              limit: 25,
              sort_by: 'title',
              sort_direction: 'ASC',
              page: pageParam,
              filters: debouncedSearch.length
                ? { search: debouncedSearch }
                : undefined,
            },
          })
        : null,
    getNextPageParam: (data) => (data?.meta.current_page ?? 1) + 1,
    initialPageParam: 1,
  });

  const isLoadingSuggestions =
    domainQuery.isPending || search !== debouncedSearch;

  const editState = useFloatingLinkEditState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });

  const {
    props: editProps,
    ref: editRef,
    editButtonProps,
    unlinkButtonProps,
  } = useFloatingLinkEdit(editState);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (
        !search.match(/[A-Za-z]*:\/\/.*/) &&
        search.length > 0 &&
        e.key === 'Enter'
      ) {
        editor.getOptions(LinkPlugin).url = 'http://' + search;
      } else {
        editor.getOptions(LinkPlugin).url = search;
      }
      if (e.key === 'Enter') {
        submitFloatingLink(editor);
      }
    },
    [search],
  );

  const url = useMemo(
    () => editor.getOptions(LinkPlugin).url,
    [editor.getOptions(LinkPlugin).url],
  );

  useEffect(() => {
    if (!hidden && !initialLoadRef.current) {
      setSearch(url || '');
      initialLoadRef.current = true;
    }
    if (hidden) {
      initialLoadRef.current = false;
    }
  }, [hidden, url]);

  useEffect(() => {
    setDebouncedSearch('');
  }, [textInputProps.defaultValue]);

  const handleChooseSuggestion = (url: string) => {
    setSearch(url);
    editor.getOptions(LinkPlugin).url = url;
    submitFloatingLink(editor);
  };

  const data =
    domainQuery.data?.pages.reduce(
      (prev, curr) => [...prev, ...(curr?.data ?? [])],
      [] as ProjectDomainUrlResource[],
    ) ?? [];

  const meta =
    domainQuery.data?.pages[(domainQuery.data?.pages.length ?? 1) - 1]?.meta;

  const input = (
    <div className="flex w-[450px] flex-col">
      <div className="flex items-center">
        <div className="flex items-center pl-3 text-slate-500 dark:text-slate-400">
          <Icons.link className="h-4 w-4" />
        </div>

        <FloatingLinkUrlInput
          className={inputVariants({ variant: 'ghost', h: 'sm' })}
          placeholder="Paste link or search"
          onKeyDown={handleKeyDown}
          defaultValue={undefined}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
        />
      </div>

      <Separator />

      <div className="flex items-center">
        <div className="flex items-center pl-3 text-slate-500 dark:text-slate-400">
          <Icons.text className="h-4 w-4" />
        </div>
        <input
          className={inputVariants({ variant: 'ghost', h: 'sm' })}
          placeholder="Text to display"
          {...textInputProps}
        />
      </div>

      <Separator />

      {isLoadingSuggestions ? (
        <div className="group flex cursor-pointer items-center gap-2 py-2">
          <div className="flex items-center pl-3">
            <Icons.add className="invisible h-4 w-4" />
          </div>
          <LoadingTextAnimation className="text-sm text-primary-600">
            Fetching website urls
          </LoadingTextAnimation>
        </div>
      ) : (
        <>
          {domainQuery.isSuccess &&
            (!domainQuery.data || data.length === 0) && (
              <div className="group flex cursor-pointer items-center gap-2 py-2">
                <Icons.add className="invisible ml-4 h-4 w-4" />
                <p className="text-sm text-primary-600">No suggestions</p>
              </div>
            )}
          {domainQuery.isError && (
            <div className="group flex cursor-pointer items-center gap-2 py-2">
              <Icons.add className="invisible ml-4 h-4 w-4" />
              <p className="text-sm text-primary-600">Oops an error occured!</p>
              <Button
                onClick={() => domainQuery.refetch()}
                variant="ghost"
                text="RETRY"
              />
            </div>
          )}
          <div className="h-56 overflow-y-scroll">
            {data.map((domain) => (
              <div className="flex cursor-pointer items-center gap-2 overflow-hidden py-1">
                <div
                  className="group relative flex flex-grow overflow-hidden"
                  onClick={() => handleChooseSuggestion(domain.url)}
                >
                  <div className="invisible absolute inset-0 flex items-center justify-center  font-bold text-primary-700 group-hover:visible">
                    USE LINK &nbsp;{' '}
                    <PlusSquare className="stroke-primary-700" size={18} />
                  </div>
                  <div className="mr-2 flex flex-shrink-0 items-center pl-3 text-slate-500 dark:text-slate-400">
                    <Dot className="h-4 w-4" />
                  </div>

                  <div className="ml-1 w-full flex-grow  overflow-hidden text-sm text-primary-600 group-hover:opacity-20">
                    <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                      {domain.title}
                    </p>
                    <p className="overflow-hidden overflow-ellipsis whitespace-nowrap underline">
                      {domain.url}
                    </p>
                  </div>
                </div>
                <SimpleButton
                  className="p-2"
                  onClick={() => window.open(domain.url)}
                >
                  <ExternalLink size={20} />
                </SimpleButton>
              </div>
            ))}
            {meta?.has_more && (
              <SimpleButton
                className="w-full py-1"
                isLoading={domainQuery.isFetchingNextPage}
                onClick={() => domainQuery.fetchNextPage()}
              >
                <div className="flex w-full justify-center">Load more</div>
              </SimpleButton>
            )}
          </div>
        </>
      )}
    </div>
  );

  const editContent = editState.isEditing ? (
    input
  ) : (
    <div className="box-content flex h-9 items-center gap-1">
      <button
        type="button"
        className={buttonVariants({ variant: 'ghost', size: 'sm' })}
        {...editButtonProps}
      >
        Edit link
      </button>

      <Separator orientation="vertical" />

      <LinkOpenButton
        className={buttonVariants({
          variant: 'ghost',
          size: 'sm',
        })}
      >
        <Icons.externalLink width={18} />
      </LinkOpenButton>

      <Separator orientation="vertical" />

      <button
        type="button"
        className={buttonVariants({
          variant: 'ghost',
          size: 'sm',
        })}
        {...unlinkButtonProps}
      >
        <Icons.unlink width={18} />
      </button>
    </div>
  );

  if (hidden) return null;
  return (
    <>
      <div
        ref={insertRef}
        className={cn(popoverVariants(), 'w-auto p-1')}
        {...insertProps}
        style={{ ...insertProps.style, zIndex: 150 }}
      >
        {input}
      </div>

      <div
        ref={editRef}
        className={cn(popoverVariants(), 'w-auto p-1')}
        {...editProps}
        style={{ ...editProps.style, zIndex: 150 }}
      >
        {editContent}
      </div>
    </>
  );
}
