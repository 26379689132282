import { DynamicIcon } from '@/Components/v2/DynamicIcon/DynamicIcon';
import { LucideProps } from 'lucide-react';

type Props = {
  title: string;
  icon?: React.ComponentType<LucideProps> | string;
  description: string;
  onClick: () => void;
};

export const WorkflowCard = ({ description, icon, onClick, title }: Props) => {
  const Icon = icon;

  return (
    <div
      className="flex aspect-video w-full max-w-72 cursor-pointer flex-col justify-center gap-2 rounded-lg bg-white p-4 shadow hover:opacity-60"
      onClick={onClick}
    >
      {Icon &&
        (typeof icon === 'string' ? (
          <DynamicIcon
            name={icon}
            className="aspect-square w-[20%] text-primary-400"
          />
        ) : (
          <Icon className={`aspect-square w-[20%] text-primary-400`} />
        ))}
      <p className="text-xl font-bold text-primary ">{title}</p>

      <p className=" text-primary">{description}</p>
    </div>
  );
};
