import PageContainer from '@/Components/PageContainer';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import { ValueWithGrowth } from '@/Pages/Analytics/components/ValueWithGrowth';
import { Button } from '@/Components/v2/Button';
import { ScanSearch } from 'lucide-react';
import { GrowthValueHeader } from '@/Pages/Analytics/components/GrowthValueHeader';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useAnalyticsQuery } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import {
  getDateParams,
  calculateGrowth,
  convertData,
  convertKey,
  sortAnalyticsData,
} from '@/Pages/Analytics/utils.ts';
import { CellWithBar } from '@/Pages/Analytics/components/CellWithBar';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { removeDomain } from '@/utils';
import { useAnalyticsTotals, useGraphState, useGrowthSorting } from './hooks';
import ToggleButton from '@/Components/ToggleButton';
import { useConnectToGoogleSearchConsole } from '@/Pages/Settings/hooks';
import { GoogleConsoleConnect } from '@/Components/Table/components/GoogleConsoleConnect';
import { AnalyticsFilters } from './components/AnalyticsFilters';
import {
  AnalyticsFilterDimension,
  AnalyticsFilterOperator,
} from '@/api/openapiSchemas';
import { AnalyticsGroupsTable } from './components/AnalyticsGroupsTable';
import { AnalyticsUrlActions } from '@/Pages/Analytics/components/AnalyticsUrlActions';
import { useEffect, useState } from 'react';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';

export const PageAnalytics = () => {
  const appState = useAppStore();
  const navigate = useNavigate({ from: '/page-analytics' });
  const search = useSearch({ from: '/page-analytics' });

  const [graphState, setGraphState] = useGraphState();
  const [pageColumnSorting, setPageColumnSorting] = useState('clicks');
  const [pageGrowthSorting, setPageGrowthSorting] =
    useGrowthSorting('dashboardpage');
  const [keywordColumnSorting, setKeywordColumnSorting] = useState('clicks');
  const [keywordGrowthSorting, setKeywordGrowthSorting] = useGrowthSorting(
    'analyticsmainpagekeywords',
  );

  const gscHookProps = useConnectToGoogleSearchConsole(
    appState.currentProject!.id,
  );

  useEffect(() => {
    if (gscHookProps.isGettingGoogleSearchConsoleData) return;
    setGraphState({
      ...graphState,
      GSCIsEnabled:
        gscHookProps.googleSearchConsoleData?.data?.settings.site !== null &&
        !gscHookProps.googleSearchConsoleError,
    });
  }, [
    gscHookProps.googleSearchConsoleError,
    gscHookProps.googleSearchConsoleData,
    gscHookProps.isGettingGoogleSearchConsoleData,
  ]);

  const totals = useAnalyticsTotals(graphState);

  const baseDimensionFilterGroups =
    graphState.country?.iso_3166_3 === 'xxx'
      ? []
      : [
          {
            filters: [
              {
                dimension: 'country' as AnalyticsFilterDimension,
                operator: 'equals' as AnalyticsFilterOperator,
                expression: graphState.country?.iso_3166_3,
              },
            ],
          },
        ];

  const currentPageDataQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range),
        dimensions: ['page'],
        dimension_filter_groups: baseDimensionFilterGroups,
      },
    },
    {
      enabled: graphState.GSCIsEnabled,
    },
  );

  const previousPageDataQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['page'],
        dimension_filter_groups: baseDimensionFilterGroups,
      },
    },
    {
      enabled: graphState.GSCIsEnabled,
    },
  );

  const keywordQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, false),
        dimensions: ['query'],
        row_limit: 1000,
        dimension_filter_groups: [
          {
            filters:
              graphState.country?.iso_3166_3 === 'xxx'
                ? []
                : [
                    {
                      dimension: 'country',
                      operator: 'equals',
                      expression: graphState.country?.iso_3166_3,
                    },
                  ],
          },
        ],
      },
    },
    {
      enabled: graphState.GSCIsEnabled,
    },
  );

  const previousKeywordQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['query'],
        row_limit: 1000,
        dimension_filter_groups: [
          {
            filters:
              graphState.country?.iso_3166_3 === 'xxx'
                ? []
                : [
                    {
                      dimension: 'country',
                      operator: 'equals',
                      expression: graphState.country?.iso_3166_3,
                    },
                  ],
          },
        ],
      },
    },
    {
      enabled: graphState.GSCIsEnabled,
    },
  );

  if (gscHookProps.isGettingGoogleSearchConsoleData) {
    return (
      <Menus>
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner color="secondary" size="2xl" />
        </div>
      </Menus>
    );
  }

  if (!graphState.GSCIsEnabled) {
    return (
      <Menus>
        <PageContainer
          title="Page Analytics"
          canScroll={false}
          actions={
            <Button
              text="Open rank tracker"
              prependIcon={ScanSearch}
              onClick={() => navigate({ to: '/rank-tracking' })}
              variant="ghost"
            />
          }
        >
          <div className="relative h-screen w-full overflow-hidden">
            <img
              src="/svg/analytics_placeholder.png"
              className="aspect-square w-full blur-sm"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="-mt-[420px] w-full">
                {gscHookProps && (
                  <GoogleConsoleConnect
                    {...gscHookProps}
                    page="page analytics"
                    message="Connect to Google Search Console to see your website's performance."
                  />
                )}
              </div>
            </div>
          </div>
        </PageContainer>
      </Menus>
    );
  }

  const pageData = convertData({
    current: convertKey('page', currentPageDataQuery.data?.data ?? []),
    previous: convertKey('page', previousPageDataQuery.data?.data ?? []),
  });

  const topPagesMostClicks =
    pageData?.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]
      ?.current.clicks ?? 0;

  const topKeywords = convertData(
    {
      current: convertKey('query', keywordQuery.data?.data ?? []),
      previous: convertKey('query', previousKeywordQuery.data?.data ?? []),
    },
    'query',
  );

  const topKeywordsMostClicks =
    topKeywords?.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]
      ?.current.clicks ?? 0;

  const handleOpenPage = (pageId: string) => {
    navigate({
      to: '/page-analytics/page/$pageId',
      params: { pageId },
      search: { page: 1 },
    });
  };

  appState.pageTitle('Page Analytics');

  return (
    <>
      <Menus>
        <PageContainer
          title="Page Analytics"
          actions={
            <Button
              text="Open rank tracker"
              prependIcon={ScanSearch}
              onClick={() => navigate({ to: '/rank-tracking' })}
              variant="ghost"
            />
          }
        >
          <p className="my-2">
            Your website's performance based on Google Search Console data.{' '}
            <a
              href="https://docs.seo.ai/a-43-page-analytics"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Read more
            </a>
            .
          </p>
          <AnalyticsFilters
            state={graphState}
            onStateChange={setGraphState}
            totals={totals}
          />
          <AnalyticsGraph state={graphState} />
          <AnalyticsGroupsTable state={graphState} navigate={navigate} />

          {/* Page table */}
          <Table.Root
            items={sortAnalyticsData(
              pageData ?? [],
              pageGrowthSorting,
              pageColumnSorting,
            )?.slice(((search.page ?? 1) - 1) * 20, 20 * (search.page ?? 1))}
            isLoading={
              currentPageDataQuery.isLoading || previousPageDataQuery.isLoading
            }
            columns={[
              {
                heading: 'Page',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    value={item.current.clicks}
                    topValue={topPagesMostClicks}
                    name={removeDomain(item.current.page)}
                    onClick={() => handleOpenPage(item.current.page)}
                    actions={
                      <AnalyticsUrlActions
                        url={item.current.page}
                        onOpen={() => handleOpenPage(item.current.page)}
                      />
                    }
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Clicks"
                    selected={pageColumnSorting === 'clicks'}
                    onClick={() => setPageColumnSorting('clicks')}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Impressions"
                    selected={pageColumnSorting === 'impressions'}
                    onClick={() => setPageColumnSorting('impressions')}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="CTR"
                    selected={pageColumnSorting === 'ctr'}
                    onClick={() => setPageColumnSorting('ctr')}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Position"
                    selected={pageColumnSorting === 'position'}
                    onClick={() => setPageColumnSorting('position')}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    absolute
                    value={item.current.position.toFixed(1)}
                    growth={calculateGrowth(item, 'position', true)}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <div className="mt-10 flex items-center gap-4">
                <h1 className="mb-1 text-xl font-semibold">Pages</h1>
                <ToggleButton
                  value={pageGrowthSorting}
                  onChange={setPageGrowthSorting}
                  options={[
                    {
                      displayName: 'Popular',
                      value: 'popular',
                    },
                    {
                      displayName: 'Rising',
                      value: 'rising',
                    },
                    {
                      displayName: 'Falling',
                      value: 'falling',
                    },
                  ]}
                />
              </div>
            </Table.Header>
            <Table.Footer>
              <Pagination
                currentPage={search.page ?? 1}
                setCurrentPage={(page) =>
                  navigate({ search: { ...search, page }, replace: true })
                }
                lastPage={Math.floor((pageData?.length ?? 0) / 20)}
              />
            </Table.Footer>
          </Table.Root>

          {/* Keywords Table */}
          <Table.Root
            isLoading={keywordQuery.isLoading}
            skeletonLoaders={20}
            items={sortAnalyticsData(
              topKeywords ?? [],
              keywordGrowthSorting,
              keywordColumnSorting,
            )?.slice((search.keywordPage - 1) * 20, search.keywordPage * 20)}
            columns={[
              {
                heading: 'Keyword',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    value={item.current.clicks}
                    topValue={topKeywordsMostClicks}
                    name={item.current.query}
                    onClick={() =>
                      navigate({
                        to: '/page-analytics/keyword/$keyword',
                        params: { keyword: item.current.query },
                        search: { page: 1 },
                      })
                    }
                    actions={
                      <AnalyticsUrlActions
                        url={item.current.page}
                        onOpen={() =>
                          navigate({
                            to: '/page-analytics/keyword/$keyword',
                            params: { keyword: item.current.query },
                            search: { page: 1 },
                          })
                        }
                      />
                    }
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Clicks"
                    selected={keywordColumnSorting === 'clicks'}
                    onClick={() => setKeywordColumnSorting('clicks')}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Impressions"
                    selected={keywordColumnSorting === 'impressions'}
                    onClick={() => setKeywordColumnSorting('impressions')}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="CTR"
                    selected={keywordColumnSorting === 'ctr'}
                    onClick={() => setKeywordColumnSorting('ctr')}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Position"
                    selected={keywordColumnSorting === 'position'}
                    onClick={() => setKeywordColumnSorting('position')}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    absolute
                    responsive={false}
                    value={item.current.position.toFixed(1)}
                    growth={calculateGrowth(item, 'position', true)}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <div className="mt-10 flex items-center gap-4">
                <h1 className="mb-1 text-xl font-semibold">Keywords</h1>
                <ToggleButton
                  value={keywordGrowthSorting}
                  onChange={setKeywordGrowthSorting}
                  options={[
                    {
                      displayName: 'Popular',
                      value: 'popular',
                    },
                    {
                      displayName: 'Rising',
                      value: 'rising',
                    },
                    {
                      displayName: 'Falling',
                      value: 'falling',
                    },
                  ]}
                />
              </div>
            </Table.Header>
            <Table.Footer>
              <Pagination
                currentPage={search.keywordPage}
                lastPage={Math.floor(
                  (keywordQuery.data?.data.length ?? 0) / 20,
                )}
                setCurrentPage={(pageNumber) =>
                  navigate({
                    search: { ...search, keywordPage: pageNumber },
                    replace: true,
                  })
                }
              />
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
};
