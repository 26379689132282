import { Tooltip } from '@/Components/v2/Tooltip';
import dayjs from 'dayjs';
import { formatLargeNumber, formatThousandSeperator } from '@/utils.ts';

export default function MonthlySearchesGraph({
  data,
  height = '1.5rem',
}: {
  data: { year: number; month: number; search_volume: number }[]
  height?: string
}) {
  if (data.length === 0) {
    return (
      <div className="justify-end items-start w-full flex">
        <p>N/A</p>
      </div>
    )
  }


  const maxSearchVolume = Math.max(...data.map((item) => item.search_volume))

  const formatDate = (date: { year: number; month: number }) => dayjs().year(date.year).month(date.month - 1).format('MMMM')

  return (
    <>
    {maxSearchVolume > 0 && (
      <div className="justify-end items-start w-full flex">
        {data.map((item) => (
          <div className="flex items-end" key={`${item.year}-${item.month}-bar"`}>
            <Tooltip
              title={`${formatDate(item)}: ${formatThousandSeperator(item.search_volume)}`}
              asChild
              side="top"
            >
              <div style={{ height }} className="flex items-end cursor-pointer">
                <span
                  className="ml-0.5 w-1.5 bg-green-400"
                  style={{
                    height: `${((item.search_volume / maxSearchVolume) * 100).toFixed(2)}%`,
                  }}
                />
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
    )}
    {maxSearchVolume <= 0 && (
      <div className="justify-end items-start w-full flex">
        <p>N/A</p>
      </div>
    )}
    </>
  )
}
