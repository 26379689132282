import React, { ReactNode } from 'react';
import { X } from 'lucide-react';

type Props = {
  active?: boolean;
  children: ReactNode | ReactNode[];
  onRemove: () => void;
  onClick?: () => void;
  disabled?: boolean;
  dense?: boolean;
  variant?: 'ghost' | 'default'
};

export const FilterItem = ({
  children,
  onRemove,
  active,
  onClick,
  disabled,
  dense,
  variant = 'default',
}: Props) => {

  const handleXClick = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <span
      onClick={disabled ? undefined : onClick}
      className={`inline-flex items-center gap-1 ${variant === 'default' ? 'rounded-lg border' : ''} ${
        dense ? 'px-1.5 py-0.5' : 'px-2.5 py-1.5'
      } text-base text-primary ${active && variant !== 'ghost' ? 'bg-amber-50' : ''} ${
        onClick && !disabled ? 'cursor-pointer' : ''
      } ${disabled ? 'cursor-not-allowed opacity-60' : ''}`}
    >
      {children}{' '}
      {active && <X onClick={disabled ? undefined : handleXClick} size={16} />}
    </span>
  );
};
