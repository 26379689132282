import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomGraphTooltip } from '@/Pages/RankTracking/components/CustomGraphTooltip';
import { formatThousandSeperator } from '@/utils';
import dayjs from 'dayjs';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAnalyticsMerchantCenterQuery } from '@/api/openapiComponents';
import { convertKey, getDateParams } from '@/Pages/Analytics/utils';
import { GraphState } from '../hooks';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { MerchantCenterQueryDimension, MerchantCenterQueryFilter } from '@/api/openapiSchemas';

type Props = {
  state: GraphState;
  page?: {
    type: 'title' | 'brand' | 'offer_id';
    dimension?: string;
    id: string;
  };
  groupFilters?: {
    dimension: string;
    operator: string;
    expression: string;
  }[];
  isLoading?: boolean;
};

export const AnalyticsGraph = ({ state, page, groupFilters, isLoading = false }: Props) => {
  const appState = useAppStore();

  const filters: {
    dimension: MerchantCenterQueryDimension;
    operator: MerchantCenterQueryFilter;
    expression: string;
  }[]  = [];

  if (page) {
    filters.push({
      dimension: page.type as MerchantCenterQueryDimension,
      operator: 'equals' as MerchantCenterQueryFilter,
      expression: page.dimension ?? page.id,
    });
  }

  if (groupFilters) {
    filters.push(...groupFilters.map((filter) => ({
      dimension: filter.dimension as MerchantCenterQueryDimension,
      operator: filter.operator as MerchantCenterQueryFilter,
      expression: filter.expression,
    })));
  }

  if (state.country.iso_3166_2 !== 'xx') {
    filters.push({
      dimension: 'customer_country_code' as MerchantCenterQueryDimension,
      operator: 'equals' as MerchantCenterQueryFilter,
      expression: state.country.iso_3166_2,
    });
  }


  const query = useAnalyticsMerchantCenterQuery({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      ...getDateParams(state.range),
      dimensions: ['date'],
      granularity: state.granularity,
      dimension_filter_groups: filters,
    },
  }, {
    enabled: state.isGMCEnabled && isLoading === false
  });

  if (!query.isSuccess) {
    return (
      <div>
        <ResponsiveContainer width="100%" height={300}>
          <div className="h-full">
            <SkeletonLoader height="full" />
          </div>
        </ResponsiveContainer>
      </div>
    );
  }

  const data = convertKey('date', query.data.data).map((item, index) => {
    if (state.granularity === 'day' || index < query.data.data.length - 2) {
      return {
        ...item,
      };
    }

    return {
      date: item.date,
      fadingClicks: item.clicks,
      fadingImpressions: item.impressions,
      fadingCtr: item.ctr,
      ...item,
    };
  });

  if (data.length === 0) {
    return (
      <div className="mt-6">
        <ResponsiveContainer width="100%" height={300}>
          <div className="flex items-center justify-center">
            <p>No data available</p>
          </div>
        </ResponsiveContainer>
      </div>
    );
  }

  return (
    <div className="mt-6">
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorClicks" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#29A6F9" stopOpacity={0.4} />
              <stop offset="95%" stopColor="#29A6F9" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPosition" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FFAE00" stopOpacity={0.4} />
              <stop offset="95%" stopColor="#FFAE00" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorImpressions" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#9553FF" stopOpacity={0.4} />
              <stop offset="95%" stopColor="#9553FF" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorCtr" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#00D841" stopOpacity={0.4} />
              <stop offset="95%" stopColor="#00D841" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={'date'}
            interval={(() => {
              let divideCount = 0;
              let dataCount = data.length;

              while (dataCount > 10) {
                dataCount = Math.floor(dataCount / 2);

                if (divideCount === 0) {
                  divideCount = 1;
                } else {
                  divideCount *= 2;
                }
              }
              return divideCount;
            })()}
            tickFormatter={(value) =>
              state.granularity === 'month'
                ? dayjs(value as string).format('MMM')
                : dayjs(value as string).format('MMM D')
            }
          />

          <Tooltip
            isAnimationActive={false}
            position={{ y: 50 }}
            content={(row) =>
              CustomGraphTooltip(
                {
                  ...row,
                  payload: row.payload
                    ?.filter((item) => item.dataKey && !item.dataKey.toString().includes('fading'))
                    .map((item) => ({
                      ...item,
                      value:
                        (item.dataKey && item.dataKey === 'ctr')
                            ? `${(Number(item.value) * 100).toFixed(2)}%`
                            : item.value,
                    })),
                },
                {
                  showWeekDay: state.granularity === 'day',
                },
              )
            }
          />

          {state.clicks && (
            <>
              <YAxis
                yAxisId={'clicks'}
                tickFormatter={(value) =>
                  formatThousandSeperator(Number(value))
                }
                axisLine={false}
                hide
              />
              <Area
                animationDuration={0}
                id="clicks"
                yAxisId={'clicks'}
                type="linear"
                strokeWidth={2}
                dataKey="clicks"
                stroke="#29A6F9"
                fill="url(#colorClicks)"
              />
              <Area
                animationDuration={0}
                id="clicks"
                yAxisId={'clicks'}
                type="linear"
                strokeDasharray={'5 5'}
                strokeWidth={2}
                dataKey="fadingClicks"
                stroke="#FFFFFF"
                fill="#00000000"
              />
            </>
          )}

          {state.ctr && (
            <>
              <YAxis
                yAxisId={'ctr'}
                orientation="right"
                axisLine={false}
                hide
              />
              <Area
                animationDuration={0}
                id="ctr"
                yAxisId={'ctr'}
                type="linear"
                strokeWidth={2}
                dataKey="ctr"
                stroke="#00D841"
                fill="url(#colorCtr)"
              />
              <Area
                animationDuration={0}
                id="ctrfade"
                yAxisId={'ctr'}
                type="linear"
                strokeDasharray={'5 5'}
                strokeWidth={2}
                dataKey="fadingCtr"
                stroke="#FFFFFF"
                fill="#00000000"
              />
            </>
          )}

          {state.impressions && (
            <>
              <YAxis
                yAxisId={'impressions'}
                orientation="right"
                axisLine={false}
                allowDataOverflow
                tickFormatter={(value) =>
                  formatThousandSeperator(Number(value))
                }
                hide
              />
              <Area
                animationDuration={0}
                id="impressions"
                yAxisId={'impressions'}
                type="linear"
                strokeWidth={2}
                dataKey="impressions"
                stroke="#9553FF"
                fill="url(#colorImpressions)"
              />
              <Area
                animationDuration={0}
                id="fadingImpressions"
                yAxisId={'impressions'}
                type="linear"
                strokeWidth={2}
                strokeDasharray={'5 5'}
                dataKey="fadingImpressions"
                stroke="#FFFFFF"
                fill="#00000000"
              />
            </>
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
