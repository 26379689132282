import PageContainer from '@/Components/PageContainer';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import { ValueWithGrowth } from '@/Pages/Analytics/components/ValueWithGrowth';
import { useEffect, useState } from 'react';
import { GrowthValueHeader } from '@/Pages/Analytics/components/GrowthValueHeader';
import { useAnalyticsQuery, useShowPageGroup } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { CellWithBar } from '@/Pages/Analytics/components/CellWithBar';
import {
  getDateParams,
  calculateGrowth,
  convertData,
  convertKey,
  sortAnalyticsData,
} from '@/Pages/Analytics/utils.ts';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { EditGroupDialog } from './components/EditGroupDialog';
import { Button } from '@/Components/v2/Button';
import { Pencil } from 'lucide-react';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { removeDomain } from '@/utils';
import { useAnalyticsTotals, useGraphState, useGrowthSorting } from './hooks';
import ToggleButton from '@/Components/ToggleButton';
import { AnalyticsFilters } from './components/AnalyticsFilters';
import { AnalyticsUrlActions } from '@/Pages/Analytics/components/AnalyticsUrlActions';
import { Event, events } from '@/events';
import { useConnectToGoogleSearchConsole } from '@/Pages/Settings/hooks';

export const PageAnalyticsGroup = () => {
  const appState = useAppStore();
  const navigate = useNavigate({ from: '/page-analytics/groups/$groupId' });
  const params = useParams({ from: '/page-analytics/groups/$groupId' });
  const search = useSearch({ from: '/page-analytics/groups/$groupId' });

  const [graphState, setGraphState] = useGraphState();
  const [pageColumnSorting, setPageColumnSorting] = useState('clicks');
  const [growthSorting, setGrowthSorting] = useGrowthSorting('grouppage');
  const [keywordColumnSorting, setKeywordColumnSorting] = useState('clicks');
  const [keywordGrowthSorting, setKeywordGrowthSorting] =
    useGrowthSorting('grouppagekeywords');

  const [showEditDialog, setShowEditDialog] = useState(false);

  const gscHookProps = useConnectToGoogleSearchConsole(
    appState.currentProject!.id,
  );

  useEffect(() => {
    const handler = () => {
      navigate({ to: '/page-analytics', search: { page: 1, keywordPage: 1 } });
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  useEffect(() => {
    const handler = () => {
      navigate({ to: '/page-analytics', search: { page: 1, keywordPage: 1 } });
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  useEffect(() => {
    if (gscHookProps.isGettingGoogleSearchConsoleData) {
      return;
    }

    if (
      graphState.GSCIsEnabled &&
      !gscHookProps.googleSearchConsoleData?.data?.settings.site &&
      gscHookProps.googleSearchConsoleData?.data?.state === 'active'
    ) {
      return;
    }

    setGraphState({
      ...graphState,
      GSCIsEnabled:
        gscHookProps.googleSearchConsoleData?.data?.state === 'active' &&
        gscHookProps.googleSearchConsoleData?.data?.settings.site !== null &&
        !gscHookProps.googleSearchConsoleError,
    });
  }, [
    gscHookProps.googleSearchConsoleError,
    gscHookProps.googleSearchConsoleData,
    gscHookProps.isGettingGoogleSearchConsoleData,
  ]);

  const groupQuery = useShowPageGroup(
    {
      pathParams: {
        project: appState.currentProject!.id,
        analyticsPageGroup: params.groupId,
      },
    },
    {
      enabled: graphState.GSCIsEnabled,
    },
  );

  const totals = useAnalyticsTotals(graphState, {
    groupFilters: groupQuery.data?.data.dimension_filter_group.filters,
  });

  const currentQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range),
        dimensions: ['page'],
        dimension_filter_groups: [
          groupQuery.data?.data.dimension_filter_group ?? { filters: [] },
          {
            filters:
              graphState.country?.iso_3166_3 === 'xxx'
                ? []
                : [
                    {
                      dimension: 'country',
                      operator: 'equals',
                      expression: graphState.country?.iso_3166_3,
                    },
                  ],
          },
        ],
      },
    },
    {
      enabled: groupQuery.isSuccess,
    },
  );

  const previousQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['page'],
        dimension_filter_groups: [
          groupQuery.data?.data.dimension_filter_group ?? { filters: [] },
          {
            filters:
              graphState.country?.iso_3166_3 === 'xxx'
                ? []
                : [
                    {
                      dimension: 'country',
                      operator: 'equals',
                      expression: graphState.country?.iso_3166_3,
                    },
                  ],
          },
        ],
      },
    },
    {
      enabled: groupQuery.isSuccess,
    },
  );

  const keywordQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, false),
        dimensions: ['query'],
        row_limit: 1000,
        dimension_filter_groups: [
          groupQuery.data?.data.dimension_filter_group ?? { filters: [] },
          {
            filters:
              graphState.country?.iso_3166_3 === 'xxx'
                ? []
                : [
                    {
                      dimension: 'country',
                      operator: 'equals',
                      expression: graphState.country?.iso_3166_3,
                    },
                  ],
          },
        ],
      },
    },
    {
      enabled: groupQuery.isSuccess,
    },
  );

  const previousKeywordQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['query'],
        row_limit: 1000,
        dimension_filter_groups: [
          groupQuery.data?.data.dimension_filter_group ?? { filters: [] },
          {
            filters:
              graphState.country?.iso_3166_3 === 'xxx'
                ? []
                : [
                    {
                      dimension: 'country',
                      operator: 'equals',
                      expression: graphState.country?.iso_3166_3,
                    },
                  ],
          },
        ],
      },
    },
    {
      enabled: groupQuery.isSuccess,
    },
  );

  const topPages = convertData({
    current: convertKey('page', currentQuery.data?.data ?? []),
    previous: convertKey('page', previousQuery.data?.data ?? []),
  });

  const topPagesMostClicks =
    topPages?.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]
      ?.current.clicks ?? 0;

  const topKeywords = convertData(
    {
      current: convertKey('query', keywordQuery.data?.data ?? []),
      previous: convertKey('query', previousKeywordQuery.data?.data ?? []),
    },
    'query',
  );

  const topKeywordsMostClicks =
    topKeywords?.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]
      ?.current.clicks ?? 0;

  const handleOpenPage = (page: string) => {
    navigate({
      to: `/page-analytics/page/$pageId`,
      params: { pageId: page },
      search: { page: 1 },
    });
  };

  return (
    <>
      <EditGroupDialog
        isOpen={showEditDialog && groupQuery.isSuccess}
        existingGroup={groupQuery.data?.data}
        onClose={() => setShowEditDialog(false)}
        onSuccess={() => {
          groupQuery.refetch();
          currentQuery.refetch();
          previousQuery.refetch();
          setShowEditDialog(false);
        }}
      />
      <Menus>
        <PageContainer
          title={`Page group: ${groupQuery.data?.data.name ?? ''}`}
          canGoBack
          actions={
            <Button
              text="Edit"
              prependIcon={Pencil}
              variant="ghost"
              onClick={() => setShowEditDialog(true)}
            />
          }
        >
          <AnalyticsFilters
            state={graphState}
            onStateChange={setGraphState}
            totals={totals}
          />
          <AnalyticsGraph
            state={graphState}
            additionalFilters={
              groupQuery.data?.data.dimension_filter_group.filters
            }
          />

          <Table.Root
            isLoading={currentQuery.isFetching}
            items={sortAnalyticsData(
              topPages ?? [],
              growthSorting,
              pageColumnSorting,
            )?.slice(((search.page ?? 1) - 1) * 20, 20 * (search.page ?? 1))}
            columns={[
              {
                heading: 'Page',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    value={item.current.clicks}
                    topValue={topPagesMostClicks}
                    name={removeDomain(item.current.page)}
                    onClick={() => handleOpenPage(item.current.page)}
                    actions={
                      <AnalyticsUrlActions
                        url={item.current.page}
                        onOpen={() => handleOpenPage(item.current.page)}
                      />
                    }
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Clicks"
                    onClick={() => setPageColumnSorting('clicks')}
                    selected={pageColumnSorting === 'clicks'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Impressions"
                    onClick={() => setPageColumnSorting('impressions')}
                    selected={pageColumnSorting === 'impressions'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="CTR"
                    onClick={() => setPageColumnSorting('ctr')}
                    selected={pageColumnSorting === 'ctr'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Position"
                    onClick={() => setPageColumnSorting('position')}
                    selected={pageColumnSorting === 'position'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    absolute
                    value={item.current.position.toFixed(1)}
                    growth={calculateGrowth(item, 'position', true)}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <div className="mt-10 flex items-center gap-4">
                <h1 className="mb-1 text-xl font-semibold">Pages</h1>
                <ToggleButton
                  value={growthSorting}
                  onChange={setGrowthSorting}
                  options={[
                    {
                      displayName: 'Popular',
                      value: 'popular',
                    },
                    {
                      displayName: 'Rising',
                      value: 'rising',
                    },
                    {
                      displayName: 'Falling',
                      value: 'falling',
                    },
                  ]}
                />
              </div>
            </Table.Header>
            <Table.Footer>
              <Pagination
                currentPage={search.page ?? 1}
                setCurrentPage={(page) =>
                  navigate({ search: { ...search, page }, replace: true })
                }
                lastPage={Math.floor((topPages?.length ?? 0) / 20)}
              />
            </Table.Footer>
          </Table.Root>

          <Table.Root
            isLoading={keywordQuery.isLoading}
            skeletonLoaders={20}
            items={sortAnalyticsData(
              topKeywords ?? [],
              keywordGrowthSorting,
              keywordColumnSorting,
            )?.slice((search.keywordPage - 1) * 20, search.keywordPage * 20)}
            columns={[
              {
                heading: 'Keyword',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    value={item.current.clicks}
                    topValue={topKeywordsMostClicks}
                    name={item.current.query}
                    onClick={() =>
                      navigate({
                        to: '/page-analytics/keyword/$keyword',
                        params: { keyword: item.current.query },
                        search: { page: 1 },
                      })
                    }
                    actions={
                      <AnalyticsUrlActions
                        url={item.current.page}
                        onOpen={() =>
                          navigate({
                            to: '/page-analytics/keyword/$keyword',
                            params: { keyword: item.current.query },
                            search: { page: 1 },
                          })
                        }
                      />
                    }
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Clicks"
                    onClick={() => setKeywordColumnSorting('clicks')}
                    selected={keywordColumnSorting === 'clicks'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Impressions"
                    onClick={() => setKeywordColumnSorting('impressions')}
                    selected={keywordColumnSorting === 'impressions'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="CTR"
                    onClick={() => setKeywordColumnSorting('ctr')}
                    selected={keywordColumnSorting === 'ctr'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Position"
                    onClick={() => setKeywordColumnSorting('position')}
                    selected={keywordColumnSorting === 'position'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    absolute
                    responsive={false}
                    value={item.current.position.toFixed(1)}
                    growth={calculateGrowth(item, 'position', true)}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <div className="mt-10 flex items-center gap-4">
                <h1 className="mb-1 text-xl font-semibold">Keywords</h1>
                <ToggleButton
                  value={keywordGrowthSorting}
                  onChange={setKeywordGrowthSorting}
                  options={[
                    {
                      displayName: 'Popular',
                      value: 'popular',
                    },
                    {
                      displayName: 'Rising',
                      value: 'rising',
                    },
                    {
                      displayName: 'Falling',
                      value: 'falling',
                    },
                  ]}
                />
              </div>
            </Table.Header>
            <Table.Footer>
              <Pagination
                currentPage={search.keywordPage}
                lastPage={Math.floor(
                  (keywordQuery.data?.data.length ?? 0) / 20,
                )}
                setCurrentPage={(pageNumber) =>
                  navigate({
                    search: { ...search, keywordPage: pageNumber },
                    replace: true,
                  })
                }
              />
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
};
