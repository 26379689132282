import { useAppStore } from '@/Pages/AppLoader/stores';
import PageContainer from '@/Components/PageContainer';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { Menus } from '@/Components/Menus';
import { useAnalyticsTotals, useGraphState } from './hooks';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { AnalyticsFilters } from './components/AnalyticsFilters';
import { useEffect } from 'react';
import { useConnectToGoogleSearchConsole } from '@/Pages/Settings/hooks.ts';

export const KeywordDetails = () => {
  const appState = useAppStore();

  const navigate = useNavigate({ from: '/page-analytics/keyword/$keyword' });
  const params = useParams({ from: '/page-analytics/keyword/$keyword' });
  const search = useSearch({ from: '/page-analytics/keyword/$keyword' });

  const [graphState, setGraphState] = useGraphState();

  const gscHookProps = useConnectToGoogleSearchConsole(
    appState.currentProject!.id,
  );

  useEffect(() => {
    setGraphState({
      ...graphState,
      GSCIsEnabled: gscHookProps.googleSearchConsoleData?.data?.settings.site !== null &&
        !gscHookProps.googleSearchConsoleError &&
        !gscHookProps.isGettingGoogleSearchConsoleData
    });
  }, [
    gscHookProps.googleSearchConsoleError,
    gscHookProps.googleSearchConsoleData,
    gscHookProps.isGettingGoogleSearchConsoleData,
  ]);

  const totals = useAnalyticsTotals(graphState, { keyword: params.keyword });

  return (
    <Menus>
      <PageContainer title={`Keyword: ${params.keyword}`} canGoBack>
        <AnalyticsFilters
          state={graphState}
          onStateChange={setGraphState}
          totals={totals}
        />
        <AnalyticsGraph
          state={graphState}
          additionalFilters={[
            {
              dimension: 'query',
              operator: 'equals',
              expression: params.keyword,
            },
          ]}
        />
      </PageContainer>
    </Menus>
  );
};
