import * as React from 'react';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { Errors } from '@/Components/Errors';
import { Button } from '@/Components/v2/Button';
import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { useAuthSignUp } from '@/api/openapiComponents';

type Props = {
  email: string;
};

export default function VerifyEmail({ email }: Props) {
  const signUpMutation = useAuthSignUp();
  const appState = useAppStore();

  const retry = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    signUpMutation.mutate({
      body: {
        email,
      },
    });
  };

  appState.pageTitle('Check your email');

  return (
    <div className="flex h-screen flex-col items-center justify-center text-gray-800">
      <div className="absolute left-0 right-0 top-2 flex w-full justify-center">
        <SeoAiLogoBlack />
      </div>
      <div className="flex w-[30rem] flex-col">
        <h2 className="mb-3 text-center font-work text-[25px] font-bold leading-6">
          Go to your e-mail and click the activation link we just send
        </h2>

        <p className="text-center ">
          Please check your e-mail for activation link we've just sent you.
        </p>

        <div className="mb-4 mt-12 flex flex-col">
          <Button
            onClick={retry}
            text="Resend activation email"
            variant="outline"
            isLoading={signUpMutation.isPending}
          />
        </div>
        <Errors error={signUpMutation.error} />
      </div>
    </div>
  );
}
