import { withRef, withVariants } from '@udecode/cn';
import { cva } from 'class-variance-authority';

import { PlateElement } from './plate-element';
import { DraggableElement } from './draggable-element';
import { ExtendedTElement } from '@/types';

const listVariants = cva('m-0 ps-6', {
  variants: {
    variant: {
      ol: 'list-decimal text-lg mb-4 mt-2',
      ul: 'list-disc [&_ul]:list-[circle] [&_ul_ul]:list-[square] text-lg mb-4 mt-2',
    },
  },
});

const ListElementVariants = withVariants(PlateElement, listVariants, [
  'variant',
]);

export const ListElement = withRef<typeof ListElementVariants>(
  ({ children, variant = 'ul', ...props }, ref) => {
    const isOuterList = props.editor.children.find(
      (element) =>
        (element as ExtendedTElement).id ===
        (props.element as ExtendedTElement).id,
    );

    if (!isOuterList) {
      return (
        <ListElementVariants
          ref={ref}
          as={variant!}
          variant={variant}
          {...props}
        >
          {children}
        </ListElementVariants>
      );
    }
    return (
      <DraggableElement element={props.element}>
        <ListElementVariants
          ref={ref}
          as={variant!}
          variant={variant}
          {...props}
        >
          {children}
        </ListElementVariants>
      </DraggableElement>
    );
  },
);
