import { Dialog } from '@/Components/Dialog';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useEditorState } from '@udecode/plate/react';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddImageDialog = ({ isOpen, onClose }: Props) => {
  const [imageUrl, setImageUrl] = useState('');
  const editor = useEditorState();

  return (
    <Dialog
      title="Add image"
      isOpen={isOpen}
      handleClose={() => {
        setImageUrl('');
        onClose();
      }}
    >
      <form
        className="flex flex-col items-end gap-2"
        onSubmit={(e) => {
          e.preventDefault();
          editor.tf.insertNode({
            type: 'img',
            url: imageUrl,
            children: [{ text: imageUrl }],
          });
          setImageUrl('');
          onClose();
        }}
      >
        <InputDecoration label="Image URL" required>
          <Input value={imageUrl} onChange={setImageUrl} />
        </InputDecoration>
        <Button
          text="Add image"
          type="submit"
          color="secondary"
          disabled={
            !imageUrl.startsWith('http://') && !imageUrl.startsWith('https://')
          }
        />
      </form>
    </Dialog>
  );
};
