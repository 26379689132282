import { formatLargeNumber } from '@/utils';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { AnalyticsTotalsData, GraphState } from '../hooks';
import { StatItem } from '@/Pages/Analytics/components/StatItem';
import { Eye, Medal, MousePointerClick, ScanSearch } from 'lucide-react';
import { calculateGrowth } from '@/Pages/Analytics/utils.ts';

type Props = {
  state: GraphState;
  totals: AnalyticsTotalsData;
  onStateChange: (state: GraphState) => void;
};

export const AnalyticsTotals = ({ state, onStateChange, totals }: Props) => {
  return (
    <div>
      {!totals.isLoading ? (
        <div className="flex justify-start">
          <div
            className={`flex items-center ${state.range === 'P16M' || state.range === 'P1Y' ? 'gap-2' : ' gap-10'}`}
          >
            <StatItem
              checked={state.clicks}
              icon={ScanSearch}
              onChange={() =>
                onStateChange({ ...state, clicks: !state.clicks })
              }
              color="blue"
              name="clicks"
              value={formatLargeNumber(totals.data?.clicks ?? 0)}
              growth={calculateGrowth(
                { current: totals.data, previous: totals.previousData },
                'clicks',
              )}
              hideGrowth={state.range === 'P16M' || state.range === 'P1Y'}
            />
            <StatItem
              checked={state.impressions}
              icon={Eye}
              onChange={() =>
                onStateChange({ ...state, impressions: !state.impressions })
              }
              color="purple"
              name="impressions"
              value={formatLargeNumber(totals.data?.impressions ?? 0)}
              growth={calculateGrowth(
                { current: totals.data, previous: totals.previousData },
                'impressions',
              )}
              hideGrowth={state.range === 'P16M' || state.range === 'P1Y'}
            />
            <StatItem
              checked={state.ctr}
              icon={MousePointerClick}
              onChange={() => onStateChange({ ...state, ctr: !state.ctr })}
              color="green"
              name="CTR"
              value={`${((totals.data?.ctr ?? 0) * 100).toFixed(1)}%`}
              growth={calculateGrowth(
                { current: totals.data, previous: totals.previousData },
                'ctr',
              )}
              hideGrowth={state.range === 'P16M' || state.range === 'P1Y'}
            />
            <StatItem
              checked={state.position}
              icon={Medal}
              onChange={() =>
                onStateChange({ ...state, position: !state.position })
              }
              color="orange"
              name="position"
              value={(totals.data?.position ?? 0).toFixed(1)}
              growth={calculateGrowth(
                { current: totals.data, previous: totals.previousData },
                'position',
                true,
              )}
              hideGrowth={state.range === 'P16M' || state.range === 'P1Y'}
            />
          </div>
        </div>
      ) : (
        <div className="w-96">
          <SkeletonLoader height="xs" />
        </div>
      )}
    </div>
  );
};
