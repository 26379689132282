import { useState } from 'react';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import DifficultyLabel from '@/Components/Labels/DifficultyLabel';
import { KeywordResearchHeader } from './components/v2/KeywordResearchHeader';
import { SortParameters, useTable } from '@/Components/Table/hooks';
import { CheckIcon, SearchIcon } from 'lucide-react';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { formatThousandSeperator } from '@/utils';
import { Button } from '@/Components/v2/Button';
import { KeywordResearchTableHeaderActions } from './components/KeywordResearchTableHeaderActions.tsx';
import { SlimKeywordResource } from '@/api/openapiSchemas';
import { useKeywordExport } from './hooks';
import {
  fetchProjectKeywordResearchQuestionExplorer,
} from '@/api/openapiComponents';
import { ErrorHelper } from '@/Services/ErrorHandling';
import PageContainer from '@/Components/PageContainer';
import { Input } from '@/Components/v2/Input/Input';
import { useQuery } from '@tanstack/react-query';
import MonthlySearchesGraph from '@/Pages/Keywords/components/MonthlySearchesGraph.tsx';

type Filters = {
  keyword: string;
  search_volume_min: number;
  search_volume_max: number;
  difficulty_min: number;
  difficulty_max: number;
};

export default function QuestionsKeywords() {
  const appState = useAppStore();
  const [showTable, setShowTable] = useState(false);

  const {
    tableState,
    setFilters,
    setSorting,
    selection,
    setSelection,
    resetSelection,
  } = useTable<
    'id' | 'keyword' | 'search_volume' | 'difficulty' | undefined,
    Filters,
    SlimKeywordResource
  >({
    filters: {
      keyword: '',
    } as Filters,
    sortBy: 'search_volume',
    sortDirection: 'DESC',
  });

  const keywordQuery = useQuery({
    queryFn: () =>
      fetchProjectKeywordResearchQuestionExplorer({
        pathParams: {
          project: appState.currentProject!.id,
        },
        queryParams: {
          ...tableState,
        },
      }),
    queryKey: ['audience_keywords'],
    enabled: showTable,
  });

  const onExportCSV = useKeywordExport(
    {
      sort_by: tableState.sortBy,
      sort_direction: tableState.sortDirection,
      ids:
        keywordQuery.data?.data.map((keyword) => keyword.id.toString()) ?? [],
    },
    'audience_keywords',
  );

  const handleSubmit = () => {
    if (!tableState.filters) return;
    resetSelection();
    keywordQuery.refetch();
    setShowTable(true);
  };

  const handleSorting = (
    sorting: SortParameters<
      'id' | 'keyword' | 'search_volume' | 'difficulty' | undefined
    >,
  ) => {
    setSorting(sorting, { submit: true });
  };

  const isLoading = keywordQuery.isFetching;

  const errorHelper = new ErrorHelper(keywordQuery.error as any);

  appState.pageTitle('Questions');

  return (
    <Menus>
      <KeywordResearchHeader
        title="Question Keywords"
        subtitle="Find question-based searches related to a seed keyword."
        backgroundImage="/svg/keyword-research-questions.png"
        bgColor="bg-[#FFF1F2]"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="flex gap-4">
            <div className="flex-1">
              <Input
                value={tableState.filters?.keyword as string}
                onChange={(value) => {
                  setFilters({ keyword: value });
                }}
                placeholder="Seed keyword..."
                error={
                  errorHelper.hasFirst('filters.search') ||
                  errorHelper.message()
                }
              />
            </div>
            <div className="w-64">
              <Button
                fullWidth
                onClick={handleSubmit}
                type="submit"
                text="Search"
                size="lg"
                color="green"
                isLoading={isLoading}
                prependIcon={SearchIcon}
              />
            </div>
          </div>
        </form>
      </KeywordResearchHeader>
      <div className="flex-grow overflow-y-auto">
        <PageContainer size="lg">
          {/* Table */}
          {showTable && (
            <>
              <Table.Root
                selection={selection}
                onSelectionChange={setSelection}
                onSortChange={setSorting}
                sorting={tableState}
                isLoading={isLoading}
                skeletonLoaders={7}
                items={keywordQuery.data?.data}
                columns={[
                  {
                    sortableHeader: false,
                    rightAlign: false,
                    heading: '',
                    width: 1,
                    render: (item) => (
                      <>
                        {item.is_saved && (
                          <span className="text-secondary">
                            <CheckIcon></CheckIcon>
                          </span>
                        )}
                      </>
                    )
                  },
                  {
                    property: 'name',
                    sortableHeader: true,
                    heading: 'TARGET KEYWORD',
                  },
                  {
                    property: 'search_volume',
                    sortableHeader: true,
                    rightAlign: true,
                    heading: 'SEARCH VOLUME',
                    render: (item) => (
                      <>
                        {item.search_volume === 0
                          ? '< 10'
                          : formatThousandSeperator(item.search_volume)}
                      </>
                    ),
                  },
                  {
                    property: 'difficulty',
                    sortableHeader: true,
                    rightAlign: true,
                    heading: 'RANK DIFFICULTY',
                    render: (item) => (
                      <DifficultyLabel difficulty={item.difficulty} />
                    ),
                  },
                  {
                    property: 'monthly_searches',
                    sortableHeader: false,
                    heading: 'TREND',
                    rightAlign: true,
                    render: (item) => <MonthlySearchesGraph data={item.monthly_searches} />,
                  },
                ]}
              >
                <Table.Header csvExportOptions={onExportCSV}>
                  <Table.ResultsTotal
                    title="keywords"
                    isLoading={isLoading}
                    total={keywordQuery.data?.data.length}
                  />
                  <KeywordResearchTableHeaderActions
                    selection={selection}
                    resetSelection={resetSelection}
                    setSelection={setSelection}
                    operationId="audience_keywords"
                  />
                </Table.Header>
                <Table.NoContent>
                  No keywords found. Try a different seed keyword
                </Table.NoContent>
              </Table.Root>
            </>
          )}
        </PageContainer>
      </div>
    </Menus>
  );
}
