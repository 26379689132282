import { cn } from '@udecode/cn';
import { useEditorState, withRef } from '@udecode/plate/react';

import { PlateElement } from './plate-element';
import { DraggableElement } from './draggable-element';
import { Stars } from 'lucide-react';
import { AIChatPlugin } from '@udecode/plate-ai/react';
import { NodeTypesEnum } from '@/types';
import { useContext } from 'react';
import { IsTableContext } from './table-element';

export const ParagraphElement = withRef<typeof PlateElement>(
  ({ children, className, ...props }, ref) => {
    const editor = useEditorState();

    const isTable = useContext(IsTableContext);

    const text = props.element.children.reduce((prev, curr: any) => {
      if (curr.type === NodeTypesEnum.A) {
        return (
          prev +
          curr.children.reduce((prev: any, curr: any) => {
            return prev + curr.text, '';
          })
        );
      }
      return prev + curr.text;
    }, '');

    const isSelected = () => {
      const currentElements = editor.api.getFragment();
      if (
        currentElements.length === 1 &&
        currentElements[0].type === NodeTypesEnum.P &&
        currentElements[0].id === props.element.id
      ) {
        return true;
      }
      return false;
    };

    return (
      <PlateElement
        ref={ref}
        className={cn(
          className,
          'relative m-0 px-0 pb-4 pt-1 text-lg leading-7',
        )}
        {...props}
      >
        {text === '' && isSelected() && !isTable && (
          <Stars
            className="absolute left-0 top-2.5 h-4 w-4 cursor-pointer select-none text-secondary"
            onClick={() => editor.getApi(AIChatPlugin).aiChat.show()}
          />
        )}
        <DraggableElement element={props.element}>{children}</DraggableElement>
      </PlateElement>
    );
  },
);
