import { MessageInput } from '@/Pages/Document/components/Chat/components/MessageInput';
import { useState } from 'react';
import { TestEffect } from './TestEffect';
import { useProjectChatStore } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ChatResource } from '@/api/openapiSchemas';
import { Face } from './Face';
import { prefersReducesMotion } from '@/utils';
import { AnimatedText } from './AnimatedText';
import { useSnackbar } from '@/Components/v2/Snackbar';

type Props = {
  onCreateChat: (
    chat: ChatResource,
    message: { message: string; images?: string[] },
  ) => void;
};

export const NewChatInput = ({ onCreateChat }: Props) => {
  const appState = useAppStore();

  const [inputValue, setInputValue] = useState('');
  const [expand, setExpand] = useState(false);

  const createChatMutation = useProjectChatStore();

  const handleSendMessage = (predefinedMessage?: string, images?: string[]) => {
    createChatMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: (data) => {
          setExpand(true);
          if (prefersReducesMotion) {
            onCreateChat(data.data, {
              message: predefinedMessage ?? inputValue,
              images,
            });
          } else {
            setTimeout(() => {
              onCreateChat(data.data, {
                message: predefinedMessage ?? inputValue,
                images,
              });
            }, 700);
          }
        },
      },
    );
  };

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center gap-8">
      {/* <div
        className={`absolute inset-0 transition-opacity duration-700 ${expand ? 'opacity-0' : ''}`}
      >
        <TestEffect />
      </div> */}
      <p
        className={`text-4xl font-bold transition-opacity duration-700 ${expand ? 'opacity-0' : ''}`}
      >
        <AnimatedText text="How can I assist?" />
      </p>
      <div
        className={`mb-12 flex items-center transition-all duration-700 ${expand ? 'flex-grow opacity-0' : ''}`}
      >
        <Face />
      </div>
      <div className="w-full max-w-4xl px-6 pb-8">
        <MessageInput
          disablePredefinedMessages
          value={inputValue}
          onChange={(value) => setInputValue(value)}
          onSubmit={handleSendMessage}
          isSendingMessage={createChatMutation.isPending || expand}
        />
      </div>
    </div>
  );
};
