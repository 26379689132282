import { useProjectsDomainUrlsStore } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ExternalLink, Unplug, Zap } from 'lucide-react';
import { Tooltip } from '@/Components/v2/Tooltip';
import { DocumentResource } from '@/api/openapiSchemas';
import { useDocumentUrl } from '@/Pages/Document/hooks';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { Input } from '@/Components/v2/Input/Input';
import { Button } from '@/Components/v2/Button';
import * as RadixPopover from '@radix-ui/react-popover';
import { useEffect, useRef, useState } from 'react';
import { VerticalMenu } from '@/Components/v2/Menu/VerticalMenu';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useFeatureFlagging } from '@/Pages/AppLoader';
import { Icon } from '@/Components/v2/Icon';
import { faShopify } from '@fortawesome/free-brands-svg-icons';

type Props = {
  disabled?: boolean;
  document: DocumentResource;
};

export const UrlInput = ({ document, disabled }: Props) => {
  const { hasFeature } = useFeatureFlagging();

  const appState = useAppStore();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  const [suggestionsIsOpen, setSuggestionsIsOpen] = useState(false);
  const [selectionIndex, setSelectionIndex] = useState<number>(0);

  const {
    urlInput,
    projectUrl,
    refetchSuggestions,
    setUrlInput,
    setProjectUrl,
    errorHelper,
    reset: resetUpdateProjectMutation,
    isSuggestionsLoading,
    suggestions,
  } = useDocumentUrl(document);

  const addUrlmutation = useProjectsDomainUrlsStore();

  const handleAddUrl = (url: string) => {
    addUrlmutation.mutate(
      {
        body: {
          url: url,
        },
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: (data) => {
          setProjectUrl(data.data);
          refetchSuggestions();
        },
      },
    );
  };

  const addUrlErrorHelper = new ErrorHelper(addUrlmutation.error);

  useEffect(() => {
    if (suggestionsIsOpen) {
      const listener = (e: MouseEvent) => {
        if (
          !containerRef.current?.contains(e.target as HTMLDivElement) &&
          !menuContainerRef.current?.contains(e.target as HTMLDivElement)
        ) {
          setSuggestionsIsOpen(false);
        }
      };
      window.addEventListener('click', listener);
      return () => {
        window.removeEventListener('click', listener);
      };
    }
  }, [containerRef, suggestionsIsOpen]);

  return (
    <div ref={containerRef} className="flex w-full flex-col">
      <RadixPopover.Root open={urlInput.length > 0 && suggestionsIsOpen}>
        <RadixPopover.Trigger>
          <Input
            onFocus={() => {
              setSuggestionsIsOpen(true);
              setSelectionIndex(0);
            }}
            fullWidth
            placeholder="Add a URL to import content and get keywords gaps insights"
            disabled={disabled || addUrlmutation.isPending}
            error={errorHelper.message() ?? addUrlErrorHelper.message()}
            value={urlInput}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !isSuggestionsLoading) {
                setUrlInput(suggestions?.[selectionIndex].url ?? '');
                setSuggestionsIsOpen(false);
              } else {
                setSuggestionsIsOpen(true);
              }
              if (e.key === 'ArrowDown') {
                setSelectionIndex((prev) => {
                  if (prev === suggestions?.length - 1) {
                    return 0;
                  }
                  return prev + 1;
                });
              }
              if (e.key === 'ArrowUp') {
                setSelectionIndex((prev) => {
                  if (prev === 0) {
                    return suggestions?.length - 1;
                  }
                  return prev - 1;
                });
              }
            }}
            onChange={(value) => {
              setUrlInput(value);
              resetUpdateProjectMutation();
              addUrlmutation.reset();
            }}
            appendIcon={
              <>
                {(projectUrl?.url || document.url) && (
                  <IconButton
                    dense
                    icon={ExternalLink}
                    onClick={() =>
                      window.open(
                        projectUrl?.url ?? document.url ?? '',
                        '_blank',
                      )
                    }
                  />
                )}
                {hasFeature('shopify-cms-integration') &&
                  projectUrl?.cms.has_reference && (
                    <IconButton
                      dense
                      icon={
                        <Icon icon={faShopify} size="lg" className="my-0.5" />
                      }
                      onClick={() =>
                        window.open(projectUrl?.cms.admin_link, '_blank')
                      }
                    />
                  )}
                <div className="mr-1.5">
                  {!projectUrl && !isSuggestionsLoading ? (
                    urlInput ? (
                      <Tooltip
                        title={'This url has not been added to the project.'}
                        description={
                          <div className="flex flex-col gap-2">
                            <p>
                              You will not have access to the following
                              features:
                            </p>
                            <ul>
                              <li>• Keyword gaps</li>
                              <li>• Internal linking</li>
                            </ul>
                            <Button
                              text="Add URL to project"
                              onClick={() => handleAddUrl(urlInput)}
                              isLoading={addUrlmutation.isPending}
                              size="sm"
                              dense
                              variant="outline"
                            />
                          </div>
                        }
                      >
                        <Unplug className="mt-1 text-amber" />
                      </Tooltip>
                    ) : undefined
                  ) : isSuggestionsLoading ? (
                    <LoadingSpinner color="secondary" />
                  ) : (
                    <Zap className="text-green" />
                  )}
                </div>
              </>
            }
          />
        </RadixPopover.Trigger>
        <RadixPopover.Portal>
          <RadixPopover.Content
            side="bottom"
            avoidCollisions={false}
            onOpenAutoFocus={(e) => e.preventDefault()}
            className="h-full overflow-hidden rounded-lg border border-primary-200 shadow-lg outline-none outline-0 ring-0"
            style={{
              width: containerRef.current?.clientWidth,
              zIndex: 9999,
              maxHeight:
                'calc(var(--radix-popover-content-available-height) - 0.5rem)',
            }}
            tabIndex={-1}
            sideOffset={4}
            align="center"
          >
            <div ref={menuContainerRef}>
              <VerticalMenu
                items={
                  suggestions?.map((suggestion, index) => ({
                    title: suggestion.url,
                    isFocused: index === selectionIndex,
                    onClick: () => {
                      setUrlInput(suggestion.url);
                      resetUpdateProjectMutation();
                      addUrlmutation.reset();
                    },
                  })) ?? []
                }
              />
            </div>
          </RadixPopover.Content>
        </RadixPopover.Portal>
      </RadixPopover.Root>
    </div>
  );
};
