'use client';

import { useEffect, useMemo } from 'react';

import { type SlateEditor, NodeApi } from '@udecode/plate';
import { type PlateEditor, useEditorPlugin } from '@udecode/plate/react';
import { AIChatPlugin, AIPlugin } from '@udecode/plate-ai/react';
import { useIsSelecting } from '@udecode/plate-selection/react';
import {
  Album,
  AlignJustify,
  AlignLeft,
  BadgeHelp,
  Check,
  CornerUpLeft,
  FeatherIcon,
  List,
  ListEnd,
  ListMinus,
  ListPlus,
  ListStartIcon,
  MessageCircleIcon,
  MessageCircleQuestion,
  PenLine,
  Quote,
  SmileIcon,
  Sparkles,
  Wand,
  X,
} from 'lucide-react';

import { CommandGroup, CommandItem } from './command';
import { AiChatSubmit } from '../use-chat-hook';
import { getStartOfDocument } from '@/Pages/Document/utils';
import { BlockType, Generating } from '@/types';
import { useDocumentStore } from '@/Pages/Document/stores';
import { serializeToMarkdown } from '../lib/serialize';
import { StreamingPlugin } from '../lib/streaming-plugin';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useQueryClient } from '@tanstack/react-query';
import { getContent } from '../lib/utils';

export type EditorChatState =
  | 'cursorCommand'
  | 'cursorSuggestion'
  | 'selectionCommand'
  | 'selectionSuggestion';

export const aiChatItems = {
  accept: {
    icon: <Check />,
    label: 'Accept',
    value: 'accept',
    onSelect: ({ editor }) => {
      editor.getTransforms(AIChatPlugin).aiChat.accept();
      editor.tf.focus({ edge: 'end' });
    },
  },
  continueWrite: {
    icon: <Sparkles />,
    label: 'Just write more',
    value: 'default',
    onSelect: ({ editor, title, documentId, projectId, executeStream }) => {
      const content = getContent(editor, title);
      editor.getApi(AIChatPlugin).aiChat.hide();

      executeStream(async () =>
        editor.getApi(StreamingPlugin).startStreaming({
          content,
          type: 'default',
          documentId,
          projectId,
          editor,
        }),
      );
    },
  },
  introduction: {
    icon: <ListStartIcon />,
    value: 'introduction',
    label: 'Introduction',
    onSelect: ({ editor, title, documentId, projectId, executeStream }) => {
      const content = getContent(editor, title);
      editor.getApi(AIChatPlugin).aiChat.hide();

      executeStream(async () =>
        editor.getApi(StreamingPlugin).startStreaming({
          content,
          type: 'introduction',
          documentId,
          projectId,
          editor,
        }),
      );
    },
  },
  section: {
    icon: <AlignLeft />,
    value: 'section',
    label: 'Section (Multiple paragraphs)',
    onSelect: ({ editor, title, documentId, projectId, executeStream }) => {
      const content = getContent(editor, title);
      editor.getApi(AIChatPlugin).aiChat.hide();

      executeStream(async () =>
        editor.getApi(StreamingPlugin).startStreaming({
          content,
          type: 'section',
          documentId,
          projectId,
          editor,
        }),
      );
    },
  },
  paragraph: {
    icon: <AlignJustify />,
    value: 'paragraph',
    label: 'Paragraph (Single paragraph)',
    onSelect: ({ editor, title, documentId, projectId, executeStream }) => {
      const content = getContent(editor, title);
      editor.getApi(AIChatPlugin).aiChat.hide();

      executeStream(async () =>
        editor.getApi(StreamingPlugin).startStreaming({
          content,
          type: 'paragraph',
          documentId,
          projectId,
          editor,
        }),
      );
    },
  },
  list: {
    icon: <List />,
    value: 'list',
    label: 'List',
    onSelect: ({ editor, title, documentId, projectId, executeStream }) => {
      const content = getContent(editor, title);
      editor.getApi(AIChatPlugin).aiChat.hide();

      executeStream(async () =>
        editor.getApi(StreamingPlugin).startStreaming({
          content,
          type: 'list',
          documentId,
          projectId,
          editor,
        }),
      );
    },
  },
  quote: {
    icon: <Quote />,
    value: 'quote',
    label: 'Quote',
    onSelect: ({ editor, title, documentId, projectId, executeStream }) => {
      const content = getContent(editor, title);
      editor.getApi(AIChatPlugin).aiChat.hide();

      executeStream(async () =>
        editor.getApi(StreamingPlugin).startStreaming({
          content,
          type: 'quote',
          documentId,
          projectId,
          editor,
        }),
      );
    },
  },
  faq: {
    icon: <MessageCircleQuestion />,
    value: 'faq',
    label: 'FAQ',
    onSelect: ({ editor, title, documentId, projectId, executeStream }) => {
      const content = getContent(editor, title);
      editor.getApi(AIChatPlugin).aiChat.hide();

      executeStream(async () =>
        editor.getApi(StreamingPlugin).startStreaming({
          content,
          type: 'faq',
          documentId,
          projectId,
          editor,
        }),
      );
    },
  },
  discard: {
    icon: <X />,
    label: 'Discard',
    shortcut: 'Escape',
    value: 'discard',
    onSelect: ({ editor }) => {
      editor.getTransforms(AIPlugin).ai.undo();
      editor.getApi(AIChatPlugin).aiChat.hide();
    },
  },
  explain: {
    icon: <BadgeHelp />,
    label: 'Explain',
    value: 'explain',
    onSelect: ({ editor }) => {
      void editor.getApi(AIChatPlugin).aiChat.submit({
        prompt: {
          default: 'Explain {editor}',
          selecting: 'Explain',
        },
      });
    },
  },
  fixSpelling: {
    icon: <Check />,
    label: 'Fix spelling & grammar',
    value: 'fixSpelling',
    onSelect: ({ editor }) => {
      void editor.getApi(AIChatPlugin).aiChat.submit({
        prompt: 'Fix spelling and grammar',
      });
    },
  },
  improveWriting: {
    icon: <Wand />,
    label: 'Improve writing',
    value: 'improveWriting',
    onSelect: ({ editor, title }) => {
      const content = editor.api.string(editor.selection!);
      void editor.getApi(AIChatPlugin).aiChat.submit({
        prompt: 'this is unused but must be set',
        options: {
          data: {
            type: 'custom' as const,
            customPrompt: 'Improve writing',
            content,
          } as AiChatSubmit,
        },
      });
    },
  },
  insertBelow: {
    icon: <ListEnd />,
    label: 'Insert below',
    value: 'insertBelow',
    onSelect: ({ aiEditor, editor }) => {
      void editor.getTransforms(AIChatPlugin).aiChat.insertBelow(aiEditor);
    },
  },
  makeLonger: {
    icon: <ListPlus />,
    label: 'Make longer',
    value: 'makeLonger',
    onSelect: ({ editor, title }) => {
      const content = editor.api.string(editor.selection!);
      void editor.getApi(AIChatPlugin).aiChat.submit({
        prompt: 'this is unused but must be set',
        options: {
          data: {
            type: 'custom' as const,
            customPrompt: 'Make longer',
            content,
          } as AiChatSubmit,
        },
      });
    },
  },
  makeShorter: {
    icon: <ListMinus />,
    label: 'Make shorter',
    value: 'makeShorter',
    onSelect: ({ editor }) => {
      const content = editor.api.string(editor.selection!);
      void editor.getApi(AIChatPlugin).aiChat.submit({
        prompt: 'this is unused but must be set',
        options: {
          data: {
            type: 'custom' as const,
            customPrompt: 'Make shorter',
            content,
          } as AiChatSubmit,
        },
      });
    },
  },
  replace: {
    icon: <Check />,
    label: 'Replace selection',
    value: 'replace',
    onSelect: ({ aiEditor, editor }) => {
      void editor.getTransforms(AIChatPlugin).aiChat.replaceSelection(aiEditor);
    },
  },
  simplifyLanguage: {
    icon: <FeatherIcon />,
    label: 'Simplify language',
    value: 'simplifyLanguage',
    onSelect: ({ editor, title }) => {
      const content = editor.api.string(editor.selection!);
      void editor.getApi(AIChatPlugin).aiChat.submit({
        prompt: 'this is unused but must be set',
        options: {
          data: {
            type: 'custom' as const,
            customPrompt: 'Simplify language',
            content,
          } as AiChatSubmit,
        },
      });
    },
  },
  summarize: {
    icon: <Album />,
    label: 'Add a summary',
    value: 'summarize',
    onSelect: ({ editor }) => {
      void editor.getApi(AIChatPlugin).aiChat.submit({
        mode: 'insert',
        prompt: {
          default: 'Summarize {editor}',
          selecting: 'Summarize',
        },
      });
    },
  },
  tryAgain: {
    icon: <CornerUpLeft />,
    label: 'Try again',
    value: 'tryAgain',
    onSelect: ({ editor }) => {
      void editor.getApi(AIChatPlugin).aiChat.reload();
    },
  },
} satisfies Record<
  string,
  {
    icon: React.ReactNode;
    label: string;
    value: string;
    component?: React.ComponentType<{ menuState: EditorChatState }>;
    filterItems?: boolean;
    items?: { label: string; value: string }[];
    shortcut?: string;
    onSelect?: ({
      aiEditor,
      editor,
      title,
      projectId,
      documentId,
    }: {
      aiEditor: SlateEditor;
      editor: PlateEditor;
      title: string;
      projectId: number;
      documentId: number;
      executeStream: (func: () => Promise<void>) => void;
    }) => void;
  }
>;

const menuStateItems: Record<
  EditorChatState,
  {
    items: (typeof aiChatItems)[keyof typeof aiChatItems][];
    heading?: string;
  }[]
> = {
  cursorCommand: [
    {
      items: [
        aiChatItems.continueWrite,
        aiChatItems.introduction,
        aiChatItems.section,
        aiChatItems.paragraph,
        aiChatItems.list,
        aiChatItems.quote,
        aiChatItems.faq,
      ],
    },
  ],
  cursorSuggestion: [
    {
      items: [aiChatItems.accept, aiChatItems.discard, aiChatItems.tryAgain],
    },
  ],
  selectionCommand: [
    {
      items: [
        aiChatItems.improveWriting,
        aiChatItems.makeLonger,
        aiChatItems.makeShorter,
        aiChatItems.fixSpelling,
        aiChatItems.simplifyLanguage,
      ],
    },
  ],
  selectionSuggestion: [
    {
      items: [
        aiChatItems.replace,
        aiChatItems.insertBelow,
        aiChatItems.discard,
        aiChatItems.tryAgain,
      ],
    },
  ],
};

export const AIMenuItems = ({
  setValue,
}: {
  setValue: (value: string) => void;
}) => {
  const client = useQueryClient();
  const documentMetaTitle = useDocumentStore((s) => s.metaTitle);
  const { editor, useOption } = useEditorPlugin(AIChatPlugin);
  const { messages } = useOption('chat');
  const aiEditor = useOption('aiEditor')!;
  const isSelecting = useIsSelecting();
  const appState = useAppStore();
  const documentId = useDocumentStore((s) => s.id);

  const menuState = useMemo(() => {
    if (messages && messages.length > 0) {
      return isSelecting ? 'selectionSuggestion' : 'cursorSuggestion';
    }

    return isSelecting ? 'selectionCommand' : 'cursorCommand';
  }, [isSelecting, messages]);

  const menuGroups = useMemo(() => {
    const items = menuStateItems[menuState];

    return items;
  }, [menuState]);

  const executeStream = async (func: () => Promise<void>) => {
    try {
      client.setQueryData<Generating>(['autoGenerationStream'], 'live');
      await func();
      client.setQueryData<Generating>(['autoGenerationStream'], false);
    } catch (e) {
      client.setQueryData<Generating>(['autoGenerationStream'], false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (menuGroups.length > 0 && menuGroups[0].items.length > 0) {
      setValue(menuGroups[0].items[0].value);
    }
  }, [menuGroups, setValue]);

  return (
    <>
      {menuGroups.map((group, index) => (
        <CommandGroup key={index} heading={group.heading}>
          {group.items.map((menuItem) => (
            <CommandItem
              key={menuItem.value}
              className="[&_svg]:text-muted-foreground"
              value={menuItem.value}
              onSelect={() => {
                menuItem.onSelect?.({
                  aiEditor,
                  editor: editor,
                  title: documentMetaTitle,
                  projectId: appState.currentProject!.id,
                  documentId,
                  executeStream,
                });
              }}
            >
              {menuItem.icon}
              <span>{menuItem.label}</span>
            </CommandItem>
          ))}
        </CommandGroup>
      ))}
    </>
  );
};
