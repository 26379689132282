import { BlockSelection } from '@/types';
import { PlateEditor } from '@udecode/plate/react';
import { createContext, PropsWithChildren, useContext, useState } from 'react';

type CurrentEditorStateContext = {
  currentEditor?: PlateEditor;
  setCurrentEditor: (editor: PlateEditor) => void;
  selection: BlockSelection | null;
  setSelection: (selection: BlockSelection) => void;
};

const EditorStateContext = createContext<CurrentEditorStateContext>({
  setCurrentEditor: () => {},
  setSelection: () => {},
  selection: null,
});

export const useCurrentEditorState = () => {
  return useContext(EditorStateContext);
};

export const CurrentEditorStateProvider = ({ children }: PropsWithChildren) => {
  const [currentEditor, setCurrentEditor] = useState<PlateEditor | undefined>(
    undefined,
  );
  const [selection, setSelection] = useState<BlockSelection | null>(null);

  return (
    <EditorStateContext.Provider
      value={{
        currentEditor: currentEditor,
        setCurrentEditor: setCurrentEditor,
        selection,
        setSelection,
      }}
    >
      {children}
    </EditorStateContext.Provider>
  );
};
