import { prefersReducesMotion } from '@/utils';
import { useEffect, useState } from 'react';

type Props = {
  text: string;
};

export const AnimatedText = ({ text }: Props) => {
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    setCurrentText('');
    const interval = setInterval(() => {
      setCurrentText((p) => {
        if (p.length === text.length) {
          clearInterval(interval);
        }
        return text.slice(0, p.length + 1);
      });
    }, 30);
  }, [text]);

  if (prefersReducesMotion) {
    return <>{text}</>;
  }

  return (
    <>
      {currentText}
      {currentText.length < text.length && '_'}
    </>
  );
};
