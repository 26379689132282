import { PropsWithChildren, useContext } from 'react';
import { useMouseOverDraggableIcon, usePlateUiDrop } from '../lib/utils';
import { TElement } from '@udecode/plate';
import { Icons } from './icons';
import { IsTableContext } from './table-element';

export const DraggableElement = ({
  children,
  element,
  disabled,
}: PropsWithChildren<{ element: TElement; disabled?: boolean }>) => {
  const { invisiblity, ...methods } = useMouseOverDraggableIcon();
  const {
    attributes,
    listeners,
    setNodeRef,
    style,
    setActivatorNodeRef,
    isOver,
  } = usePlateUiDrop(element);

  const isTable = useContext(IsTableContext);

  if (disabled) {
    return children;
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full" style={style} {...methods} ref={setNodeRef}>
        {!isTable ? (
          <div
            className={`${invisiblity} w-5 select-none self-center`}
            contentEditable={false}
            ref={setActivatorNodeRef}
            {...attributes}
            {...listeners}
            tabIndex={-1}
          >
            <Icons.dragHandle className="h-4 w-4 text-muted-foreground" />
          </div>
        ) : (
          <div className="select-none" />
        )}
        <div className="w-full">{children}</div>
      </div>
      {isOver && <div className="h-[2px] w-full select-none bg-secondary" />}
    </div>
  );
};
