import { SelectionActions } from '@/Components/Table/components/SelectionActions';
import { useCopyTable } from '@/Components/Table/hooks';
import { SlimKeywordResource } from '@/api/openapiSchemas';
import { Calendar, Copy, ListPlus } from 'lucide-react';
import { AddKeywordsToListDialog } from '@/Pages/components';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useCreate } from '@/api/openapiComponents.ts';
import { useAppStore } from '@/Pages/AppLoader/stores.ts';

type Props = {
  selection: SlimKeywordResource[];
  resetSelection: () => void;
  operationId?: string;
  setSelection: (added: T[], removed: T[]) => void;
};

export const KeywordResearchTableHeaderActions = ({
  selection,
  resetSelection,
  operationId,
  setSelection
}: Props) => {
  const client = useQueryClient();

  const createPlannedContentKeywordMutation = useCreate();

  const appStore = useAppStore();

  const handleAddedKeywords = (listIds: number[]) => {
    client.setQueriesData(
      {
        predicate: (query) => query.queryKey.includes(operationId),
      },
      (oldData: { data: SlimKeywordResource[] }) => {
        return {
          ...oldData,
          data: oldData.data.map((keyword) => {
            if (listIds.some((id) => id === keyword.id)) {
              return {
                ...keyword,
                is_saved: true,
              };
            }
            return keyword;
          }),
        };
      },
    );
  };

  const handleCopyToClipboard = useCopyTable(
    selection.map((item) => item.name).join('\n'),
  );

  const [itemsToBeAddedToList, setItemsToBeAddedToList] = useState<
    { name: string; id: number }[]
  >([]);

  const handleSaveKeywords = (): boolean => {
    const itemsToBeSaved = selection.filter(value => !value.is_saved)

    createPlannedContentKeywordMutation.mutate({
      pathParams: {
        project: appStore.currentProject!.id,
      },
      body: {
        items: itemsToBeSaved.map((value) => ({
          keyword: value.name,
        })),
      },
    }, {
      onSuccess: () => {
        handleAddedKeywords(itemsToBeSaved.map((value) => value.id));
        setSelection(itemsToBeSaved.map((item) => ({
          ...item,
          is_saved: true,
        })), [])
      }
    })
  };

  return (
    <>
      <AddKeywordsToListDialog
        keywords={itemsToBeAddedToList}
        onSuccess={() => {
          handleAddedKeywords(selection.map((value) => value.id));
          setItemsToBeAddedToList([]);
          resetSelection();
        }}
        onClose={() => setItemsToBeAddedToList([])}
        asNewPlannedContent
      />
      <SelectionActions
        selection={selection}
        actions={[
          {
            prependIcon: Copy,
            text: 'Copy',
            variant: 'ghost',
            onClick: handleCopyToClipboard,
          },
          {
            prependIcon: ListPlus,
            size: 'sm',
            dense: true,
            text: 'Add to list',
            variant: 'ghost',
            onClick: () => {
              setItemsToBeAddedToList(
                selection.map((value) => ({
                  id: value.id,
                  name: value.name,
                })),
              );
            },
          },
          {
            prependIcon: Calendar,
            size: 'sm',
            dense: true,
            text: 'Save',
            variant: 'ghost',
            onClick: (): void => {
              handleSaveKeywords()
            }
          }
        ]}
      />
    </>
  );
};
