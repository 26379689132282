import { useState } from 'react';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import DifficultyLabel from '@/Components/Labels/DifficultyLabel';
import { KeywordResearchHeader } from './components/v2/KeywordResearchHeader';
import { useTable } from '@/Components/Table/hooks';
import { CheckIcon, SearchIcon } from 'lucide-react';
import {
  RangeSelector,
  formatRange,
} from '@/Components/Table/components/RangeSelector';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { formatThousandSeperator } from '@/utils';
import { StringSelector } from '@/Components/Table/components/StringSelector';
import { Button } from '@/Components/v2/Button';
import { KeywordResearchTableHeaderActions } from './components/KeywordResearchTableHeaderActions';
import { SlimKeywordResource } from '@/api/openapiSchemas';
import { useKeywordExport } from './hooks';
import {
  fetchProjectKeywordResearchRelatedExplorer,
} from '@/api/openapiComponents';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { ErrorAlert } from '@/Components/v2/Alert';
import { Input } from '@/Components/v2/Input/Input';
import PageContainer from '@/Components/PageContainer';
import { useQuery } from '@tanstack/react-query';
import MonthlySearchesGraph from '@/Pages/Keywords/components/MonthlySearchesGraph.tsx';

export default function LongTailKeywords() {
  const placeholder = useGetRandomPlaceHolder();

  const appState = useAppStore();

  const [showTable, setShowTable] = useState(false);

  const {
    tableState,
    setFilters,
    setSorting,
    selection,
    setSelection,
    resetSelection,
  } = useTable<
    'id' | 'keyword' | 'search_volume' | 'difficulty' | undefined,
    {
      search: string;
      search_volume_min: number;
      search_volume_max: number;
      difficulty_min: number;
      difficulty_max: number;
      exclude_phrase: string;
    },
    SlimKeywordResource
  >({
    sortBy: 'search_volume',
    sortDirection: 'DESC',
    filters: {
      search: '',
    },
  });

  const keywordQuery = useQuery({
    queryFn: () =>
      fetchProjectKeywordResearchRelatedExplorer({
        pathParams: {
          project: appState.currentProject!.id,
        },
        queryParams: {
          filters: tableState.filters,
        },
      }),
    queryKey: ['matching_terms_keywords'],
    enabled: showTable,
  });

  const onExportCSV = useKeywordExport(
    {
      sort_by: tableState.sortBy,
      sort_direction: tableState.sortDirection,
      ids:
        keywordQuery.data?.data.map((keyword) => keyword.id.toString()) ?? [],
    },
    'matching_terms_keywords',
  );

  const handleSubmit = () => {
    if (!tableState.filters) return;
    resetSelection();
    keywordQuery.refetch();
    setShowTable(true);
  };

  const isLoading = keywordQuery.isFetching;

  const errorHelper = new ErrorHelper(keywordQuery.error as any);

  appState.pageTitle('Long-tail');

  return (
    <Menus>
      <KeywordResearchHeader
        title="Long-tail Keywords"
        subtitle="Identify niche, low-competition keywords based on a seed keyword."
        backgroundImage="/svg/keyword-research-long-tail.png"
        bgColor="bg-[#EFF6FF]"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (e.isPropagationStopped()) {
              return;
            }

            handleSubmit();
          }}
        >
          <div className="flex gap-4">
            <div className="flex-grow">
              <Input
                value={tableState.filters?.search as string}
                onChange={(value) => setFilters({ search: value })}
                placeholder={placeholder.keyword}
                error={
                  errorHelper.isError()
                    ? errorHelper.hasFirst('filters.search')
                    : undefined
                }
              />
            </div>
            <div className="w-64">
              <Button
                fullWidth
                type="submit"
                onClick={handleSubmit}
                text="Search"
                size="lg"
                color="green"
                isLoading={isLoading}
                prependIcon={SearchIcon}
              />
            </div>
          </div>
        </form>
        <div className="flex select-none text-base">
          {/* Difficulty */}
          <Table.FilterPopover
            name="Difficulty"
            variant="ghost"
            filterName={formatRange({
              max: tableState.filters?.difficulty_max,
              min: tableState.filters?.difficulty_min,
              name: 'Difficulty',
            })}
            onRemoveFilter={() =>
              setFilters({
                difficulty_max: undefined,
                difficulty_min: undefined,
              })
            }
          >
            <RangeSelector
              onAccept={(values) =>
                setFilters({
                  difficulty_min: values.min,
                  difficulty_max: values.max,
                })
              }
              values={{
                max: tableState.filters?.difficulty_max,
                min: tableState.filters?.difficulty_min,
              }}
            />
          </Table.FilterPopover>
          {/* Search volume */}
          <Table.FilterPopover
            name="Search volume"
            variant="ghost"
            filterName={formatRange({
              max: tableState.filters?.search_volume_max,
              min: tableState.filters?.search_volume_min,
              name: 'Search volume',
            })}
            onRemoveFilter={() =>
              setFilters({
                search_volume_min: undefined,
                search_volume_max: undefined,
              })
            }
          >
            <RangeSelector
              values={{
                min: tableState.filters?.search_volume_min,
                max: tableState.filters?.search_volume_max,
              }}
              onAccept={(values) =>
                setFilters({
                  search_volume_min: values.min,
                  search_volume_max: values.max,
                })
              }
            />
          </Table.FilterPopover>

          {/* Exclude phrase */}
          <Table.FilterPopover
            name="Exclude phrase"
            variant="ghost"
            filterName={
              tableState.filters?.exclude_phrase
                ? `Exclude phrase: ${tableState.filters.exclude_phrase}`
                : undefined
            }
            onRemoveFilter={() =>
              setFilters({
                exclude_phrase: undefined,
              })
            }
          >
            <StringSelector
              name="Exclude phrase"
              value={tableState.filters?.exclude_phrase}
              onAccept={(value) => setFilters({ exclude_phrase: value })}
            />
          </Table.FilterPopover>
        </div>
      </KeywordResearchHeader>
      <div className="flex-grow overflow-y-auto">
        <PageContainer size="lg">
          {errorHelper.isError() && (
            <div className="mx-auto mt-2 w-full max-w-xl">
              <ErrorAlert title={errorHelper.message()} />
            </div>
          )}
          {/* Table */}
          {showTable && (
            <>
              <Table.Root
                selection={selection}
                onSelectionChange={setSelection}
                skeletonLoaders={7}
                sorting={tableState}
                onSortChange={(s) => setSorting(s, { submit: true })}
                isLoading={isLoading}
                items={keywordQuery.data?.data}
                columns={[
                  {
                    sortableHeader: false,
                    rightAlign: false,
                    heading: '',
                    width: 1,
                    render: (item) => (
                      <>
                        {item.is_saved && (
                          <span className="text-secondary">
                            <CheckIcon></CheckIcon>
                          </span>
                        )}
                      </>
                    )
                  },
                  {
                    property: 'name',
                    sortableHeader: true,
                    heading: 'TARGET KEYWORD',
                    render: (item) => <>{item.name}</>,
                  },
                  {
                    property: 'search_volume',
                    sortableHeader: true,
                    rightAlign: true,
                    heading: 'SEARCH VOLUME',
                    render: (item) => (
                      <>
                        {item.search_volume === 0
                          ? '< 10'
                          : formatThousandSeperator(item.search_volume)}
                      </>
                    ),
                  },
                  {
                    property: 'difficulty',
                    sortableHeader: true,
                    rightAlign: true,
                    heading: 'RANK DIFFICULTY',
                    render: (item) => (
                      <DifficultyLabel difficulty={item.difficulty} />
                    ),
                  },
                  {
                    property: 'monthly_searches',
                    sortableHeader: false,
                    heading: 'TREND',
                    rightAlign: true,
                    render: (item) => <MonthlySearchesGraph data={item.monthly_searches} />,
                  },
                ]}
              >
                <Table.Header csvExportOptions={onExportCSV}>
                  <Table.ResultsTotal
                    title="keywords"
                    isLoading={isLoading}
                    total={keywordQuery.data?.data.length}
                  />
                  <KeywordResearchTableHeaderActions
                    selection={selection}
                    resetSelection={resetSelection}
                    setSelection={setSelection}
                    operationId="matching_terms_keywords"
                  />
                </Table.Header>
                <Table.NoContent>
                  No keywords found. Try a different seed keyword
                </Table.NoContent>
              </Table.Root>
            </>
          )}
        </PageContainer>
      </div>
    </Menus>
  );
}
