import { Button, ButtonProps } from '@/Components/v2/Button';
import { Tooltip } from '@/Components/v2/Tooltip';
import { ReactNode } from '@tanstack/react-router';

type Props = {
  title: string;
  removeBorder?: boolean;
  actions?: ReactNode;
  buttonProps?: {
    toolTip?: {
      title: string;
      description: string;
    };
  } & ButtonProps;
};

export const SectionHeader = ({
  title,
  buttonProps,
  removeBorder,
  actions,
}: Props) => {
  return (
    <>
      <div className="flex w-full items-center justify-between">
        <div className="text-lg font-bold">{title}</div>
        <div className="flex items-center">
          {actions}
          {buttonProps &&
            (buttonProps.toolTip ? (
              <Tooltip
                disabled={!buttonProps.disabled}
                title={buttonProps.toolTip.title}
                description={buttonProps.toolTip.description}
                side="top"
              >
                <Button color="secondary" {...buttonProps} />
              </Tooltip>
            ) : (
              <Button color="secondary" {...buttonProps} />
            ))}
        </div>
      </div>
      <div
        className={`mt-2 h-0.5 w-full ${removeBorder ? '' : 'bg-gray-200'}`}
      />
    </>
  );
};
