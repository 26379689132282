import { ReactNode } from '@tanstack/react-router';

type Props = {
  icon?: ReactNode;
  action: ReactNode;
  name: string;
  description?: string;
};

export const SetupItem = ({ action, name, description, icon }: Props) => {
  return (
    <div className="flex w-full items-center justify-between rounded border border-primary-300 p-4 shadow">
      <div className="flex items-center">
        <div className="flex w-96 items-center gap-4">
          {icon}
          <p className="text-lg font-semibold text-primary">{name}</p>
        </div>
        <p className="text-primary">{description}</p>
      </div>
      {action}
    </div>
  );
};
