import {
  GraphState,
  useAnalyticsFeedSeoGroups,
  useGrowthSorting,
} from '@/Pages/Analytics/ProductAnalytics/hooks.ts';
import { UseNavigateResult } from '@tanstack/react-router';
import * as Table from '@/Components/Table';
import { useMerchantFeedSingle } from '@/api/openapiComponents.ts';
import { useAppStore } from '@/Pages/AppLoader/stores.ts';
import { calculateGrowth, sortAnalyticsData } from '@/Pages/Analytics/utils';
import { CellWithBar } from '@/Pages/Analytics/components/CellWithBar.tsx';
import { AnalyticsUrlActions } from '@/Pages/Analytics/components/AnalyticsUrlActions';
import { GrowthValueHeader } from '@/Pages/Analytics/components/GrowthValueHeader';
import { ValueWithGrowth } from '@/Pages/Analytics/components/ValueWithGrowth';
import ToggleButton from '@/Components/ToggleButton.tsx';
import { Group, SquarePen } from 'lucide-react';
import { Button } from '@/Components/v2/Button';
import { useState } from 'react';

type Props = {
  state: GraphState;
  navigate: UseNavigateResult<string>;
};
export const AnalyticsFeedGroupsTable = ({ state, navigate }: Props) => {
  const appState = useAppStore();

  const feedData = useMerchantFeedSingle(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: state.isGMCEnabled,
      retry: (failureCount, error) => {
        if (error.status === 404) {
          return false;
        }

        return failureCount <= 3;
      },
    },
  );

  const groupDataQuery = useAnalyticsFeedSeoGroups(
    appState.currentProject!.id,
    state,
    feedData.data?.data.groups?.map((group) => group.id) ?? [],
    feedData.isSuccess && state.isGMCEnabled,
  );

  const [groupColumnSorting, setGroupColumnSorting] = useState('clicks');
  const [groupGrowthSorting, setGroupGrowthSorting] =
    useGrowthSorting('dashboardgroup');

  const data = groupDataQuery.data?.toSorted(
    (a, b) => b.current?.clicks - (a.current?.clicks ?? 0),
  );

  const handleOpenPage = (slug: string) => {
    navigate({
      to: '/product-analytics/feed-groups/$groupId',
      params: {
        groupId: slug,
      },
    });
  };

  const topValue = data?.[0]?.current.clicks ?? 0;

  return (
    <Table.Root
      isLoading={groupDataQuery.isLoading || feedData.isLoading}
      skeletonLoaders={4}
      items={sortAnalyticsData(data ?? [], groupGrowthSorting, 'clicks')}
      columns={[
        {
          heading: 'Group',
          expanded: true,
          render: (item) => (
            <CellWithBar
              name={item.name}
              value={item.current.clicks}
              topValue={topValue}
              onClick={() => handleOpenPage(item.slug)}
              actions={
                <AnalyticsUrlActions onOpen={() => handleOpenPage(item.slug)} />
              }
            />
          ),
        },
        {
          heading: (
            <GrowthValueHeader
              heading="Clicks"
              onClick={() => setGroupColumnSorting('clicks')}
              selected={groupColumnSorting === 'clicks'}
            />
          ),
          rightAlign: true,
          render: (item) => (
            <ValueWithGrowth
              hideGrowth={state.range === 'P1Y' || state.range === 'P16M'}
              responsive={false}
              value={item.current.clicks}
              growth={calculateGrowth(item, 'clicks')}
            />
          ),
        },
        {
          heading: (
            <GrowthValueHeader
              heading="Impressions"
              onClick={() => setGroupColumnSorting('impressions')}
              selected={groupColumnSorting === 'impressions'}
            />
          ),
          rightAlign: true,
          render: (item) => (
            <ValueWithGrowth
              hideGrowth={state.range === 'P1Y' || state.range === 'P16M'}
              value={item.current.impressions}
              growth={calculateGrowth(item, 'impressions')}
            />
          ),
        },
        {
          heading: (
            <GrowthValueHeader
              heading="CTR"
              onClick={() => setGroupColumnSorting('ctr')}
              selected={groupColumnSorting === 'ctr'}
            />
          ),
          rightAlign: true,
          render: (item) => (
            <ValueWithGrowth
              hideGrowth={state.range === 'P1Y' || state.range === 'P16M'}
              value={(item.current.ctr * 100).toFixed(1)}
              growth={calculateGrowth(item, 'ctr')}
            />
          ),
        },
      ]}
    >
      <Table.Header>
        <div className="mt-8 flex w-full justify-between">
          <div className="flex gap-2">
            <h1 className="mb-1 text-xl font-semibold">Product Groups</h1>
            <ToggleButton
              value={groupGrowthSorting}
              onChange={setGroupGrowthSorting}
              options={[
                {
                  displayName: 'Popular',
                  value: 'popular',
                },
                {
                  displayName: 'Rising',
                  value: 'rising',
                },
                {
                  displayName: 'Falling',
                  value: 'falling',
                },
              ]}
            />
          </div>
          <Button
            prependIcon={SquarePen}
            onClick={() => navigate({ to: '/feed-seo' })}
            text="manage groups"
            variant="ghost"
          />
        </div>
      </Table.Header>

      <Table.NoContent>
        <div className="flex flex-col items-center gap-4">
          <Group size={128} className="text-primary-200" />
          <p className="text-lg font-bold text-primary">No FeedSEO groups</p>
        </div>
      </Table.NoContent>
    </Table.Root>
  );
};
