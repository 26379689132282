import { FullScreenPageContainer } from '@/Components/FullScreenPageContainer';
import { CreationStepControls } from './components/CreationStepControls';
import { feedSeoSteps } from './util';
import { useNavigate, useRouter } from '@tanstack/react-router';
import {
  useMerchantFeedAttributeIndex,
  useMerchantFeedGroupShow,
  useMerchantFeedGroupUpdate,
} from '@/api/openapiComponents';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { AttributeSelectionItem } from './components/AttributeSelectionItem';
import { Button } from '@/Components/v2/Button';
import { Eye, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useAppStore } from '../AppLoader/stores';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import Toggle from '@/Components/Toggle';
import { MerchantFeedAttributeResource } from '@/api/openapiSchemas';
import { FeedPreviewDialog } from './components/FeedPreviewDialog';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { ConfirmDialog } from '@/Components/ConfirmDialog';

type Props = {
  feedId: string;
  optimizationGroupId: number;
  isNew: boolean;
};

export const ChooseAttributes = ({
  optimizationGroupId,
  feedId,
  isNew,
}: Props) => {
  const appState = useAppStore();
  const navigate = useNavigate();
  const router = useRouter();

  const [showMissingCreditsDialog, setShowMissingCreditsDialog] =
    useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState<number[]>([]);
  const [attributeToBePreviewed, setAttributeToBePreviewed] =
    useState<MerchantFeedAttributeResource>();

  const updateGroupMutation = useMerchantFeedGroupUpdate();
  const attributeQuery = useMerchantFeedAttributeIndex({});
  const groupQuery = useMerchantFeedGroupShow({
    pathParams: {
      project: appState.currentProject!.id,
      merchantFeed: feedId,
      group: optimizationGroupId,
    },
  });

  const isLoading = attributeQuery.isLoading || groupQuery.isLoading;

  useEffect(() => {
    if (groupQuery.isSuccess && attributeQuery.isSuccess) {
      if (isNew) {
        setSelectedAttributes(
          attributeQuery.data?.data.map((attr) => attr.id) ?? [],
        );
      } else {
        setSelectedAttributes(
          groupQuery.data?.data.attributes?.map((attr) => attr.id) ?? [],
        );
      }
    }
  }, [groupQuery.isSuccess, attributeQuery.isSuccess]);

  const handleSubmit = () => {
    if (missingCredits > 0) {
      return setShowMissingCreditsDialog(true);
    }
    updateGroupMutation.mutate(
      {
        body: {
          filters: groupQuery.data?.data.filters,
          optimizable_attributes: selectedAttributes.map((id) => ({
            attribute_id: id,
            additional_instructions: '',
          })),
        },
        pathParams: {
          group: optimizationGroupId,
          merchantFeed: feedId,
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          navigate({
            to: `/feed-seo/$feedId/finalize/$optimizationGroupId`,
            params: { feedId, optimizationGroupId: '' + optimizationGroupId },
          });
        },
      },
    );
  };

  const creditCost = Number(groupQuery.data?.data.items_count ?? 0);
  const missingCredits =
    creditCost -
    Number(
      appState.subscription?.usage.credits.merchant_feed_product_optimizations
        .available ?? 0,
    );

  const isEverythingSelected =
    attributeQuery.data?.data.length === selectedAttributes.length;

  const errorHelper = new ErrorHelper(updateGroupMutation.error);

  return (
    <>
      <ConfirmDialog
        isOpen={showMissingCreditsDialog}
        title={'Not enough credits'}
        description={[
          `You need an additional ${missingCredits} credits to perform this action.`,
        ]}
        confirmText="Buy more credits"
        onClose={() => setShowMissingCreditsDialog(false)}
        onConfirm={() => {
          navigate({ to: '/account/subscription' });
        }}
      />
      <FeedPreviewDialog
        attribute={attributeToBePreviewed}
        feedId={feedId}
        optimizationGroupId={optimizationGroupId}
        isOpen={!!attributeToBePreviewed}
        onClose={() => setAttributeToBePreviewed(undefined)}
      />
      <FullScreenPageContainer
        title={`Optimization Group`}
        subtitle={`${groupQuery.data?.data.name} | ${groupQuery.data?.data.items_count} products`}
        actions={
          <Button
            text="Close"
            prependIcon={X}
            variant="ghost"
            onClick={() => navigate({ to: '/feed-seo' })}
          />
        }
        controls={
          <CreationStepControls
            error={errorHelper.message()}
            steps={feedSeoSteps}
            currentStep={1}
            onPrevious={() => router.history.back()}
            onNext={handleSubmit}
            disabled={isLoading}
            isLoading={updateGroupMutation.isPending}
          />
        }
      >
        <InputDecoration
          label="Attributes to optimize"
          actions={
            <div className="flex items-center gap-2 font-bold">
              SELECT ALL
              <Toggle
                value={isEverythingSelected}
                onChange={() =>
                  setSelectedAttributes(
                    isEverythingSelected
                      ? []
                      : (attributeQuery.data?.data?.map((attr) => attr.id) ??
                          []),
                  )
                }
              />
            </div>
          }
        >
          <div className="overflow-hidden rounded border border-primary-300 shadow">
            {isLoading ? (
              <>
                <div className="flex flex-col gap-2 py-2">
                  <SkeletonLoader height="sm" />
                  <SkeletonLoader height="sm" />
                  <SkeletonLoader height="sm" />
                  <SkeletonLoader height="sm" />
                  <SkeletonLoader height="sm" />
                </div>
              </>
            ) : (
              attributeQuery.data?.data.map((attribute) => (
                <AttributeSelectionItem
                  key={attribute.id}
                  icon={attribute.icon}
                  onChange={() => {
                    if (selectedAttributes.includes(attribute.id)) {
                      setSelectedAttributes(
                        selectedAttributes.filter(
                          (attr) => attr !== attribute.id,
                        ),
                      );
                    } else {
                      setSelectedAttributes([
                        ...selectedAttributes,
                        attribute.id,
                      ]);
                    }
                  }}
                  selected={selectedAttributes.includes(attribute.id)}
                  name={attribute.display_name ?? ''}
                  description={attribute.description ?? ''}
                  action={
                    <Button
                      text="preview"
                      prependIcon={Eye}
                      variant="ghost"
                      onClick={() => setAttributeToBePreviewed(attribute)}
                    />
                  }
                />
              ))
            )}
          </div>
        </InputDecoration>
      </FullScreenPageContainer>
    </>
  );
};
