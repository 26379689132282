import { LucideProps } from 'lucide-react';
import { IconBadge } from './IconBadge';
import { SimpleCheckbox } from '@/Components/SimpleCheckbox';

type Props = {
  icon: React.ComponentType<LucideProps> | string;
  name: string;
  description: string;
  action: React.ReactNode;
  selected?: boolean;
  onChange: (selected: boolean) => void;
};

export const AttributeSelectionItem = ({
  action,
  description,
  onChange,
  icon,
  name,
  selected,
}: Props) => {
  return (
    <div className="flex w-full items-center gap-4 border-b border-b-primary-300">
      <div className="flex-shrink-0 p-2">
        <IconBadge
          icon={icon || 'ban'}
          className="bg-secondary-200"
          cornerFlip
        />
      </div>
      <div className="w-72 flex-shrink-0 text-lg font-bold text-primary">
        {name}
      </div>
      <div className="flex-grow truncate text-primary">{description}</div>
      <div className="flex-shrink-0">{action}</div>
      <div
        className={`h-16 w-20 ${selected ? 'bg-green-300' : 'bg-gray-100'} flex flex-shrink-0 items-center justify-center`}
      >
        <SimpleCheckbox checked={!!selected} onChange={onChange} />
      </div>
    </div>
  );
};
