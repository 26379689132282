import { autoformatPlugin } from '../lib/autoformatPlugin';
import { BasicMarksPlugin } from '@udecode/plate-basic-marks/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { HeadingPlugin } from '@udecode/plate-heading/react';
import { NodeIdPlugin } from '@udecode/plate-node-id';
import { ExitBreakPlugin, SoftBreakPlugin } from '@udecode/plate-break/react';

import { BlockSelectionPlugin } from '@udecode/plate-selection/react';
import { AIChatPlugin, AIPlugin } from '@udecode/plate-ai/react';
import { AIMenu } from '../components/ai-menu';
import { SlashPlugin } from '@udecode/plate-slash-command/react';
import { autoformatListPlugin } from './autoformatListPlugin';
import {
  ListItemContentPlugin,
  ListItemPlugin,
  ListPlugin,
  TodoListPlugin,
} from '@udecode/plate-list/react';
import { ImagePlugin, PlaceholderPlugin } from '@udecode/plate-media/react';
import { LinkPlugin } from '@udecode/plate-link/react';
import { LinkFloatingToolbar } from '../components';
import { HorizontalRulePlugin } from '@udecode/plate-horizontal-rule/react';
import { TablePlugin } from '@udecode/plate-table/react';
import { EmojiPlugin } from '@udecode/plate-emoji/react';
import emojiMartData from '@emoji-mart/data';
import { ResetNodePlugin } from '@udecode/plate-reset-node/react';
import {
  createPlatePlugin,
  ParagraphPlugin,
  PlateEditor,
} from '@udecode/plate/react';
import {
  isSelectionAtCodeBlockStart,
  unwrapCodeBlock,
} from '@udecode/plate-code-block';
import { MarkdownPlugin } from '@udecode/plate-markdown';
import { HEADING_LEVELS } from '@udecode/plate-heading';
import { VoidElement } from '../components/void-element';
import { useAIChatHooks } from '../use-chat-hook';
import { StreamingPlugin } from './streaming-plugin';
import copyToClipboard from 'copy-to-clipboard';
import { plateToHTML } from '@/Pages/Document/utils';
import { BlockType, isLeafNode, LeafType, NodeTypesEnum } from '@/types';

const resetBlockTypesCommonRule = {
  types: [BlockquotePlugin.key, TodoListPlugin.key],
  defaultType: ParagraphPlugin.key,
};

const resetBlockTypesCodeBlockRule = {
  types: [],
  defaultType: ParagraphPlugin.key,
  onReset: unwrapCodeBlock,
};

export const blockSelectionReadOnlyPlugin = BlockSelectionPlugin.configure({
  api: {},
  extendEditor: null,
  options: {},
  render: {},
  useHooks: null,
  handlers: {},
});

export const EditableVoidPlugin = createPlatePlugin({
  key: 'editable-void',
  node: {
    component: VoidElement,
    isElement: true,
    isVoid: true,
  },
});

const getPlainText = (node: BlockType | LeafType, text: string) => {
  if (isLeafNode(node)) {
    text = text + node.text;
  } else {
    for (const n of node.children) {
      if (!isLeafNode(n) && n.type === NodeTypesEnum.LI) {
        text = getPlainText(n, text) + '\n';
      } else {
        text = getPlainText(n, text);
      }
    }
  }
  return text;
};

const handleCopy = (editor: PlateEditor) => {
  const fragment = editor.api.getFragment();
  setTimeout(() => {
    copyToClipboard(' ', {
      onCopy: (dataTransfer) => {
        const data = dataTransfer as DataTransfer;
        if (!data) return;
        const selectedEntries = fragment;
        let plainText = '';
        selectedEntries.forEach((entry) => {
          const plain = getPlainText(entry as BlockType, '');
          plainText += `${plain}${plain.endsWith('\n') ? '' : '\n'}`;
        });

        const html = plateToHTML(selectedEntries as BlockType[]);
        data.setData('text/html', html);
        data.setData('text/plain', plainText);
      },
    });
  }, 100);
  return true;
};

export const plugins = [
  EditableVoidPlugin,
  // Autoformat plugins
  autoformatListPlugin,
  autoformatPlugin,

  // AI Plugins
  AIPlugin,
  AIChatPlugin.extend(() => ({
    useHooks: useAIChatHooks,
  })).configure({
    render: { afterEditable: () => <AIMenu /> },
  }),

  // List Plugins
  ListPlugin,
  TodoListPlugin,
  ListItemPlugin,
  ListItemContentPlugin,
  ParagraphPlugin,

  StreamingPlugin,

  SoftBreakPlugin.configure({
    options: {
      rules: [
        { hotkey: 'shift+enter' },
        {
          hotkey: 'enter',
          query: {
            allow: [BlockquotePlugin.key, TablePlugin.key],
          },
        },
      ],
    },
  }),

  ImagePlugin,

  ResetNodePlugin.configure({
    options: {
      rules: [
        {
          ...resetBlockTypesCommonRule,
          hotkey: 'Enter',
          predicate: (editor) =>
            editor.api.isEmpty(editor.selection, { block: true }),
        },
        {
          ...resetBlockTypesCommonRule,
          hotkey: 'Backspace',
          predicate: (editor) => editor.api.isAt({ start: true }),
        },
        {
          ...resetBlockTypesCodeBlockRule,
          hotkey: 'Backspace',
          predicate: isSelectionAtCodeBlockStart,
        },
      ],
    },
  }),
  ExitBreakPlugin.configure({
    options: {
      rules: [
        {
          hotkey: 'mod+enter',
        },
        {
          before: true,
          hotkey: 'mod+shift+enter',
        },
        {
          hotkey: 'enter',
          level: 1,
          query: {
            allow: HEADING_LEVELS,
            end: true,
            start: true,
          },
          relative: true,
        },
      ],
    },
  }),

  SlashPlugin.configure({
    options: {
      trigger: '/',
      triggerPreviousCharPattern: /^$/,
    },
  }),
  EmojiPlugin.configure({ options: { data: emojiMartData } }),

  // Dnd plugins
  NodeIdPlugin,

  LinkPlugin.extend({
    render: {
      afterEditable: () => <LinkFloatingToolbar />,
    },
  }),

  PlaceholderPlugin,

  HorizontalRulePlugin,
  MarkdownPlugin.configure({ options: { indentList: true } }),
  ImagePlugin,
  HeadingPlugin.configure({ options: { levels: 4 } }),
  BlockquotePlugin,
  BasicMarksPlugin,
  TablePlugin.configure({
    options: {},
  }),
  createPlatePlugin({
    key: 'CopyPastePlugin',
    handlers: {
      onCopy: (editor) => {
        return handleCopy(editor);
      },
      onCut: (editor) => {
        return handleCopy(editor);
      },
    },
  }),
];
