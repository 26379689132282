import { DocumentResource } from '@/api/openapiSchemas';
import { useCallback, useEffect, useState } from 'react';
import { useCurrentEditorState } from './EditorStateProvider';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { Checkmark } from './ReportSection/components/Checkmark';
import { Report } from './ReportSection/Report';
import { NoKeywordPrompt } from './NoKeywordPropmpt';
import { Chat } from './Chat/Chat';
import { Workflows } from './Workflows/Workflows';
import { Tools } from './Tools/Tools';
import { CurrentSlideOver } from '@/types';
import { Dialog } from '@/Components/Dialog';
import { CompetitorTable } from './ReportSection/components/CompetitorTable';
import { useQuery } from '@tanstack/react-query';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useSearch } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import {
  ChevronLeft,
  ChevronsLeft,
  ChevronsRight,
  Files,
  FolderOpen,
  PanelLeft,
  Plus,
  SquarePen,
} from 'lucide-react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';

type Props = {
  document: DocumentResource;
  hideTabs?: boolean;
  onOpenEditor?: () => void;
  onWorkflowSelect: (workflowId: number) => void;
  onOpenSidebar?: () => void;
  setCurrentSlideOver: (slideOver?: CurrentSlideOver) => void;
  refetchDocument: () => void;
};

export const EditorSideBar = ({
  document,
  hideTabs,
  onOpenEditor,
  onOpenSidebar,
  refetchDocument,
  onWorkflowSelect,
  setCurrentSlideOver,
}: Props) => {
  const appState = useAppStore();
  const search = useSearch({ from: '/documents/$documentId' });

  const [tabIndex, setTabIndex] = useState(0);
  const [showKeywordDistribution, setShowKeywordDistribution] = useState(false);
  const {
    selection,
    currentEditor: editor,
    setSelection,
  } = useCurrentEditorState();

  const { data: isGenerating } = useQuery<boolean>({
    queryKey: ['autoGenerationStream'],
  });

  const isComplete = () => {
    if (!document.keyword) return false;
    if (
      (document.document_report?.total_score ?? 0) >=
      (document.document_report?.total_target_score ?? 0)
    )
      return true;

    const metaTitle =
      document.document_report?.meta_title_competitiveness_score;
    if (metaTitle?.is_completed) return false;

    const metaDescription = document.document_report?.meta_description;
    if (metaDescription?.is_completed) return false;

    const keyword = document.document_report?.internal_links;
    if (keyword?.is_completed) return false;

    const internalLinks = document.document_report?.internal_links;
    if (internalLinks?.is_completed) return false;

    const missingKeywords = document.document_report?.missing_keywords;
    if (missingKeywords?.some((keyword) => !keyword.is_completed)) return false;

    const wordCount = document.document_report?.word_count;
    if (wordCount?.is_completed) return false;

    const h1 = document.document_report?.title_competitiveness_score;
    if (h1?.is_completed) return false;
    return true;
  };

  const handleDeselect = useCallback(() => {
    if (editor) {
      setSelection((prev) => {
        if (prev) {
          return { ...prev, selected: { ...prev.selected, showInChat: false } };
        }
        return prev;
      });
    }
  }, [editor]);

  useEffect(() => {
    if (selection && tabIndex !== 1 && !search.v2) {
      setTabIndex(1);
    }
    if (selection && tabIndex !== 0 && search.v2) {
      setTabIndex(0);
    }
  }, [selection]);

  return (
    <>
      <Dialog
        title="Competitor overview"
        size={'xl'}
        isOpen={showKeywordDistribution}
        handleClose={() => setShowKeywordDistribution(false)}
      >
        <CompetitorTable
          keyword={document.keyword?.name}
          competitors={document?.document_report?.competitors ?? []}
          keywordProperty="keywords"
          keywords={document.document_report?.missing_keywords ?? []}
        />
      </Dialog>
      <div
        className={`w-full ${search.v2 ? 'flex h-10 justify-between border-b border-primary-200' : ''}`}
      >
        <div className="flex items-center pl-2">
          {search.v2 && (
            <div className="relative">
              <IconButton icon={PanelLeft} onClick={onOpenSidebar} />
              <div className="absolute right-1 top-1 h-3 w-3 rounded-full border-2 border-primary-100 bg-blue" />
            </div>
          )}
          <div
            className={`${search.v2 ? 'ml-3 h-10' : 'mx-auto mt-2 h-12 w-full max-w-2xl px-6'} pt-1`}
          >
            {!hideTabs && (
              <Tabs
                onChange={setTabIndex}
                noBorder={search.v2}
                tabs={[
                  ...(search.v2
                    ? [
                        {
                          text: 'CHAT',
                        },
                        {
                          text: 'SEO',
                          appendIcon: isComplete() ? undefined : (
                            <Checkmark checked={false} />
                          ),
                        },
                      ]
                    : [
                        {
                          text: 'SEO',
                          appendIcon: isComplete() ? undefined : (
                            <Checkmark checked={false} />
                          ),
                        },
                        {
                          text: 'CHAT',
                        },
                      ]),
                  {
                    text: 'WORKFLOWS',
                  },
                  {
                    text: 'TOOLS',
                  },
                ]}
                value={tabIndex}
              />
            )}
          </div>
        </div>
        {search.v2 && (
          <div className="flex items-center gap-1 pr-4">
            {onOpenEditor && (
              <Button
                variant="ghost"
                text="Open editor"
                prependIcon={ChevronsLeft}
                onClick={onOpenEditor}
              />
            )}
            <IconButton icon={SquarePen} />
            <IconButton icon={FolderOpen} />
          </div>
        )}
      </div>
      <div className="mx-auto w-full flex-grow overflow-hidden">
        {((tabIndex === 0 && !search.v2) || (tabIndex === 1 && search.v2)) &&
          !hideTabs &&
          (document.keyword ? (
            <Report
              currentProject={appState.currentProject!}
              setCurrentSlideOver={setCurrentSlideOver}
              document={document}
              editor={editor}
              targetKeyword={document.keyword.name}
              isGenerating={isGenerating}
              isLoadingReport={Boolean(
                document.document_report?.is_generating ?? true,
              )}
            />
          ) : (
            <div className="mx-auto flex h-full w-full max-w-2xl flex-col items-center overflow-y-auto pt-10">
              <NoKeywordPrompt
                document={document}
                onSetKeyword={refetchDocument}
              />
            </div>
          ))}
        {((tabIndex === 1 && !search.v2) ||
          (tabIndex === 0 && search.v2) ||
          hideTabs) && (
          <Chat
            deselectSelection={handleDeselect}
            document={document}
            selection={selection}
          />
        )}
        {tabIndex === 2 && !hideTabs && (
          <Workflows document={document} onSelectWorkflow={onWorkflowSelect} />
        )}
        {tabIndex === 3 && !hideTabs && (
          <Tools
            document={document}
            onOpenCompetitorBenchmarkTable={() =>
              setShowKeywordDistribution(true)
            }
            setCurrentSlideOver={setCurrentSlideOver}
          />
        )}
      </div>
    </>
  );
};
