import { redirect } from '@tanstack/react-router';
import { RouterContext } from './types';

type Guard<T = undefined> = (
  context: RouterContext,
  to: string | undefined,
  params: T,
) => void;

export const hasFeature: Guard<string> = (context, _, feature: string) => {
  if (!context.hasFeature(feature)) {
    throw redirect({
      to: '/',
    });
  }
};

export const isAuthenticated: Guard = (context, to?: string) => {
  if (!context.isAuthenticated) {
    if (to) {
      throw redirect({
        to,
      });
    }
    throw redirect({
      to: '/login',
      search: {
        redirect: window.location.pathname,
      },
    });
  }
  if (!context.hasOrganisation) {
    //TODO: redirect to organisation page when it is implemented
  }
};

export const hasProject: Guard = (context) => {
  if (!context.hasProject) {
    throw redirect({
      to: '/onboarding/create-project',
    });
  }
};

export const hasNoProject: Guard = (context) => {
  if (context.hasProject) {
    throw redirect({
      to: context.projectCount > 1 ? '/' : '/onboarding/create-draft',
    });
  }
};

export const hasActiveSubscription: Guard = (context) => {
  if (context.hasChurnedSubscription) {
    throw redirect({
      to: '/subscription-expired',
    });
  }
  if (!context.hasActiveSubscription) {
    throw redirect({
      to: '/finalize',
    });
  }
  if (context.hasUnpaidInvoices) {
    throw redirect({ to: '/subscription-unpaid' });
  }
};

export const hasNoSubscription: Guard = (context) => {
  if (context.hasActiveSubscription) {
    throw redirect({
      to: '/',
    });
  }
};

export const hasUnpaidSubscription: Guard = (context) => {
  if (!context.hasUnpaidInvoices) {
    throw redirect({
      to: '/',
    });
  }
};

export const guards = (context: RouterContext, guards: Guard[]) => {
  for (const guard of guards) {
    guard(context);
  }
};
