import { GoogleLogo } from '@/Components/GoogleLogo';
import { Button } from '@/Components/v2/Button';
import { useAppStore } from '@/Pages/AppLoader/stores';
import {
  GetGoogleMerchantCenterResponse,
  SelectAccountError,
  SelectAccountResponse,
  SelectAccountVariables,
  SetupGoogleMerchantCenterError,
  SetupGoogleMerchantCenterResponse,
  SetupGoogleMerchantCenterVariables,
} from '@/api/openapiComponents';
import { UseMutateFunction } from '@tanstack/react-query';
import { GMCChooseAccountDialog } from '@/Pages/Settings/Connections/components/GMCChooseAccountDialog.tsx';
import { GoogleMerchantDialogState } from '@/Pages/Settings/hooks.ts';

export type GoogleConsoleConnectProps = {
  onConnect?: () => void;
  googleMerchantDialog: GoogleMerchantDialogState;
  setGoogleMerchantDialog: React.Dispatch<
    React.SetStateAction<GoogleMerchantDialogState>
  >;
  hasNoAvailableAccounts: boolean;
  selectAccountForGoogleMerchantCenter: UseMutateFunction<
    SelectAccountResponse,
    SelectAccountError,
    SelectAccountVariables,
    unknown
  >;
  setupGoogleMerchantCenter: UseMutateFunction<
    SetupGoogleMerchantCenterResponse,
    SetupGoogleMerchantCenterError,
    SetupGoogleMerchantCenterVariables,
    unknown
  >;
  isSettingUpGoogleMerchantCenter: boolean;
  initialMerchantCenterState: GoogleMerchantDialogState;
  isSelectingAccountForGoogleMerchantCenter: boolean;
  googleMerchantCenterData: GetGoogleMerchantCenterResponse | undefined;
  isGettingGoogleMerchantCenterData: boolean;
  hideDialog?: boolean;
  page?: string;
  message?: string;
};
export const GoogleMerchantCenterConnect = ({
  hideDialog,
  googleMerchantDialog,
  setGoogleMerchantDialog,
  selectAccountForGoogleMerchantCenter,
  setupGoogleMerchantCenter,
  initialMerchantCenterState,
  isSelectingAccountForGoogleMerchantCenter,
  isGettingGoogleMerchantCenterData,
  isSettingUpGoogleMerchantCenter,
  page,
  message,
  onConnect,
  hasNoAvailableAccounts,
  googleMerchantCenterData
}: GoogleConsoleConnectProps) => {
  const projectId = useAppStore((state) => state.currentProject!.id);

  const shouldShowDialog = () => {
    if (isGettingGoogleMerchantCenterData) {
      return false;
    }
    if (googleMerchantCenterData?.data.state !== 'active' || !googleMerchantCenterData?.data.settings.account) {
      return true;
    }
    return false;
  };

  return shouldShowDialog() ? (
    <>
      <GMCChooseAccountDialog
        isLoading={isSelectingAccountForGoogleMerchantCenter}
        isOpen={googleMerchantDialog.isOpen}
        hasNoAvailableAccounts={hasNoAvailableAccounts}
        onConnect={() => {
          selectAccountForGoogleMerchantCenter({
            pathParams: {
              project: projectId,
            },
            body: {
              account_id: googleMerchantDialog.selectedAccount!,
            },
          });
          setGoogleMerchantDialog(initialMerchantCenterState);
          onConnect?.();
        }}
        setGoogleDialog={setGoogleMerchantDialog}
        googleDialog={googleMerchantDialog}
      />
      {!hideDialog && (
        <div className="mx-auto mt-[420px] w-[50%] rounded-xl bg-white px-4 py-8 shadow-base-small">
          <div className="flex flex-col justify-center gap-4 text-center text-primary">
            <h1 className="text-xl font-bold">Connect to see {page}</h1>
            {message && <p>{message}</p>}
            <div className="flex justify-center">
              <Button
                prependIcon={<GoogleLogo />}
                text="Connect Merchant Center"
                color="secondary"
                isLoading={isSettingUpGoogleMerchantCenter}
                onClick={setupGoogleMerchantCenter as any}
              />
            </div>
          </div>
        </div>
      )}
    </>
  ) : null;
};
