import { createContext, useCallback, useContext, useEffect } from 'react';
import { useAppStore } from './stores';
import Router from '../Router/Router';
import HashIds from 'hashids';
import { useInitApp } from './hooks/useInitApp';
import { SubscriptionResource } from '@/api/openapiSchemas';
import { namedDebounce } from '@/utils';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';

export const hasher = new HashIds('iaoes', 10);

const FeatureFlaggingContext = createContext<{
  hasFeature: (featureName: string) => boolean;
}>({ hasFeature: () => false });

export const useFeatureFlagging = () => {
  return useContext(FeatureFlaggingContext);
};

export default function AppLoader() {
  const appState = useAppStore();

  const handleInitializeApp = useInitApp();

  useEffect(() => {
    handleInitializeApp();
  }, []);

  useEffect(() => {
    if (appState.organisation) {
      const url = `organisations.${appState.organisation?.id}`;
      window.Echo.private(url).listen(`SubscriptionUpdatedEvent`, () => {
        namedDebounce(
          'refreshSubscription',
          () => {
            appState.refreshSubscription();
          },
          500,
        );
      });
      return () => {
        window.Echo.leave(url);
      };
    }
  }, [appState.organisation?.id]);

  const hasFeature = (featureName: string) => {
    return !!appState.organisation?.features?.some((feature) => {
      return feature.name === featureName;
    });
  };

  const hasPermission = (
    permissionName: keyof SubscriptionResource['permissions'],
  ) => {
    return !!appState.subscription?.permissions[permissionName];
  };

  if (!appState.initialized) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <LoadingSpinner size="2xl" color="secondary" />
      </div>
    );
  }

  return (
    <FeatureFlaggingContext.Provider value={{ hasFeature }}>
      <Router
        projectCount={appState.projects?.length ?? 0}
        isAuthenticated={!!appState.auth}
        hasProject={!!appState.currentProject}
        hasActiveSubscription={!!appState.subscription?.is_active}
        hasChurnedSubscription={appState.subscription?.is_churned === true}
        hasOrganisation={!!appState.organisation}
        hasUnpaidInvoices={!!appState.subscription?.is_unpaid}
        hasPermission={hasPermission}
        hasFeature={hasFeature}
      />
    </FeatureFlaggingContext.Provider>
  );
}
