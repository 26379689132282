import React, { useState } from 'react';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { getCookieValue } from '@/utils';
import { Button } from '@/Components/v2/Button';
import { Errors } from '@/Components/Errors';
import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { SEO_AI_AUTH } from '@/api/openapiFetcher';
import {
  fetchOrganisationSubscriptionIndex,
  fetchOrganisationSubscriptionList,
  fetchProductsIndex,
  useAuthRegister,
  useAuthToken,
  useSetupCheckout,
} from '@/api/openapiComponents';

type FormValues = {
  name: string;
  password: string;
};

export default function Register() {
  const appState = useAppStore();

  const initialValues: FormValues = {
    name: '',
    password: '',
  };

  const [formValues, setFormValues] = useState(initialValues);

  const authTokenMutation = useAuthToken();
  const registerMutation = useAuthRegister();

  const setupCheckoutMutation = useSetupCheckout();

  const onHandleChange = (value: string, name: string) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const trackingCookie = getCookieValue('seo-ai-app-data');
    let trackingData: any;
    try {
      trackingData = trackingCookie ? JSON.parse(trackingCookie) : undefined;
    } catch (e) {}

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    registerMutation.mutate(
      {
        body: {
          name: formValues.name,
          password: formValues.password,
          token: token!,
          tracking_data: trackingData,
        },
      },
      {
        onSuccess: (userData) => {
          authTokenMutation.mutate(
            {
              body: {
                email: userData.data.email,
                password: formValues.password,
              },
            },
            {
              onSuccess: async (data) => {
                localStorage.setItem(SEO_AI_AUTH, data.data.token);

                const plans = await fetchProductsIndex({
                  queryParams: {
                    limit: 100,
                  },
                });
                const monthlyBasicPlan = plans.data.find(
                  (plan) =>
                    plan.name?.toLowerCase() === 'basic' &&
                    plan.interval === 'month',
                );

                const subscription = await fetchOrganisationSubscriptionList({
                  pathParams: {
                    organisation: userData.data.org_id,
                  },
                });

                const isElligibleForTrial = subscription.data?.length === 0;

                setupCheckoutMutation.mutate(
                  {
                    pathParams: {
                      organisation: userData.data.org_id!,
                    },
                    body: {
                      price: monthlyBasicPlan!.id,
                      enable_trial: isElligibleForTrial,
                    },
                  },
                  {
                    onSuccess: (data) => {
                      if (data?.data?.link) {
                        window.location.replace(data.data.link);
                      }
                    },
                  },
                );
              },
            },
          );
        },
      },
    );
  };

  appState.pageTitle('Create your account');

  return (
    <div className="flex h-screen flex-col items-center justify-center text-gray-800">
      <div className="absolute left-0 right-0 top-2 flex w-full justify-center">
        <SeoAiLogoBlack />
      </div>
      <div className="flex w-96 flex-col">
        <h2 className="mb-3 text-center font-work text-[25px] font-bold leading-normal">
          Continue setup
        </h2>
        <p className="mb-2 text-center">
          Please provide your name and password for your account.
        </p>

        <Errors error={registerMutation.error ?? setupCheckoutMutation.error} />

        <form onSubmit={submit} className="animate-fade-slow">
          {/* Name input */}
          <InputDecoration label="Your name" required>
            <Input
              name="name"
              autoComplete="name"
              placeholder="John Smith"
              value={formValues.name}
              onChange={(value) => onHandleChange(value, 'name')}
            />
          </InputDecoration>

          <div className="h-6" />

          {/* Password input */}
          <InputDecoration label="Password">
            <Input
              type="password"
              name="password"
              placeholder="********"
              value={formValues.password}
              onChange={(value) => onHandleChange(value, 'password')}
            />
          </InputDecoration>

          {/* Submit */}
          <div className="mt-6">
            <Button
              color="secondary"
              fullWidth
              type="submit"
              isLoading={
                registerMutation.isPending ||
                registerMutation.isSuccess ||
                setupCheckoutMutation.isPending ||
                setupCheckoutMutation.isSuccess
              }
              text="Next"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
