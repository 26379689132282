import {
  useMerchantFeedGroupAttributePreviewIndex,
  useMerchantFeedGroupAttributePreviewStore,
} from '@/api/openapiComponents';
import {
  MerchantFeedAttributeResource,
  MerchantFeedGroupItemPreviewResource,
} from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import * as Table from '@/Components/Table';
import { useEffect, useState } from 'react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ExternalLink } from 'lucide-react';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  feedId: string;
  optimizationGroupId: number;
  attribute?: MerchantFeedAttributeResource;
};

export const FeedPreviewDialog = ({
  attribute,
  feedId,
  optimizationGroupId,
  isOpen,
  onClose,
}: Props) => {
  const appState = useAppStore();

  const [previewData, setPreviewData] =
    useState<(MerchantFeedGroupItemPreviewResource & {})[]>();

  const optimizeitemsMutation = useMerchantFeedGroupAttributePreviewStore();
  const previewQuery = useMerchantFeedGroupAttributePreviewIndex(
    {
      pathParams: {
        project: appState.currentProject!.id,
        merchantFeed: feedId,
        group: optimizationGroupId,
      },
      queryParams: {
        attribute: attribute?.id,
      },
    },
    {
      enabled: isOpen && !!attribute,
    },
  );

  useEffect(() => {
    if (previewQuery.isSuccess) {
      setPreviewData(previewQuery.data.data);
      optimizeitemsMutation.mutate({
        body: {
          attribute: attribute?.id,
          item_ids: previewQuery.data.data.map((item) => item.id),
        },
        pathParams: {
          group: optimizationGroupId,
          merchantFeed: feedId,
          project: appState.currentProject!.id,
        },
      });
    }
  }, [previewQuery.isSuccess]);

  useEffect(() => {
    if (isOpen) {
      const channel = window.Echo.private(`merchant_feeds.${feedId}`).listen(
        'MerchantFeedGroupItemUpdated',
        (event) => {
          setPreviewData((prev) =>
            prev?.map((item) =>
              item.id === event.item.id
                ? {
                    ...item,
                    values: [
                      event.item.values.find(
                        (e) => e.field === attribute?.name,
                      ),
                    ],
                  }
                : item,
            ),
          );
        },
      );

      return () => channel.stopListening('MerchantFeedGroupItemUpdated');
    }
  }, [isOpen]);

  return (
    <Dialog
      title={`Optimized ${attribute?.display_name} preview`}
      handleClose={onClose}
      size="xl"
      isOpen={isOpen}
    >
      <p>
        The optimized texts can be edited after creating the feed has been
        optimized
      </p>
      <Table.Root
        isLoading={previewQuery.isLoading}
        skeletonLoaders={10}
        items={previewData ?? []}
        columns={[
          {
            width: 2,
            heading: 'ID',
            render: (item) => item.id,
          },
          {
            width: 30,
            heading: `Original ${attribute?.display_name}`,
            render: (item) =>
              item.item?.values?.find((value) => value.field !== 'link')?.value,
          },
          {
            heading: `Optimized ${attribute?.display_name}`,
            render: (item) => item.values?.[0]?.value ?? <SkeletonLoader />,
          },
          {
            render: (item) => (
              <IconButton
                icon={ExternalLink}
                onClick={() =>
                  window.open(
                    item.item?.values?.find((value) => value.field === 'link')
                      ?.value,
                    '_blank',
                  )
                }
              />
            ),
          },
        ]}
      ></Table.Root>
    </Dialog>
  );
};
