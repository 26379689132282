import { ReactNode } from '@tanstack/react-router';

type Props = {
  name: string;
  count: number;
  property: string;
  action: ReactNode;
};

export const AddonItem = ({ action, count, property, name }: Props) => {
  return (
    <div className="flex items-center justify-between rounded-lg bg-gray-50 px-8 py-6 text-primary">
      <div className="flex flex-col gap-3">
        <p className="text-sm italic">{name}</p>
        <p className="font-bold">
          <span className="mr-1 text-2xl font-bold">{count}</span>
          <span>{property}</span>
        </p>
      </div>

      <div>{action}</div>
    </div>
  );
};
