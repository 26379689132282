import { useRouter } from '@tanstack/react-router';
import PageContainer from './PageContainer';
import { IconButton } from './v2/IconButton/IconButton';
import { ArrowLeft } from 'lucide-react';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  title: string;
  subtitle?: string;
  actions?: React.ReactNode;
  controls?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  canGoBack?: boolean;
};

export const FullScreenPageContainer = ({
  children,
  title,
  subtitle,
  actions,
  controls,
  size,
  canGoBack,
}: Props) => {
  const router = useRouter();

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="flex h-16 w-full flex-shrink-0 items-center justify-between overflow-hidden border-b border-b-primary-200 bg-white p-4">
        <div className="flex items-center gap-4">
          {canGoBack && (
            <IconButton
              icon={ArrowLeft}
              onClick={() => router.history.back()}
            />
          )}
          <p className="gap-4 text-2xl font-bold text-primary">
            {title}{' '}
            {subtitle && (
              <span className="ml-4 text-sm font-normal text-primary">
                {subtitle}
              </span>
            )}
          </p>
        </div>
        <div>{actions}</div>
      </div>
      <div className="flex-grow overflow-y-auto bg-primary-50">
        <PageContainer size={size}>{children}</PageContainer>
      </div>
      {controls && (
        <div className="flex h-20 w-full flex-shrink-0 overflow-hidden border-t border-t-primary-200 bg-white p-2">
          {controls}
        </div>
      )}
    </div>
  );
};
