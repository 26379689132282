import {
  useMerchantFeedGroupDestroy,
  useMerchantFeedGroupIndex,
} from '@/api/openapiComponents';
import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import { useAppStore } from '../AppLoader/stores';
import { PageHeaderSection } from '@/Components/PageHeaderSection';
import * as Table from '@/Components/Table';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ChartLine, Edit, Plus, Trash2 } from 'lucide-react';
import { Button } from '@/Components/v2/Button';
import { useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FeedStatusLabel } from './components/FeedStatusLabel';
import { useEffect, useState } from 'react';
import { MerchantFeedGroupResource } from '@/api/openapiSchemas';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useQueryClient } from '@tanstack/react-query';

dayjs.extend(utc);

type Props = {
  feedId: string;
};

export const ManageOptimizationGroups = ({ feedId }: Props) => {
  const appState = useAppStore();
  const navigate = useNavigate();
  const client = useQueryClient();

  useEffect(() => {
    SavvyCal('init');
    SavvyCal('inline', {
      link: 'seoai/feed-seo',
      selector: '#feedseo-config-booking-calendar',
    });
  }, []);

  const deleteGroupMutation = useMerchantFeedGroupDestroy();

  const [groupToBeDeleted, setGroupToBeDeleted] =
    useState<MerchantFeedGroupResource>();

  const groupsQuery = useMerchantFeedGroupIndex({
    pathParams: {
      merchantFeed: feedId,
      project: appState.currentProject!.id,
    },
  });

  useEffect(() => {
    const channel = window.Echo.private(
      `projects.${appState.currentProject!.id}`,
    );

    channel.listen(
      'MerchantFeedGroupUpdated',
      (event: MerchantFeedGroupResource) =>
        client.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey.includes('merchantFeedGroupIndex'),
          },
          (prev: any) => {
            return {
              ...prev,
              data: prev.data.map((item: MerchantFeedGroupResource) => {
                if (item.id === event.id) {
                  return event;
                }
                return item;
              }),
            };
          },
        ),
    );

    return () => {
      channel.stopListening('MerchantFeedGroupUpdated');
    };
  }, [groupsQuery.isSuccess]);

  return (
    <Menus>
      <ConfirmDialog
        title="Delete optimization group"
        content={`Are you sure you want to delete the optimization group "${groupToBeDeleted?.name}"?`}
        isLoading={deleteGroupMutation.isPending}
        onConfirm={() => {
          deleteGroupMutation.mutate(
            {
              pathParams: {
                project: appState.currentProject!.id,
                merchantFeed: feedId,
                group: groupToBeDeleted!.id,
              },
            },
            {
              onSuccess: () => {
                groupsQuery.refetch();
                setGroupToBeDeleted(undefined);
              },
            },
          );
        }}
        onCancel={() => setGroupToBeDeleted(undefined)}
        isOpen={!!groupToBeDeleted}
      />
      <div>
        <PageHeaderSection
          title="Optimization Groups"
          description="Optimize your product feed to get more clicks and impressions in Google Shopping and Free Listings."
        />
        <PageContainer>
          <Table.Root
            items={groupsQuery.data?.data ?? []}
            isLoading={groupsQuery.isLoading}
            columns={[
              {
                heading: 'Name',
                render: (item) => item.name,
              },
              {
                heading: 'Attributes',
                render: (item) =>
                  item.attributes?.map((attr) => attr.display_name).join(', '),
              },
              {
                heading: 'Products',
                rightAlign: true,
                render: (item) => item.items_count,
              },
              {
                heading: 'Created',
                render: (item) =>
                  dayjs(item.created_at).local().format('DD/MM/YYYY'),
              },
              {
                heading: 'Status',
                render: (item) => <FeedStatusLabel group={item} />,
              },
              {
                rightAlign: true,
                render: (item) => (
                  <div className="flex items-center gap-2">
                    <IconButton
                      onClick={() => {
                        navigate({
                          to: '/product-analytics/feed-groups/$groupId',
                          params: { groupId: '' + item.id },
                          search: { page: 1, keywordPage: 1 },
                        });
                      }}
                      icon={ChartLine}
                      size="sm"
                    />
                    <Button
                      text="Edit"
                      dense
                      size="sm"
                      variant="outline"
                      prependIcon={Edit}
                      onClick={() =>
                        navigate({
                          to: '/feed-seo/$feedId/view/$optimizationGroupId',
                          params: { feedId, optimizationGroupId: '' + item.id },
                          search: { page: 1 },
                        })
                      }
                    />
                    <IconButton
                      icon={Trash2}
                      onClick={() => setGroupToBeDeleted(item)}
                    />
                  </div>
                ),
              },
            ]}
          >
            <Table.Header>
              <div className="flex w-full items-center justify-between">
                <p className="text-xl font-semibold text-primary">
                  Optimization groups
                </p>
                <Button
                  text="Create new"
                  prependIcon={Plus}
                  variant="ghost"
                  onClick={() =>
                    navigate({
                      to: '/feed-seo/$feedId/create-group',
                      params: { feedId },
                    })
                  }
                />
              </div>
            </Table.Header>
          </Table.Root>

          <div className="mt-12 w-full">
            <PageHeaderSection
              noBackground
              title="Book a 1-on-1 setup session"
              description="Let one of our team members guide you through setting up and optimizing your feed using our newly launched Feed Optimizer"
            />
            <div id="feedseo-config-booking-calendar" className="w-full" />
          </div>
        </PageContainer>
      </div>
    </Menus>
  );
};
