import { LucideProps, X } from 'lucide-react';
import React, { ReactNode, useState } from 'react';
import { IconButton } from '../IconButton/IconButton';
import { isComponentFunction } from '../utils';
import { convertRemToPixels } from './util';

type Props = {
  value: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  name?: string;
  maxHeight?: number;
  minHeight?: number;
  counter?: boolean | number;
  counterMax?: number;
  hint?: string;
  persistentHint?: boolean;
  placeholder?: string;
  disabled?: boolean;
  error?: string | string[] | null;
  dense?: boolean;
  rows?: number;
  clearable?: boolean;
  resize?: boolean;
  autoComplete?: string;
  prependIcon?: React.ComponentType<LucideProps> | ReactNode;
  appendIcon?: React.ComponentType<LucideProps> | ReactNode;
  onChange: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
};

const TextArea = ({
  onChange,
  onPaste,
  onKeyDown,
  onFocus,
  value,
  fullHeight,
  fullWidth,
  appendIcon,
  autoComplete,
  clearable,
  resize = false,
  counter,
  counterMax,
  dense,
  rows = 3,
  disabled,
  error,
  hint,
  name,
  persistentHint,
  placeholder,
  prependIcon,
  maxHeight,
  minHeight,
}: Props) => {
  const [isFocussed, setIsFocussed] = useState(false);
  const PrependIcon = prependIcon;
  const AppendIcon = appendIcon;

  const rightIconRef = React.useRef<HTMLDivElement>(null);
  const leftIconRef = React.useRef<HTMLDivElement>(null);

  const getContainerColors = () => {
    if (disabled) return 'bg-gray-100 border-primary-300';

    if (error) {
      return `${isFocussed ? 'border-red-500' : 'border-red-300'}`;
    }

    return `bg-white ${
      isFocussed ? 'border-secondary-400' : 'border-primary-300'
    }`;
  };

  const getTextColors = () => {
    if (disabled) return 'text-gray-400 placeholder:text-gray-300';

    return 'text-gray-600 placeholder:text-gray-300';
  };

  const getRightPadding = () => {
    let padding = dense ? 0.25 : 0.5;

    if (rightIconRef.current) {
      return convertRemToPixels(padding) * 2 + rightIconRef.current.clientWidth;
    }

    if (clearable && !appendIcon) padding += 0.5;
    if (clearable) padding += 0.75;
    if (appendIcon) padding += 2;

    return `${padding}rem`;
  };

  const getLeftPadding = () => {
    let padding = dense ? 0.5 : 0.8;

    if (leftIconRef.current) {
      return convertRemToPixels(padding) * 2 + leftIconRef.current.clientWidth;
    }

    if (prependIcon) padding += 2;

    return `${padding}rem`;
  };

  return (
    <div
      className={`${fullHeight ? 'h-full' : ''} ${fullWidth ? 'w-full' : ''}`}
    >
      <div
        className={`relative flex flex-col overflow-hidden rounded-lg border ${getContainerColors()} ${
          fullHeight ? 'h-full' : ''
        }`}
      >
        <textarea
          style={{
            paddingLeft: getLeftPadding(),
            paddingRight: getRightPadding(),
            minHeight: minHeight ? `${minHeight}rem` : undefined,
            maxHeight: maxHeight ? `${maxHeight}rem` : undefined,
          }}
          className={`min-h-[4rem] flex-grow rounded-md border-none bg-transparent outline-none ring-0 placeholder:italic placeholder:text-gray-400 ${
            !resize ? 'resize-none' : ''
          } ${getTextColors()} ${disabled ? 'cursor-not-allowed' : ''} ${
            dense ? 'py-1 leading-5' : `py-2`
          }`}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          onFocus={(e) => {
            setIsFocussed(true);
            onFocus?.(e);
          }}
          onBlur={() => setIsFocussed(false)}
          name={name}
          value={value}
          rows={rows}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          autoComplete={autoComplete}
        />

        {PrependIcon &&
          (isComponentFunction(PrependIcon) ? (
            <div ref={leftIconRef}>
              <PrependIcon
                className={`absolute h-6 w-6 text-primary-600 ${
                  dense ? 'left-[0.5rem] top-1' : 'left-[0.8rem] top-2'
                }`}
              />
            </div>
          ) : (
            (PrependIcon as unknown as ReactNode)
          ))}
        {(AppendIcon || clearable) && (
          <div
            ref={rightIconRef}
            className={`absolute top-0 flex items-center ${
              dense
                ? 'right-[0.25rem] top-1 gap-1'
                : 'right-[0.5rem] top-2 gap-2'
            }`}
          >
            {clearable && (
              <IconButton icon={X} dense onClick={() => onChange('')} />
            )}
            {AppendIcon &&
              (isComponentFunction(AppendIcon) ? (
                <AppendIcon className={`h-6 w-6 text-primary-600`} />
              ) : (
                (AppendIcon as unknown as ReactNode)
              ))}
          </div>
        )}
      </div>
      {(hint || error || counter !== undefined) && (
        <div className="w-full">
          <div
            className={`flex w-full items-start pl-3 pr-2 pt-1 text-sm ${getTextColors()}`}
          >
            <div
              className={`flex-grow break-words transition-opacity ${
                error ? 'text-red-500' : ''
              } ${isFocussed || persistentHint || error ? '' : 'opacity-0'}`}
            >
              {error ?? hint}
            </div>
            {(counter || counterMax !== undefined) && (
              <div
                className={`flex-shrink-0 ${
                  counterMax &&
                  (typeof counter === 'number'
                    ? counter
                    : (value?.length ?? 0)) > counterMax
                    ? 'text-red-500'
                    : ''
                }`}
              >
                {counter &&
                  (typeof counter === 'number'
                    ? counter
                    : (value?.length ?? 0))}
                {counterMax && `/${counterMax}`}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

TextArea.displayName = 'TextArea';

export { TextArea };
