import { Popover } from '@/Components/v2/Menu/Popover';
import { formatThousandSeperator } from '@/utils';

type Props = {
  value: number | string;
  hideGrowth?: boolean;
  growth: {
    growth: number | null;
    difference: number;
  };
  absolute?: boolean;
  side?: 'left' | 'right' | 'top' | 'bottom';
  responsive?: boolean;
};

export const ValueWithGrowth = ({
  growth,
  value,
  absolute,
  side = 'left',
  hideGrowth = false,
  responsive = true,
}: Props) => {
  return (
    <div className="flex flex-shrink-0 items-center gap-1">
      <div className={responsive ? '2xl:w-[3vw]' : ''} />
      <Popover
        disabled={growth.growth === null || hideGrowth}
        type="hover"
        side={side}
        trigger={
          <div className="text-primary">
            {typeof value === 'number'
              ? formatThousandSeperator(value ?? 0)
              : value}
          </div>
        }
      >
        <div className="flex flex-col gap-2 p-4 text-primary">
          <p className="font-bold text-primary">
            {absolute ? 'Previous:' : 'Difference:'}
          </p>
          {growth.difference > 0 ? '+' : ''}
          {absolute
            ? (
                Number(value) +
                (Number.isNaN(growth.difference) ? 0 : growth.difference)
              )
                .toFixed(2)
                .replace('.00', '')
            : growth.difference.toFixed(2).replace('.00', '')}
        </div>
      </Popover>
      <div
        className={`-mt-0.5 w-10 text-xs ${growth.growth !== null && growth.growth > 0 ? 'text-green-700' : ''} ${growth.growth !== null && growth.growth < 0 ? 'text-red' : ''} ${hideGrowth ? 'opacity-0' : ''}`}
      >
        {growth.growth !== null && growth.growth > 0 && '+'}
        {growth.growth !== null &&
          !absolute &&
          `${Number(growth.growth)?.toFixed(0)}%`}
        {growth.growth !== null &&
          !Number.isNaN(growth.difference) &&
          absolute &&
          `${growth.difference.toFixed(1)}`}
      </div>
    </div>
  );
};
