import { Menus } from '@/Components/Menus';
import { ProjectChat } from './components/ProjectChat';
import { useNavigate } from '@tanstack/react-router';
import { useChatStore } from './store';
import { ChatsSlideover } from './components/ChatsSlideover';
import { useAppStore } from '../AppLoader/stores';
import { AnimatedText } from './components/AnimatedText';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ChatResource } from '@/api/openapiSchemas';
import { useProjectChatShow } from '@/api/openapiComponents';

export type InitialMessage = {
  message: string;
  images?: string[];
};

type Props = {
  chatId: number;
};

export const ChatPage = ({ chatId }: Props) => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const { setInitialMessage, initialMessage } = useChatStore();

  appState.pageTitle('Chat');

  const chatQuery = useProjectChatShow({
    pathParams: {
      project: appState.currentProject!.id,
      chat: chatId,
    },
  });

  return (
    <Menus>
      <div className="relative flex h-[calc(100vh-10px)] w-full flex-grow flex-col items-center overflow-hidden">
        <ChatsSlideover
          chatId={chatId}
          onNewChat={() => {
            navigate({ to: '/chat' });
            setInitialMessage(undefined);
          }}
          onSelectChat={(id) => {
            navigate({ to: '/chat/$chatId', params: { chatId: '' + id } });
            setInitialMessage(undefined);
          }}
          onDeleteChat={(id) => {
            if (chatId === id) {
              navigate({ to: '/chat' });
              setInitialMessage(undefined);
            }
          }}
        />
        <div className="w-full max-w-4xl flex-shrink-0 pl-6 pt-4 text-lg font-bold">
          {chatQuery.data?.data.animate ? (
            <AnimatedText text={chatQuery.data?.data.name ?? ''} />
          ) : (
            chatQuery.data?.data.name
          )}{' '}
          &nbsp;
        </div>
        <div className="w-full flex-grow overflow-hidden">
          <ProjectChat
            chatId={chatId}
            initialMessage={initialMessage}
            onClearInitialMessage={() => setInitialMessage(undefined)}
          />
        </div>
      </div>
    </Menus>
  );
};
