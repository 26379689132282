import * as React from 'react';
import { useHeadingState, useMetaTitleState } from '@/Pages/Document/hooks';
import { Generating } from '@/types';
import { SectionHeader } from './section-header';
import { DocumentResource } from '@/api/openapiSchemas';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Copy } from 'lucide-react';

type Props = {
  isLoading: Generating;
  handleTitleFocus: () => void;
  document: DocumentResource;
};

const EditorTitle = ({ isLoading, handleTitleFocus, document }: Props) => {
  const [heading, setHeading] = useHeadingState();
  const [metaTitle, setMetaTitle] = useMetaTitleState();
  const [copied, setCopied] = React.useState(false);
  const titleRef = React.useRef<HTMLTextAreaElement | null>(null);

  React.useEffect(() => {
    if (titleRef.current) {
      titleRef.current.style.height = '1px';
      titleRef.current.style.height =
        (titleRef.current?.scrollHeight ?? 42) + 'px';
    }
  });

  return (
    <div className="h=[25vh] p-5 pt-2">
      <div className="relative flex flex-col bg-white">
        <SectionHeader>H1</SectionHeader>
        <div className="relative">
          <textarea
            disabled={!!isLoading}
            ref={titleRef}
            placeholder={
              document.keyword?.name
                ? 'Click here to get title ideas'
                : 'Add your main heading...'
            }
            className="intro-step-3 mt-2 w-full cursor-pointer resize-none overflow-y-hidden border-0 p-0 text-4xl font-black leading-[3rem] placeholder:text-primary-400"
            value={heading}
            onFocus={handleTitleFocus}
            onChange={(e) => {
              if (heading === metaTitle) {
                setMetaTitle(e.target.value);
              }
              setHeading(e.target.value);
            }}
          />
          <div className="absolute -right-4 top-[1.25rem]">
            <div className="relative flex justify-center">
              <IconButton
                size="sm"
                icon={Copy}
                isLoading={!!isLoading}
                onClick={() => {
                  setCopied(true);
                  navigator.clipboard.writeText(heading);
                }}
              />
              {copied && (
                <p className="absolute -top-5 bg-white  text-green-500">
                  Copied!
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EditorTitle.displayName = 'EditorTitle';

export { EditorTitle };
