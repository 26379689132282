import { AiChatMessageResource } from '@/api/openapiSchemas';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useGetDraggableState } from '@/Hooks/useGetDraggableState';
import {
  AIMessage,
  AIMessageOverlay,
} from '@/Pages/Document/components/Chat/components/AIMessage';
import { HumanMessage } from '@/Pages/Document/components/Chat/components/HumanMessage';
import { MessageInput } from '@/Pages/Document/components/Chat/components/MessageInput';
import { ScrollContainer } from '@/Pages/Document/components/Chat/components/ScrollContainer';
import { BlockSelection, isHumanMessage, Message } from '@/types';
import { DragOverlay } from '@dnd-kit/core';
import { ReactNode } from '@tanstack/react-router';
import { ArrowDown } from 'lucide-react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  input: {
    value: string;
    onChange: (e: string) => void;
  };
  currentToolCall?: string;
  disabled?: boolean;
  initialMessage?: ReactNode;
  scroll: {
    handleScrollIntoView: () => void;
    isInView: boolean;
    scrollToDiv: React.RefObject<HTMLDivElement>;
    scrollContainerDiv: React.RefObject<HTMLDivElement>;
  };
  hideEditorActions?: boolean;
  placeholder?: ReactNode;
  chatMessages?: AiChatMessageResource[];
  streamedMessage?: string;
  selection?: BlockSelection | null;
  isLoading: boolean;
  isStreamingMessage: boolean;
  isSendingMessage: boolean;
  isLoadingMessage: boolean;
  isDeletingMessage: boolean;
  isClearingChat?: boolean;
  onClearChat?: () => void;
  onCopy?: (value: string) => void;
  onSendMessage: (predefinedMessage?: string, images?: string[]) => void;
  onRefreshMessage: (messageId: number) => void;
  onEditMessage: (messageId: number, text: string, images?: string[]) => void;
  allowDocumentCreation?: boolean;
};

export const ChatLayout = ({
  input,
  hideEditorActions,
  onClearChat,
  isClearingChat,
  streamedMessage,
  disabled,
  initialMessage,
  scroll,
  isDeletingMessage,
  onEditMessage,
  onRefreshMessage,
  onSendMessage,
  onCopy,
  chatMessages,
  selection,
  placeholder,
  isLoading,
  currentToolCall,
  isLoadingMessage,
  isSendingMessage,
  isStreamingMessage,
  allowDocumentCreation,
}: Props) => {
  const { draggableState } = useGetDraggableState();
  const [stickToBottom, setStickToBottom] = useState(false);

  useEffect(() => {
    if (stickToBottom) {
      scroll.handleScrollIntoView();
    }
  }, [streamedMessage]);

  useEffect(() => {
    if (isStreamingMessage) {
      setStickToBottom(true);
      scroll.handleScrollIntoView();
    }
  }, [isStreamingMessage]);

  const displayMessages = () => {
    let messages: (string | Message)[] = [];
    if (!selection && !chatMessages) {
      return;
    }

    if (chatMessages) {
      messages = [...messages, ...(chatMessages ?? [])];
    }

    const components: ReactNode[] = [];

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      if (typeof message === 'string' || message.display_type === 'SELECTION') {
        continue;
      }
      if (isHumanMessage(message)) {
        let selectedText: string | undefined = undefined;

        if (
          typeof messages[i + 1] === 'string' ||
          messages[i + 1]?.display_type === 'SELECTION'
        ) {
          selectedText = messages[i + 1]?.content ?? messages[i + 1];
        }

        components.push(
          <HumanMessage
            key={message.id}
            id={message.id}
            images={message.images}
            isModerated={message.is_moderated}
            isLoading={
              message.id === 0 && isLoadingMessage && isDeletingMessage
            }
            selectedText={selectedText}
            disabled={isStreamingMessage || !!disabled || isDeletingMessage}
            text={message.content}
            onSubmitEdit={onEditMessage}
          />,
        );
      } else {
        components.push(
          <AIMessage
            hasSelection={!!selection}
            isLastAIMessage={i === 0}
            key={message.id}
            text={message.content}
            id={message.id}
            hideEditorActions={hideEditorActions}
            handleRefreshMessage={onRefreshMessage}
            onCopy={onCopy}
            allowDocumentCreation={allowDocumentCreation}
          />,
        );
      }
    }
    return components;
  };

  return (
    <div className="relative flex h-full w-full flex-col justify-center">
      {chatMessages?.length === 0 && placeholder ? (
        placeholder
      ) : (
        <>
          <ScrollContainer
            reverse
            ref={scroll.scrollContainerDiv}
            onWheel={(e) => {
              if (e.deltaY < 0) {
                setStickToBottom(false);
              }
            }}
          >
            <div className="mx-auto flex w-full max-w-4xl flex-col-reverse items-end gap-4 px-3 pb-4">
              <div ref={scroll.scrollToDiv} />
              {isLoading && (
                <div className="flex flex-col gap-2">
                  <SkeletonLoader height="md" />
                  <SkeletonLoader height="md" />
                  <SkeletonLoader height="md" />
                  <SkeletonLoader height="md" />
                  <SkeletonLoader height="md" />
                  <SkeletonLoader height="md" />
                  <SkeletonLoader height="md" />
                </div>
              )}
              {(!!streamedMessage || isLoadingMessage) && (
                <AIMessageOverlay
                  text={streamedMessage ?? ''}
                  id={0}
                  toolCall={currentToolCall}
                />
              )}
              {displayMessages()}
              {initialMessage && (
                <div className="mb-8 w-full">{initialMessage}</div>
              )}
            </div>
          </ScrollContainer>
          <div className="relative h-0 w-full">
            <div
              className={`absolute bottom-4 right-4 z-40 animate-bounce rounded-3xl ${
                scroll.isInView ? 'hidden' : 'block'
              }`}
            >
              <IconButton
                icon={ArrowDown}
                onClick={scroll.handleScrollIntoView}
                color="primary"
              />
            </div>
          </div>
        </>
      )}

      <div
        className="z-50 -mt-4 flex justify-center"
        style={{ scrollbarGutter: 'stable both-edges' }}
      >
        <ScrollContainer disableScroll>
          <div className="w-full max-w-4xl px-3 pb-8">
            <MessageInput
              onClearChat={onClearChat}
              isClearingChat={isClearingChat}
              isSendingMessage={isSendingMessage || isStreamingMessage}
              value={input.value}
              onChange={input.onChange}
              onSubmit={onSendMessage}
              selection={selection}
            />
          </div>
        </ScrollContainer>
      </div>
      {chatMessages &&
        draggableState &&
        createPortal(
          <DragOverlay adjustScale={false} zIndex={2} dropAnimation={null}>
            {chatMessages && draggableState && (
              <AIMessageOverlay text={draggableState.activeElement.text} />
            )}
          </DragOverlay>,
          window.document.body,
        )}
    </div>
  );
};
