'use client';
import React, { useMemo } from 'react';
import {
  TooltipProvider,
  FloatingToolbar,
  FloatingToolbarButtons,
  FixedToolbar,
  FixedToolbarButtons,
} from './components';
import { createUniqueIdentifier } from './lib/transform';
import { DefaultLeaf } from 'slate-react';
import { Range, Text, Editor as EditorInstance, BaseEditor } from 'slate';
import {
  BlockSelection,
  BlockType,
  CurrentSlideOver,
  ExtendedKeywordSuggestion,
  ExtendedNewKeywordSuggestion,
  InternalLinkingResource,
  LeafType,
  NodeTypesEnum,
  SettingsState,
  isExtendedKeywordSuggestion,
  isExtendedNewKeywordSuggestion,
  isInternalLinkingResource,
  isLeafNode,
} from '@/types';
import {
  getIndexRangesOfKeyword,
  getKeywordPosInsideSentence,
  hasNoTextMarked,
  isMarkAllText,
} from '../../utils';
import {
  removeLigaturesFromMarkdown,
  serializeToMarkdown,
} from './lib/serialize';
import { DocumentResource, UserResource } from '@/api/openapiSchemas';
import {
  getAnchorAndFocusPos,
  getHoverColorForLinks,
  getParagraphText,
} from './lib/utils';
import {
  useInternalLinkingStore,
  useKeywordInjectionStore,
} from '../../stores';
import { useShallow } from 'zustand/react/shallow';
import { InsertHereLine } from './components/insert-here-line';
import { Editor } from './components/editor';
import { Plate } from '@udecode/plate/react';
import { useSearch } from '@tanstack/react-router';

type Props = {
  document: DocumentResource;
  selection: BlockSelection | null;
  setSelection: React.Dispatch<React.SetStateAction<BlockSelection | null>>;
  projectId: number;
  editor: PlateEditorRef | null;
  user: UserResource;
  setCurrentSlideOver: (value?: CurrentSlideOver) => void;
  currentSlideOver: CurrentSlideOver | undefined;
  settings: SettingsState;
  handleSettings: (key: keyof SettingsState, value: string) => void;
};

export function PlateEditor({
  setSelection,
  selection,
  projectId,
  document,
  editor,
  user,
  setCurrentSlideOver,
  handleSettings,
  settings,
  currentSlideOver,
}: Props) {
  const search = useSearch({ from: '/documents/$documentId' });
  const { data, handleHoverInternalLinkFromEditor } = useInternalLinkingStore(
    useShallow((state) => ({
      data: state.data,
      handleHoverInternalLinkFromEditor:
        state.handleHoverInternalLinkFromEditor,
    })),
  );

  const {
    keywordSuggestions,
    handleHoverFromEditor,
    mode,
    newKeywordSuggestions,
  } = useKeywordInjectionStore(
    useShallow((state) => ({
      keywordSuggestions: state.keywordSuggestions,
      handleHoverFromEditor: state.handleHoverFromEditor,
      mode: state.mode,
      newKeywordSuggestions: state.newKeywordSuggestions,
    })),
  );

  const key = useMemo(() => {
    return createUniqueIdentifier();
  }, []);

  const highlightText = () => {
    if (editor && editor.selection) {
      if (hasNoTextMarked(editor)) {
        setSelection(null);
        return;
      }
      const editorSelection = editor.selection;
      const currentSelection = editor.getFragment();
      if (isMarkAllText(editor)) {
        setSelection({
          highlightedSelection: editorSelection,
          selected: {
            text: serializeToMarkdown(currentSelection),
            showInChat: true,
          },
        });
        return;
      }
      setSelection({
        highlightedSelection: editorSelection,
        selected: {
          text: serializeToMarkdown(currentSelection),
          showInChat: true,
        },
      });
    }
  };

  return (
    <>
      <TooltipProvider delayDuration={0}>
        <Plate
          editor={editor}
          key={key}
          onChange={() => {
            if (editor && editor.selection) {
              highlightText();
            }
          }}
          decorate={({ entry: [node, path] }) => {
            const ranges: any = [];
            if (
              currentSlideOver === 'keyword-injection' ||
              currentSlideOver === 'keyword-gap'
            ) {
              if (
                keywordSuggestions.length > 0 &&
                editor &&
                mode === 'UPDATE EXISTING'
              ) {
                if (
                  !isLeafNode(node as BlockType | LeafType) &&
                  (node.type === NodeTypesEnum.P ||
                    node.type === NodeTypesEnum.LIC ||
                    node.type === NodeTypesEnum.BLOCKQUOTE)
                ) {
                  const paragraphText = getParagraphText(
                    node.children as BlockType[],
                  );
                  const resources = keywordSuggestions.filter(
                    (keywordSuggestion) =>
                      paragraphText.includes(
                        removeLigaturesFromMarkdown(keywordSuggestion.text),
                      ),
                  );
                  if (resources.length > 0) {
                    resources.forEach((resource) => {
                      const { startIndex, endIndex } = getIndexRangesOfKeyword(
                        resource.text,
                        paragraphText,
                      );

                      const keywordPosition = getAnchorAndFocusPos(
                        node.children as BlockType[],
                        startIndex,
                        endIndex,
                        path,
                      );

                      if (keywordPosition) {
                        ranges.push({
                          anchor: keywordPosition.anchor,
                          focus: keywordPosition.focus,
                          resource,
                        });
                      }
                    });
                  }
                }
              }
              if (
                newKeywordSuggestions.length > 0 &&
                editor &&
                mode === 'NEW PARAGRAPHS'
              ) {
                if (
                  !isLeafNode(node as BlockType | LeafType) &&
                  (node.type === NodeTypesEnum.P ||
                    node.type === NodeTypesEnum.LIC ||
                    node.type === NodeTypesEnum.BLOCKQUOTE)
                ) {
                  const children = node.children as BlockType[];
                  const paragraphText = getParagraphText(children);
                  const resources = [
                    ...newKeywordSuggestions.filter((keywordSuggestion) =>
                      paragraphText.includes(
                        removeLigaturesFromMarkdown(keywordSuggestion.text),
                      ),
                    ),
                  ];

                  if (resources.length > 0) {
                    resources.forEach((resource) => {
                      const { endIndex } = getIndexRangesOfKeyword(
                        resource.text,
                        paragraphText,
                      );

                      const keywordPosition = getAnchorAndFocusPos(
                        node.children as BlockType[],
                        endIndex,
                        endIndex,
                        path,
                      );
                      if (keywordPosition) {
                        ranges.push({
                          anchor: keywordPosition.focus,
                          focus: keywordPosition.focus,
                          resource,
                        });
                      }
                    });
                  }
                }
              }
            }

            if (
              currentSlideOver === 'internal-linking' &&
              data.length > 0 &&
              editor
            ) {
              if (
                !isLeafNode(node as BlockType | LeafType) &&
                (node.type === NodeTypesEnum.P ||
                  node.type === NodeTypesEnum.LIC ||
                  node.type === NodeTypesEnum.BLOCKQUOTE)
              ) {
                const paragraphText = getParagraphText(
                  node.children as BlockType[],
                );

                const resources = data.filter(
                  (l) =>
                    l.anchorTextStartIndex !== null &&
                    l.anchorTextEndIndex !== null &&
                    paragraphText.includes(
                      removeLigaturesFromMarkdown(l.linkResource.sentence),
                    ),
                );

                if (resources.length > 0) {
                  resources.forEach((r) => {
                    const keywordPosition = getKeywordPosInsideSentence(
                      r,
                      paragraphText,
                      (node as BlockType).children as LeafType[],
                      path,
                    );
                    if (keywordPosition) {
                      ranges.push({
                        anchor: keywordPosition.anchor,
                        focus: keywordPosition.focus,
                        resource: r,
                      });
                    }
                  });
                }
              }
            }

            if (
              Text.isText(node) &&
              editor &&
              editor.selection &&
              selection !== null
            ) {
              const intersection = Range.intersection(
                selection.highlightedSelection,
                EditorInstance.range(editor as BaseEditor, path),
              );
              if (intersection === null) {
                return ranges;
              }

              const range = {
                highlighted: true,
                ...intersection,
              };
              ranges.push(range);
            }

            return ranges;
          }}
          renderLeaf={(props) => {
            if (props.leaf.resource) {
              const resource = props.leaf.resource as
                | InternalLinkingResource
                | ExtendedKeywordSuggestion
                | ExtendedNewKeywordSuggestion;
              return (
                <>
                  <span
                    ref={(ref) => {
                      if (isInternalLinkingResource(resource) && ref) {
                        resource.isHoveredFromTab &&
                          ref.scrollIntoView({
                            behavior: 'instant',
                            block: 'center',
                          });
                      }
                      if (isExtendedKeywordSuggestion(resource) && ref) {
                        resource.hoveredFromTab &&
                          ref.scrollIntoView({
                            behavior: 'instant',
                            block: 'center',
                          });
                      }
                    }}
                    onMouseEnter={() => {
                      if (isInternalLinkingResource(resource)) {
                        handleHoverInternalLinkFromEditor(
                          resource.linkResource,
                          true,
                        );
                      }
                      if (isExtendedKeywordSuggestion(resource)) {
                        handleHoverFromEditor(resource, true);
                      }
                    }}
                    onMouseLeave={() => {
                      if (isInternalLinkingResource(resource)) {
                        handleHoverInternalLinkFromEditor(
                          resource.linkResource,
                          false,
                        );
                      }
                      if (isExtendedKeywordSuggestion(resource)) {
                        handleHoverFromEditor(resource, false);
                      }
                    }}
                    className={getHoverColorForLinks(resource)}
                  >
                    <DefaultLeaf {...props} />
                    {mode === 'NEW PARAGRAPHS' &&
                      isExtendedNewKeywordSuggestion(resource) && (
                        <InsertHereLine resource={resource} />
                      )}
                  </span>
                </>
              );
            }
            if (props.leaf.highlighted) {
              return (
                <span
                  {...props.attributes}
                  className="bg-green-200 selection:bg-green-200"
                >
                  {props.children}
                </span>
              );
            }
            return (
              <span className="selection:bg-green-200">
                <DefaultLeaf {...props} />
              </span>
            );
          }}
        >
          {(!search.v2 ||
            search.tabIndex === 0 ||
            search.tabIndex === undefined) && (
            <div
              className={
                search.v2
                  ? 'flex w-full justify-center'
                  : 'absolute left-24 top-[0.7rem] z-50'
              }
            >
              <FixedToolbar>
                <FixedToolbarButtons />
              </FixedToolbar>
            </div>
          )}
          <Editor
            user={user}
            settings={settings}
            handleSettings={handleSettings}
            currentSlideOver={currentSlideOver}
            setCurrentSlideOver={setCurrentSlideOver}
            projectId={projectId}
            document={document}
            style={{ wordBreak: 'break-word' }}
            className="overflow-y-hidden px-0"
            autoFocus
            focusRing={false}
            variant="ghost"
            size="md"
          />
          <FloatingToolbar>
            <FloatingToolbarButtons />
          </FloatingToolbar>
        </Plate>
      </TooltipProvider>
    </>
  );
}
