import * as React from 'react';
import {
  CalendarDays,
  Files,
  Settings,
  UserCircle,
  ChevronDown,
  ChevronUp,
  Search,
  PlusIcon,
  Plus,
  ChevronsRight,
  ChevronsLeft,
  ScanText,
  ScanLine,
  Globe,
  TextSearch,
  LayoutTemplate,
  Rocket,
  Stars,
  ChartNoAxesColumn,
  ListCheck,
  MessagesSquare,
  BarChartHorizontalBig,
  AppWindow,
} from 'lucide-react';
import ReactCountryFlag from 'react-country-flag';
import { Link, useNavigate } from '@tanstack/react-router';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { Button } from '@/Components/v2/Button';
import { hasher, useFeatureFlagging } from '@/Pages/AppLoader';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarCollapsable } from '../SidebarCollapsable';
import { isSubMenu } from '@/types';
import { useCreateDocument } from '@/api/openapiComponents';

export const checkForHighlight = (pathName: string) =>
  window.location.pathname === pathName
    ? true
    : false || window.location.pathname.startsWith(pathName);

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Sidebar = () => {
  const appState = useAppStore();
  const { hasFeature } = useFeatureFlagging();
  const [projectPopup, setProjectPopup] = React.useState(false);
  const [projects, setProjects] = React.useState(appState.projects);

  const navigate = useNavigate();

  const projectMenuRef = React.useRef<HTMLDivElement | null>(null);

  const createDocumentMutation = useCreateDocument();
  const latestDocument = localStorage.getItem('latestDocument');

  const handleNewDocument = () => {
    createDocumentMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: (data) =>
          navigate({
            to: '/documents/$documentId',
            params: { documentId: hasher.encode(data.data.id) },
          }),
      },
    );
  };

  const firstNavigationMenu = [
    ...(hasFeature('editor-in-sidebar')
      ? [
          {
            name: 'Editor',
            ...(latestDocument
              ? {
                  href: '/documents/$documentId',
                  params: { documentId: latestDocument },
                  search: { v2: true },
                }
              : {
                  onClick: handleNewDocument,
                }),
            icon: AppWindow,
            to: `/documents/$documentId`,
            isLoading:
              createDocumentMutation.isPending ||
              createDocumentMutation.isSuccess,
            current: checkForHighlight('/documents'),
          },
        ]
      : []),
    ...(hasFeature('product-analytics') && hasFeature('google-merchant-center')
      ? [
          {
            name: 'Analytics',
            icon: ChartNoAxesColumn,
            items: [
              {
                name: 'Pages',
                href: '/page-analytics',
                icon: ChartNoAxesColumn,
                current: checkForHighlight('/page-analytics'),
              },
              {
                name: 'Products',
                href: '/product-analytics',
                icon: ChartNoAxesColumn,
                current: checkForHighlight('/product-analytics'),
              },
            ],
          },
        ]
      : [
          {
            name: 'Analytics',
            href: '/page-analytics',
            icon: ChartNoAxesColumn,
            current: checkForHighlight('/page-analytics'),
          },
        ]),
    {
      name: 'Chat',
      href: '/chat',
      isBeta: true,
      icon: MessagesSquare,
      current: checkForHighlight('/chat'),
    },
    {
      name: 'Pages',
      href: '/all-pages',
      search: {
        page: 1,
        sortBy: 'clicks',
        sortDirection: 'DESC' as const,
      },
      icon: Globe,
      current: checkForHighlight('/all-pages'),
    },
    {
      name: 'Documents',
      href: '/created-content',
      icon: Files,
      current: checkForHighlight('/created-content'),
    },
    ...(hasFeature('new-ai-draft')
      ? [
          {
            name: 'AI writer',
            href: '/ai-writer',
            icon: Stars,
            current: checkForHighlight('/ai-writer'),
          },
        ]
      : []),
    ...(hasFeature('feed-seo')
      ? [
          {
            name: 'Feed optimization',
            href: '/feed-seo',
            icon: ListCheck,
            current: checkForHighlight('/feed-seo'),
          },
        ]
      : []),
    {
      name: 'Keyword research',
      icon: TextSearch,
      items: [
        {
          name: 'AI',
          href: '/keyword-research/ai-keywords',
          icon: Stars,
          current: checkForHighlight('/keyword-research/ai-keywords'),
        },
        {
          name: 'Questions',
          href: '/keyword-research/question-keywords',
          icon: Stars,
          current: checkForHighlight('/keyword-research/question-keywords'),
        },
        {
          name: 'Long-tail',
          href: '/keyword-research/matching-terms',
          icon: Stars,
          current: checkForHighlight('/keyword-research/matching-terms'),
        },
        {
          name: 'Volume',
          href: '/keyword-research/search-volume',
          icon: BarChartHorizontalBig,
          current: checkForHighlight('/keyword-research/search-volume'),
        },
        {
          name: 'Saved keywords',
          href: '/saved-keywords',
          icon: CalendarDays,
          current: checkForHighlight('/saved-keywords'),
        },
        ...(hasFeature('keyword-scanner')
          ? [
              {
                name: 'Keyword clustering',
                href: '/keyword-clustering/v2',
                search: { page: 1 },
                icon: ScanLine,
                isBeta: true,
                tooltip: appState.subscription?.permissions.content_scanner
                  ? undefined
                  : {
                      text: 'Upgrade to a higher plan to get access to this feature.',
                      navLink: '/account/subscription',
                      navLinkText: 'See pricing',
                    },
                disabled: !appState.subscription?.permissions.content_scanner,
                current: checkForHighlight('/keyword-clustering/v2'),
              },
            ]
          : []),
      ],
    },

    {
      name: 'Settings',
      icon: Settings,
      items: [
        {
          name: 'Configuration',
          href: '/settings/edit-project',
          icon: CalendarDays,
          current: checkForHighlight('/settings/edit-project'),
        },
        {
          name: 'Brand voice',
          href: '/settings/brand-voice',
          icon: CalendarDays,
          current: checkForHighlight('/settings/brand-voice'),
        },
        {
          name: 'Templates',
          href: '/settings/templates',
          icon: LayoutTemplate,
          tooltip: appState.subscription?.permissions.custom_document_templates
            ? undefined
            : {
                text: 'Upgrade to a higher plan to get access to this feature.',
                navLink: '/account/subscription',
                navLinkText: 'See pricing',
              },
          disabled:
            !appState.subscription?.permissions.custom_document_templates,
          current: checkForHighlight('/settings/templates'),
        },
        {
          name: 'Connections',
          href: '/settings/connections',
          icon: CalendarDays,
          current: checkForHighlight('/settings/connections'),
        },
        ...(hasFeature('custom-fields')
          ? [
              {
                name: 'Custom fields',
                href: '/settings/custom-fields/mapping',
                icon: CalendarDays,
                current: checkForHighlight('/settings/custom-fields'),
              },
            ]
          : []),
      ],
    },
  ];

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setProjects(
      appState.projects?.filter(
        (org) =>
          org.domain.toLowerCase().includes(value) ||
          org.name.toLowerCase().includes(value),
      ),
    );
  };

  const navigateToCreateProject = () => {
    navigate({ to: `/create-project` });
  };

  const toggleProjectPopup = () => {
    setProjectPopup((prev) => !prev);
  };

  const thirdNavigationMenu = [
    {
      name: 'Getting started',
      href: '/getting-started',
      icon: Rocket,
      current: false,
    },

    {
      name: 'Account',
      href: '/account/users',
      icon: UserCircle,
      current: checkForHighlight('/account'),
    },
  ];

  React.useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!projectMenuRef.current?.contains(e.target as HTMLDivElement)) {
        const element = e.target as HTMLDivElement;
        if (element.id !== 'project-menu-dropdown') {
          setProjectPopup(false);
        }
      }
    };
    window.addEventListener('click', listener);
    return () => {
      window.removeEventListener('click', listener);
    };
  }, [projectMenuRef]);

  return (
    <>
      <div className={`flex  shrink-0 flex-col`}>
        <div className="flex w-72 grow flex-col gap-y-7 overflow-y-auto bg-primary-50 px-4 py-4">
          <div
            ref={projectMenuRef}
            id="project-menu-dropdown"
            className="relative -mb-4"
          >
            {projectPopup && (
              <div
                className={`absolute left-0 right-0 top-[100%] z-20 transition-opacity duration-300`}
              >
                <div className="z-20 rounded-lg border border-primary-200 bg-white pt-4 text-primary">
                  <div className="flex w-full px-4">
                    <div className="flex h-9 w-full items-center rounded-full border bg-white px-4">
                      <div className="mr-3 flex h-5 items-center">
                        <Search width={16} height={16} />
                      </div>
                      <div className="flex h-5 items-center">
                        <input
                          onChange={(e) => handleFilter(e)}
                          type="text"
                          className="h-full w-full border-0 p-0  placeholder:text-gray-300 focus:outline-none focus:ring-0"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="slim-scroll my-3 flex max-h-[200px] w-full flex-wrap overflow-y-auto px-4">
                    {projects?.map((project, index) => {
                      const { name, language } = project;
                      return (
                        <div
                          key={index}
                          className={'group flex w-full justify-between py-1.5'}
                        >
                          <div className="mr-3 flex h-6 w-6 shrink-0 items-center">
                            <ReactCountryFlag
                              countryCode={language?.country?.iso_3166_2}
                              svg
                              style={{
                                height: '18px',
                                width: '23px',
                              }}
                            />
                          </div>
                          <div
                            onClick={() => {
                              navigate({ search: '', replace: true });
                              appState.setCurrentProject(project);
                              setProjectPopup(false);
                            }}
                            className="mr-2 flex flex-grow cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-primary hover:text-primary-800"
                          >
                            {name}
                          </div>
                          <div
                            onClick={() => {
                              appState.setCurrentProject(project);
                              setProjectPopup(false);
                              navigate({ to: `/settings/edit-project` });
                            }}
                            className="flex shrink-0 cursor-pointer items-center"
                          >
                            <Settings
                              className="invisible h-4 w-4 stroke-gray-400 group-hover:visible"
                              width={16}
                              height={16}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    onClick={navigateToCreateProject}
                    className="border-button-grey group flex w-full cursor-pointer gap-3 border-t p-3"
                  >
                    <PlusIcon className="stroke-gray-400" />
                    <div className="text-primary hover:text-primary-800">
                      Add website
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              onClick={toggleProjectPopup}
              className="group mb-2 flex w-full cursor-pointer justify-between rounded-lg py-2 font-work hover:bg-primary-125"
            >
              <div className="flex w-full items-center px-2">
                <div className="mr-3 flex h-6 w-6 shrink-0 items-center">
                  <ReactCountryFlag
                    countryCode={
                      appState.currentProject?.language?.country?.iso_3166_2 ??
                      ''
                    }
                    svg
                    style={{
                      height: '18px',
                      width: '23px',
                    }}
                  />
                </div>
                <div className="flex flex-grow flex-col overflow-hidden">
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap font-bold">
                    {appState.currentProject?.name}
                  </div>
                </div>
                <div className="mr-2 flex w-max flex-shrink-0 items-center justify-center">
                  {projectPopup ? (
                    <ChevronUp className="pointer-events-none stroke-primary" />
                  ) : (
                    <ChevronDown className="pointer-events-none stroke-primary" />
                  )}
                </div>
              </div>
            </div>
          </div>
          {!hasFeature('editor-in-sidebar') && (
            <Button
              fullWidth
              prependIcon={Plus}
              color={'secondary'}
              isLoading={
                createDocumentMutation.isPending ||
                createDocumentMutation.isSuccess
              }
              text={'New document'}
              onClick={handleNewDocument}
            />
          )}

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="space-y-1">
                  {firstNavigationMenu.map((item) => {
                    if (isSubMenu(item)) {
                      return (
                        <SidebarCollapsable
                          key={item.name}
                          title={item.name}
                          icon={<item.icon />}
                          items={item.items as any}
                        />
                      );
                    }
                    return (
                      <SidebarMenuItem
                        item={item as any}
                        isBeta={item.isBeta}
                        key={item.name}
                      />
                    );
                  })}
                </ul>
              </li>
              <ul className="mb-4 mt-auto space-y-1">
                {thirdNavigationMenu.map((item) => (
                  <li key={item.name} className="mt-auto">
                    <Link
                      target={item.href.includes('http') ? '_blank' : ''}
                      to={item.href}
                      onClick={(e) => {
                        if (item.name === 'Website settings') {
                          e.preventDefault();
                          navigate({ to: `/settings/edit-project` });
                        }
                      }}
                      className={classNames(
                        item.current
                          ? 'bg-primary-125 text-primary-800'
                          : 'text-primary-500 hover:bg-primary-125 hover:text-primary-800',
                        'group flex gap-x-3 rounded-md p-2 font-semibold leading-6',
                      )}
                    >
                      <item.icon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

Sidebar.displayName = 'Sidebar';

export { Sidebar };
