import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '@/Pages/Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '@/Pages/Router/guards.ts';
import { ProductAnalytics } from './ProductAnalytics.tsx';
import { ProductAnalyticsGroup } from './ProductAnalyticsGroup.tsx';
import { ManageGroups } from './ManageGroups';
import { ProductDetails } from './ProductDetails.tsx';
import { ProductAnalyticsFeedGroup } from '@/Pages/Analytics/ProductAnalytics/ProductAnalyticsFeedGroup';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: (search: Record<string, string>) => ({
      page: Number(search.page ?? 1),
      keywordPage: Number(search.keywordPage ?? 1),
    }),
    path: '/product-analytics',
    component: () => <ProductAnalytics />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: (search: Record<string, string>) => ({
      page: Number(search.page ?? 1),
      keywordPage: Number(search.keywordPage ?? 1),
    }),
    path: '/product-analytics/groups/$groupId',
    component: () => <ProductAnalyticsGroup />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: (search: Record<string, string>) => ({
      page: Number(search.page ?? 1),
      keywordPage: Number(search.keywordPage ?? 1),
    }),
    path: '/product-analytics/feed-groups/$groupId',
    component: () => <ProductAnalyticsFeedGroup />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: (search: Record<string, string>) =>
      ({
        page: Number(search.page ?? 1),
        type: search.type as 'brand' | 'title' | 'offer_id',
        dimension: search.dimension as string | undefined,
      }) as {
        page: number;
        type?: 'brand' | 'title' | 'offer_id';
        dimension?: string;
      },
    path: '/product-analytics/page/$pageId',
    component: () => <ProductDetails />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/product-analytics/groups',
    component: () => <ManageGroups />,
  }),
];
