import { useAppStore } from '../AppLoader/stores';
import { useState } from 'react';
import {
  useOrganisationsOrganisationInvitesIndex,
  useOrganisationsUsersIndex,
} from '@/api/openapiComponents';
import { InviteUserDialog } from './components/InviteUserDialog';
import { UserAction } from './components/UserAction';
import * as Table from '@/Components/Table';
import { SectionHeader } from '../components/SectionHeader';
import { Plus } from 'lucide-react';
import AppLabel, { Variant } from '@/Components/Labels/AppLabel';
import { Button } from '@/Components/v2/Button';
import { useNavigate } from '@tanstack/react-router';

export const Users = () => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const [showInviteUserDialog, setShowInviteUserDialog] = useState(false);

  const invitesQuery = useOrganisationsOrganisationInvitesIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
    queryParams: {
      limit: 100,
    },
  });
  const invites = invitesQuery.data?.data;

  const organisationUsersQuery = useOrganisationsUsersIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
    queryParams: {
      limit: 100,
    },
  });
  const users = organisationUsersQuery.data?.data;

  if (!appState.organisation) return null;

  const handleInvite = () => {
    setShowInviteUserDialog(false);
    invitesQuery.refetch();
  };

  const handleUserAction = (type: 'user' | 'invite') => {
    if (type === 'user') {
      organisationUsersQuery.refetch();
    }
    if (type === 'invite') {
      invitesQuery.refetch();
    }
  };

  const isOwner =
    appState.organisation.users?.find((u) => u.id === appState.auth!.user.id)
      ?.role === 'owner';

  return (
    <div className="mt-12">
      <InviteUserDialog
        organisationId={appState.auth!.user.org_id}
        isOpen={showInviteUserDialog}
        onClose={handleInvite}
      />
      <SectionHeader
        title="Account users"
        removeBorder
        actions={
          <div className="mr-4 flex items-center gap-4 text-sm text-primary">
            (
            {(appState.subscription?.usage.credits.users.used ?? 0) +
              (invites?.length ?? 0)}
            /{appState.subscription?.usage.credits.users.limit}) seats used
            {isOwner && (
              <Button
                text="Buy more users"
                color="secondary"
                variant="outline"
                onClick={() =>
                  navigate({
                    to: '/account/subscription',
                    search: { openUsersDialog: true },
                  })
                }
              />
            )}
          </div>
        }
        buttonProps={{
          onClick: () => setShowInviteUserDialog(true),
          color: 'secondary',
          prependIcon: Plus,
          disabled:
            (appState.subscription?.usage.credits.users.used ?? 0) +
              (invites?.length ?? 0) >=
            (appState.subscription?.usage.credits.users.limit ?? 0),
          text: 'Add user',
        }}
      />
      <Table.Root
        items={[...(users ?? []), ...(invites ?? [])]}
        columns={[
          {
            heading: 'Name',
            property: 'name',
            width: 16,
          },
          {
            heading: 'Email',
            property: 'email',
          },
          {
            heading: 'Role',
            render: (user) => user.role,
          },
          {
            heading: 'Status',
            render: (user) =>
              user.role ? (
                <AppLabel variant={Variant.green}>Active</AppLabel>
              ) : (
                <AppLabel variant={Variant.blue}>Invited</AppLabel>
              ),
          },
          {
            heading: '',
            render: (user) =>
              isOwner &&
              user.id !== appState.auth?.user.id && (
                <UserAction
                  isAdmin={users?.some((u) => u.id === appState.auth!.user.id)}
                  auth={appState.auth!}
                  user={user.role ? user : undefined}
                  invite={user.role ? undefined : user}
                  onClose={handleUserAction}
                />
              ),
          },
        ]}
      ></Table.Root>
    </div>
  );
};
