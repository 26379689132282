import PageContainer from '@/Components/PageContainer';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import { ValueWithGrowth } from '@/Pages/Analytics/components/ValueWithGrowth';
import { useEffect, useState } from 'react';
import { GrowthValueHeader } from '@/Pages/Analytics/components/GrowthValueHeader';
import {
  useAnalyticsMerchantCenterFeedGroupQuery,
  useProjectsAnalyticsGoogleMerchantCenterFeedGroupsShow,
} from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { CellWithBar } from '@/Pages/Analytics/components/CellWithBar';
import {
  getDateParams,
  calculateGrowth,
  convertData,
  convertKey,
  sortAnalyticsData,
} from '@/Pages/Analytics/utils';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { useAnalyticsTotals, useGraphState, useGrowthSorting } from './hooks';
import ToggleButton from '@/Components/ToggleButton';
import { AnalyticsFilters } from './components/AnalyticsFilters';
import { AnalyticsUrlActions } from '@/Pages/Analytics/components/AnalyticsUrlActions';
import {
  MerchantCenterQueryDimension,
  MerchantCenterQueryFilter,
} from '@/api/openapiSchemas';
import { useConnectToGoogleMerchantCenter } from '@/Pages/Settings/hooks.ts';
import { GoogleMerchantCenterConnect } from '@/Components/Table/components/GoogleMerchantCenterConnect.tsx';
import { Button } from '@/Components/v2/Button';
import { Pencil } from 'lucide-react';
import { Event, events } from '@/events';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';

export const ProductAnalyticsFeedGroup = () => {
  const appState = useAppStore();
  const navigate = useNavigate({
    from: '/product-analytics/feed-groups/$groupId',
  });
  const params = useParams({ from: '/product-analytics/feed-groups/$groupId' });
  const search = useSearch({ from: '/product-analytics/feed-groups/$groupId' });

  const [graphState, setGraphState] = useGraphState();
  const [productColumnSorting, setProductColumnSorting] = useState('clicks');
  const [growthSorting, setGrowthSorting] = useGrowthSorting('feedgrouppage');
  const [filters, setFilters] = useState<
    {
      dimension: MerchantCenterQueryDimension;
      operator: MerchantCenterQueryFilter;
      expression: string;
    }[]
  >([]);

  useEffect(() => {
    const handler = () => {
      navigate({
        to: '/product-analytics',
        search: { page: 1, keywordPage: 1 },
      });
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  useEffect(() => {
    const handler = () => {
      navigate({
        to: '/product-analytics',
        search: { page: 1, keywordPage: 1 },
      });
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  const gscHookProps = useConnectToGoogleMerchantCenter(
    appState.currentProject!.id,
  );

  useEffect(() => {
    if (gscHookProps.isGettingGoogleMerchantCenterData) return;
    setGraphState({
      ...graphState,
      isGMCEnabled:
        gscHookProps.googleMerchantCenterData?.data.settings.account !== null &&
        !gscHookProps.googleMerchantCenterError,
    });
  }, [
    gscHookProps.googleMerchantCenterError,
    gscHookProps.googleMerchantCenterData,
    gscHookProps.isGettingGoogleMerchantCenterData,
  ]);

  const groupQuery = useProjectsAnalyticsGoogleMerchantCenterFeedGroupsShow({
    pathParams: {
      project: appState.currentProject!.id,
      merchantFeedGroup: Number(params.groupId),
    },
  });

  useEffect(() => {
    if (groupQuery.isSuccess) {
      const ids = groupQuery.data?.data.ids ?? [];
      if (ids.length > 0) {
        setFilters((prevState) => {
          const newState = [...prevState];
          const index = newState.findIndex((f) => f.dimension === 'offer_id');

          if (index !== -1) {
            newState[index] = {
              dimension: 'offer_id' as MerchantCenterQueryDimension,
              operator: 'in' as MerchantCenterQueryFilter,
              expression: ids.join(','),
            };
          } else {
            newState.push({
              dimension: 'offer_id' as MerchantCenterQueryDimension,
              operator: 'in' as MerchantCenterQueryFilter,
              expression: ids.join(','),
            });
          }

          return newState;
        });
      }
    }

    if ((graphState.country?.iso_3166_2 ?? 'xx') !== 'xx') {
      setFilters((prevState) => {
        const newState = [...prevState];
        const index = newState.findIndex(
          (f) => f.dimension === 'customer_country_code',
        );

        if (index !== -1) {
          newState[index] = {
            dimension: 'customer_country_code' as MerchantCenterQueryDimension,
            operator: 'equals' as MerchantCenterQueryFilter,
            expression: graphState.country?.iso_3166_2,
          };
        } else {
          newState.push({
            dimension: 'customer_country_code' as MerchantCenterQueryDimension,
            operator: 'equals' as MerchantCenterQueryFilter,
            expression: graphState.country?.iso_3166_2,
          });
        }

        return newState;
      });
    }
  }, [groupQuery.isSuccess, graphState.country.iso_3166_2]);

  const currentQuery = useAnalyticsMerchantCenterFeedGroupQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
        merchantFeedGroup: Number(params.groupId),
      },
      queryParams: {
        ...getDateParams(graphState.range),
        dimension_filter_groups: [
          filters.filter((f) => f.demension === 'customer_country_code')[0],
        ].filter((f) => Boolean(f)),
      },
    },
    {
      enabled: groupQuery.isSuccess,
    },
  );

  const previousQuery = useAnalyticsMerchantCenterFeedGroupQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
        merchantFeedGroup: Number(params.groupId),
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimension_filter_groups: [
          filters.filter((f) => f.demension === 'customer_country_code')[0],
        ].filter((f) => Boolean(f)),
      },
    },
    {
      enabled: groupQuery.isSuccess,
    },
  );

  const totals = useAnalyticsTotals(graphState, {
    groupFilters: filters,
    loaded: groupQuery.isSuccess,
  });

  if (gscHookProps.isGettingGoogleMerchantCenterData) {
    return (
      <Menus>
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner color="secondary" size="2xl" />
        </div>
      </Menus>
    );
  }

  if (!graphState.isGMCEnabled) {
    return (
      <Menus>
        <PageContainer
          title={`Product group: ${groupQuery.data?.data.name ?? ''}`}
          canScroll={false}
        >
          <div className="relative h-screen w-full overflow-hidden">
            <img
              src="/svg/analytics_placeholder.png"
              className="aspect-square w-full blur-sm"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="-mt-[420px] w-full">
                {gscHookProps && (
                  <GoogleMerchantCenterConnect
                    {...gscHookProps}
                    page="Product analytics"
                    message="Connect to Google Merchant Center to see your shop's product feed performance."
                  />
                )}
              </div>
            </div>
          </div>
        </PageContainer>
      </Menus>
    );
  }

  const topPages = convertData(
    {
      current: convertKey(
        'link',
        convertKey(
          'offer_id',
          convertKey('title', currentQuery.data?.data ?? [], 0),
          1,
        ),
        2,
      ),
      previous: convertKey(
        'link',
        convertKey(
          'offer_id',
          convertKey('title', previousQuery.data?.data ?? [], 0),
          1,
        ),
        2,
      ),
    },
    'offer_id',
  );

  const topPagesMostClicks =
    topPages?.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]
      ?.current.clicks ?? 0;

  const handleOpenPage = (page: string, dimension?: string) => {
    navigate({
      to: `/product-analytics/page/$pageId`,
      params: { pageId: page },
      search: { page: 1, type: 'offer_id', dimension },
    });
  };

  const handleEditGroup = () => {
    navigate({
      to: '/feed-seo/$feedId/view/$optimizationGroupId',
      params: {
        feedId: groupQuery.data?.data.feed?.id,
        optimizationGroupId: groupQuery.data?.data.id,
      },
    });
  };

  return (
    <Menus>
      <PageContainer
        title={`Product group: ${groupQuery.data?.data.name ?? ''}`}
        canGoBack
        actions={
          <Button
            text="Edit"
            prependIcon={Pencil}
            variant="ghost"
            onClick={handleEditGroup}
          />
        }
      >
        <AnalyticsFilters
          state={graphState}
          onStateChange={setGraphState}
          totals={totals}
        />
        <AnalyticsGraph
          state={graphState}
          isLoading={currentQuery.isFetching && previousQuery.isFetching}
          groupFilters={[
            {
              dimension: 'offer_id',
              operator: 'in',
              expression: groupQuery.data?.data.ids!.join(',') ?? '',
            },
          ]}
        />

        <Table.Root
          isLoading={currentQuery.isFetching && previousQuery.isFetching}
          items={sortAnalyticsData(
            topPages ?? [],
            growthSorting,
            productColumnSorting,
          )?.slice(((search.page ?? 1) - 1) * 20, 20 * (search.page ?? 1))}
          columns={[
            {
              heading: 'Title',
              expanded: true,
              render: (item) => (
                <CellWithBar
                  value={item.current.clicks}
                  topValue={topPagesMostClicks}
                  name={item.current.title}
                  onClick={() =>
                    handleOpenPage(item.current.title, item.current.offer_id)
                  }
                  actions={
                    <AnalyticsUrlActions
                      onOpen={() =>
                        handleOpenPage(
                          item.current.title,
                          item.current.offer_id,
                        )
                      }
                      url={item.current.link}
                    />
                  }
                />
              ),
            },
            {
              heading: (
                <GrowthValueHeader
                  heading="Clicks"
                  onClick={() => setProductColumnSorting('clicks')}
                  selected={productColumnSorting === 'clicks'}
                />
              ),
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  hideGrowth={
                    graphState.range === 'P1Y' || graphState.range === 'P16M'
                  }
                  responsive={false}
                  value={item.current.clicks}
                  growth={calculateGrowth(item, 'clicks')}
                />
              ),
            },
            {
              heading: (
                <GrowthValueHeader
                  heading="Impressions"
                  onClick={() => setProductColumnSorting('impressions')}
                  selected={productColumnSorting === 'impressions'}
                />
              ),
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  hideGrowth={
                    graphState.range === 'P1Y' || graphState.range === 'P16M'
                  }
                  value={item.current.impressions}
                  growth={calculateGrowth(item, 'impressions')}
                />
              ),
            },
            {
              heading: (
                <GrowthValueHeader
                  heading="CTR"
                  onClick={() => setProductColumnSorting('ctr')}
                  selected={productColumnSorting === 'ctr'}
                />
              ),
              rightAlign: true,
              render: (item) => (
                <ValueWithGrowth
                  hideGrowth={
                    graphState.range === 'P1Y' || graphState.range === 'P16M'
                  }
                  value={(item.current.ctr * 100).toFixed(1)}
                  growth={calculateGrowth(item, 'ctr')}
                />
              ),
            },
          ]}
        >
          <Table.Header>
            <div className="mt-10 flex items-center gap-4">
              <h2 className="mb-1 text-xl font-semibold">Products</h2>
              <ToggleButton
                value={growthSorting}
                onChange={setGrowthSorting}
                options={[
                  {
                    displayName: 'Popular',
                    value: 'popular',
                  },
                  {
                    displayName: 'Rising',
                    value: 'rising',
                  },
                  {
                    displayName: 'Falling',
                    value: 'falling',
                  },
                ]}
              />
            </div>
          </Table.Header>
          <Table.Footer>
            <Pagination
              currentPage={search.page ?? 1}
              setCurrentPage={(page) =>
                navigate({ search: { ...search, page }, replace: true })
              }
              lastPage={Math.floor((topPages?.length ?? 0) / 20)}
            />
          </Table.Footer>
        </Table.Root>
      </PageContainer>
    </Menus>
  );
};
