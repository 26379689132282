import { PlateRenderElementProps } from '@udecode/plate/react';

export const VoidElement = ({
  children,
  attributes,
}: PlateRenderElementProps) => {
  return (
    <div {...attributes} contentEditable={false}>
      {children}
    </div>
  );
};
