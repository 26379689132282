import PageContainer from '@/Components/PageContainer';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { Menus } from '@/Components/Menus';
import * as Table from '@/Components/Table';
import { ValueWithGrowth } from '@/Pages/Analytics/components/ValueWithGrowth';
import { Button } from '@/Components/v2/Button';
import { ScanSearch } from 'lucide-react';
import { GrowthValueHeader } from '@/Pages/Analytics/components/GrowthValueHeader';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useAnalyticsMerchantCenterQuery } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import {
  getDateParams,
  calculateGrowth,
  convertData,
  convertKey,
  sortAnalyticsData,
} from '@/Pages/Analytics/utils';
import { CellWithBar } from '@/Pages/Analytics/components/CellWithBar';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import {
  useAnalyticsTotals,
  useGraphState,
  useGrowthSorting,
  MapProductData,
} from './hooks';
import ToggleButton from '@/Components/ToggleButton';
import { useConnectToGoogleMerchantCenter } from '@/Pages/Settings/hooks';
import { AnalyticsFilters } from './components/AnalyticsFilters';
import { AnalyticsFeedGroupsTable } from './components/AnalyticsFeedGroupsTable';
import { AnalyticsUrlActions } from '@/Pages/Analytics/components/AnalyticsUrlActions';
import { GoogleMerchantCenterConnect } from '@/Components/Table/components/GoogleMerchantCenterConnect';
import { useEffect, useState } from 'react';
import {
  MerchantCenterQueryDimension,
  MerchantCenterQueryFilter,
  MerchantCenterQueryResource,
} from '@/api/openapiSchemas';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';

export const ProductAnalytics = () => {
  const appState = useAppStore();
  const navigate = useNavigate({ from: '/product-analytics' });
  const search = useSearch({ from: '/product-analytics' });

  const [graphState, setGraphState] = useGraphState();
  const [pageColumnSorting, setPageColumnSorting] = useState('clicks');
  const [pageGrowthSorting, setPageGrowthSorting] =
    useGrowthSorting('dashboardpage');
  const [productColumnSorting, setProductColumnSorting] = useState('clicks');
  const [keywordGrowthSorting, setKeywordGrowthSorting] = useGrowthSorting(
    'analyticsmainpagekeywords',
  );

  const gscHookProps = useConnectToGoogleMerchantCenter(
    appState.currentProject!.id,
  );

  useEffect(() => {
    if (gscHookProps.isGettingGoogleMerchantCenterData) return;
    setGraphState({
      ...graphState,
      isGMCEnabled:
        gscHookProps.googleMerchantCenterData?.data.settings.account !== null &&
        !gscHookProps.googleMerchantCenterError,
    });
  }, [
    gscHookProps.googleMerchantCenterError,
    gscHookProps.googleMerchantCenterData,
    gscHookProps.isGettingGoogleMerchantCenterData,
  ]);

  const baseDimensionFilterGroups =
    graphState.country?.iso_3166_3 === 'xxx'
      ? undefined
      : {
          dimension: 'customer_country_code' as MerchantCenterQueryDimension,
          operator: 'equals' as MerchantCenterQueryFilter,
          expression: graphState.country?.iso_3166_2,
        };

  const totals = useAnalyticsTotals(graphState, {
    groupFilters: baseDimensionFilterGroups
      ? [baseDimensionFilterGroups]
      : undefined,
    loaded: true,
  });

  const currentPageDataQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range),
        dimensions: ['brand'],
        dimension_filter_groups: baseDimensionFilterGroups
          ? [baseDimensionFilterGroups]
          : undefined,
      },
    },
    {
      enabled: graphState.isGMCEnabled,
    },
  );

  const previousPageDataQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['brand'],
        dimension_filter_groups: baseDimensionFilterGroups
          ? [baseDimensionFilterGroups]
          : undefined,
      },
    },
    {
      enabled: graphState.isGMCEnabled,
    },
  );

  const keywordQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range),
        dimensions: ['offer_id'],
        row_limit: 1000,
        dimension_filter_groups: baseDimensionFilterGroups
          ? [baseDimensionFilterGroups]
          : undefined,
      },
    },
    {
      enabled: graphState.isGMCEnabled,
    },
  );

  const productTitleQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range),
        dimensions: ['title', 'offer_id'],
        row_limit: 1000,
        dimension_filter_groups: baseDimensionFilterGroups
          ? [baseDimensionFilterGroups]
          : undefined,
      },
    },
    {
      enabled: graphState.isGMCEnabled,
    },
  );

  const PreviousProductTitleQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['title', 'offer_id'],
        row_limit: 1000,
        dimension_filter_groups: baseDimensionFilterGroups
          ? [baseDimensionFilterGroups]
          : undefined,
      },
    },
    {
      enabled: graphState.isGMCEnabled,
    },
  );

  const previousKeywordQuery = useAnalyticsMerchantCenterQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['offer_id'],
        row_limit: 1000,
        dimension_filter_groups: baseDimensionFilterGroups
          ? [baseDimensionFilterGroups]
          : undefined,
      },
    },
    {
      enabled: graphState.isGMCEnabled,
    },
  );

  if (gscHookProps.isGettingGoogleMerchantCenterData) {
    return (
      <Menus>
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner color="secondary" size="2xl" />
        </div>
      </Menus>
    );
  }

  if (!graphState.isGMCEnabled) {
    return (
      <Menus>
        <PageContainer
          title="Product Analytics"
          canScroll={false}
          actions={
            <Button
              text="Open rank tracker"
              prependIcon={ScanSearch}
              onClick={() => navigate({ to: '/rank-tracking' })}
              variant="ghost"
            />
          }
        >
          <div className="relative h-screen w-full overflow-hidden">
            <img
              src="/svg/analytics_placeholder.png"
              className="aspect-square w-full blur-sm"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="-mt-[420px] w-full">
                {gscHookProps && (
                  <GoogleMerchantCenterConnect
                    {...gscHookProps}
                    page="Product analytics"
                    message="Connect to Google Merchant Center to see your shop's product feed performance."
                  />
                )}
              </div>
            </div>
          </div>
        </PageContainer>
      </Menus>
    );
  }

  const pageData = convertData(
    {
      current: convertKey('brand', currentPageDataQuery.data?.data ?? []),
      previous: convertKey('brand', previousPageDataQuery.data?.data ?? []),
    },
    'brand',
  );

  const topPagesMostClicks: number =
    pageData?.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]
      ?.current.clicks ?? 0;

  const combinedProductData = MapProductData(
    convertKey('offer_id', keywordQuery.data?.data ?? [], 0),
    convertKey('offer_id', productTitleQuery.data?.data ?? [], 1),
  );
  const combinedPreviousProductData = MapProductData(
    convertKey('offer_id', previousKeywordQuery.data?.data ?? [], 0),
    convertKey('offer_id', PreviousProductTitleQuery.data?.data ?? [], 1),
  );

  const topKeywords = convertData(
    {
      current: convertKey('title', combinedProductData),
      previous: convertKey('title', combinedPreviousProductData),
    },
    'title',
  );

  const topKeywordsMostClicks: number =
    topKeywords?.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]
      ?.current.clicks ?? 0;

  const handleOpenPage = (
    pageId: string,
    dimension: string,
    type: 'brand' | 'title' | 'offer_id' = 'brand',
  ) => {
    navigate({
      to: '/product-analytics/page/$pageId',
      params: { pageId },
      search: { page: 1, type: type, dimension: dimension },
    });
  };

  appState.pageTitle('Product Analytics');

  return (
    <>
      <Menus>
        <PageContainer
          title="Product Analytics"
          actions={
            <Button
              text="Open rank tracker"
              prependIcon={ScanSearch}
              onClick={() => navigate({ to: '/rank-tracking' })}
              variant="ghost"
            />
          }
        >
          <p className="my-2">
            Your Product's performance based on Google Merchant Center data.{' '}
            <a
              href="https://docs.seo.ai/a-43-page-analytics"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Read more
            </a>
            .
          </p>
          <AnalyticsFilters
            state={graphState}
            onStateChange={setGraphState}
            totals={totals}
          />
          <AnalyticsGraph state={graphState} isLoading={false} />
          <AnalyticsFeedGroupsTable state={graphState} navigate={navigate} />
          {/*<AnalyticsGroupsTable state={graphState} navigate={navigate} />*/}

          {/* Page table */}
          <Table.Root
            items={sortAnalyticsData(
              pageData ?? [],
              pageGrowthSorting,
              pageColumnSorting,
            )?.slice(((search.page ?? 1) - 1) * 20, 20 * (search.page ?? 1))}
            isLoading={
              currentPageDataQuery.isLoading || previousPageDataQuery.isLoading
            }
            columns={[
              {
                heading: 'Brand',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    value={item.current.clicks}
                    topValue={topPagesMostClicks}
                    name={item.current.brand}
                    onClick={() => handleOpenPage(item.current.brand)}
                    actions={
                      <AnalyticsUrlActions
                        onOpen={() => handleOpenPage(item.current.brand)}
                      />
                    }
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Clicks"
                    onClick={() => setPageColumnSorting('clicks')}
                    selected={pageColumnSorting === 'clicks'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Impressions"
                    onClick={() => setPageColumnSorting('impressions')}
                    selected={pageColumnSorting === 'impressions'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="CTR"
                    onClick={() => setPageColumnSorting('ctr')}
                    selected={pageColumnSorting === 'ctr'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <div className="mt-10 flex items-center gap-4">
                <h1 className="mb-1 text-xl font-semibold">Brands</h1>
                <ToggleButton
                  value={pageGrowthSorting}
                  onChange={setPageGrowthSorting}
                  options={[
                    {
                      displayName: 'Popular',
                      value: 'popular',
                    },
                    {
                      displayName: 'Rising',
                      value: 'rising',
                    },
                    {
                      displayName: 'Falling',
                      value: 'falling',
                    },
                  ]}
                />
              </div>
            </Table.Header>
            <Table.Footer>
              <Pagination
                currentPage={search.page ?? 1}
                setCurrentPage={(page) =>
                  navigate({ search: { ...search, page }, replace: true })
                }
                lastPage={Math.floor((pageData?.length ?? 0) / 20)}
              />
            </Table.Footer>
          </Table.Root>

          {/* Keywords Table */}
          <Table.Root
            isLoading={
              keywordQuery.isLoading ||
              previousKeywordQuery.isLoading ||
              productTitleQuery.isLoading ||
              PreviousProductTitleQuery.isLoading
            }
            skeletonLoaders={20}
            items={sortAnalyticsData(
              topKeywords ?? [],
              keywordGrowthSorting,
              productColumnSorting,
            )?.slice((search.keywordPage - 1) * 20, search.keywordPage * 20)}
            columns={[
              {
                heading: 'title',
                expanded: true,
                render: (item) => (
                  <CellWithBar
                    value={item.current.clicks}
                    topValue={topKeywordsMostClicks}
                    name={item.current.title}
                    onClick={() =>
                      handleOpenPage(
                        item.current.title,
                        item.current.offer_id,
                        'offer_id',
                      )
                    }
                    actions={
                      <AnalyticsUrlActions
                        onOpen={() =>
                          handleOpenPage(
                            item.current.title,
                            item.current.offer_id,
                            'offer_id',
                          )
                        }
                      />
                    }
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Clicks"
                    onClick={() => setProductColumnSorting('clicks')}
                    selected={productColumnSorting === 'clicks'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.clicks}
                    growth={calculateGrowth(item, 'clicks')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="Impressions"
                    onClick={() => setProductColumnSorting('impressions')}
                    selected={productColumnSorting === 'impressions'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={item.current.impressions}
                    growth={calculateGrowth(item, 'impressions')}
                  />
                ),
              },
              {
                heading: (
                  <GrowthValueHeader
                    heading="CTR"
                    onClick={() => setProductColumnSorting('ctr')}
                    selected={productColumnSorting === 'ctr'}
                  />
                ),
                rightAlign: true,
                render: (item) => (
                  <ValueWithGrowth
                    hideGrowth={
                      graphState.range === 'P1Y' || graphState.range === 'P16M'
                    }
                    responsive={false}
                    value={(item.current.ctr * 100).toFixed(1)}
                    growth={calculateGrowth(item, 'ctr')}
                  />
                ),
              },
            ]}
          >
            <Table.Header>
              <div className="mt-10 flex items-center gap-4">
                <h1 className="mb-1 text-xl font-semibold">Products</h1>
                <ToggleButton
                  value={keywordGrowthSorting}
                  onChange={setKeywordGrowthSorting}
                  options={[
                    {
                      displayName: 'Popular',
                      value: 'popular',
                    },
                    {
                      displayName: 'Rising',
                      value: 'rising',
                    },
                    {
                      displayName: 'Falling',
                      value: 'falling',
                    },
                  ]}
                />
              </div>
            </Table.Header>
            <Table.Footer>
              <Pagination
                currentPage={search.keywordPage}
                lastPage={Math.floor(topKeywords.length / 20)}
                setCurrentPage={(pageNumber) =>
                  navigate({
                    search: { ...search, keywordPage: pageNumber },
                    replace: true,
                  })
                }
              />
            </Table.Footer>
          </Table.Root>
        </PageContainer>
      </Menus>
    </>
  );
};
