import { DocumentResource } from '@/api/openapiSchemas';
import { GoogleConsoleConnect } from '@/Components/Table/components/GoogleConsoleConnect';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';
import { PageDetails } from '@/Pages/Analytics/PageAnalytics/PageDetails';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useConnectToGoogleSearchConsole } from '@/Pages/Settings/hooks';
import { useState } from 'react';
import { UrlInput } from './PlateEditor/components/url-input';

type Props = {
  document: DocumentResource;
};

export const DocumentUrlAnalytics = ({ document }: Props) => {
  const appState = useAppStore();
  const [page, setPage] = useState(1);

  const gscHookProps = useConnectToGoogleSearchConsole(
    appState.currentProject!.id,
  );

  if (gscHookProps.isGettingGoogleSearchConsoleData) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner size="2xl" color="secondary" />
      </div>
    );
  }

  if (!gscHookProps.googleSearchConsoleData?.data.settings.site) {
    return (
      <div className="flex h-full w-full flex-col ">
        <img
          src="/svg/analytics_placeholder.png"
          className="aspect-square w-full blur-sm"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="-mt-[420px] w-full">
            {gscHookProps && (
              <GoogleConsoleConnect
                {...gscHookProps}
                page="page analytics"
                message="Connect to Google Search Console to see your website's performance."
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  if (!document.project_url) {
    return (
      <div className="flex h-full w-full flex-col ">
        <img
          src="/svg/analytics_placeholder.png"
          className="aspect-square w-full blur-sm"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex flex-col gap-2 rounded-xl bg-white p-4 shadow-lg">
            <p className="text-xl font-bold">
              Add a URL to check the analytics for the page{' '}
            </p>
            <UrlInput document={document} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full pb-8">
      <PageDetails
        onPageChange={setPage}
        pageId={document.project_url.url}
        pageNumber={page}
      />
    </div>
  );
};
