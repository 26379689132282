import { createRoute, useNavigate } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { hasher } from '../AppLoader';
import DocumentLoader from './DocumentLoader';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';
import { useCreateDocument } from '@/api/openapiComponents';
import { useEffect } from 'react';
import { useAppStore } from '../AppLoader/stores';

export const documentRoute = createRoute({
  getParentRoute: () => rootRoute,
  validateSearch: (
    search: Record<string, boolean | undefined>,
  ): {
    v2?: boolean;
    tabIndex?: number;
    new?: boolean;
    generate?: boolean;
    googleDocs?: 'success' | 'failed';
    redirect?: boolean;
    document_template?: number;
  } => ({
    v2: search.v2,
    tabIndex:
      search.tabIndex !== undefined ? Number(search.tabIndex) : undefined,
    new: search.new,
    generate: search.generate,
    redirect: search.redirect,
    googleDocs: search.googleDocs as 'success' | 'failed' | undefined,
    document_template: search.document_template as number | undefined,
  }),
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/documents/$documentId',
  component: () => {
    const params = documentRoute.useParams();

    return (
      <DocumentLoader
        documentId={hasher.decode(params.documentId)[0] as number}
      />
    );
  },
});

const newDocumentRoute = createRoute({
  getParentRoute: () => rootRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/new-doc',
  component: () => {
    const appState = useAppStore();
    const createDocumentMutation = useCreateDocument();

    const navigate = useNavigate();

    useEffect(() => {
      createDocumentMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
          },
        },
        {
          onSuccess: (data) => {
            navigate({
              to: `/documents/$documentId`,
              params: { documentId: hasher.encode(data.data.id) },
            });
          },
        },
      );
    }, []);

    return (
      <div className="flex h-screen w-screen items-center justify-center overflow-hidden">
        <LoadingSpinner color="secondary" size="2xl" />
      </div>
    );
  },
});

export const routes = [documentRoute, newDocumentRoute];
