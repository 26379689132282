import { ReactNode } from '@tanstack/react-router';
import { PropsWithChildren } from 'react';

type Props = {
  title: string;
  actions?: ReactNode;
};

export const ReportSection = ({
  actions,
  title,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div className="flex flex-col rounded-lg bg-primary-125 p-5">
      <div className="mb-6 flex w-full items-center justify-between overflow-hidden text-sm">
        <p className="flex-shrink-0">{title}</p>
        <div className="flex grow justify-end overflow-hidden">{actions}</div>
      </div>
      {children}
    </div>
  );
};
