import dayjs from 'dayjs';

export type AnalyticsRange =
  | 'P1W'
  | 'P2W'
  | 'P4W'
  | 'P3M'
  | 'P6M'
  | 'P1Y'
  | 'P16M';

export const convertData = <T>(
  data: { current: T[]; previous?: T[] | undefined },
  key = 'page',
) => {
  if (data.current.length === 0) {
    return [];
  }

  return data.current.map((item) => {
    return {
      current: item,
      previous: data.previous?.find((prev) => prev[key] === item[key]),
    };
  });
};

export const calculateGrowth = <T>(
  data: {
    current: T;
    previous?: T;
  },
  dataKey: string,
  inverted = false,
) => {
  const a = inverted ? 'current' : 'previous'; // pre
  const b = inverted ? 'previous' : 'current'; // cur

  return {
    growth: !data[a]?.[dataKey]
      ? null
      : ((data?.[b]?.[dataKey] - data?.[a]?.[dataKey]) / data?.[a]?.[dataKey]) *
        100,
    difference: data[a] ? data?.[b]?.[dataKey] - data?.[a]?.[dataKey] : 0,
  };
};

export const sortAnalyticsData = <T>(
  data: {
    current: T;
    previous?: T;
  }[],
  sorting: 'rising' | 'falling' | 'popular',
  dataKey: keyof T,
) => {
  if (sorting === 'popular') {
    return data.toSorted((a, b) => {
      return (b.current[dataKey] ?? 0) - (a.current[dataKey] ?? 0);
    });
  }
  return data.toSorted((a, b) => {
    if (sorting === 'falling') {
      return (
        (a.current[dataKey] ?? 0) -
        (a.previous?.[dataKey] ?? 0) -
        ((b.current[dataKey] ?? 0) - (b.previous?.[dataKey] ?? 0))
      );
    } else {
      return (
        (b.current[dataKey] ?? 0) -
        (b.previous?.[dataKey] ?? 0) -
        ((a.current[dataKey] ?? 0) - (a.previous?.[dataKey] ?? 0))
      );
    }
  });
};

export const rangeDisplayNames = {
  P1W: '7 days',
  P2W: '14 days',
  P4W: '28 days',
  P3M: '3 months',
  P6M: '6 months',
  P1Y: '1 year',
  P16M: '16 months',
};

export const rangeDays = {
  P1W: 7,
  P2W: 14,
  P4W: 28,
};

export const rangeMonths = {
  P3M: 3,
  P6M: 6,
  P1Y: 12,
  P16M: 16,
};

export const convertKey = <T extends { keys: string[] }>(
  key: string,
  data: T[],
  position: number = 0,
) => {
  return data.map((d) => ({
    ...d,
    [key]: d.keys[position],
  }));
};

export const getSubtractParams = (range?: keyof typeof rangeDisplayNames) => {
  const days = rangeDays[range];
  const months = rangeMonths[range];

  if (days) {
    return {
      unit: 'days',
      value: days,
    };
  }
  if (months) {
    return {
      unit: 'months',
      value: months,
    };
  }
};

export const getDateParams = (range: AnalyticsRange, previous = false) => {
  let today = dayjs().subtract(1, 'day');

  const substractParams = getSubtractParams(range);

  if (previous) {
    today = today.subtract(substractParams?.value, substractParams?.unit);
  }

  return {
    end_date: today.format('YYYY-MM-DD'),
    start_date: today
      .subtract(substractParams?.value, substractParams?.unit)
      .format('YYYY-MM-DD'),
  };
};
