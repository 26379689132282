import { MerchantFeedGroupResource } from '@/api/openapiSchemas';
import AppLabel, { Variant } from '@/Components/Labels/AppLabel';

type Props = {
  group?: MerchantFeedGroupResource;
};

export const FeedStatusLabel = ({ group }: Props) => {
  const getVariant = () => {
    if (group?.status === 'deployed') {
      return Variant.green;
    }
    if (group?.status === 'ready') {
      return Variant.blue;
    }
    if (group?.status === 'processing') {
      return Variant.yellow;
    }
    if (group?.status === 'failed') {
      return Variant.red;
    }
    return Variant.gray;
  };

  const batchStatusString = group?.batch && group?.status === 'processing'
    ? ` (${group.batch.progress}%)`
    : '';

  return <AppLabel variant={getVariant()}>• {group?.status}{batchStatusString}</AppLabel>;
};
