import * as Table from '@/Components/Table';
import { ValueWithGrowth } from '@/Pages/Analytics/components/ValueWithGrowth';
import { GrowthValueHeader } from '@/Pages/Analytics/components/GrowthValueHeader';
import { useAnalyticsQuery } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import {
  getDateParams,
  calculateGrowth,
  convertData,
  convertKey,
  sortAnalyticsData,
} from '@/Pages/Analytics/utils.ts';
import { CellWithBar } from '@/Pages/Analytics/components/CellWithBar';
import { useAnalyticsTotals, useGraphState, useGrowthSorting } from './hooks';
import ToggleButton from '@/Components/ToggleButton';
import { AnalyticsGraph } from './components/AnalyticsGraph';
import { AnalyticsFilters } from './components/AnalyticsFilters';
import { AnalyticsUrlActions } from '@/Pages/Analytics/components/AnalyticsUrlActions';
import { useEffect, useState } from 'react';
import { Event, events } from '@/events';
import { useConnectToGoogleSearchConsole } from '@/Pages/Settings/hooks';
import { useNavigate } from '@tanstack/react-router';

type Props = {
  pageId: string;
  pageNumber: number;
  onPageChange: (pageNumber: number) => void;
  onKeywordClick: (keyword: string) => void;
};

export const PageDetails = ({
  onPageChange,
  pageId,
  pageNumber,
  onKeywordClick,
}: Props) => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const [graphState, setGraphState] = useGraphState();
  const [pageColumnSorting, setPageColumnSorting] = useState('clicks');
  const [growthSorting, setGrowthSorting] = useGrowthSorting('pagedetails');

  const gscHookProps = useConnectToGoogleSearchConsole(
    appState.currentProject!.id,
  );

  useEffect(() => {
    setGraphState({
      ...graphState,
      GSCIsEnabled:
        gscHookProps.googleSearchConsoleData?.data?.settings.site !== null &&
        !gscHookProps.googleSearchConsoleError &&
        !gscHookProps.isGettingGoogleSearchConsoleData,
    });
  }, [
    gscHookProps.googleSearchConsoleError,
    gscHookProps.googleSearchConsoleData,
    gscHookProps.isGettingGoogleSearchConsoleData,
  ]);

  useEffect(() => {
    const handler = () => {
      navigate({ to: '/page-analytics', search: { page: 1, keywordPage: 1 } });
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  useEffect(() => {
    const handler = () => {
      onPageChange(1);
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  const totals = useAnalyticsTotals(graphState, { page: pageId });

  const currentQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range),
        dimensions: ['query'],
        dimension_filter_groups: [
          {
            filters: [
              {
                dimension: 'page',
                operator: 'equals',
                expression: pageId,
              },
            ],
          },
          {
            filters:
              graphState.country?.iso_3166_3 === 'xxx'
                ? []
                : [
                    {
                      dimension: 'country',
                      operator: 'equals',
                      expression: graphState.country?.iso_3166_3,
                    },
                  ],
          },
        ],
      },
    },
    {
      enabled: graphState.GSCIsEnabled,
    },
  );

  const previousQuery = useAnalyticsQuery(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
      queryParams: {
        ...getDateParams(graphState.range, true),
        dimensions: ['query'],
        dimension_filter_groups: [
          {
            filters: [
              {
                dimension: 'page',
                operator: 'equals',
                expression: pageId,
              },
            ],
          },
          {
            filters:
              graphState.country?.iso_3166_3 === 'xxx'
                ? []
                : [
                    {
                      dimension: 'country',
                      operator: 'equals',
                      expression: graphState.country?.iso_3166_3,
                    },
                  ],
          },
        ],
      },
    },
    {
      enabled: graphState.GSCIsEnabled,
    },
  );

  const data = convertData(
    {
      current: convertKey('query', currentQuery.data?.data ?? []),
      previous: convertKey('query', previousQuery.data?.data ?? []),
    },
    'query',
  );

  const mostClicks =
    data?.toSorted((a, b) => b.current.clicks - a.current.clicks)[0]?.current
      .clicks ?? 0;

  return (
    <>
      <AnalyticsFilters
        state={graphState}
        onStateChange={setGraphState}
        totals={totals}
      />
      <AnalyticsGraph state={graphState} page={pageId} />
      <Table.Root
        isLoading={currentQuery.isLoading}
        skeletonLoaders={20}
        items={sortAnalyticsData(
          data ?? [],
          growthSorting,
          pageColumnSorting,
        )?.slice((pageNumber - 1) * 20, pageNumber * 20)}
        columns={[
          {
            heading: 'Keyword',
            expanded: true,
            render: (item) => (
              <CellWithBar
                value={item.current.clicks}
                topValue={mostClicks}
                name={item.current.query}
                onClick={() => onKeywordClick(item.current.query)}
                actions={
                  <AnalyticsUrlActions
                    url={item.current.page}
                    onOpen={() => onKeywordClick(item.current.query)}
                  />
                }
              />
            ),
          },
          {
            heading: (
              <GrowthValueHeader
                heading="Clicks"
                onClick={() => setPageColumnSorting('clicks')}
                selected={pageColumnSorting === 'clicks'}
              />
            ),
            rightAlign: true,
            render: (item) => (
              <ValueWithGrowth
                hideGrowth={
                  graphState.range === 'P1Y' || graphState.range === 'P16M'
                }
                responsive={false}
                value={item.current.clicks}
                growth={calculateGrowth(item, 'clicks')}
              />
            ),
          },
          {
            heading: (
              <GrowthValueHeader
                heading="Impressions"
                onClick={() => setPageColumnSorting('impressions')}
                selected={pageColumnSorting === 'impressions'}
              />
            ),
            rightAlign: true,
            render: (item) => (
              <ValueWithGrowth
                hideGrowth={
                  graphState.range === 'P1Y' || graphState.range === 'P16M'
                }
                responsive={false}
                value={item.current.impressions}
                growth={calculateGrowth(item, 'impressions')}
              />
            ),
          },
          {
            heading: (
              <GrowthValueHeader
                heading="CTR"
                onClick={() => setPageColumnSorting('ctr')}
                selected={pageColumnSorting === 'ctr'}
              />
            ),
            rightAlign: true,
            render: (item) => (
              <ValueWithGrowth
                hideGrowth={
                  graphState.range === 'P1Y' || graphState.range === 'P16M'
                }
                responsive={false}
                value={(item.current.ctr * 100).toFixed(1)}
                growth={calculateGrowth(item, 'ctr')}
              />
            ),
          },
          {
            heading: (
              <GrowthValueHeader
                heading="Position"
                onClick={() => setPageColumnSorting('position')}
                selected={pageColumnSorting === 'position'}
              />
            ),
            rightAlign: true,
            render: (item) => (
              <ValueWithGrowth
                hideGrowth={
                  graphState.range === 'P1Y' || graphState.range === 'P16M'
                }
                absolute
                responsive={false}
                value={item.current.position.toFixed(1)}
                growth={calculateGrowth(item, 'position', true)}
              />
            ),
          },
        ]}
      >
        <Table.Header>
          <div className="mt-10 flex items-center gap-4">
            <h1 className="mb-1 text-xl font-semibold">Keywords</h1>
            <ToggleButton
              value={growthSorting}
              onChange={setGrowthSorting}
              options={[
                {
                  displayName: 'Popular',
                  value: 'popular',
                },
                {
                  displayName: 'Rising',
                  value: 'rising',
                },
                {
                  displayName: 'Falling',
                  value: 'falling',
                },
              ]}
            />
          </div>
        </Table.Header>
        <Table.Footer>
          <Pagination
            currentPage={pageNumber ?? 1}
            lastPage={Math.floor((currentQuery.data?.data.length ?? 0) / 20)}
            setCurrentPage={(pageNumber) => onPageChange(pageNumber)}
          />
        </Table.Footer>
      </Table.Root>
    </>
  );
};
