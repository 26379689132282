import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { BlockSelection } from '@/types';
import { useTextAreaResize } from '../hooks/useTextAreaResize';
import { PredefinedMessages } from './PredefinedMessages';
import { useEffect, useState } from 'react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { ImagePlus, Trash2, X } from 'lucide-react';
import { Tooltip } from '@/Components/v2/Tooltip';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { ImagePlugin } from '@udecode/plate-media/react';
import { Popover } from '@/Components/v2/Menu/Popover';

type Props = {
  onClearChat?: () => void;
  onSubmit: (predefinedMessage?: string, images?: string[]) => void;
  value: string;
  onChange: (e: string) => void;
  isSendingMessage: boolean;
  isClearingChat?: boolean;
  selection?: BlockSelection | null;
  disablePredefinedMessages?: boolean;
};

export const MessageInput = ({
  onChange,
  onClearChat,
  onSubmit,
  value,
  disablePredefinedMessages,
  isSendingMessage,
  isClearingChat,
  selection,
}: Props) => {
  const { showSnackbar } = useSnackbar();
  const { textAreaRef, bottomChatRef } = useTextAreaResize(value);
  const [isHovered, setIsHovered] = useState(false);

  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    if (
      !selection?.selected.showInChat &&
      textAreaRef.current &&
      !isSendingMessage
    ) {
      textAreaRef.current.focus();
    }
  }, [isSendingMessage, textAreaRef.current]);

  const handleReadImageFile = (file: File) => {
    if (!file.type.match(/image.*/)) {
      showSnackbar({
        color: 'red',
        message: 'Only images are allowed',
      });
      return;
    }
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64 = reader.result as string;

      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');

        const canvasContext = canvas.getContext('2d');
        if (!canvasContext) return;

        const MAX_WIDTH = 512;
        const MAX_HEIGHT = 512;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;

        canvasContext.drawImage(img, 0, 0, width, height);

        const newBase64 = canvas.toDataURL('image/png');

        setImages((prev) => [...prev, newBase64]);
      };
      img.src = base64;
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (predefinedMessage?: string, images?: string[]) => {
    if ((images?.length ?? 0) > 2) {
      showSnackbar({
        color: 'red',
        message: 'Only 2 images are allowed',
      });
      return;
    }
    onSubmit(predefinedMessage, images);
    setImages([]);
  };

  return (
    <div
      className={`relative flex w-full flex-col overflow-hidden rounded-3xl border border-slate-200 bg-slate-100 p-1 ${
        isSendingMessage || isClearingChat
          ? 'pointer-events-none opacity-50'
          : ''
      }`}
      onDragOver={(e) => {
        e.preventDefault();
        setIsHovered(true);
      }}
      onDragExit={() => setIsHovered(false)}
      onDrop={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsHovered(false);
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          handleReadImageFile(e.dataTransfer.files[i]);
        }
      }}
    >
      {isHovered && (
        <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center bg-primary-150 bg-opacity-50">
          Drop image here
        </div>
      )}
      {selection && selection.selected.showInChat && !isSendingMessage && (
        <div className="m-1 mb-2 rounded-2xl bg-primary-50 p-2">
          <p className="font-bold">Seleced text</p>
          <div className="max-h-32 overflow-y-auto">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                a: ({ node, ...props }) => (
                  <a
                    {...props}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                  />
                ),
              }}
              className="prose text-base"
            >
              {selection.selected.text}
            </ReactMarkdown>
          </div>
        </div>
      )}
      {images.length > 0 && (
        <div className="editor-scroll-bar mt-2 flex w-full gap-2 overflow-x-auto px-2">
          {images.map((image, index) => (
            <Popover
              type="hover"
              trigger={
                <div className="group relative flex h-28 w-28 flex-shrink-0 items-center justify-center overflow-hidden rounded-md border border-primary-300 bg-white object-contain">
                  <img
                    key={index}
                    src={image}
                    alt="image"
                    className="max-h-full max-w-full"
                  />
                  <div className="absolute right-0 top-0 opacity-0 group-hover:opacity-100">
                    <IconButton
                      icon={X}
                      onClick={() =>
                        setImages(images.filter((_, i) => i !== index))
                      }
                      dense
                      size="sm"
                    />
                  </div>
                </div>
              }
            >
              <img src={image} alt="image" />
            </Popover>
          ))}
        </div>
      )}
      <div className="flex flex-col items-start">
        <textarea
          onPaste={async (e) => {
            if (e.clipboardData.files.length > 0) {
              e.preventDefault();
              for (let i = 0; i < e.clipboardData.files.length; i++) {
                handleReadImageFile(e.clipboardData.files[i]);
              }
            }
          }}
          ref={textAreaRef}
          onFocus={() => setIsHovered(false)}
          disabled={isSendingMessage || isClearingChat}
          value={isSendingMessage ? '' : value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              if (!value) return;
              e.preventDefault();
              handleSubmit(undefined, images);
            }
          }}
          rows={2}
          placeholder="Message AI assistant..."
          className="mb-4 w-full resize-none border-0 bg-transparent pl-4 pr-4 pt-4 text-lg text-primary"
        />
        <div className="ml-1 mr-1 mt-1 flex w-full flex-shrink-0 items-center justify-between gap-1">
          <div className="mb-1 flex pl-1">
            {onClearChat && (
              <Tooltip title="Clear chat" side="top">
                <IconButton icon={Trash2} onClick={() => onClearChat()} />
              </Tooltip>
            )}
            {!disablePredefinedMessages && (
              <PredefinedMessages onSendMessage={handleSubmit} />
            )}
            <Tooltip title="Add image" side="top">
              <IconButton
                icon={ImagePlus}
                onClick={() => {
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.accept = 'image/*';
                  input.onchange = (e) => {
                    const files = (e.target as HTMLInputElement).files;
                    if (!files) return;
                    for (let i = 0; i < files.length; i++) {
                      handleReadImageFile(files[i]);
                    }
                  };
                  input.click();
                }}
              />
            </Tooltip>
          </div>
          <div
            onClick={() => {
              if (!value) return;
              handleSubmit(undefined, images);
            }}
            className={`mb-2 mr-3 aspect-square flex-shrink-0 rounded-full ${value ? 'cursor-pointer bg-primary hover:opacity-60 ' : 'bg-primary-300'} p-2`}
          >
            <img src="/svg/paperplane.svg" className="h-4 w-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
