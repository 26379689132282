'use client';

import { fetchProjectDocumentWriteMoreStore } from '@/api/openapiComponents';
import { SEO_AI_AUTH } from '@/api/openapiFetcher';
import { WriteMorePromptType } from '@/api/openapiSchemas';
import { URL } from '@/Components/Utils/v2/api';
import { useChat as useBaseChat } from 'ai/react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import type { Descendant, History, SlateEditor } from '@udecode/plate';

import { deserializeInlineMd, MarkdownPlugin } from '@udecode/plate-markdown';

import {
  AIChatPluginConfig,
  AIPluginConfig,
  useChatChunk,
} from '@udecode/plate-ai/react';
import { useEditorPlugin } from '@udecode/plate/react';

export type AiChatSubmit = {
  type: WriteMorePromptType;
  customPrompt?: string;
  content?: string;
};

export const useChat = (projectId: number, documentId: number) => {
  return useBaseChat({
    id: 'editor',
    fetch: async (_, info) => {
      const body = JSON.parse(info.body ?? '{}');

      await fetchProjectDocumentWriteMoreStore({
        pathParams: { project: projectId, document: documentId },
        body: {
          type: body.data.type,
          custom_prompt: body.data.customPrompt,
          content: body.data.content,
        },
      });

      const encoder = new TextEncoder();

      const readableStream = new ReadableStream({
        async start(controller) {
          const stream = new EventSourcePolyfill(
            `${URL}/projects/${projectId}/documents/${documentId}/write-more-stream`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
              },
              withCredentials: true,
            },
          );

          stream.addEventListener('update', (e) => {
            if (e.data === '[DONE]') {
              controller.close();
              stream.close();
              return;
            }
            const data = JSON.parse(e.data);
            controller.enqueue(
              encoder.encode(`0:${JSON.stringify(data.content)}\n`),
            );
          });
        },
      });

      return new Response(readableStream, {
        headers: {
          Connection: 'keep-alive',
          'Content-Type': 'text/plain',
        },
      });
    },
  });
};

export const useAIChatHooks = () => {
  const { editor, tf } = useEditorPlugin<AIPluginConfig>({ key: 'ai' });
  const { useOption } = useEditorPlugin<AIChatPluginConfig>({ key: 'aiChat' });
  const mode = useOption('mode');

  useChatChunk({
    onChunk: ({ isFirst, nodes }) => {
      if (mode === 'insert' && nodes.length > 0) {
        withAIBatch(
          editor,
          () => {
            tf.ai.insertNodes(nodes);
          },
          { split: isFirst },
        );
      }
    },
    onFinish: ({ content }) => {
      if (mode !== 'insert') return;

      const blockAbove = editor.api.block();

      if (!blockAbove) return;

      editor.undo();
      editor.history.redos.pop();

      const paragraphs = content.split('\n\n');

      const nodes = paragraphs.reduce(
        (prev, curr) => [
          ...prev,
          ...deserializeInlineMd(editor, curr + '\n\n'),
        ],
        [] as Descendant[],
      );

      withAIBatch(
        editor,
        () => {
          tf.insertNodes(nodes);
        },
        { split: true },
      );
    },
  });
};

export type AIBatch = History['undos'][number] & { ai?: boolean };

export const withAIBatch = (
  editor: SlateEditor,
  fn: () => void,
  {
    split,
  }: {
    split?: boolean;
  } = {},
) => {
  if (split) {
    editor.tf.withNewBatch(fn);
  } else {
    editor.tf.withMerging(fn);
  }

  const lastBatch = editor.history.undos?.at(-1) as AIBatch | undefined;

  if (lastBatch) {
    lastBatch.ai = true;
  }
};
