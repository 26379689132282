import { Menu } from 'lucide-react';
import { useEffect, useState } from 'react';
import { IconButton } from './v2/IconButton/IconButton';
import { SlideOver } from './SlideOver';
import { Sidebar } from './v2/Sidebar/Sidebar';

export const ResponsiveSidebar = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setIsSmallScreen(false);
      } else {
        setIsSmallScreen(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="relative flex h-full">
      {isSmallScreen ? (
        <div className="absolute left-1 top-1 flex lg:hidden">
          <IconButton icon={Menu} onClick={() => setShowSidebar(true)} />
          <SlideOver
            side="left"
            isOpen={showSidebar}
            dense
            size="xs"
            onClickOutside={() => setShowSidebar(false)}
          >
            <div className="flex h-full w-full">
              <Sidebar />
            </div>
          </SlideOver>
        </div>
      ) : (
        <Sidebar />
      )}
    </div>
  );
};
