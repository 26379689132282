import { createRoute } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { Profile } from './Profile';
import { CancelSubscription } from './CancelSubscription';
import { Subscription } from './Subscription';
import { AccountWrapper } from './AccountWrapper';
import { Users } from './Users';
import { Organisation } from './Organisation';

const wrapperRoute = createRoute({
  getParentRoute: () => rootRoute,
  beforeLoad: ({ context }) => {
    guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
  },
  path: '/account',
  component: () => <AccountWrapper />,
});

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/account/subscription/cancel',
    component: () => <CancelSubscription />,
  }),
  wrapperRoute.addChildren([
    createRoute({
      getParentRoute: () => wrapperRoute,
      beforeLoad: ({ context }) => {
        guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
      },
      path: '/profile',
      component: () => <Profile />,
    }),
    createRoute({
      getParentRoute: () => wrapperRoute,
      validateSearch: (search: Record<string, string>) =>
        ({
          openUsersDialog: search.openUsersDialog ? true : undefined,
        }) as { openUsersDialog?: boolean },
      beforeLoad: ({ context }) => {
        guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
      },
      path: '/subscription',
      component: () => <Subscription />,
    }),
    createRoute({
      getParentRoute: () => wrapperRoute,
      beforeLoad: ({ context }) => {
        guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
      },
      path: '/users',
      component: () => <Users />,
    }),
    createRoute({
      getParentRoute: () => wrapperRoute,
      beforeLoad: ({ context }) => {
        guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
      },
      path: '/organisation',
      component: () => <Organisation />,
    }),
  ]),
];
