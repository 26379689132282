import * as React from 'react';
import BeatLoader from '@/Components/BeatLoader';
import { CornerDownRight, Info, Pencil, SendHorizontal } from 'lucide-react';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Button } from '@/Components/v2/Button';
import { TextArea } from '@/Components/v2/TextArea/TextArea';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Popover } from '@/Components/v2/Menu/Popover';
import { Tooltip } from '@/Components/v2/Tooltip';

type Props = {
  text: string;
  id: number;
  isLoading?: boolean;
  disabled?: boolean;
  selectedText?: string;
  isModerated?: boolean;
  images?: string[];
  onSubmitEdit: (messageId: number, text: string, images?: string[]) => void;
};

export function HumanMessage({
  text,
  id,
  isLoading,
  disabled,
  isModerated,
  selectedText,
  images,
  onSubmitEdit,
}: Props) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedMessageValue, setEditedMessageValue] = React.useState('');

  const handleEdit = () => {
    setIsEditing(true);
    setEditedMessageValue(text);
  };

  const handleSubmitEdit = () => {
    onSubmitEdit(id, editedMessageValue, images);
    setIsEditing(false);
  };

  return (
    <>
      <div className="group pl-24">
        <div
          className={`relative rounded-2xl pt-2 ${isModerated ? 'bg-red-100' : 'bg-slate-100'}`}
        >
          {isModerated && (
            <div className="absolute right-3 top-3">
              <Tooltip title="Your message has been deemed inappropriate">
                <Info className=" text-red" size={16} />
              </Tooltip>
            </div>
          )}
          {selectedText && (
            <div className="px-2 pb-2">
              <div className="rounded-lg bg-primary-50 p-2">
                <p className="font-bold">Seleced text</p>
                <div className="max-h-32 overflow-y-auto">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      a: ({ node, ...props }) => (
                        <a
                          {...props}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 underline"
                        />
                      ),
                    }}
                    className="prose text-lg"
                  >
                    {selectedText}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          )}
          {!!images?.length && (
            <div className="editor-scroll-bar mt-2 flex w-full gap-2 overflow-x-auto px-2">
              {images?.map((image, index) => (
                <Popover
                  type="hover"
                  trigger={
                    <div className="group relative flex h-28 w-28 flex-shrink-0 items-center justify-center overflow-hidden rounded-md border border-primary-300 bg-white object-contain">
                      <img
                        key={index}
                        src={image}
                        alt="image"
                        className="max-h-full max-w-full"
                      />
                    </div>
                  }
                >
                  <img src={image} alt="image" />
                </Popover>
              ))}
            </div>
          )}
          {isEditing ? (
            <div className="relative p-2">
              <TextArea
                value={editedMessageValue}
                rows={5}
                maxHeight={15}
                resize
                onChange={(value) => setEditedMessageValue(value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (e.shiftKey) {
                      return;
                    }
                    e.preventDefault();

                    handleSubmitEdit();
                  }
                }}
                appendIcon={
                  <IconButton
                    icon={SendHorizontal}
                    onClick={handleSubmitEdit}
                    color="secondary"
                  />
                }
              />
            </div>
          ) : (
            <div className="flex flex-col gap-1 p-6">
              <p className="whitespace-pre-wrap text-lg">
                {text}
                {isLoading && (
                  <BeatLoader
                    className="mt-6"
                    size={'0.5rem'}
                    color="rgb(74 222 128 / 1)"
                  />
                )}
              </p>
            </div>
          )}
          <div className="absolute bottom-0 right-full top-0 pr-1 opacity-0 group-hover:opacity-100">
            <IconButton
              color="primary"
              onClick={handleEdit}
              disabled={disabled}
              icon={Pencil}
            />
          </div>
        </div>
      </div>
      <div className="-mb-2 mt-2 flex gap-2 text-primary-400">
        {' '}
        <CornerDownRight size={16} /> You asked
      </div>
    </>
  );
}
