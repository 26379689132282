type Props = {
  heading: string;
  selected?: boolean;
  onClick?: () => void;
};

export const GrowthValueHeader = ({ heading, selected, onClick }: Props) => {
  return (
    <div
      className={`pr-12 uppercase ${selected ? 'underline' : ''} ${onClick ? 'cursor-pointer hover:opacity-55' : ''}`}
      onClick={onClick}
    >
      {heading}
    </div>
  );
};
