import { unified } from 'unified';
import markdown from 'remark-parse';
import remarkGfm from 'remark-gfm';
import slate from './compiler';
import { MarkdownNode } from '@/types';
import { nextLine } from '@/data/initialPlateJSValue';

const processor = unified().use(markdown).use(remarkGfm).use(slate);

export const deserializeSnippet = (markdownString: string) => [
  ...(processor.processSync(markdownString).result as TDescendant[]),
];
export default (markdownString: string, options?: { noNewline?: boolean }) =>
  [
    ...(processor.processSync(markdownString).result as MarkdownNode[]),
    ...(options?.noNewline ? [] : [nextLine]),
  ] as any[];
