import { useProjectChatIndex } from '@/api/openapiComponents';
import { SlideOver } from '@/Components/SlideOver';
import { Button } from '@/Components/v2/Button';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { ChevronsLeft, ChevronsRight, Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { ChatItem } from './ChatItem';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useQueryClient } from '@tanstack/react-query';
import { ChatResource } from '@/api/openapiSchemas';

type Props = {
  chatId?: number;
  onNewChat: () => void;
  onSelectChat: (chatId: number) => void;
  onDeleteChat?: (chatId: number) => void;
};

export const ChatsSlideover = ({
  chatId,
  onNewChat,
  onSelectChat,
  onDeleteChat,
}: Props) => {
  const appState = useAppStore();
  const client = useQueryClient();

  const [showChats, setShowChats] = useState(false);
  const [chatPageNumber, setChatPageNumber] = useState(1);

  const chatsQuery = useProjectChatIndex({
    pathParams: {
      project: appState.currentProject!.id,
    },
    queryParams: {
      limit: 25,
      page: chatPageNumber,
    },
  });

  useEffect(() => {
    const channel = window.Echo.private(
      `projects.${appState.currentProject!.id}`,
    ).listen('ChatUpdated', (event: ChatResource) => {
      client.setQueriesData(
        {
          predicate: (query) => query.queryKey.includes('projectChatShow'),
        },
        (oldData: { data: ChatResource }) => ({
          ...oldData,
          data: { ...event, animate: true },
        }),
      );
      client.setQueriesData(
        {
          predicate: (query) => query.queryKey.includes('projectChatIndex'),
        },
        (oldData: { data: ChatResource[] }) => ({
          ...oldData,
          data: oldData.data.map((chat) =>
            chat.id === event.id ? event : chat,
          ),
        }),
      );
    });

    return () => channel.stopListening('ChatUpdated');
  }, []);

  return (
    <SlideOver
      title="Chats"
      isOpen={showChats}
      onClickOutside={() => setShowChats(false)}
      titleColor="text-primary"
      actions={
        <div className="flex p-3">
          {!!chatId &&
            (showChats ? (
              <IconButton
                icon={Plus}
                onClick={() => {
                  onNewChat();
                  setShowChats(false);
                }}
              />
            ) : (
              <Button
                prependIcon={Plus}
                variant="ghost"
                text="new"
                onClick={() => {
                  onNewChat();
                  setShowChats(false);
                }}
              />
            ))}

          {showChats ? (
            <IconButton
              icon={ChevronsRight}
              onClick={() => setShowChats(false)}
            />
          ) : (
            <Button
              text="chats"
              prependIcon={ChevronsLeft}
              onClick={() => setShowChats(true)}
              variant="ghost"
            />
          )}
        </div>
      }
    >
      {chatsQuery.isFetching && (
        <div className="flex flex-col gap-1">
          {Array.from({ length: 25 }).map(() => (
            <SkeletonLoader height="xs" />
          ))}
        </div>
      )}
      {chatsQuery.data?.data.map((chat, index) => (
        <ChatItem
          key={chat.id}
          chat={chat}
          onSelectChat={() => {
            onSelectChat(chat.id);
            setShowChats(false);
          }}
          onDeleteChat={() => {
            onDeleteChat?.(chat.id);
            chatsQuery.refetch();
          }}
        />
      ))}
      <Pagination
        currentPage={chatPageNumber}
        setCurrentPage={(page) => setChatPageNumber(page)}
        lastPage={chatsQuery.data?.meta.last_page}
      />
    </SlideOver>
  );
};
