import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import { SetupItem } from './components/SetupItem';
import { IconBadge } from './components/IconBadge';
import { SlidersHorizontal, Tag, Text } from 'lucide-react';
import { Button } from '@/Components/v2/Button';
import { useAppStore } from '../AppLoader/stores';
import { useConnectToGoogleMerchantCenter } from '../Settings/hooks';
import { GMCChooseAccountDialog } from '../Settings/Connections/components/GMCChooseAccountDialog';
import { ChooseProductFeedDialog } from '../Document/components/Workflows/components/ChooseProductFeedDialog';
import { useEffect, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { PageHeaderSection } from '@/Components/PageHeaderSection';
import { MerchantFeedResource } from '@/api/openapiSchemas';
import { useMerchantFeedSingle } from '@/api/openapiComponents';

type Props = {
  feed?: MerchantFeedResource;
};

export const FeedSeoNotConfigured = ({ feed }: Props) => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const projectId = appState.currentProject!.id;

  const [showChooseProductFeedDialog, setShowChooseProductFeedDialog] =
    useState(false);
  const [chosenFeed, setChosenFeed] = useState<
    MerchantFeedResource | undefined
  >(feed);

  useEffect(() => {
    SavvyCal('init');
    SavvyCal('inline', {
      link: 'seoai/feed-seo',
      selector: '#feedseo-config-booking-calendar',
    });
  }, []);

  useEffect(() => {
    if (chosenFeed) {
      const channel = window.Echo.private(`projects.${projectId}`);

      channel.listen(
        'MerchantCenter.ImportStarted',
        (event: MerchantFeedResource) => setChosenFeed(event),
      );

      channel.listen(
        'MerchantCenter.ImportCompleted',
        (event: MerchantFeedResource) => setChosenFeed(event),
      );

      channel.listen(
        'MerchantCenter.ImportProgress',
        (event: MerchantFeedResource) => setChosenFeed(event),
      );

      return () => {
        channel.stopListening('MerchantCenter.ImportStarted');
        channel.stopListening('MerchantCenter.ImportProgress');
        channel.stopListening('MerchantCenter.ImportCompleted');
      };
    }
  }, [chosenFeed]);

  const {
    googleMerchantDialog,
    setGoogleMerchantDialog,
    setupGoogleMerchantCenter,
    isSettingUpGoogleMerchantCenter,
    selectAccountForGoogleMerchantCenter,
    isSelectingAccountForGoogleMerchantCenter,
    googleMerchantCenterData,
    initialMerchantCenterState,
    hasNoAvailableAccounts,
  } = useConnectToGoogleMerchantCenter(projectId);

  const isMerchantCenterConnected =
    googleMerchantCenterData?.data.state === 'active';

  return (
    <Menus>
      <ChooseProductFeedDialog
        isOpen={showChooseProductFeedDialog}
        onClose={() => setShowChooseProductFeedDialog(false)}
        onSelect={(feed) => {
          setChosenFeed(feed);
        }}
      />
      <GMCChooseAccountDialog
        isLoading={isSelectingAccountForGoogleMerchantCenter}
        isOpen={googleMerchantDialog.isOpen}
        onConnect={() => {
          selectAccountForGoogleMerchantCenter({
            pathParams: {
              project: projectId,
            },
            body: {
              account_id: googleMerchantDialog.selectedAccount!,
            },
          });
          setGoogleMerchantDialog(initialMerchantCenterState);
        }}
        setGoogleDialog={setGoogleMerchantDialog}
        googleDialog={googleMerchantDialog}
        hasNoAvailableAccounts={hasNoAvailableAccounts}
      />
      <PageHeaderSection
        noBackground
        title="Setup product feed optimization"
        description="Use AI to optimize your product feed to get more clicks and impressions in Google Shopping and Free Listings."
        content={
          <img src={'/svg/feed-seo-header.svg'} className="mb-8 mt-16 w-96" />
        }
      />
      <PageContainer size="lg" height="content">
        <div className="flex flex-col items-center gap-2">
          <div className="flex w-full flex-col gap-12">
            <SetupItem
              icon={<IconBadge className="bg-blue-500" icon={Tag} />}
              name="Connect to Google Merchant Center"
              description="Allows access to submit optimized feeds."
              action={
                <div className="flex items-center gap-4 text-sm italic text-primary-400">
                  {!isMerchantCenterConnected &&
                    'Connecting can take a few minutes'}
                  <Button
                    text={isMerchantCenterConnected ? 'Connected' : 'Connect'}
                    disabled={isMerchantCenterConnected}
                    color="secondary"
                    isLoading={isSettingUpGoogleMerchantCenter}
                    variant={isMerchantCenterConnected ? 'outline' : 'fill'}
                    onClick={() => setupGoogleMerchantCenter()}
                  />
                </div>
              }
            />
            <SetupItem
              icon={<IconBadge className="bg-blue-100" icon={Text} />}
              name="Add product feed"
              description="Select the main feed that needs to be optimized."
              action={
                <div className="flex items-center gap-4 text-sm italic text-primary-400">
                  {chosenFeed !== undefined &&
                    chosenFeed.status === 'importing' &&
                    `${chosenFeed.item_count} products imported`}
                  <Button
                    text={chosenFeed ? 'Selected' : 'Select'}
                    color="secondary"
                    variant={chosenFeed ? 'outline' : 'fill'}
                    onClick={() => setShowChooseProductFeedDialog(true)}
                    isLoading={
                      chosenFeed !== undefined &&
                      chosenFeed.status !== 'completed'
                    }
                    disabled={!!chosenFeed || !isMerchantCenterConnected}
                  />
                </div>
              }
            />
            <SetupItem
              icon={
                <IconBadge className="bg-blue-200" icon={SlidersHorizontal} />
              }
              name="Select Products"
              description="Choose which products to optimize and how."
              action={
                <Button
                  text="Select"
                  color="secondary"
                  disabled={
                    !chosenFeed ||
                    chosenFeed.status !== 'completed' ||
                    !isMerchantCenterConnected
                  }
                  onClick={() =>
                    navigate({
                      to: '/feed-seo/$feedId/create-group',
                      params: { feedId: chosenFeed?.id! },
                    })
                  }
                />
              }
            />
          </div>
          <div className="mt-12 w-full">
            <PageHeaderSection
              noBackground
              title="Book a 1-on-1 setup session"
              description="Let one of our team members guide you through setting up and optimizing your feed using our newly launched Feed Optimizer"
            />
            <div id="feedseo-config-booking-calendar" className="w-full" />
          </div>
        </div>
      </PageContainer>
    </Menus>
  );
};
