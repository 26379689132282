import { Dialog } from '@/Components/Dialog';
import { WarningAlert } from '@/Components/v2/Alert';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { GoogleMerchantDialogState } from '@/Pages/Settings/hooks.ts';
import ReactCountryFlag from 'react-country-flag';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  hasNoAvailableAccounts: boolean;
  onConnect: () => void;
  setGoogleDialog: React.Dispatch<
    React.SetStateAction<GoogleMerchantDialogState>
  >;
  googleDialog: Omit<GoogleMerchantDialogState, 'isOpen'>;
};

export const GMCChooseAccountDialog = ({
  isLoading,
  isOpen,
  hasNoAvailableAccounts,
  onConnect,
  googleDialog,
  setGoogleDialog,
}: Props) => {
  return (
    <Dialog
      persistent
      isOpen={isOpen}
      size="sm"
      actions={
        hasNoAvailableAccounts
          ? []
          : [
              {
                text: 'Connect',
                color: 'secondary',
                onClick: onConnect,
                isLoading: isLoading,
              },
            ]
      }
    >
      {hasNoAvailableAccounts ? (
        <WarningAlert title="You have no access to any available accounts." />
      ) : (
        <InputDecoration
          label="Choose Account"
          tooltip="Account use with your Google Merchant connection."
        >
          <Select
            onChange={(selectedAccount) => {
              setGoogleDialog((prev) => ({ ...prev, selectedAccount }));
            }}
            value={googleDialog.selectedAccount}
            options={
              googleDialog.accounts?.map((account) => ({
                prependIcon: (
                  <ReactCountryFlag
                    countryCode={account.languageCode.split('-')[1]}
                    svg
                    style={{
                      height: '18px',
                      width: '23px',
                    }}
                    className="border border-gray-100"
                  />
                ),
                value: account.accountId,
                title: account.accountName,
              })) ?? []
            }
            placeholder="Choose an account for your connection"
          />
        </InputDecoration>
      )}
    </Dialog>
  );
};
