import { SendSearchResult } from '@/types';
import { useEffect } from 'react';
import ValidationErrors from '@/Components/ValidationErrors';
import { useDocumentStore } from './stores';
import { useShallow } from 'zustand/react/shallow';
import { useAppStore } from '../AppLoader/stores';
import { useNavigate, useRouter, useSearch } from '@tanstack/react-router';
import { DocumentLoading } from './DocumentLoading';
import { useCreateDocument, useGetDocument } from '@/api/openapiComponents';
import { Button } from '@/Components/v2/Button';
import { DocumentLayout } from './DocumentLayout';
import { CurrentEditorStateProvider } from './components/EditorStateProvider';
import { hasher } from '../AppLoader';

type Props = {
  documentId: number;
};

export default function DocumentLoader({ documentId }: Props) {
  const router = useRouter();
  const navigate = useNavigate();
  const search = useSearch({ from: '/documents/$documentId' });
  const currentProject = useAppStore(
    useShallow((state) => state.currentProject),
  );

  const { setInitialProps, isLoadingHeadingDescription, reset } =
    useDocumentStore(
      useShallow(({ setInitialProps, isLoadingHeadingDescription, reset }) => ({
        setInitialProps,
        isLoadingHeadingDescription,
        reset,
      })),
    );

  const createDocumentMutation = useCreateDocument();
  const documentQuery = useGetDocument(
    {
      pathParams: {
        document: documentId,
        project: currentProject!.id,
      },
    },
    {
      staleTime: 10000,
      retry: false,
      gcTime: 0,
    },
  );

  useEffect(() => {
    if (documentQuery.error?.status === 404) {
      createDocumentMutation.mutate(
        {
          pathParams: {
            project: currentProject!.id,
          },
        },
        {
          onSuccess: (data) => {
            navigate({
              to: `/documents/$documentId`,
              params: { documentId: '' + hasher.encode(data.data.id) },
              search: { ...search },
            });
          },
        },
      );
    }
  }, [documentQuery.error?.status]);

  useEffect(() => {
    if (!documentQuery.isLoading && documentQuery.data?.data) {
      const heading = documentQuery.data.data.title;
      const metaDescription = documentQuery.data.data.meta_description;
      const metaTitle = documentQuery.data.data.meta_title ?? '';
      const projectUrl = documentQuery.data.data.project_url;
      setInitialProps(
        heading,
        metaDescription,
        metaTitle,
        projectUrl,
        documentId,
      );
    }
  }, [documentQuery.isLoading]);

  useEffect(() => {
    localStorage.setItem('latestDocument', hasher.encode(documentId));
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    const url = `documents.${documentId}`;
    window.Echo.private(url).listen<SendSearchResult>(
      `DocumentUpdatedEvent`,
      (e) => {
        documentQuery.refetch();
      },
    );
    return () => {
      window.Echo.leave(url);
    };
  }, []);

  if (documentQuery.error?.message === 'Not found') {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4">
        Document not found
        <Button
          text="Go back"
          variant="outline"
          onClick={() => router.history.back()}
        />
      </div>
    );
  }

  if (documentQuery.error && documentQuery.error.status !== 404) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ValidationErrors apiError={documentQuery.error} />
      </div>
    );
  }

  if (documentQuery.isLoading || isLoadingHeadingDescription) {
    return <DocumentLoading />;
  }

  return (
    <CurrentEditorStateProvider>
      <DocumentLayout
        refetchDocument={documentQuery.refetch}
        document={documentQuery.data!.data}
      />
    </CurrentEditorStateProvider>
  );
}
