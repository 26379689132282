import { DynamicIcon } from '@/Components/v2/DynamicIcon/DynamicIcon';
import { LucideProps } from 'lucide-react';

type Props = {
  icon?: React.ComponentType<LucideProps> | string;
  cornerFlip?: boolean;
  className?: string;
};

export const IconBadge = ({ icon, className = '', cornerFlip }: Props) => {
  const Icon = icon;

  return (
    <div
      className={`relative flex h-12 w-12 items-center justify-center overflow-hidden rounded ${className}`}
    >
      {cornerFlip && (
        <div className="absolute right-0 top-0 h-3 w-3 bg-white opacity-40" />
      )}
      {typeof icon === 'string' ? (
        <DynamicIcon name={icon} className="h-6 w-6 text-white" />
      ) : !Icon ? null : (
        <Icon className="h-6 w-6 text-white" />
      )}
    </div>
  );
};
