import { ReactNode, createContext, useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { FilterItem } from './FilterItem';
import { SimplePopover } from '@/Components/SimplePopover';

export const FilterPopoverContext = createContext(() => {});

type Props = {
  name: string;
  filterName?: string | number;
  children: ReactNode;
  onRemoveFilter: () => void;
  disabled?: boolean;
  variant?: 'default' | 'ghost';
};

export const FilterPopover = ({
  children,
  onRemoveFilter,
  name,
  filterName,
  disabled,
  variant = 'default',
}: Props) => {
  const filter = '' + (filterName ? filterName : '');

  const [isOpen, setIsOpen] = useState(false);

  return (
    <SimplePopover
      disabled={disabled}
      align="start"
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={
        <>
          <FilterItem
            disabled={disabled}
            active={filter && filter.length > 0}
            onRemove={onRemoveFilter}
            variant={variant}
          >
            {filter && filter.length > 0 ? (
              filter
            ) : (
              <>
                {name} {isOpen ? ( <ChevronUp size={16} /> ) : (<ChevronDown size={16} />)}
              </>
            )}
          </FilterItem>
        </>
      }
    >
      {children}
    </SimplePopover>
  );
};
