import { forwardRef, PropsWithChildren } from 'react';

type Props = {
  reverse?: boolean;
  disableScroll?: boolean;
  onWheel?: (e: React.WheelEvent<HTMLDivElement>) => void;
};

export const ScrollContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<Props>
>(({ children, reverse, disableScroll, onWheel }, ref) => {
  return (
    <div
      ref={ref}
      onWheel={onWheel}
      style={{ scrollbarGutter: 'stable both-edges' }}
      className={`editor-scroll-bar mx-auto flex h-full w-full ${reverse ? 'flex-col-reverse' : 'flex-col'} flex-grow items-center gap-4 ${disableScroll ? 'overflow-hidden' : 'overflow-y-scroll'}`}
    >
      {children}
    </div>
  );
});
