import { ErrorAlert } from '@/Components/v2/Alert';
import { Button } from '@/Components/v2/Button';
import { ReactNode } from '@tanstack/react-router';
import { ChevronRight } from 'lucide-react';

type Props = {
  steps: string[];
  currentStep: number;
  isLoading?: boolean;
  disabled?: boolean;
  onPrevious: () => void;
  onNext?: () => void;
  additionalActions?: ReactNode;
  nextText?: string;
  error?: string;
};

export const CreationStepControls = ({
  currentStep,
  steps,
  error,
  disabled,
  nextText,
  isLoading,
  additionalActions,
  onNext,
  onPrevious,
}: Props) => {
  return (
    <div className="flex w-full items-center justify-between">
      <Button
        text="Previous"
        color="secondary"
        variant="outline"
        disabled={isLoading}
        onClick={onPrevious}
      />

      <div className="flex items-center gap-4">
        {steps.map((step, index) => (
          <div
            className={`flex items-center gap-4 ${index <= currentStep ? 'text-primary' : 'text-primary-300'}`}
          >
            {step} {index < steps.length - 1 && <ChevronRight />}
          </div>
        ))}
      </div>

      <div className="flex items-center gap-4">
        {error && <ErrorAlert title={error} />}
        {additionalActions}

        <Button
          color="secondary"
          isLoading={isLoading}
          disabled={disabled}
          text={nextText ?? 'Next'}
          onClick={onNext}
        />
      </div>
    </div>
  );
};
