import { createRoute, useParams } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasFeature,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { ChatPage } from './ChatPage';
import { NewChat } from './NewChat';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/chat',
    component: () => <NewChat />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/chat/$chatId',
    component: () => {
      const params = useParams({ from: '/chat/$chatId' });
      return <ChatPage chatId={Number(params.chatId)} />;
    },
  }),
];
