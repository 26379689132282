import {
  useOrganisationSubscriptionList,
  useProductsIndex,
  useSetupCheckout,
} from '@/api/openapiComponents';
import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useEffect, useState } from 'react';
import LogoutLink from '../Finalize/LogoutLink';
import { Errors } from '@/Components/Errors';
import { Button } from '@/Components/v2/Button';

export const Finalize = () => {
  const appState = useAppStore();

  const [shouldRedirect, setShouldRedirect] = useState(
    localStorage.getItem('finalizeShouldRedirect') !== null,
  );

  const setupCheckoutMutation = useSetupCheckout();
  const subscriptionQuery = useOrganisationSubscriptionList({
    pathParams: {
      organisation: appState.organisation!.id,
    },
  });
  const plansQuery = useProductsIndex({
    queryParams: {
      filters: {
        type: 'plan',
      },
      limit: 100,
    },
  });

  const isElligibleForTrial = subscriptionQuery.data?.data?.length === 0;

  const plans = (plansQuery.data?.data ?? []).filter(
    (plan) => plan.interval === 'month' && plan.name?.split(' ').length === 1,
  );

  useEffect(() => {
    if (plansQuery.isSuccess && subscriptionQuery.isSuccess) {
      const basicPlan = plans.find(
        (plan) => plan.name?.toLowerCase() === 'basic',
      );
      if (shouldRedirect && basicPlan) {
        localStorage.removeItem('finalizeShouldRedirect');
        handleSelectTrial();
      }

      if (!basicPlan) {
        setShouldRedirect(false);
      }
    }
  }, [plansQuery.isSuccess, subscriptionQuery.isSuccess]);

  const handleSelectTrial = () => {
    const basicPlan = plans.find(
      (plan) => plan.name?.toLowerCase() === 'basic',
    );

    if (!basicPlan) {
      setShouldRedirect(false);
      return;
    }

    setupCheckoutMutation.mutate(
      {
        pathParams: {
          organisation: appState.organisation!.id,
        },
        body: {
          price: basicPlan.id,
          enable_trial: isElligibleForTrial,
        },
      },
      {
        onSuccess: (data) => {
          if (data?.data?.link) {
            localStorage.setItem('finalizeDidRedirect', 'true');
            window.location.replace(data.data.link);
          }
        },
      },
    );
  };

  if (shouldRedirect || !plansQuery.isSuccess) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner color="secondary" size="2xl" />
      </div>
    );
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center text-gray-800">
      <div className="absolute left-0 right-0 top-2 flex w-full justify-center">
        <SeoAiLogoBlack />
      </div>
      <div className="absolute right-1 top-1">
        <LogoutLink />
      </div>
      <div className="flex w-full max-w-screen-lg flex-col items-center">
        <h2 className="mb-3 text-center font-work text-[25px] font-bold leading-normal">
          Start your trial
        </h2>
        <p className="mb-2 max-w-96 text-center">
          You're almost there! Unlock full access with a 7-day free trial of the
          Basic Plan
        </p>

        <Errors error={setupCheckoutMutation.error} />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSelectTrial();
          }}
          className="mt-8 w-full max-w-96 animate-fade-slow"
        >
          {/* Submit */}
          <div className="mt-6">
            <Button
              color="secondary"
              fullWidth
              type="submit"
              isLoading={
                setupCheckoutMutation.isPending ||
                setupCheckoutMutation.isSuccess
              }
              text="Start free trial"
            />
          </div>
        </form>

        <div className="absolute bottom-6 left-0 right-0 flex justify-center gap-1  text-gray-800">
          By continuing, you agree to our{' '}
          <a
            className="underline"
            href="https://seo.ai/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms and conditions
          </a>
        </div>
      </div>
    </div>
  );
};
