import { useEffect, useState } from 'react';
import { ProductResource } from '@/api/openapiSchemas';
import { useAppStore } from '../AppLoader/stores';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { Button } from '@/Components/v2/Button';
import ToggleButton from '@/Components/ToggleButton';
import {
  useOrganisationSubscriptionCancelDowngrade,
  useOrganisationSubscriptionIndex,
  useOrganisationSubscriptionResume,
  useProductsIndex,
} from '@/api/openapiComponents';
import { SubscriptionItem } from '../Auth/Finalize/SubscriptionItem';
import { useOpenStripePage } from './hooks';
import { ChangeSubscriptionDialog } from './components/ChangeSubscriptionDialog';
import { Select } from '@/Components/v2/Select';
import { formatThousandSeperator } from '@/utils';
import { SectionHeader } from '../components/SectionHeader';
import { AddonItem } from './components/AddonItem';
import dayjs from 'dayjs';

export const Subscription = () => {
  const appState = useAppStore();
  const navigate = useNavigate();

  const search = useSearch({ from: '/account/subscription' });

  const feedSeoIntervals = {
    plus: [1000, 5000, 10000, 20000],
    enterprise: [50000, 100000, 200000, 500000, 1000000],
  };

  const isOwner = appState.organisation?.users?.find((u) => u.id === appState.auth!.user.id)
    ?.role === 'owner';

  const getIntervalFromLookupKey = (
    intervals: number[],
    lookupKey?: string,
  ) => {
    if (!lookupKey) {
      return intervals[0];
    }
    if (lookupKey.split('_').length !== 3) {
      return intervals[0];
    }
    const amount = Number(lookupKey.split('_')[1]);

    if (Number.isNaN(amount)) {
      return intervals[0];
    }

    return intervals.find((interval) => interval === amount) ?? intervals[0];
  };

  const [intervalFilter, setIntervalFilter] = useState<'month' | 'year'>(
    'month',
  );
  const [plusFeedTokens, setPlusFeedTokens] = useState(
    getIntervalFromLookupKey(
      feedSeoIntervals['plus'],
      appState.subscription?.products.find((p) => p.name === 'plus')
        ?.lookup_key,
    ),
  );
  const [premiumFeedTokens, setPremiumFeedTokens] = useState(
    getIntervalFromLookupKey(
      feedSeoIntervals['enterprise'],
      appState.subscription?.products.find((p) => p.name === 'enterprise')
        ?.lookup_key,
    ),
  );

  const [productToBeChangedTo, setProductToBeChangedTo] = useState<{
    product?: ProductResource;
    includeUsers?: boolean;
  }>({});

  const {
    openStripePage: handleOpenInvoices,
    isLoading: isLoadingOpenInvoices,
  } = useOpenStripePage(appState.auth!.user.org_id, 'invoices');

  const resumeSubscriptionMutation = useOrganisationSubscriptionResume({
    onSuccess: () => subscriptionQuery.refetch(),
  });

  const cancelDowngradeMutation = useOrganisationSubscriptionCancelDowngrade({
    onSuccess: () => subscriptionQuery.refetch(),
  });

  const productsQuery = useProductsIndex({
    queryParams: {
      limit: 100,
      sort_by: 'position',
      sort_direction: 'ASC',
    },
  });
  const products = productsQuery.data?.data;

  const subscriptionQuery = useOrganisationSubscriptionIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
  });

  const subscription = subscriptionQuery.data?.data;
  const plan = subscription?.products.find((p) => p.type === 'plan');

  const subscriptionInterval = plan?.interval;

  useEffect(() => {
    if (subscriptionInterval) {
      setIntervalFilter(subscriptionInterval as 'month' | 'year');
    }
  }, [subscriptionInterval]);

  useEffect(() => {
    if (plan && search.openUsersDialog) {
      setProductToBeChangedTo({ product: plan, includeUsers: true });
    }
  }, [plan, search.openUsersDialog]);

  const isLoading = productsQuery.isLoading || subscriptionQuery.isLoading;

  const getLookupKey = (name: string, tokens: number, interval: string) => {
    const tokensPart =
      (tokens === 1000 && name === 'plus') ||
      (tokens === 50000 && name === 'enterprise')
        ? ''
        : `_${tokens}`;

    return `${name}${tokensPart}_${interval}`;
  };

  appState.pageTitle('Account');

  const currentBasicProduct = products?.find(
    (product) =>
      product.lookup_key === `basic_${intervalFilter}`,
  );
  const currentPlusProduct = products?.find(
    (product) =>
      product.lookup_key ===
      getLookupKey('plus', plusFeedTokens, intervalFilter),
  );
  const currentEnterpriseProduct = products?.find(
    (product) =>
      product.lookup_key ===
      getLookupKey('enterprise', premiumFeedTokens, intervalFilter),
  );

  return (
    <>
      <ChangeSubscriptionDialog
        productToBeChangedTo={productToBeChangedTo.product}
        plan={plan}
        setProductToBeChangedTo={(product) =>
          setProductToBeChangedTo({ product })
        }
        intervalFilter={intervalFilter}
        subscription={subscription}
        onChangeSubscription={() => subscriptionQuery.refetch()}
        includeUsers={productToBeChangedTo.includeUsers}
      />
      <div className="mt-8">
        <SectionHeader title="Plan" removeBorder />
      </div>
      <div className={'mb-8 flex w-full flex-col flex-nowrap items-center'}>
        {subscription?.is_canceled && (
          <div className="mb-4 flex items-center gap-4 rounded-lg bg-amber-100 p-2">
            Your subscription is canceled and will end on{' '}
            {dayjs(subscription.current_period_ending_at).format('DD MMM YYYY')}{' '}
            {isOwner && (
              <Button
                text="Resume subcription"
                color="secondary"
                isLoading={resumeSubscriptionMutation.isPending}
                onClick={() =>
                  resumeSubscriptionMutation.mutate(
                    {
                      pathParams: {
                        organisation: appState.auth!.user.org_id,
                      },
                    },
                    {
                      onSuccess: () => subscriptionQuery.refetch(),
                    },
                  )
                }
              />
            )}
          </div>
        )}
        {subscription?.has_scheduled_update && (
          <div className="mb-4 flex items-center gap-4 rounded-lg bg-amber-100 p-2">
            Your subscription is scheduled to downgrade on{' '}
            {dayjs(subscription.current_period_ending_at).format('DD MMM YYYY')}{' '}
            {isOwner && (
              <Button
                text="Cancel downgrade"
                color="secondary"
                isLoading={cancelDowngradeMutation.isPending}
                onClick={() =>
                  cancelDowngradeMutation.mutate(
                    {
                      pathParams: {
                        organisation: appState.auth!.user.org_id,
                      },
                    },
                    {
                      onSuccess: () => subscriptionQuery.refetch(),
                    },
                  )
                }
              />
            )}
          </div>
        )}
        {subscriptionInterval !== 'year' && !isLoading && (
          <ToggleButton
            options={[
              {
                displayName: 'Monthly',
                value: 'month',
              },
              {
                displayName: 'Yearly',
                value: 'year',
              },
            ]}
            size="sm"
            value={intervalFilter}
            className="mb-6"
            onChange={(value) => setIntervalFilter(value as 'month' | 'year')}
          />
        )}
        {isLoading ? (
          <div className="flex w-full items-end justify-center gap-8 pl-4">
            <div className="h-96 w-full animate-pulse rounded-lg bg-gray-50" />
            <div className="h-96 w-full animate-pulse rounded-lg bg-gray-50" />
            <div className="h-96 w-full animate-pulse rounded-lg bg-gray-50" />
          </div>
        ) : (
          <div className="flex w-full justify-between gap-8">
            <SubscriptionItem
              name="Basic"
              isCurrent={subscription?.products.some(
                (p) => p.id === currentBasicProduct?.id,
              )}
              actionText={
                subscription?.is_trialing ? 'Start paid plan' : 'Choose'
              }
              disabled={
                subscription?.is_canceled ||
                subscription?.has_scheduled_update ||
                !isOwner
              }
              isTrialing={subscription?.is_trialing}
              bulletPoints={[
                {
                  text: '1 user seat',
                },
                {
                  text: '1 website',
                },
                {
                  text: 'Product feed optimization',
                  disabled: true,
                },
              ]}
              description="For starters, small websites and freelancers."
              headline={`$${currentBasicProduct?.price}`}
              headlineAppendix={` / ${intervalFilter}`}
              onClick={() =>
                setProductToBeChangedTo({ product: currentBasicProduct })
              }
            />
            <SubscriptionItem
              name="Plus"
              actionText={
                subscription?.is_trialing ? 'Start paid plan' : 'Choose'
              }
              disabled={
                subscription?.is_canceled ||
                subscription?.has_scheduled_update ||
                !isOwner
              }
              isTrialing={subscription?.is_trialing}
              isCurrent={subscription?.products.some(
                (p) => p.id === currentPlusProduct?.id,
              )}
              bulletPoints={[
                {
                  text: '3 user seats',
                },
                {
                  text: 'Unlimited websites',
                },
                {
                  text: (
                    <div className="flex w-full items-center gap-2">
                      <Select
                        value={plusFeedTokens}
                        onChange={(value) => setPlusFeedTokens(value as number)}
                        options={feedSeoIntervals.plus.map((value) => ({
                          title: formatThousandSeperator(value),
                          value,
                        }))}
                      />{' '}
                      feed optimized products
                    </div>
                  ),
                },
              ]}
              description="For small e-commerce sites, mid-sized websites and small teams."
              headline={`$${currentPlusProduct?.price}`}
              headlineAppendix={` / ${intervalFilter}`}
              onClick={() =>
                setProductToBeChangedTo({ product: currentPlusProduct })
              }
            />
            <SubscriptionItem
              name="Enterprise"
              actionText={
                subscription?.is_trialing ? 'Start paid plan' : 'Choose'
              }
              isTrialing={subscription?.is_trialing}
              disabled={
                subscription?.is_canceled ||
                subscription?.has_scheduled_update ||
                !isOwner
              }
              isCurrent={subscription?.products.some(
                (p) => p.id === currentEnterpriseProduct?.id,
              )}
              bulletPoints={[
                {
                  text: '5 user seats',
                },
                {
                  text: 'Unlimited websites',
                },
                {
                  text: (
                    <div className="flex w-full items-center gap-2">
                      <Select
                        value={premiumFeedTokens}
                        onChange={(value) =>
                          setPremiumFeedTokens(value as number)
                        }
                        options={feedSeoIntervals.enterprise.map((value) => ({
                          title: formatThousandSeperator(value),
                          value,
                        }))}
                      />{' '}
                      feed optimized products
                    </div>
                  ),
                },
              ]}
              description="Ideal for large e-commerce sites, agencies and enterprises."
              headline={`$${currentEnterpriseProduct?.price}`}
              headlineAppendix={` / ${intervalFilter}`}
              onClick={() =>
                setProductToBeChangedTo({ product: currentEnterpriseProduct })
              }
            />
          </div>
        )}
        <p className="mx-auto mt-6 text-center italic text-primary">
          <br /> Unlimited is within fair usage guidelines. Usage beyond typical
          levels might lead to certain restrictions.
        </p>
      </div>

      <div className="mb-2">
        <SectionHeader title="Addons" removeBorder />
      </div>

      <AddonItem
        name="Extra user seats"
        count={
          subscription?.products.find(
            (product) => product.name === 'Additional users',
          )?.quantity ?? 0
        }
        property="additional users"
        action={
          <Button
            text="Manage user seats"
            color="secondary"
            disabled={!isOwner}
            onClick={() =>
              setProductToBeChangedTo({ product: plan, includeUsers: true })
            }
          />
        }
      />

      <div className="mt-12">
        <SectionHeader title="Actions" removeBorder />
        <div className="flex items-center justify-end gap-4 rounded-lg bg-gray-50 px-8 py-8 text-primary">
          <Button
            text="view invoices"
            color="secondary"
            variant="outline"
            disabled={!isOwner}
            isLoading={isLoadingOpenInvoices}
            onClick={handleOpenInvoices}
          />
          <Button
            text="Cancel subscription"
            color="secondary"
            disabled={!isOwner}
            onClick={() => navigate({ to: '/account/subscription/cancel' })}
            variant="outline"
          />
        </div>
      </div>
    </>
  );
};
