import { LucideProps } from 'lucide-react';
import { isComponentFunction } from '../utils';
import { ReactNode, useEffect, useRef } from 'react';
import { Tooltip } from '../Tooltip';

export type TabItemProps = {
  color?:
    | 'green'
    | 'red'
    | 'blue'
    | 'gray'
    | 'yellow'
    | 'primary'
    | 'secondary'
    | 'white';
  active?: boolean;
  disabled?: boolean;
  dense?: boolean;
  text: string;
  tooltip?: { text: string; navLink?: string; navLinkText?: string };
  grow?: boolean;
  onClick?: () => void;
  prependIcon?: React.ReactNode | React.ComponentType<LucideProps>;
  appendIcon?: React.ReactNode | React.ComponentType<LucideProps>;
};

export const TabItem = ({
  text,
  appendIcon,
  prependIcon,
  dense,
  grow,
  color,
  tooltip,
  disabled,
  onClick,
  active,
}: TabItemProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const getLineColor = () => {
    switch (color) {
      case 'green':
        return 'bg-green hover:bg-green-100';
      case 'red':
        return 'bg-red hover:bg-red-100';
      case 'blue':
        return 'bg-blue hover:bg-blue-100';
      case 'gray':
        return 'bg-gray hover:bg-gray-100';
      case 'yellow':
        return 'bg-yellow hover:bg-yellow-100';
      case 'primary':
        return 'bg-primary hover:bg-primary-100';
      case 'secondary':
        return 'bg-secondary hover:bg-secondary-100';
      default:
        return 'bg-gray hover:bg-gray-100';
    }
  };

  const getTextColor = () => {
    switch (color) {
      case 'green':
        return 'text-green';
      case 'red':
        return 'text-red';
      case 'blue':
        return 'text-blue';
      case 'gray':
        return 'text-gray';
      case 'yellow':
        return 'text-yellow';
      case 'primary':
        return 'text-primary';
      case 'secondary':
        return 'text-secondary';
      default:
        return 'text-gray';
    }
  };

  const getHoverColor = () => {
    switch (color) {
      case 'green':
        return 'hover:bg-green-100';
      case 'red':
        return 'hover:bg-red-100';
      case 'blue':
        return 'hover:bg-blue-100';
      case 'gray':
        return 'hover:bg-gray-100';
      case 'yellow':
        return 'hover:bg-yellow-100';
      case 'primary':
        return 'hover:bg-primary-100';
      case 'secondary':
        return 'hover:bg-secondary-100';
      default:
        return 'hover:bg-gray-100';
    }
  };

  const PrependIcon = prependIcon;
  const AppendIcon = appendIcon;

  useEffect(() => {
    if (active) {
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [active]);

  return (
    <div
      ref={containerRef}
      className={`relative flex items-center ${
        grow ? 'flex-grow' : ''
      } justify-center gap-2 ${getTextColor()} ${dense ? '' : 'px-5'} ${
        disabled
          ? `cursor-not-allowed opacity-50`
          : `cursor-pointer ${getHoverColor()}`
      }`}
      onClick={disabled ? undefined : onClick}
    >
      <Tooltip
        title={tooltip?.text}
        navLink={tooltip?.navLink}
        navLinkText={tooltip?.navLinkText}
        disabled={!tooltip}
      >
        <div className="flex items-center justify-center gap-2">
          {PrependIcon &&
            (isComponentFunction(PrependIcon) ? (
              <PrependIcon className={`h-5 w-5 flex-shrink-0 text-primary`} />
            ) : (
              <div className={`flex shrink-0`}>{PrependIcon as ReactNode}</div>
            ))}
          <p
            className={`whitespace-nowrap text-sm ${dense ? '' : 'py-2'} ${
              active ? 'font-bold' : ''
            }`}
          >
            {text}
          </p>
          {AppendIcon &&
            (isComponentFunction(AppendIcon) ? (
              <AppendIcon className={`h-5 w-5 flex-shrink-0 text-primary`} />
            ) : (
              <div className={`flex shrink-0`}>{AppendIcon as ReactNode}</div>
            ))}
          {active && (
            <div
              className={`absolute -bottom-0.5 left-0 right-0 h-1 rounded ${getLineColor()} `}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
};
