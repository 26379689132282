import { useLoadingEllipsis } from '@/Hooks/useLoadingEllipsis';

type Props = {
  fixedWidth?: boolean;
};

export const AnimatedEllipsis = ({ fixedWidth }: Props) => {
  const ellipsis = useLoadingEllipsis(true);

  if (fixedWidth) {
    return (
      <div>
        {ellipsis}
        <span className="opacity-0">{'...'.slice(ellipsis.length)}</span>
      </div>
    );
  }

  return ellipsis;
};
