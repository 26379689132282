import { MerchantFeedGroupResource } from '@/api/openapiSchemas';
import { ReactNode } from '@tanstack/react-router';
import { FeedStatusLabel } from './FeedStatusLabel';

type Props = {
  actions?: {
    top?: ReactNode;
    bottom?: ReactNode;
    atStatus?: ReactNode;
  };
  group?: MerchantFeedGroupResource;
};

export const OptimizationGroupDetails = ({ group, actions }: Props) => {
  return (
    <div className="relative rounded border border-primary-300 bg-white p-4">
      {actions?.top && (
        <div className="absolute right-2 top-2">{actions?.top}</div>
      )}
      {actions?.bottom && (
        <div className="absolute bottom-2 right-2">{actions?.bottom}</div>
      )}
      <p className="font-bold text-primary">Conditions</p>
      {group?.filters?.map((filter) => (
        <div key={filter.value} className="flex">
          <p className="text-primary">{filter.field ?? 'Any'}: </p>
          <p className="text-primary">{filter.value}</p>
        </div>
      ))}

      <p className="mt-6 font-bold text-primary">Products</p>
      {group?.items_count}

      <p className="mt-6 font-bold text-primary">Parameters to be optimized</p>
      {group?.attributes?.map((attr) => <div>{attr.display_name}</div>)}

      <div className='flex gap-2'>
      <p className="mt-6 font-bold text-primary">Current status</p>
        {actions?.atStatus}
      </div>
      <FeedStatusLabel group={group} />
    </div>
  );
};
