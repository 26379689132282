import { Movable, MoveEvent } from '@/Components/Movable';
import { useSearch } from '@tanstack/react-router';
import { GripVertical } from 'lucide-react';

type Props = {
  onMove: (e: MoveEvent) => void;
};

export const DraggableSide = ({ onMove }: Props) => {
  const search = useSearch({ from: '/documents/$documentId' });

  return (
    <div
      className={`absolute bottom-0 top-0 z-50 flex ${search.v2 ? 'left-0' : 'right-0'}`}
    >
      <Movable onMove={onMove}>
        <div className="group relative flex h-full w-1 cursor-ew-resize flex-col justify-center hover:bg-secondary">
          {search.v2 && (
            <>
              <div className="pointer-events-none absolute left-0 top-1/2 z-30 h-20 w-6 -translate-y-10 bg-white">
                <div className="flex h-full w-1 flex-col justify-center group-hover:bg-secondary">
                  <div className="h-8 w-2 bg-white" />
                </div>
              </div>
              <div className="relative -m-2.5 h-8 w-5 rounded-md bg-white shadow-xl">
                <div className="absolute inset-0 z-40 flex items-center justify-center">
                  <GripVertical size={16} />
                </div>
              </div>
            </>
          )}
        </div>
      </Movable>
    </div>
  );
};
