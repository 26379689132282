import React, { useEffect, useState } from 'react';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import {
  ProductResource,
  SubscriptionProductResource,
  SubscriptionResource,
} from '@/api/openapiSchemas';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useNavigate } from '@tanstack/react-router';
import { AlertCircle } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useOrganisationsOrganisationInvitesIndex,
  useOrganisationSubscriptionEndTrial,
  useOrganisationSubscriptionUpdate,
  useProductsIndex,
  useTaxCalculation,
} from '@/api/openapiComponents';
import { addonFilter } from '../util';
import { Select } from '@/Components/v2/Select';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';

type Props = {
  productToBeChangedTo?: ProductResource;
  subscription?: SubscriptionResource;
  plan?: SubscriptionProductResource;
  intervalFilter: string;
  includeUsers?: boolean;
  onChangeSubscription: () => void;
  setProductToBeChangedTo: (product: ProductResource | undefined) => void;
};

export const ChangeSubscriptionDialog = ({
  plan,
  productToBeChangedTo,
  subscription,
  includeUsers,
  intervalFilter,
  setProductToBeChangedTo,
  onChangeSubscription,
}: Props) => {
  const [actualPrice, setActualPrice] = useState<{
    total: string;
    tax: string;
    sub_total: string;
    tax_percentage: number;
    lines: {
      id: number;
      name: string;
      price: number;
      quantity: number;
      sub_total: number;
    }[];
  }>();
  const client = useQueryClient();
  const appState = useAppStore();

  const action = (() => {
    if (subscription?.is_trialing) {
      return 'start paid plan';
    }
    if (productToBeChangedTo?.id === plan?.id) {
      return 'update credits';
    }
    return productToBeChangedTo?.price! > plan?.price!
      ? 'upgrade to'
      : 'downgrade to';
  })();

  const addonsQuery = useProductsIndex(
    {
      queryParams: {
        filters: {
          type: 'addon',
        },
        sort_by: 'position',
        sort_direction: 'ASC',
        limit: 100,
      },
    },
    {
      enabled: !!productToBeChangedTo,
    },
  );

  const invitesQuery = useOrganisationsOrganisationInvitesIndex({
    pathParams: {
      organisation: appState.auth!.user.org_id,
    },
    queryParams: {
      limit: 100,
    },
  });
  const invites = invitesQuery.data?.data;

  const userAddon = addonsQuery.data?.data?.find(
    (addon) =>
      addon.name === 'Additional users' && addon.interval === intervalFilter,
  );

  const endTrialMutation = useOrganisationSubscriptionEndTrial();
  const updateSubscriptionMutation = useOrganisationSubscriptionUpdate();

  const taxCalculationMutation = useTaxCalculation();
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    setActualPrice(undefined);
    if (productToBeChangedTo && userAddon) {
      taxCalculationMutation.mutate(
        {
          body: {
            organisation_id: appState.auth!.user.org_id,
            products: [
              {
                product_id: productToBeChangedTo.id,
                quantity: 1,
              },
              ...(userCount > 0
                ? [{ product_id: userAddon?.id, quantity: userCount }]
                : []),
            ],
          },
        },
        {
          onSuccess: (data) => setActualPrice(data.data as any),
        },
      );
    }
  }, [productToBeChangedTo, userCount, userAddon]);

  useEffect(() => {
    setUserCount(
      subscription?.products.find((p) => p.name === 'Additional users')
        ?.quantity ?? 0,
    );
  }, [!!productToBeChangedTo]);

  const userCreditIntervals = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  const isLoading =
    updateSubscriptionMutation.isPending ||
    endTrialMutation.isPending ||
    (updateSubscriptionMutation.isSuccess && !endTrialMutation.isError) ||
    (endTrialMutation.isSuccess && !updateSubscriptionMutation.isError);

  const handleFinishUpgrade = () => {
    client.invalidateQueries({
      predicate: (query) =>
        query.queryKey.includes('organisationSubscriptionIndex'),
    });
    updateSubscriptionMutation.reset();
    appState.refreshSubscription();
    setProductToBeChangedTo(undefined);
    onChangeSubscription();
  };

  const handleChangeSubscription = async () => {
    if (subscription?.is_trialing) {
      await endTrialMutation.mutateAsync({
        pathParams: {
          organisation: appState.auth!.user.org_id,
        },
      });

      if (productToBeChangedTo?.name === 'basic') {
        handleFinishUpgrade();
        return;
      }
    }

    updateSubscriptionMutation.mutate(
      {
        pathParams: {
          organisation: appState.auth!.user.org_id,
        },
        body: {
          products: [
            {
              quantity: 1,
              product_id: productToBeChangedTo?.id!,
            },
            ...(userCount > 0
              ? [
                  {
                    quantity: userCount,
                    product_id: userAddon?.id!,
                  },
                ]
              : []),
          ],
        },
      },
      {
        onSuccess: handleFinishUpgrade,
      },
    );
  };

  // Check if the subscription to be downgraded to allows fewer projects than the user currently have
  const addonWebsiteCount =
    actualPrice?.lines.find((line) => line.name === 'Additional websites')
      ?.quantity ?? 0;
  const productWebsiteCount = Number(
    productToBeChangedTo?.settings?.find(
      (setting) => setting.setting?.name === 'organisation_project_limit',
    )?.default_value,
  );

  const availableUsers =
    ((includeUsers
      ? subscription?.products.find((product) => product.type === 'plan')
          ?.settings
      : productToBeChangedTo?.settings
    )?.find((setting) => setting.setting?.name === 'organisation_user_limit')
      ?.default_value ?? 0) + userCount;

  const mustDeleteProjects =
    (appState.projects?.length ?? 0) > addonWebsiteCount + productWebsiteCount;

  // Warning message: Also displays a warning if the user attempts to buy more autogenerate credits than content audits
  const warning = (() => {
    if (mustDeleteProjects) {
      return {
        critical: true,
        text: 'You will need to delete some websites to change to this plan.',
      };
    }
    if (
      availableUsers <
      appState.organisation!.users!.length + (invites?.length ?? 0)
    ) {
      return {
        critical: true,
        text: 'You will need to remove some users to change to this plan.',
      };
    }
    const contentCreditAddons =
      actualPrice?.lines.find(
        (line) => line.name === 'Additional content audits',
      )?.quantity ?? 0;
    const contentCreditsPlan =
      productToBeChangedTo?.settings?.find(
        (setting) => setting.setting?.name === 'seo_audit_limit',
      )?.default_value ?? 0;
    const contentAudits = contentCreditAddons + contentCreditsPlan;

    const autoGenerateAddons =
      actualPrice?.lines.find((line) => line.name === 'Additional AI articles')
        ?.quantity ?? 0;
    const autoGeneratePlan =
      productToBeChangedTo?.settings?.find(
        (setting) => setting.setting?.name === 'generated_article_limit',
      )?.default_value ?? 0;
    const autoGenerateCredits = autoGeneratePlan + autoGenerateAddons;

    if (autoGenerateCredits > contentAudits) {
      return {
        critical: false,
        text: 'You will need more content audits to use all AI article credits on new content',
      };
    }
  })();

  return (
    <ConfirmDialog
      isOpen={!!productToBeChangedTo}
      isLoading={isLoading}
      size={'sm'}
      title={`Change ${includeUsers ? 'additional users' : 'subscription plan'}`}
      content={
        <>
          {includeUsers && (
            <InputDecoration label="Total amount of extra users">
              {userAddon ? (
                <Select
                  placeholder="No additional users"
                  value={userCount}
                  onChange={(value) => setUserCount(value ?? 0)}
                  options={userCreditIntervals.map((interval) => ({
                    title: interval + ` user${interval > 1 ? 's' : ''}`,
                    value: interval,
                  }))}
                />
              ) : (
                <SkeletonLoader />
              )}
            </InputDecoration>
          )}
          <div className={`mb-6 mt-4 flex justify-between gap-4`}>
            <div className="flex w-full flex-grow flex-col items-end overflow-hidden rounded-lg bg-gray-100 p-4">
              {actualPrice ? (
                <>
                  {actualPrice.lines.map((line) => (
                    <p
                      className="mt-1 flex w-full justify-between"
                      key={`${line.id}line`}
                    >
                      {line.type === 'plan' && 'Plan: '}
                      {line.name}{' '}
                      <div className="flex">
                        ${line.sub_total.toLocaleString()} /{' '}
                        {productToBeChangedTo?.interval}
                      </div>
                    </p>
                  ))}
                  <div className="mt-4 flex flex-grow flex-col items-end gap-2 border-t border-gray-300">
                    <p className="">
                      Subtotal: ${actualPrice.sub_total.toLocaleString()} /{' '}
                      {productToBeChangedTo?.interval}
                    </p>
                    <p className="">
                      Tax ({actualPrice.tax_percentage}%): $
                      {actualPrice.tax.toLocaleString()} /{' '}
                      {productToBeChangedTo?.interval}
                    </p>
                  </div>
                  <p className="border-t border-gray-300 font-bold">
                    Total: ${actualPrice.total.toLocaleString()} /{' '}
                    {productToBeChangedTo?.interval}
                  </p>
                </>
              ) : (
                <>
                  <div className="m-1 mb-1 h-4 w-full rounded bg-gray-300" />
                  {userCount > 0 && (
                    <div className="m-1 h-4 w-24 rounded bg-gray-300" />
                  )}
                  <div className="mt-4 flex flex-grow flex-col items-end gap-2 border-t border-gray-300 py-1">
                    <div className="h-4 w-28 rounded bg-gray-300" />
                    <div className="h-4 w-20 rounded bg-gray-300" />
                  </div>
                  <div className="h-1 w-16 border-t border-gray-300" />
                  <div className="h-4 w-16 rounded bg-gray-300" />
                </>
              )}
              <div className={`mt-4 text-sm italic text-gray-500 `}>
                New price for next period. You'll be prorated the amount
                immediately for the current subscription period.
              </div>
            </div>
          </div>
          {warning && (
            <div
              className={`flex items-end items-center gap-2 rounded-md  p-2  ${
                warning.critical
                  ? 'bg-red-50 text-red-500'
                  : 'bg-amber-50 text-amber-500'
              }`}
            >
              <AlertCircle /> {warning.text}
            </div>
          )}
        </>
      }
      confirmText={action}
      disabled={mustDeleteProjects}
      onClose={() => setProductToBeChangedTo(undefined)}
      onConfirm={handleChangeSubscription}
      error={updateSubscriptionMutation.error ?? endTrialMutation.error}
    />
  );
};
