import { InternalLinkingSlideOver } from './components';
import { CurrentSlideOver } from '@/types';
import { DocumentLengthSlideOver } from './components/DocumentLengthSlideOver';
import { MetadataSlideOver } from './components/MetadataSlideOver/MetadataSlideOver';
import { DocumentResource, ProjectResource } from '@/api/openapiSchemas';
import { TitleScoreSlideOver } from './components/TitleScoreSlideOver/TitleScoreSlideOver';
import { QuestionSlideover } from './components/QuestionsSlideover';
import { ExternalLinksSlideOver } from './components/ExternalLinksSlideOver/ExternalLinksSlideOver';
import { KeywordInjectionSlideOver } from './components/KeywordInjection';
import { OutlineSlideOver } from './components/OutlineSliderOver/OutlineSlideOver';
import { OutlineItem } from '@/Components/DocumentForm/hooks';
import { MetaTitleSlideOver } from './components/MetaTitleSlideOver';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { KeywordGapSlideover } from './components/KeywordGapSlideover/KeywordGapSlideover';
import { PlateEditor } from '@udecode/plate/react';

type Props = {
  editor: PlateEditor | null;
  document: DocumentResource;
  project: ProjectResource;
  currentlyOpenSlideover?: CurrentSlideOver;
  metaData?: string;
  setMetaData?: (value: string) => void;
  onClose: () => void;
  onInsertHeading: (heading: string) => void;
  onInsertOutline: (outline: OutlineItem[]) => void;
};

export function DocumentSlideOvers({
  currentlyOpenSlideover,
  document,
  onInsertHeading,
  onInsertOutline,
  ...props
}: Props) {
  const { data: isGenerating } = useQuery<boolean>({
    queryKey: ['autoGenerationStream'],
  });

  useEffect(() => {
    if (!isGenerating) {
      const listener = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          props.onClose();
        }
      };

      window.addEventListener('keydown', listener);
      return () => window.removeEventListener('keydown', listener);
    }
  }, [isGenerating]);

  return (
    <>
      <OutlineSlideOver
        {...props}
        document={document}
        onInsert={onInsertHeading}
        onGenerateOutline={onInsertOutline}
        subHeadings={document.text
          .split('\n')
          .filter(
            (heading) =>
              heading.startsWith('#') && heading.replaceAll('#', '').length > 0,
          )}
        isOpen={currentlyOpenSlideover === 'outline-builder'}
      />
      <InternalLinkingSlideOver
        {...props}
        documentId={document.id}
        documentReport={document.document_report}
        isOpen={currentlyOpenSlideover === 'internal-linking'}
      />
      <TitleScoreSlideOver
        {...props}
        projectId={props.project.id}
        document={document}
        isOpen={currentlyOpenSlideover === 'title-score'}
      />
      <DocumentLengthSlideOver
        {...props}
        document={document}
        isOpen={currentlyOpenSlideover === 'document-length'}
      />
      <MetadataSlideOver
        {...props}
        document={document}
        hideSuggestions={
          document.title.length === 0 || (document.word_count ?? 0) < 20
        }
        isOpen={currentlyOpenSlideover === 'meta-description'}
      />
      <QuestionSlideover
        {...props}
        document={document}
        isOpen={currentlyOpenSlideover === 'questions'}
      />
      <ExternalLinksSlideOver
        {...props}
        document={document}
        isOpen={currentlyOpenSlideover === 'external-links'}
      />
      <KeywordInjectionSlideOver
        {...props}
        document={document}
        isOpen={currentlyOpenSlideover === 'keyword-injection'}
      />
      <MetaTitleSlideOver
        {...props}
        document={document}
        isOpen={currentlyOpenSlideover === 'meta-title'}
      />
      <KeywordGapSlideover
        {...props}
        document={document}
        isOpen={currentlyOpenSlideover === 'keyword-gap'}
      />
    </>
  );
}
