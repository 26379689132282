import { useProductsIndex, useSetupCheckout } from '@/api/openapiComponents';
import { ProductResource } from '@/api/openapiSchemas';
import { Errors } from '@/Components/Errors';
import { SeoAiLogoBlack } from '@/Components/SVGs/SeoAiLogoBlack';
import { Button } from '@/Components/v2/Button';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useState } from 'react';
import LogoutLink from '../Finalize/LogoutLink';

export const SubscriptionExpired = () => {
  const appState = useAppStore();

  const [selectedPlan, setSelectedPlan] = useState<ProductResource>();

  const setupCheckoutMutation = useSetupCheckout();
  const plansQuery = useProductsIndex({
    queryParams: {
      filters: {
        type: 'plan',
      },
      limit: 100,
    },
  });

  const plans = (plansQuery.data?.data ?? []).filter(
    (plan) => plan.interval === 'month' && plan.name?.split(' ').length === 1,
  );

  const handleSubmit = () => {
    setupCheckoutMutation.mutate(
      {
        pathParams: {
          organisation: appState.organisation!.id,
        },
        body: {
          price: selectedPlan!.id,
          enable_trial: false,
        },
      },
      {
        onSuccess: (data) => {
          if (data?.data?.link) {
            window.location.replace(data.data.link);
          }
        },
      },
    );
  };

  appState.pageTitle('Subscription expired');

  return (
    <div className="flex h-screen flex-col items-center justify-center text-gray-800">
      <div className="absolute left-0 right-0 top-2 flex w-full justify-center">
        <SeoAiLogoBlack />
      </div>
      <div className="absolute right-1 top-1">
        <LogoutLink />
      </div>
      <div className="flex w-full max-w-screen-lg flex-col items-center">
        <h2 className="mb-3 text-center font-work text-[25px] font-bold leading-normal">
          Your account is currently inactive
        </h2>
        <p className="mb-2 text-center">
          But good news, it’s easy to get started again!
        </p>
        <p className="mb-2 text-center">
          Choose a plan that suits you best, hit Go, and get back to growing
          your organic traffic 🚀
        </p>

        <Errors />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="mt-8 w-full max-w-96 animate-fade-slow"
        >
          {plansQuery.isLoading ? (
            <SkeletonLoader height="sm" />
          ) : (
            <InputDecoration label="Plan" required>
              <Select
                options={plans.map((plan) => ({
                  title: plan.name + ` ($${plan.price} / Month)`,
                  value: plan.id,
                }))}
                placeholder="Select subscription plan"
                value={selectedPlan?.id}
                onChange={(value) =>
                  setSelectedPlan(plans.find((plan) => plan.id === value))
                }
              />
            </InputDecoration>
          )}

          {/* Submit */}
          <div className="mt-6">
            <Button
              color="secondary"
              fullWidth
              disabled={!selectedPlan}
              type="submit"
              isLoading={
                setupCheckoutMutation.isPending ||
                setupCheckoutMutation.isSuccess
              }
              text="Start subscription"
            />
          </div>
        </form>

        <div className="absolute bottom-6 left-0 right-0 flex justify-center gap-1  text-gray-800">
          By continuing, you agree to our{' '}
          <a
            className="underline"
            href="https://seo.ai/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms and conditions
          </a>
        </div>
      </div>
    </div>
  );
};
