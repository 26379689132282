import { Check } from 'lucide-react';
import { SectionHeader } from '../components/SectionHeader';
import { useOpenStripePage } from './hooks';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import ValidationErrors from '@/Components/ValidationErrors';
import { useAppStore } from '../AppLoader/stores';
import { useOrganisationsUpdate } from '@/api/openapiComponents';
import { useState } from 'react';

export const Organisation = () => {
  const appState = useAppStore();

  const [organisationName, setOrganisationName] = useState(
    appState.organisation?.name ?? '',
  );
  const [organisationEmail, setOrganisationEmail] = useState(
    appState.organisation?.billing_email ?? '',
  );

  const organisationUpdateMutation = useOrganisationsUpdate();

  const handleSaveOrganisationDetails = () => {
    organisationUpdateMutation.mutate({
      pathParams: {
        organisation: appState.auth!.user.org_id,
      },

      body: {
        name: organisationName,
        billing_email: organisationEmail,
      },
    });
  };

  const isOwner =
    appState.organisation?.users?.find((u) => u.id === appState.auth!.user.id)
      ?.role === 'owner';

  const { openStripePage: handleAddTaxId, isLoading: isLoadingAddTaxId } =
    useOpenStripePage(appState.auth!.user.org_id, 'tax');

  return (
    <div className="mt-12">
      {appState.organisation && (
        <>
          <SectionHeader title="Organisation settings" removeBorder />
          <div className={`flex items-center gap-2 `}>
            Tax verification status:
            <p
              className={`${
                appState.organisation.tax_status === 'verified'
                  ? 'text-green-400'
                  : ''
              }`}
            >
              {appState.organisation.tax_status}
            </p>
            {appState.organisation.tax_status === 'verified' && (
              <Check className="-ml-1 text-green-400" size={16} />
            )}
            {appState.organisation.tax_status !== 'verified' && isOwner && (
              <Button
                onClick={handleAddTaxId}
                isLoading={isLoadingAddTaxId}
                text="Add tax id"
                color="gray"
                size="sm"
                dense
                variant="outline"
              />
            )}
          </div>
          <div className="flex items-start justify-between gap-4">
            <div className="flex w-full flex-col">
              <h2 className="mb-2 ml-3 mt-4 text-sm">Organisation name</h2>
              <Input
                value={organisationName}
                onChange={setOrganisationName}
                disabled={!isOwner}
              />
            </div>
            <div className="flex w-full flex-col ">
              <h2 className="mb-2 mt-4 w-full pl-3 text-sm">Billing email</h2>
              <Input
                value={organisationEmail}
                onChange={setOrganisationEmail}
                disabled={!isOwner}
              />
              {isOwner && (
                <div className="mt-6 flex justify-end">
                  <Button
                    onClick={handleSaveOrganisationDetails}
                    isLoading={organisationUpdateMutation.isPending}
                    text="Save organisation"
                    color="secondary"
                    variant="outline"
                  />
                </div>
              )}
            </div>
          </div>
          <ValidationErrors
            errors={organisationUpdateMutation.error?.errors}
            apiError={organisationUpdateMutation.error}
          />
        </>
      )}
    </div>
  );
};
