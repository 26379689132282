import { ReactNode } from '@tanstack/react-router';
import PageContainer from './PageContainer';

type Props = {
  actions?: ReactNode;
  title?: string;
  description?: string;
  content?: ReactNode;
  noBackground?: boolean;
};

export const PageHeaderSection = ({
  description,
  content,
  title,
  actions,
  noBackground,
}: Props) => {
  return (
    <div
      className={`relative ${noBackground ? '' : 'border-b border-gray-200 bg-gray-50'}`}
    >
      {actions && <div className="absolute right-0 top-0 p-4">{actions}</div>}
      <PageContainer size="md">
        <div className="flex w-full flex-col items-center justify-center gap-4">
          {content}
          {title && <p className="text-2xl font-bold text-primary">{title}</p>}
          {description && (
            <p className="px-6 text-center text-primary">{description}</p>
          )}
        </div>
      </PageContainer>
    </div>
  );
};
