import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { cva } from 'class-variance-authority';
import { cn, getDocumentText } from '../lib/utils';
import type { VariantProps } from 'class-variance-authority';
import {
  BlockType,
  CurrentSlideOver,
  Generating,
  InternalLinkingResource,
  NodeTypesEnum,
  SettingsState,
} from '@/types';
import {
  useGenerateLiveDocument,
  usePostAutoGenerateMutation,
  useSetEditor,
} from '@/Components/Utils/v2/api';
import { getWordCount } from '@/Pages/Document/utils';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import diff from 'fast-diff';
import { useSaveChanges } from '@/Pages/Document/hooks';
import { DocumentResource, UserResource } from '@/api/openapiSchemas';
import { Loader } from '@/Components/Loader';
import { EditorRefContext } from '@/Pages/Document/context';
import {
  useDocumentStore,
  useInternalLinkingStore,
} from '@/Pages/Document/stores';
import { useShallow } from 'zustand/react/shallow';
import { tracking } from '@/Services/tracking/Tracking';

import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { Square } from 'lucide-react';
import { EditorTitle } from './editor-title';
import { DocumentMetadata } from '../../DocumentMetadata';
import { SectionHeader } from './section-header';
import { PlateContent, useEditorState } from '@udecode/plate/react';
import { useCurrentEditorState } from '../../EditorStateProvider';
import { AddImageDialog } from './add-image-dialog';
import { useSearch } from '@tanstack/react-router';
import { Settings } from '../../SettingDialogs/component/Settings';
import { DocumentUrlAnalytics } from '../../DocumentUrlAnalytics';

export const editorVariants = cva(
  cn(
    'relative overflow-x-auto whitespace-pre-wrap break-words',
    'min-h-[80px] w-full rounded-md bg-white px-3 py-2 overflow-x-hidden  ring-offset-white placeholder:text-slate-100 focus-visible:outline-none dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400',
    '[&_[data-slate-placeholder]]:text-primary-300 [&_[data-slate-placeholder]]:!opacity-100 dark:[&_[data-slate-placeholder]]:text-slate-400',
    '[&_[data-slate-placeholder]]:top-[auto_!important]',
    '[&_strong]:font-bold',
  ),
  {
    variants: {
      variant: {
        outline: 'border border-slate-200 dark:border-slate-800',
        ghost: '',
      },
      focused: {
        true: 'ring-2 ring-slate-950 ring-offset-2 dark:ring-slate-300',
      },
      disabled: {
        true: 'cursor-not-allowed opacity-50',
      },
      focusRing: {
        true: 'focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 dark:focus-visible:ring-slate-300',
        false: '',
      },
      size: {
        sm: '',
        md: '',
      },
    },
    defaultVariants: {
      variant: 'outline',
      focusRing: true,
      size: 'sm',
    },
  },
);

export type EditorProps = PlateContentProps &
  VariantProps<typeof editorVariants> & {
    document: DocumentResource;
    projectId: number;
    user: UserResource;
    setCurrentSlideOver: (value?: CurrentSlideOver) => void;
    currentSlideOver: CurrentSlideOver | undefined;
    settings: SettingsState;
    handleSettings: (key: keyof SettingsState, value: string) => void;
  };

const Editor = React.forwardRef<HTMLDivElement, EditorProps>(
  (
    {
      projectId,
      className,
      focused,
      focusRing,
      size,
      variant,
      document,
      setCurrentSlideOver,
      currentSlideOver,
      ...props
    },
    _,
  ) => {
    const { setCurrentEditor } = useCurrentEditorState();
    const { heading } = useDocumentStore(
      useShallow(({ heading, metaTitle, metaDescription }) => ({
        heading,
        metaTitle,
        metaDescription,
      })),
    );
    const [copied, setCopied] = useState(false);
    const search = useSearch({ from: '/documents/$documentId' });
    const editorFocusRef = useContext(EditorRefContext);
    const formerText = useRef<string | null>(null);
    const { data, handleChangeSentence, handleRemoveInternalLink } =
      useInternalLinkingStore(
        useShallow((state) => ({
          data: state.data,
          handleChangeSentence: state.handleChangeSentence,
          handleRemoveInternalLink: state.handleRemoveInternalLink,
        })),
      );
    const editor = useEditorState();
    const setEditor = useSetEditor();
    const client = useQueryClient();
    const showImageDialog = useDocumentStore((state) => state.showImageDialog);
    const setShowImageDialog = useDocumentStore(
      (state) => state.setShowImageDialog,
    );

    useEffect(() => {
      if (editor) {
        setCurrentEditor(editor);
      }
    }, []);

    const { mutate: startWriteMore, isLoading } = usePostAutoGenerateMutation(
      editor,
      projectId,
      document.id,
    );

    const { isSaving } = useSaveChanges(
      document.id,
      projectId,
      { editor },
      !!isLoading,
    );

    const initialDocumentGeneration = useGenerateLiveDocument(
      document.id,
      projectId,
      editor,
    );
    const onBackRef = useRef<() => void>();
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const isAtBottom = useRef(false);

    const { data: isGenerating } = useQuery<Generating>({
      queryKey: ['autoGenerationStream'],
    });

    const updateSentence = (diffedValue: diff.Diff[], type: 'del' | 'ins') => {
      const firstPart = diffedValue[0][1];
      const operationPart = diffedValue[1][1];
      const secondPart = diffedValue[2][1];

      const linkSuggestions = data.reduce(
        (prev, curr) => {
          if (operationPart.includes(curr.linkResource.sentence)) {
            return { ...prev, deletedItems: [...prev.deletedItems, curr] };
          }
          return { ...prev, remainingItems: [...prev.remainingItems, curr] };
        },
        { deletedItems: [], remainingItems: [] } as {
          deletedItems: InternalLinkingResource[];
          remainingItems: InternalLinkingResource[];
        },
      );
      const remainingItems = linkSuggestions.remainingItems;
      const firstHalf = firstPart.split(/\n|\./);
      const firstPartOfSentence = firstHalf[firstHalf.length - 1];
      const secondHalf = secondPart.split(/\n|\./);
      const secondPartOfSentence = secondHalf[0];

      if (type === 'del') {
        const deletedItems = linkSuggestions.deletedItems;
        deletedItems.forEach((lr) => handleRemoveInternalLink(lr.linkResource));
      }
      const linkingResource = remainingItems.find(
        (lr) =>
          lr.linkResource.sentence
            .trim()
            .includes(firstPartOfSentence.trimStart()) &&
          lr.linkResource.sentence
            .trim()
            .includes(secondPartOfSentence.trimEnd()),
      );
      if (
        linkingResource &&
        firstPartOfSentence !== '' &&
        secondPartOfSentence !== ''
      ) {
        if (type === 'ins') {
          handleChangeSentence(
            linkingResource,
            firstPartOfSentence,
            secondPartOfSentence,
            operationPart,
          );
        } else {
          handleChangeSentence(
            linkingResource,
            firstPartOfSentence,
            secondPartOfSentence,
          );
        }
      }
    };

    useEffect(() => {
      let timer: NodeJS.Timeout | undefined;
      if (copied) {
        timer = setTimeout(() => {
          setCopied(false);
        }, 1000);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [copied]);

    useEffect(() => {
      const value = getDocumentText(editor.children as BlockType[]);
      if (currentSlideOver === 'internal-linking' && formerText.current) {
        const diffedValue = diff(value, formerText.current);
        if (diffedValue.length === 3) {
          if (diffedValue[1][0] === 1) {
            updateSentence(diffedValue, 'del');
          }
          if (diffedValue[1][0] === -1) {
            updateSentence(diffedValue, 'ins');
          }
        }
      }

      formerText.current = value;
    }, [editor.selection]);

    useEffect(() => {
      client.setQueryData(['document-project-id'], projectId);
    }, []);

    // Handle live generating documents
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      if (params.get('generate')) {
        isAtBottom.current = true;
        const result = editor.api.string({
          focus: { offset: 0, path: [0, 0] },
          anchor: { offset: 0, path: [editor.children.length, 0] },
        });

        if (result !== '') {
          // If this is not a new article, dont generate anything
          return;
        }

        // Remove query param from url
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname.split('?')[0],
        );

        editor.tf.select({
          anchor: {
            offset: 0,
            path: [0, 0],
          },
          focus: {
            offset: 0,
            path: [0, 0],
          },
        });
        (window.document.activeElement as HTMLElement)?.blur();
        initialDocumentGeneration.generate({
          templateDocumentId: params.get('document_template')
            ? parseInt(params.get('document_template')!)
            : undefined,
        });

        return () => {
          if (onBackRef.current) {
            onBackRef.current();
          }
          client.setQueryData<Generating>(['autoGenerationStream'], false);
        };
      }

      return () =>
        client.setQueryData<Generating>(['autoGenerationStream'], false);
    }, []);

    useEffect(() => {
      const result = editor.api.string({
        focus: { offset: 0, path: [0, 0] },
        anchor: { offset: 0, path: [editor.children.length, 0] },
      });
      setEditor(editor);
      if (result !== '') {
        client.setQueryData<boolean>(['checkWordCount'], true);
      } else {
        client.setQueryData<boolean>(['checkWordCount'], false);
      }
    }, [editor.children]);

    const wordCount = getWordCount(heading, editor);
    useEffect(() => {
      const div = scrollRef.current;
      if (
        isLoading &&
        isAtBottom.current &&
        div &&
        div.scrollTop - div.clientHeight <
          div.scrollHeight - div.clientHeight - 150
      ) {
        div.scrollTo({
          top: div.scrollHeight - div.clientHeight - 150,
          behavior: 'smooth',
        });
      }
    }, [wordCount]);

    const handleWheel = (e: SyntheticEvent<HTMLDivElement>) => {
      const div = scrollRef.current;
      if (!div) {
        return;
      }

      //Scroll up
      if ((e.nativeEvent as unknown as { deltaY: number }).deltaY < 0) {
        isAtBottom.current = false;
      }
      //Scroll down
      else {
        if ((div.scrollTop + div.clientHeight ?? 0) > div.scrollHeight - 200) {
          isAtBottom.current = true;
        }
      }
    };

    const handleTitleFocus = () => {
      if (heading.length === 0) {
        tracking.event('title_suggestions_opened', {
          click_source: 'editor_input',
        });
        setCurrentSlideOver('title-score');
      }
    };

    return (
      <>
        <AddImageDialog
          isOpen={showImageDialog}
          onClose={() => setShowImageDialog(false)}
        />
        <div className="relative h-full w-full">
          {isSaving && (
            <Loader className="absolute right-6 top-2 z-[60] h-5 w-5 stroke-emerald-400" />
          )}

          <div
            ref={scrollRef}
            onWheel={handleWheel}
            role="textbox"
            className={`editor-scroll-bar relative flex h-full justify-center overflow-x-hidden overflow-y-scroll bg-white`}
          >
            <div
              className={`w-full ${search.tabIndex !== 2 ? 'max-w-4xl' : ''} bg-white px-12`}
            >
              {(!search.v2 || search.tabIndex === 1) && (
                <div className=" pl-5">
                  <DocumentMetadata
                    onCloseSlideOver={() => setCurrentSlideOver(undefined)}
                    onOpenMetaDescription={() =>
                      setCurrentSlideOver('meta-description')
                    }
                    onOpenMetaTitle={() => setCurrentSlideOver('meta-title')}
                    document={document}
                    currentSlideOver={currentSlideOver}
                  />
                </div>
              )}
              {(!search.v2 ||
                search.tabIndex === 0 ||
                search.tabIndex === undefined) && (
                <>
                  <div className="relative">
                    <EditorTitle
                      document={document}
                      handleTitleFocus={handleTitleFocus}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className="pl-5">
                    <SectionHeader>BODY</SectionHeader>
                  </div>
                  <div className="relative flex min-h-[30%] flex-col">
                    <div
                      className={`absolute z-20 h-[100%] w-[100%] bg-white opacity-20 ${
                        !isLoading ? 'hidden' : 'block'
                      }`}
                    />
                    <SortableContext
                      items={editor?.children.map((el: any) => el.id) || []}
                      strategy={verticalListSortingStrategy}
                    >
                      <PlateContent
                        placeholder="Write a paragraph, or press 'space' for AI or '/' for commands..."
                        disabled={isGenerating}
                        onFocus={() => {
                          setCurrentEditor(editor);
                        }}
                        renderEditable={(props) => (
                          <div ref={editorFocusRef}>{props}</div>
                        )}
                        className={cn(
                          editorVariants({
                            focused,
                            focusRing,
                            size,
                            variant,
                          }),
                          className,
                          'pb-56',
                        )}
                        disableDefaultStyles
                        tabIndex={isLoading ? -1 : undefined}
                        aria-disabled={!!isLoading}
                        {...props}
                      />
                    </SortableContext>
                  </div>
                </>
              )}
              {search.v2 && search.tabIndex === 2 && (
                <DocumentUrlAnalytics document={document} />
              )}
              {search.v2 && search.tabIndex === 3 && (
                <Settings
                  document={document}
                  settings={props.settings}
                  handleSettings={props.handleSettings}
                />
              )}
              {/* <CustomFields document={document} /> */}
            </div>
          </div>
          {isGenerating === 'write-more' && (
            <div className="sticky bottom-4 z-[51] translate-x-[calc(50%-29px)]">
              <IconButton
                id="write-more-button"
                icon={
                  <div className="p-2">
                    <Square fill="white" size={28} />
                  </div>
                }
                size="xl"
                variant="fill"
                color="secondary"
              />
            </div>
          )}

          {(!search.v2 || search.tabIndex === 0) && (
            <div className="sticky bottom-0 left-0 right-0 z-50 pl-4">
              <div className="relative">
                <div className="absolute bottom-8 left-12 z-50 bg-white text-primary-400">
                  {document.word_count ?? 0}{' '}
                  {!(document.document_report?.is_generating ?? true) ? (
                    <>of {document.document_report?.word_count.target} words</>
                  ) : (
                    'words'
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  },
);
Editor.displayName = 'Editor';

export { Editor };
