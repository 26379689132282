import {
  useDataSourceIndex,
  useDataSourceStore,
} from '@/api/openapiComponents';
import { MerchantFeedResource } from '@/api/openapiSchemas';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { Select } from '@/Components/v2/Select';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (feed: MerchantFeedResource) => void;
};

export const ChooseProductFeedDialog = ({
  isOpen,
  onClose,
  onSelect,
}: Props) => {
  const appState = useAppStore();
  const [chosenFeedId, setChosenFeedId] = useState<string>();

  const importFeedMutation = useDataSourceStore();
  const feedsQuery = useDataSourceIndex(
    {
      pathParams: {
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: isOpen,
    },
  );

  const handleSubmit = () => {
    importFeedMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
          dataSourceId: chosenFeedId!,
        },
      },
      {
        onSuccess: (data) => {
          onSelect(data.data);
          onClose();
        },
      },
    );
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={onClose}
      title="Choose product feed"
      disabled={!chosenFeedId}
      confirmText="Choose"
      onCancel={onClose}
      isLoading={importFeedMutation.isPending}
      content={
        feedsQuery.isLoading ? (
          <SkeletonLoader />
        ) : (
          <InputDecoration label="Feed" required>
            <Select
              placeholder="Select a feed"
              options={
                feedsQuery.data?.data.map((feed) => ({
                  title: feed.displayName,
                  value: feed.dataSourceId,
                })) ?? []
              }
              value={chosenFeedId}
              onChange={(value) => setChosenFeedId(value as string)}
            />
          </InputDecoration>
        )
      }
      onConfirm={() => {
        if (!chosenFeedId) return;
        handleSubmit();
      }}
    />
  );
};
