import { useProjectChatDestroy } from '@/api/openapiComponents';
import { ChatResource } from '@/api/openapiSchemas';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { Trash2 } from 'lucide-react';

type Props = {
  chat: ChatResource;
  onSelectChat: () => void;
  onDeleteChat: () => void;
};

export const ChatItem = ({ chat, onDeleteChat, onSelectChat }: Props) => {
  const appState = useAppStore();
  const deleteChatMutation = useProjectChatDestroy();

  const handleDeleteChat = () => {
    deleteChatMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
          chat: chat.id,
        },
      },
      {
        onSuccess: () => {
          onDeleteChat();
        },
      },
    );
  };

  return (
    <div className="group flex w-full overflow-hidden">
      <div
        className={`mb-1 flex-grow cursor-pointer truncate rounded px-2 py-1 font-bold ${chat.name ? 'text-primary' : 'italic text-primary-400'} hover:bg-primary-100`}
        onClick={() => {
          onSelectChat();
        }}
      >
        {chat.name || 'Unnamed chat'}
      </div>
      <div className="opacity-0 group-hover:opacity-100">
        <IconButton
          icon={Trash2}
          onClick={handleDeleteChat}
          isLoading={deleteChatMutation.isPending}
        />
      </div>
    </div>
  );
};
