import {
  useAcceptPendingUpdate,
  useDenyPendingUpdate,
  useListPendingUpdates,
} from '@/api/openapiComponents';
import { DocumentResource } from '@/api/openapiSchemas';
import { Dialog } from '@/Components/Dialog';
import { ErrorAlert } from '@/Components/v2/Alert';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { DiffRow } from '@/Pages/Diff/components/DiffRow';
import { EditableContainer } from '@/Pages/Diff/components/EditableContainer';
import { MarkdownDiff } from '@/Pages/Diff/components/MarkdownDiff';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { Check, StopCircle, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { WorkflowRunning } from '@/Pages/Document/DocumentLayout';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { SEO_AI_AUTH } from '@/api/openapiFetcher';

type Props = {
  document: DocumentResource;
  onAcceptSuccess: (markdown: string) => void;
  onClose: () => void;
  workflow: WorkflowRunning;
};

export const WorkflowOverlay = ({
  document,
  workflow,
  onClose,
  onAcceptSuccess,
}: Props) => {
  const appState = useAppStore();

  const [streamingFailed, setStreamingFailed] = useState(false);
  const [streamIsFinished, setStreamIsFinished] = useState(false);
  const [batchId, setBatchId] = useState<string>();

  const [newBody, setNewBody] = useState('');

  const declineMutation = useDenyPendingUpdate();
  const acceptMutation = useAcceptPendingUpdate();

  const streamRef = useRef<EventSourcePolyfill | null>(null);

  const pendingUpdatesQuery = useListPendingUpdates(
    {
      pathParams: {
        batchId: batchId!,
        project: appState.currentProject!.id,
      },
    },
    {
      enabled: !!batchId,
    },
  );

  const closeOverlay = () => {
    setNewBody('');
    setBatchId(undefined);
    setStreamIsFinished(false);
    setStreamingFailed(false);
    onClose();
  };

  const handleStartStream = () => {
    const stream = new EventSourcePolyfill(
      `/api/v2/projects/${appState.currentProject?.id}/documents/${document.id}/workflow-streamed?workflow[id]=${workflow.id}`,
      {
        withCredentials: true,
        heartbeatTimeout: 10 * 60 * 1000,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
        },
      },
    );

    stream.onerror = () => {
      stream.close();
      setStreamingFailed(true);
    };

    stream.addEventListener('batch_id', (event: { data: string }) => {
      const data = JSON.parse(event.data);
      setBatchId(data.content);
    });

    stream.addEventListener('update', (event: { data: string }) => {
      if (event.data === '[DONE]') {
        setStreamIsFinished(true);
        return stream.close();
      }
      const data = JSON.parse(event.data);
      setNewBody((p) => p + data.content);
    });

    streamRef.current = stream;

    const abort = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        stream.close();
        setStreamIsFinished(true);
        window.removeEventListener('keydown', abort);
      }
    };

    window.addEventListener('keydown', abort);
  };

  useEffect(() => {
    if (workflow.isRunning) {
      handleStartStream();
    }
  }, [workflow.isRunning]);

  const handleStopStream = () => {
    streamRef.current?.close();
    setStreamIsFinished(true);
  };

  const handleDecline = () => {
    acceptMutation.reset();
    declineMutation.reset();
    if (!batchId || pendingUpdatesQuery.data?.data.length === 0) {
      return closeOverlay();
    }

    closeOverlay();

    declineMutation.mutate({
      pathParams: {
        pendingUpdate: pendingUpdatesQuery.data?.data[0].id,
        project: appState.currentProject!.id,
      },
    });
  };

  const handleAccept = () => {
    acceptMutation.reset();
    declineMutation.reset();

    if (!batchId || pendingUpdatesQuery.data?.data.length === 0) {
      closeOverlay();
      onAcceptSuccess(newBody);
      return;
    }
    acceptMutation.mutate(
      {
        pathParams: {
          pendingUpdate: pendingUpdatesQuery.data?.data[0].id,
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          closeOverlay();
          onAcceptSuccess(newBody);
        },
      },
    );
  };

  const errorHelper = new ErrorHelper(
    declineMutation.error ?? acceptMutation.error,
  );

  return (
    <Dialog
      fullScreen
      title={`Workflow for ${document.title}`}
      isOpen={workflow.isRunning}
      prependActions={
        <div className="flex justify-end">
          {(errorHelper.isError() || streamingFailed) && (
            <ErrorAlert
              title={errorHelper.message() ?? 'Workflow streaming failed'}
            />
          )}
        </div>
      }
      actions={[
        ...(!streamIsFinished && !streamingFailed
          ? [
              {
                prependIcon: StopCircle,
                text: 'Stop stream',
                variant: 'outline',
                onClick: handleStopStream,
              },
            ]
          : []),
        {
          prependIcon: X,
          text: streamingFailed ? 'Close' : 'Decline changes',
          variant: 'outline',
          isLoading:
            declineMutation.isPending ||
            acceptMutation.isPending ||
            pendingUpdatesQuery.isLoading,
          disabled: !streamIsFinished && !streamingFailed,
          onClick: handleDecline,
        },
        ...(!streamingFailed
          ? [
              {
                prependIcon: Check,
                text: 'Accept changes',
                color: 'secondary' as const,
                disabled: !streamIsFinished,
                isLoading:
                  acceptMutation.isPending ||
                  declineMutation.isPending ||
                  pendingUpdatesQuery.isLoading,
                onClick: handleAccept,
              },
            ]
          : []),
      ]}
    >
      <DiffRow before="Before" after="After" />
      <DiffRow
        header="Body"
        before={
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className="prose text-base leading-tight"
          >
            {document.text}
          </ReactMarkdown>
        }
        isLoading={!newBody && !streamIsFinished}
        isFailed={streamingFailed}
        after={
          <EditableContainer
            disabled
            onStartEditing={() => {}}
            onFinishedEditing={() => {}}
            preview={
              <MarkdownDiff
                oldMarkdown={newBody}
                newMarkdown={newBody}
                onChange={() => {}}
              />
            }
            editor={<div />}
          />
        }
      />
    </Dialog>
  );
};
