import { createRoute, useParams, useSearch } from '@tanstack/react-router';
import { rootRoute } from '../Router';
import {
  guards,
  hasActiveSubscription,
  hasProject,
  isAuthenticated,
} from '../Router/guards';
import { FeedSeoNotConfigured } from './FeedSeoNotConfigured';
import { EditOptimizationGroup } from './EditOptimizationGroup';
import { ChooseAttributes } from './ChooseAttributes';
import { FeedSeoLoader } from './FeedSeoLoader';
import { FinalizeOptimizationGroup } from './FinalizeOptimizationGroup';
import { ViewOptimizationGroup } from './ViewOptimizationGroup';

export const routes = [
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/feed-seo',
    component: () => <FeedSeoLoader />,
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/feed-seo/$feedId/create-group',
    component: () => {
      const params = useParams({ from: '/feed-seo/$feedId/create-group' });
      return <EditOptimizationGroup feedId={params.feedId} />;
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/feed-seo/$feedId/edit-group/$optimizationGroupId',
    component: () => {
      const params = useParams({
        from: '/feed-seo/$feedId/edit-group/$optimizationGroupId',
      });
      return (
        <EditOptimizationGroup
          feedId={params.feedId}
          optimizationGroupId={Number(params.optimizationGroupId)}
        />
      );
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    validateSearch: (search: Record<string, string>) => {
      return {
        new: search.new === 'true' || search.new === true,
      };
    },
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/feed-seo/$feedId/choose-attributes/$optimizationGroupId',
    component: () => {
      const params = useParams({
        from: '/feed-seo/$feedId/choose-attributes/$optimizationGroupId',
      });

      const search = useSearch({
        from: '/feed-seo/$feedId/choose-attributes/$optimizationGroupId',
      });

      return (
        <ChooseAttributes
          feedId={params.feedId}
          isNew={search.new}
          optimizationGroupId={Number(params.optimizationGroupId)}
        />
      );
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    path: '/feed-seo/$feedId/finalize/$optimizationGroupId',
    component: () => {
      const params = useParams({
        from: '/feed-seo/$feedId/finalize/$optimizationGroupId',
      });
      return (
        <FinalizeOptimizationGroup
          feedId={params.feedId}
          optimizationGroupId={Number(params.optimizationGroupId)}
        />
      );
    },
  }),
  createRoute({
    getParentRoute: () => rootRoute,
    beforeLoad: ({ context }) => {
      guards(context, [isAuthenticated, hasProject, hasActiveSubscription]);
    },
    validateSearch: (search: Record<string, string>) =>
      ({
        page: Number(search.page ?? 1),
      }) as { page: number },
    path: '/feed-seo/$feedId/view/$optimizationGroupId',
    component: () => {
      const params = useParams({
        from: '/feed-seo/$feedId/view/$optimizationGroupId',
      });
      return (
        <ViewOptimizationGroup
          feedId={params.feedId}
          optimizationGroupId={Number(params.optimizationGroupId)}
        />
      );
    },
  }),
];
