type Listener = (event: string, payload: any) => void;

const listeners = new Map<string, Set<Listener>>();

export enum Event {
  ProjectChanged = 'project-changed',
}

const broadcast = (event: Event, payload: any) => {
  if (listeners.has(event)) {
    listeners.get(event)!.forEach((listener) => listener(event, payload));
  }
};

const subscribe = (event: Event, listener: Listener) => {
  if (!listeners.has(event)) {
    listeners.set(event, new Set());
  }
  listeners.get(event)!.add(listener);
};

const unsubscribe = (event: Event, listener: Listener) => {
  if (listeners.has(event)) {
    listeners.get(event)!.delete(listener);
  }
};

export const events = {
  broadcast,
  subscribe,
  unsubscribe,
};
