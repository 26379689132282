import AppLabel, { TagVariant } from '@/Components/Labels/AppLabel';
import { useDebounce } from '@/Hooks/useDebounce';
import { Color, OrganisationTagResource } from '@/api/openapiSchemas';
import React, { useEffect, useRef, useState } from 'react';
import { EditableTag } from './EditableTag';
import { useQueryClient } from '@tanstack/react-query';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import * as RadixPopover from '@radix-ui/react-popover';
import {
  useOrganisationsTagsDestroy,
  useOrganisationsTagsIndex,
  useOrganisationsTagsStore,
  useOrganisationsTagsUpdate,
} from '@/api/openapiComponents';

type Props = {
  zIndex?: number;
  isSettings?: boolean;
  organisationId: number;
  tags: OrganisationTagResource[];
  onTagUpdated: (tags: OrganisationTagResource[]) => void;
};

const isRealTag = (tag: any): tag is OrganisationTagResource => {
  return !!tag.id;
};

export const TagEditor = ({
  tags,
  zIndex = 9998,
  organisationId,
  onTagUpdated,
  isSettings,
}: Props) => {
  const [editingTagId, setEditingTagId] = useState<number>();
  const [isEditing, setIsEditing] = useState(false);
  const [editingTags, setEditingTags] =
    useState<OrganisationTagResource[]>(tags);
  const [selectionIndex, setSelectionIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [tagIdToBeDeleted, setTagIdToBeDeleted] = useState<number>();

  const inputRef = useRef<HTMLInputElement>(null);
  const rootRef = useRef<HTMLInputElement>(null);

  const client = useQueryClient();

  const tagsQuery = useOrganisationsTagsIndex({
    pathParams: {
      organisation: organisationId,
    },
    queryParams: {
      filters: debouncedSearch !== '' ? { search: debouncedSearch } : undefined,
      limit: 100,
    },
  });

  useDebounce(
    () => {
      setDebouncedSearch(search);
      tagsQuery.refetch();
    },
    [search],
    200,
  );

  const deleteTagMutation = useOrganisationsTagsDestroy();
  const updateTagMutation = useOrganisationsTagsUpdate();
  const createTagMutation = useOrganisationsTagsStore();

  const isLoading = createTagMutation.isPending;

  useEffect(
    () => () => {
      if (isEditing && isSettings) {
        onTagUpdated(editingTags);
      }
    },
    [editingTags],
  );

  // Tags are cached so that frontend filtering can be done while the new tags are being fetched from the backend to improve the feel
  const [cachedTags, setCachedTags] = useState<OrganisationTagResource[]>([]);
  useEffect(() => {
    if (tagsQuery.data?.data) setCachedTags(tagsQuery.data?.data);
  }, [tagsQuery.data?.data]);

  let filteredTags = [
    ...cachedTags.filter(
      (tag) =>
        !editingTags.some((eTag) => eTag.id === tag.id) &&
        tag.name.toLowerCase().includes(search.toLowerCase()),
    ),
  ];

  // If a tag has an exact match, place it at the top
  filteredTags.sort((a, b) => (a.name === search ? 0 : 1));

  filteredTags = [
    ...filteredTags,
    ...(search !== '' && !filteredTags.some((tag) => tag.name === search)
      ? [{ name: `${search} (Create new)` }]
      : []),
  ] as OrganisationTagResource[];

  useEffect(() => {
    setSelectionIndex(0);
  }, [debouncedSearch]);

  // **** Event Handlers ****

  const updateEditingTags = (tags: OrganisationTagResource[]) => {
    setEditingTags(tags);
    onTagUpdated(tags);
  };

  const handleClick = () => {
    if (!isEditing) {
      setIsEditing(true);
      updateEditingTags([...tags]);
    }
    if (inputRef.current && isEditing) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && search === '') {
      updateEditingTags(
        editingTags.filter((_, index) => index !== editingTags.length - 1),
      );
    }
    if (e.key === 'ArrowUp') {
      setSelectionIndex((p) => (p === 0 ? filteredTags.length - 1 : p - 1));
    }
    if (e.key === 'ArrowDown') {
      setSelectionIndex((p) => (p === filteredTags.length - 1 ? 0 : p + 1));
    }

    if (e.key === 'Enter') {
      handleCreateNewTag(filteredTags[selectionIndex]);
    }
  };

  const handleCreateNewTag = (tag: OrganisationTagResource) => {
    if (search.length === 0 && filteredTags.length === 0) {
      return;
    }
    if (search.length !== 0 && tag.id === undefined) {
      createTagMutation.mutate(
        {
          pathParams: {
            organisation: organisationId,
          },
          body: {
            name: search,
          },
        },
        {
          onSuccess: (data) => {
            setSearch('');
            const queryKey =
              client.getQueryCache().find({
                predicate: (query) =>
                  query.queryKey.includes('organisationsTagsIndex'),
              })?.queryKey ?? [];
            client.setQueriesData(
              {
                queryKey,
              },
              (oldData?: { data: OrganisationTagResource[] }) => {
                if (!oldData) return;
                return {
                  ...oldData,
                  data: [
                    ...oldData.data.map((t) => (t.id === tag.id ? tag : t)),
                  ],
                };
              },
            );
            updateEditingTags([...editingTags, data.data]);
          },
        },
      );
    } else {
      setSearch('');
      updateEditingTags([...editingTags, tag]);
      if (search.length === 0 && selectionIndex === filteredTags.length - 1) {
        setSelectionIndex(Math.max(0, selectionIndex - 1));
      }
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    createTagMutation.reset();
    setSelectionIndex(0);
  };

  const handleDelete = (tagId: number) => {
    deleteTagMutation.mutate(
      {
        pathParams: {
          organisation: organisationId,
          tag: tagId,
        },
      },
      {
        onSuccess: () => {
          setEditingTagId(undefined);
          setTagIdToBeDeleted(undefined);
          tagsQuery.refetch();
          setCachedTags(cachedTags.filter((tag) => tag.id !== tagId));
          updateEditingTags(editingTags.filter((tag) => tag.id !== tagId));
          onTagUpdated(editingTags);
        },
      },
    );
  };

  const handleRemove = (tagId: number) => {
    setEditingTagId(undefined);
    updateEditingTags(editingTags.filter((tag) => tag.id !== tagId));
  };

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (
        editingTagId === undefined &&
        !rootRef.current?.contains(e.target as HTMLDivElement) &&
        isEditing &&
        tagIdToBeDeleted === undefined
      ) {
        onTagUpdated(editingTags);
        setEditingTagId(undefined);
        setIsEditing(false);
      }
    };
    window.addEventListener('click', listener);
    return () => {
      window.removeEventListener('click', listener);
    };
  }, [rootRef, isEditing, editingTags, editingTagId]);

  const newTagsAreEditedTags =
    JSON.stringify(tags) === JSON.stringify(editingTags) || true;

  const handleFinishEditingTag = (
    values: {
      color: string;
      name: string;
      id: number;
    },
    close?: boolean,
  ) => {
    // update editing tags with the new tag properties
    const newTags = [...editingTags];
    const tagIndex = newTags.findIndex((tag) => tag.id === values.id);
    newTags[tagIndex] = values;
    setEditingTags(newTags);

    // Update tag settings on backend
    if (close) {
      setEditingTagId(undefined);
    }
    updateTagMutation.mutate(
      {
        pathParams: {
          organisation: organisationId,
          tag: values.id,
        },
        body: {
          color: values.color as Color,
          name: values.name,
        },
      },
      {
        onSuccess: () => {
          const queryKey =
            client.getQueryCache().find({
              predicate: (query) =>
                query.queryKey.includes('organisationsTagsIndex'),
            })?.queryKey ?? [];
          client.setQueriesData(
            {
              queryKey,
            },
            (oldData?: { data: OrganisationTagResource[] }) => {
              if (!oldData) return;
              return {
                ...oldData,
                data: [
                  ...oldData.data.map((t) =>
                    t.id === newTags[tagIndex].id ? newTags[tagIndex] : t,
                  ),
                ],
              };
            },
          );
          onTagUpdated(editingTags);
        },
      },
    );
  };

  return (
    <RadixPopover.Root
      open={isEditing && !!filteredTags.length && !editingTagId}
    >
      <RadixPopover.Trigger asChild className="w-full">
        <div
          ref={rootRef}
          className={`flex h-full min-h-[2.5rem] w-full cursor-text items-center rounded-md px-1.5 py-1 ${
            isEditing
              ? 'border border-green-400'
              : 'border border-gray-200 hover:bg-gray-100'
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          <ConfirmDialog
            title="Delete tag"
            description={[
              'This deletes the tag permanently and removes it from all content',
            ]}
            isOpen={!!tagIdToBeDeleted}
            confirmColor="red"
            confirmText="delete"
            isLoading={deleteTagMutation.isPending}
            onConfirm={() => handleDelete(tagIdToBeDeleted!)}
            onClose={() => setTagIdToBeDeleted(undefined)}
          />
          <div
            className={`flex w-full min-w-[15vw] flex-wrap items-center gap-1`}
          >
            {(isEditing || isLoading || !newTagsAreEditedTags
              ? editingTags
              : tags
            ).map((tag) => (
              <EditableTag
                key={tag.id}
                onRemove={handleRemove}
                tag={tag}
                onEditTag={setEditingTagId}
                onDelete={() => setTagIdToBeDeleted(tag.id)}
                isEditing={isEditing}
                isLoading={isLoading || deleteTagMutation.isPending}
                isEditingTag={editingTagId === tag.id}
                onFinishEditingTag={handleFinishEditingTag}
                isError={updateTagMutation.isError}
              />
            ))}
            {isEditing && (
              <input
                value={search}
                onChange={handleSearchChange}
                autoFocus
                size={search.length > 0 ? search.length : 1}
                ref={inputRef}
                onKeyDown={handleKeyDown}
                className={`block h-7 max-w-[15vw] flex-grow border-0 border-transparent p-0 focus:ring-0 ${
                  createTagMutation.error ? 'bg-red-200' : 'bg-transparent'
                }`}
              />
            )}
          </div>
        </div>
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          tabIndex={-1}
          autoFocus={false}
          onOpenAutoFocus={(e) => e.preventDefault()}
          className="flex flex-col overflow-y-auto bg-white shadow-md outline outline-1 outline-gray-200"
          style={{
            width: rootRef.current?.clientWidth,
            zIndex: zIndex,
            maxHeight:
              'min(var(--radix-popover-content-available-height),20rem)',
          }}
          align="center"
        >
          {filteredTags.map((tag, index) => (
            <div
              key={tag.id}
              tabIndex={-1}
              className={`cursor-pointer px-2 py-1  ${
                index === selectionIndex ? 'bg-gray-50' : 'hover:bg-gray-50'
              } ${
                index !== filteredTags.length - 1
                  ? 'border-b border-gray-50'
                  : ''
              } ${isLoading ? 'opacity-50' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (filteredTags[index].id === undefined) {
                  handleCreateNewTag(filteredTags[index]);
                } else {
                  setSearch('');
                  updateEditingTags([...editingTags, filteredTags[index]]);
                }
              }}
            >
              {!isRealTag(tag) ? (
                (tag as any).name
              ) : (
                <AppLabel variant={TagVariant[tag.color]}>
                  <div className="w-full gap-1 truncate">{tag.name}</div>
                </AppLabel>
              )}
            </div>
          ))}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};
