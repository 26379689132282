import {
  BoldPlugin,
  ItalicPlugin,
  SubscriptPlugin,
  SuperscriptPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import {
  BlockquoteElement,
  HeadingElement,
  HrElement,
  ImageElement,
  LinkElement,
  ListElement,
  ListItemElement,
  ParagraphElement,
  Placeholder,
  TableCellElement,
  TableCellHeaderElement,
  TableElement,
  TableRowElement,
  withPlaceholder,
} from '../components';
import { ParagraphPlugin, PlateLeaf } from '@udecode/plate/react';
import { withProps } from '@udecode/cn';
import { ImagePlugin, PlaceholderPlugin } from '@udecode/plate-media/react';
import { LinkPlugin } from '@udecode/plate-link/react';
import {
  TableCellHeaderPlugin,
  TableCellPlugin,
  TablePlugin,
  TableRowPlugin,
} from '@udecode/plate-table/react';
import { AIPlugin } from '@udecode/plate-ai/react';
import { AILeaf } from '../components/ai-leaf';
import {
  BulletedListPlugin,
  ListItemPlugin,
  NumberedListPlugin,
} from '@udecode/plate-list/react';
import { HorizontalRulePlugin } from '@udecode/plate-horizontal-rule/react';
import { SlashInputPlugin } from '@udecode/plate-slash-command/react';
import { SlashInputElement } from '../components/slash-input-element';
import { EmojiInputPlugin } from '@udecode/plate-emoji/react';
import { EmojiInputElement } from '../components/emoji-input-element';

export const components = {
  // Marks components
  [AIPlugin.key]: AILeaf,
  [BlockquotePlugin.key]: BlockquoteElement,
  [BoldPlugin.key]: withProps(PlateLeaf, { as: 'strong' }),
  [ItalicPlugin.key]: withProps(PlateLeaf, { as: 'em' }),
  [ParagraphPlugin.key]: withPlaceholder(ParagraphElement, {
    placeholder:
      "Write a paragraph, or press 'space' for AI or '/' for commands...",
  }),
  [SubscriptPlugin.key]: withProps(PlateLeaf, { as: 'sub' }),
  [SuperscriptPlugin.key]: withProps(PlateLeaf, { as: 'sup' }),
  [UnderlinePlugin.key]: withProps(PlateLeaf, { as: 'u' }),
  [HorizontalRulePlugin.key]: HrElement,
  [PlaceholderPlugin.key]: Placeholder,
  [EmojiInputPlugin.key]: EmojiInputElement,

  // Link components
  [LinkPlugin.key]: LinkElement,

  // List components
  [ListItemPlugin.key]: withProps(ListItemElement, { as: 'li' }),
  [BulletedListPlugin.key]: withProps(ListElement, { variant: 'ul' }),
  [NumberedListPlugin.key]: withProps(ListElement, { variant: 'ol' }),

  // Heading components
  [HEADING_KEYS.h1]: withPlaceholder(
    withProps(HeadingElement, { variant: 'h1' }),
    { placeholder: 'Write a heading' },
  ),
  [HEADING_KEYS.h2]: withPlaceholder(
    withProps(HeadingElement, { variant: 'h2' }),
    { placeholder: 'Write a heading' },
  ),
  [HEADING_KEYS.h3]: withPlaceholder(
    withProps(HeadingElement, { variant: 'h3' }),
    { placeholder: 'Write a heading' },
  ),
  [HEADING_KEYS.h4]: withPlaceholder(
    withProps(HeadingElement, { variant: 'h4' }),
    { placeholder: 'Write a heading' },
  ),
  [HEADING_KEYS.h5]: withPlaceholder(
    withProps(HeadingElement, { variant: 'h5' }),
    { placeholder: 'Write a heading' },
  ),
  [HEADING_KEYS.h6]: withPlaceholder(
    withProps(HeadingElement, { variant: 'h6' }),
    { placeholder: 'Write a heading' },
  ),

  //Image
  [ImagePlugin.key]: ImageElement,

  // Table components
  [TableCellHeaderPlugin.key]: TableCellHeaderElement,
  [TableCellPlugin.key]: TableCellElement,
  [TablePlugin.key]: TableElement,
  [TableRowPlugin.key]: TableRowElement,

  // Extra components
  [SlashInputPlugin.key]: SlashInputElement,
};
