import PageContainer from '@/Components/PageContainer.tsx';
import { Card } from '@/Components/v2/Card';
import { CardBody } from '@/Components/v2/Card/components/CardBody.tsx';
import { ReactNode } from 'react';

type Props = {
  title?: string;
  subtitle?: string;
  backgroundImage?: string;
  bgColor?: string;
  children: ReactNode;
};

export const KeywordResearchHeader = ({
  title,
  subtitle,
  backgroundImage,
  bgColor,
  children
}: Props) => {
  return (
    <div className={`${backgroundImage ? 'relative' : ''} overflow-hidden ${bgColor ? bgColor : ''} border-b border-gray-200`}>
      {backgroundImage && (<img aria-hidden={true} alt="background image" className="absolute h-full right-0 top-0 bottom-0" src={backgroundImage} />)}
      <PageContainer size="lg">
        <div className="flex flex-col gap-4 z-10">
          {title && (<h1 className="text-xl font-bold text-primary">{title}</h1>)}
          {subtitle && (<h2 className="text-primary text-lg">
            {subtitle}
          </h2>)}
          <Card variant="fill">
            <CardBody>
              {children}
            </CardBody>
          </Card>
        </div>
      </PageContainer>
    </div>
  );
}
