import { DocumentResource } from '@/api/openapiSchemas';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppStore } from '../AppLoader/stores';
import { useShallow } from 'zustand/react/shallow';
import PlateEditorErrorBoundaryHOC from './components/PlateEditorErrorBoundary';
import { ActiveDraggableState, isEditorUniqueID, NodeTypesEnum } from '@/types';
import { PlateEditor } from './components/PlateEditor/PlateEditor';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import {
  ArrowLeft,
  FolderOpen,
  GripVertical,
  Plus,
  Settings,
  Upload,
  X,
} from 'lucide-react';
import { useNavigate, useRouter, useSearch } from '@tanstack/react-router';
import { Movable, MoveEvent } from '@/Components/Movable';
import { DocumentInfoContext } from './components/PlateEditor/contexts/DocumentInfoContext';
import {
  ActiveDraggableContext,
  EditorContext,
  EditorRefContext,
} from './context';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  pointerWithin,
} from '@dnd-kit/core';
import deserialize, {
  deserializeSnippet,
} from './components/PlateEditor/lib/deserialize';
import { tracking } from '@/Services/tracking/Tracking';
import { SettingsDialog } from './components/SettingDialogs/SettingsDialog';
import { useGetSettingsState, useSLideoverState } from './hooks';
import { DocumentSlideOvers } from './components/DocumentSlideOvers';
import { ReactEditor } from 'slate-react';
import { OutlineItem } from '@/Components/DocumentForm/hooks';
import { useQuery } from '@tanstack/react-query';
import { DocumentExports } from './components/DocumentExports';
import { WorkflowOverlay } from './components/Workflows/components/WorkflowOverlay';
import { usePlateEditor } from '@udecode/plate/react';
import { plugins } from './components/PlateEditor/lib/plugins';
import { getInitialPlateJSValue } from './utils';
import { components } from './components/PlateEditor/lib/components';
import { Banner } from '@/Components/Banner/Banner';
import { AnimatedEllipsis } from '@/Components/AnimatedEllipsis';
import { useCurrentEditorState } from './components/EditorStateProvider';
import { deepClone } from './components/PlateEditor/lib/streaming-plugin';
import { EditorSideBar } from './components/EditorSideBar';
import { Button } from '@/Components/v2/Button';
import { SlideOver } from '@/Components/SlideOver';
import { Sidebar } from '@/Components/v2/Sidebar/Sidebar';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { DraggableSide } from './components/DraggableSide';

type Props = {
  refetchDocument: () => Promise<{ data: { data: DocumentResource } }>;
  document: DocumentResource;
};

export type WorkflowRunning = {
  isRunning: boolean;
  id: number;
};

const getInitialChatWidth = () => {
  if (window.outerWidth > 2400) {
    return 1000;
  }
  if (window.outerWidth > 1800) {
    return 760;
  }

  return 540;
};

export const DocumentLayout = ({ document, refetchDocument }: Props) => {
  const router = useRouter();
  const search = useSearch({ from: '/documents/$documentId' });
  const navigate = useNavigate({ from: '/documents/$documentId' });

  const [showSidebar, setShowSidebar] = useState(false);

  const value = useMemo(
    () => getInitialPlateJSValue(document.text),
    [document.text],
  );

  const editor = usePlateEditor({
    plugins: plugins,
    value: value,
    override: {
      components: components,
    },
  });
  const { setSelection, selection } = useCurrentEditorState();
  const [showEditor, setShowEditor] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [RunningWorkflow, setRunningWorkflow] = useState<WorkflowRunning>({
    isRunning: false,
    id: -1,
  });

  const onWorkflowSelect = (workflowId: number) => {
    setRunningWorkflow({
      isRunning: true,
      id: workflowId,
    });
  };

  const { currentSlideOver, setCurrentSlideOver } = useSLideoverState(
    !!document.keyword,
  );

  const chatPanelRef = useRef<HTMLDivElement>(null);
  const editorFocusRef = useRef<HTMLDivElement>(null);

  const [draggableState, setDraggableState] =
    useState<ActiveDraggableState | null>(null);

  const { settings, handleDialog, handleSettings } =
    useGetSettingsState(document);

  const { data: isGenerating } = useQuery<boolean>({
    queryKey: ['autoGenerationStream'],
  });

  const { currentProject, auth, pageTitle } = useAppStore(
    useShallow((state) => ({
      currentProject: state.currentProject,
      auth: state.auth,
      pageTitle: state.pageTitle,
    })),
  );

  useEffect(() => {
    if (chatPanelRef.current) {
      const width = localStorage.getItem('SEO_AI_chatWidth');
      if (width) {
        setChatWidth(parseInt(width));
      } else {
        setChatWidth(getInitialChatWidth(), true);
      }
    }
  }, [chatPanelRef.current]);

  useEffect(() => {
    const func = () => {
      setChatWidth(chatPanelRef.current?.getBoundingClientRect().width ?? 0);
    };
    window.addEventListener('resize', func);

    return () => window.removeEventListener('resize', func);
  }, []);

  const setChatWidth = (width: number, initial?: boolean) => {
    if (chatPanelRef.current === null) return;
    const limitedWidth = Math.min(
      Math.max(width, 400),
      window.innerWidth - 800,
    );
    chatPanelRef.current.style.width = `${limitedWidth}px`;

    if (!initial) {
      localStorage.setItem('SEO_AI_chatWidth', limitedWidth.toString());
    }
  };

  const handleDragChatBorder = (e: MoveEvent) => {
    if (chatPanelRef.current === null) return;

    const offset = search.v2 ? -e.offset.x : e.offset.x;

    setChatWidth(chatPanelRef.current.getBoundingClientRect().width - offset);
  };

  const handleDragStart = (e: DragStartEvent) => {
    if (e.active.data.current?.text) {
      setDraggableState((prev) =>
        prev === null
          ? {
              activeElement: {
                id: e.active.id,
                text: e.active.data.current?.text,
              },
              hoveredElementID: undefined,
            }
          : { ...prev },
      );
    }
  };

  const handleDrag = (e: DragEndEvent) => {
    const activeId = e.active.id;
    const overId = e.over?.id;
    if (editor) {
      const overIndex = editor.children.findIndex((x) => x.id === overId);
      const activeIndex = editor.children.findIndex((x) => x.id === activeId);
      if (overId && isEditorUniqueID(activeId) && isEditorUniqueID(overId)) {
        if (activeIndex >= 0 && overIndex >= 0) {
          editor.tf.moveNodes({
            at: [activeIndex],
            to: [overIndex],
          });
        }
      }
      if (
        overId &&
        !isEditorUniqueID(activeId) &&
        isEditorUniqueID(overId) &&
        draggableState
      ) {
        editor.tf.insertNodes(
          deserializeSnippet(draggableState.activeElement.text),
          {
            at: [overIndex + 1],
          },
        );
      }
    }
    tracking.event('document_chat_dragged_content');
    setDraggableState(null);
  };

  const handleInsertHeading = (value: string, type?: string, focus = true) => {
    if (!editor) return;

    tracking.event('subheading_suggestions_inserted', {
      type: type,
      value: value,
    });

    const wasEmptyDocument = editor.children.length === 1;

    const fragment = editor.children[editor.selection?.anchor.path[0] ?? 0];
    if (fragment.children[0]?.text === '') {
      if (editor.children.length - 1 === editor.selection?.anchor.path[0]) {
        editor.tf.deleteBackward('block');
      } else {
        editor.tf.deleteForward('block');
      }
    }

    editor?.tf.insertNode({
      type: (() => {
        if (type === 'H1') return NodeTypesEnum.H2;
        if (type === 'H6') return NodeTypesEnum.H5;
        return type
          ? (NodeTypesEnum[type as keyof typeof NodeTypesEnum] ??
              NodeTypesEnum.H2)
          : NodeTypesEnum.H2;
      })(),
      children: [{ text: value }],
    });

    if (wasEmptyDocument) {
      editor.tf.removeNodes({ at: { path: [0], offset: 0 } });
    }

    if (focus) {
      // To supress the warning in Sentry we have to catch and ignore the error
      try {
        ReactEditor.focus(editor as ReactEditor);
      } catch (e) {}
    }
  };

  const handleInsertOutline = (outline: OutlineItem[]) => {
    for (const h2 of outline) {
      handleInsertHeading(h2.title, 'H2', false);
      for (const h3 of h2.items ?? []) {
        handleInsertHeading(h3.title, 'H3', false);
      }
    }

    ReactEditor.focus(editor as ReactEditor);
  };

  pageTitle(document.title || 'Untitled');

  return (
    <>
      <SlideOver
        side="left"
        isOpen={showSidebar}
        dense
        size="xs"
        onClickOutside={() => setShowSidebar(false)}
      >
        <div className="flex h-full w-full">
          <Sidebar />
        </div>
      </SlideOver>
      <WorkflowOverlay
        document={document}
        workflow={RunningWorkflow}
        onClose={() => setRunningWorkflow({ isRunning: false, id: -1 })}
        onAcceptSuccess={(markdown) => {
          setRunningWorkflow({ isRunning: false, id: -1 });
          editor.tf?.select({
            anchor: {
              offset: 0,
              path: [0, 0],
            },
            focus: {
              offset: 0,
              path: [editor.children.length - 1, 0],
            },
          });
          editor?.tf.delete();
          editor!.tf.insertNodes(deepClone(deserialize(markdown)));
        }}
      />
      <SettingsDialog
        document={document}
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
        settings={settings}
        handleSettings={handleSettings}
      />

      <DocumentInfoContext.Provider
        value={{
          documentId: document.id,
          projectId: currentProject!.id,
        }}
      >
        <EditorRefContext.Provider value={editorFocusRef}>
          {document.keyword && (
            <DocumentSlideOvers
              onInsertHeading={handleInsertHeading}
              onInsertOutline={handleInsertOutline}
              editor={editor}
              document={document}
              project={currentProject!}
              onClose={() => setCurrentSlideOver(undefined)}
              currentlyOpenSlideover={currentSlideOver}
            />
          )}
          <DndContext
            onDragOver={(e) => {
              if (draggableState) {
                setDraggableState((prev) => {
                  if (prev) {
                    return { ...prev, hoveredElementID: e.over?.id };
                  }
                  return prev;
                });
              }
            }}
            onDragStart={handleDragStart}
            onDragEnd={handleDrag}
            autoScroll={{ layoutShiftCompensation: false }}
            collisionDetection={pointerWithin}
          >
            <ActiveDraggableContext.Provider value={draggableState}>
              <EditorContext.Provider value={editor}>
                <div className="flex h-screen w-screen flex-col">
                  <Banner
                    isOpen={!!isGenerating}
                    className={`top-0 bg-emerald-400 text-white`}
                  >
                    Generating
                    <AnimatedEllipsis />
                  </Banner>
                  <div
                    className={`relative flex w-screen flex-grow overflow-hidden bg-primary-50 ${search.v2 ? ' flex-row-reverse' : ''}`}
                  >
                    {showEditor && (
                      <div className="relative flex min-w-0 flex-grow flex-col items-center bg-white shadow-xl">
                        <DraggableSide onMove={handleDragChatBorder} />
                        {search.v2 ? (
                          <div className="flex h-10 w-full flex-shrink-0 border-b border-primary-200 pl-4">
                            <div className="relative mr-3 flex items-center text-xl font-bold">
                              <IconButton
                                variant="ghost"
                                icon={X}
                                onClick={() => setShowEditor(false)}
                              />
                            </div>

                            <Tabs
                              value={search.tabIndex ?? 0}
                              onChange={(index) =>
                                navigate({
                                  search: { tabIndex: index, v2: true },
                                })
                              }
                              tabs={[
                                {
                                  text: 'CONTENT',
                                },
                                {
                                  text: 'META FIELDS',
                                },
                                {
                                  text: 'ANALYTICS',
                                },
                              ]}
                              noBorder
                            />
                            <div className="flex items-center gap-2 pr-4">
                              <IconButton
                                icon={Settings}
                                onClick={() =>
                                  navigate({
                                    search: { tabIndex: 3, v2: true },
                                  })
                                }
                              />
                              <DocumentExports
                                document={document}
                                refetchDocument={refetchDocument}
                              />
                              <IconButton icon={Upload} />
                            </div>
                          </div>
                        ) : (
                          <div className="flex w-full items-center justify-between pb-2 pl-2 pr-6 pt-3">
                            <div className="flex items-center gap-2 text-xl font-bold">
                              <Button
                                prependIcon={ArrowLeft}
                                text="Exit"
                                dense
                                variant="ghost"
                                onClick={() =>
                                  router.navigate({ to: '/created-content' })
                                }
                              />
                            </div>
                            <div className="flex items-center gap-4 ">
                              <DocumentExports
                                document={document}
                                refetchDocument={refetchDocument}
                              />
                              <IconButton
                                icon={Settings}
                                onClick={() => setShowSettings(true)}
                              />
                            </div>
                          </div>
                        )}
                        <div className="w-full flex-grow overflow-hidden">
                          <PlateEditorErrorBoundaryHOC
                            document={document.id}
                            projectId={currentProject!.id}
                            fallback={<div>Loading</div>}
                            text={document.text}
                            refetchDocument={refetchDocument}
                          >
                            <PlateEditor
                              user={auth!.user}
                              handleSettings={handleSettings}
                              settings={settings}
                              setCurrentSlideOver={setCurrentSlideOver}
                              currentSlideOver={currentSlideOver}
                              projectId={currentProject!.id}
                              selection={selection}
                              setSelection={setSelection}
                              document={document}
                              editor={editor}
                            />
                          </PlateEditorErrorBoundaryHOC>
                        </div>
                      </div>
                    )}
                    <div
                      ref={chatPanelRef}
                      style={{ width: showEditor ? undefined : '100%' }}
                      className={`relative flex flex-shrink-0 flex-col`}
                    >
                      <EditorSideBar
                        hideTabs={search.v2 && !showEditor}
                        onOpenEditor={
                          showEditor ? undefined : () => setShowEditor(true)
                        }
                        onOpenSidebar={() => setShowSidebar(true)}
                        refetchDocument={refetchDocument}
                        setCurrentSlideOver={setCurrentSlideOver}
                        onWorkflowSelect={onWorkflowSelect}
                        document={document}
                      />
                    </div>
                  </div>
                </div>
              </EditorContext.Provider>
            </ActiveDraggableContext.Provider>
          </DndContext>
        </EditorRefContext.Provider>
      </DocumentInfoContext.Provider>
    </>
  );
};
