import { Menus } from '@/Components/Menus';
import PageContainer from '@/Components/PageContainer';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { Outlet, useNavigate, useRouterState } from '@tanstack/react-router';
import { Box, Building2, CircleUser, Users } from 'lucide-react';
import LogoutLink from '../Auth/Finalize/LogoutLink';

export const AccountWrapper = () => {
  const routerState = useRouterState();
  const navigate = useNavigate();

  const tabs = [
    {
      text: 'Users',
      icon: Users,
      path: '/users',
    },
    {
      text: 'Subscription',
      icon: Box,
      path: '/subscription',
    },
    {
      text: 'Organisation',
      icon: Building2,
      path: '/organisation',
    },
    {
      text: 'Your profile',
      icon: CircleUser,
      path: '/profile',
    },
  ];

  return (
    <Menus>
      <PageContainer title="Account" size="lg" actions={<LogoutLink />}>
        <div className="my-3 pt-10">
          <Tabs
            type="grow"
            value={tabs.findIndex((item) =>
              routerState.location.pathname.startsWith('/account' + item.path),
            )}
            onChange={(index) =>
              navigate({ to: `/account${tabs[index].path}` })
            }
            tabs={tabs.map((item) => ({
              text: item.text,
              prependIcon: item.icon,
            }))}
          />
        </div>
        <Outlet />
      </PageContainer>
    </Menus>
  );
};
