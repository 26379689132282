import { useNavigate } from '@tanstack/react-router';
import { useChatStore } from './store';
import { Menus } from '@/Components/Menus';
import { NewChatInput } from './components/NewChatInput';
import { ChatsSlideover } from './components/ChatsSlideover';
import { useQueryClient } from '@tanstack/react-query';
import { useAppStore } from '../AppLoader/stores';

export const NewChat = () => {
  const appState = useAppStore();
  const navigate = useNavigate();
  const client = useQueryClient();

  const { setInitialMessage } = useChatStore();

  appState.pageTitle('Chat');

  return (
    <Menus>
      <div className="relative flex h-[calc(100vh-10px)] w-full flex-grow flex-col items-center overflow-hidden">
        <ChatsSlideover
          onNewChat={() => {
            navigate({ to: '/chat' });
            setInitialMessage(undefined);
          }}
          onSelectChat={(id) => {
            navigate({ to: '/chat/$chatId', params: { chatId: '' + id } });
            setInitialMessage(undefined);
          }}
        />
        <NewChatInput
          onCreateChat={(chat, initialMessage) => {
            navigate({ to: '/chat/$chatId', params: { chatId: '' + chat.id } });
            setInitialMessage(initialMessage);
            client.refetchQueries({
              predicate: (query) => query.queryKey.includes('projectChatIndex'),
            });
          }}
        />
      </div>
    </Menus>
  );
};
