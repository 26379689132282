import { create } from 'zustand';

type State = {
  menu?: string;
  toggleMenu: (key: string) => void;
  openMenu: (key: string) => void;
};

export const useSidebarStore = create<State>((set, get) => ({
  openMenu: (key: string) => {
    set((state) => ({
      ...state,
      menu: key,
    }));
  },
  toggleMenu: (key: string) => {
    if (get().menu === key) {
      set((state) => ({
        ...state,
        menu: undefined,
      }));
    } else {
      set((state) => ({
        ...state,
        menu: key,
      }));
    }
  },
}));
