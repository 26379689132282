import { useState } from 'react';
import { ChangePasswordDialog } from './components/ChangePasswordDialog';
import ValidationErrors from '@/Components/ValidationErrors';
import { useAppStore } from '../AppLoader/stores';
import { Button } from '@/Components/v2/Button';
import { Input } from '@/Components/v2/Input/Input';
import { useProfileUpdate } from '@/api/openapiComponents';
import { SectionHeader } from '../components/SectionHeader';

export const Profile = () => {
  const appState = useAppStore();

  const [name, setName] = useState(appState.auth!.user.name);
  const [email, setEmail] = useState(appState.auth!.user.email);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  // **** Profile hooks ****
  const updateProfileMutation = useProfileUpdate();

  const handleSaveProfile = () => {
    updateProfileMutation.mutate({
      body: {
        email,
        name,
      },
    });
  };

  appState.pageTitle('Account');

  return (
    <div className="mt-12">
      {/* Profile section */}
      <SectionHeader title="Profile settings" removeBorder />
      <div className="flex w-full flex-col">
        <div className="mb-8 flex w-full items-end gap-4">
          <div className="flex w-full flex-col">
            <h2 className="mb-2 ml-3 mt-4 text-sm">Email</h2>
            <Input value={email} onChange={setEmail} />
          </div>
          <div className="flex w-full flex-col">
            <h2 className="mb-2 ml-3 mt-4 text-sm">Name</h2>
            <Input value={name} onChange={setName} />
          </div>
        </div>
        <ChangePasswordDialog
          isOpen={showPasswordDialog}
          onClose={() => setShowPasswordDialog(false)}
        />
        <div className="flex w-full">
          <ValidationErrors
            errors={updateProfileMutation.error?.errors}
            apiError={updateProfileMutation.error}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button
            onClick={() => setShowPasswordDialog(true)}
            text="Change password"
            color="gray"
            variant="outline"
          />

          <Button
            onClick={handleSaveProfile}
            text="Save profile"
            color="secondary"
            variant="outline"
            isLoading={updateProfileMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
