import { fetchProjectDocumentWriteMoreStore } from '@/api/openapiComponents';
import { SEO_AI_AUTH } from '@/api/openapiFetcher';
import { WriteMorePromptType } from '@/api/openapiSchemas';
import { URL } from '@/Components/Utils/v2/api';
import { StreamPackage, StreamPackageJson } from '@/types';
import { createPlatePlugin, PlateEditor } from '@udecode/plate/react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import deserialize from './deserialize';

export const StreamingPlugin = createPlatePlugin({
  key: 'custom-streaming-plugin',
  api: {
    startStreaming: async ({
      content,
      documentId,
      editor,
      projectId,
      type,
      customPrompt,
    }: {
      editor: PlateEditor;
      projectId: number;
      documentId: number;
      content: string;
      type: WriteMorePromptType;
      customPrompt?: string;
    }) => {
      await fetchProjectDocumentWriteMoreStore({
        pathParams: { project: projectId, document: documentId },
        body: {
          type: type,
          custom_prompt: customPrompt,
          content: content,
        },
      });

      return new Promise<void>((resolve) => {
        const stream = new EventSourcePolyfill(
          `${URL}/projects/${projectId}/documents/${documentId}/write-more-stream`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(SEO_AI_AUTH)}`,
            },
            withCredentials: true,
          },
        );
        stream.onerror = (e) => {
          stream.close();
          throw e;
        };

        const closeStream = () => {
          if (editor && editor.selection) {
            const selection = editor.selection;
            editor.tf.select(selection);
          }
          stream.close();

          resolve();
        };

        let queue: string[] = [];

        const interval = setInterval(() => {
          if (queue.length > 0) {
            let queueContent: string[] = [];

            const newLineTokens = ['\n\n', '\n'];
            const newLineToken = newLineTokens.find((token) =>
              queue.join('').includes(token),
            );

            if (newLineToken && queue.join('').includes(newLineToken)) {
              const str = queue.join('');

              queueContent = [str.split(newLineToken)[0], newLineToken];
              queue = [str.split(newLineToken)[1]];
            } else {
              queueContent = queue;
              queue = [];
            }

            const content = queueContent.join('');

            text += content.replace('[DONE]', '');
            editor.tf.insertText(content.replace('[DONE]', ''));

            const incoming = deserialize(text + content);
            const isBreaking =
              incoming.length > 2 ||
              (newLineToken && content.includes(newLineToken)) ||
              content.includes('[DONE]');

            if (isBreaking && text !== '') {
              const block = editor.api.block({ highest: true });
              const path = block![1];
              const newNodes = deepClone(
                deserialize(text, { noNewline: true }),
              );
              editor.tf.insertNodes(newNodes);
              editor.tf.removeNodes({ at: path });
              text = '';
              editor.tf.insertBreak();

              if (block![0].type.startsWith('h')) {
                editor.tf.toggleBlock('p');
              }

              // If node is a list break out of the list
              if (newNodes[0].type === 'ol' || newNodes[0].type === 'ul') {
                editor.tf.insertBreak();
              }
            }

            if (content.includes('[DONE]')) {
              clearInterval(interval);
            }
          }
        }, 100);

        let text = '';

        stream.addEventListener('update' as any, ({ data }: StreamPackage) => {
          if (!editor || !data) {
            return;
          }
          try {
            if (data === '[DONE]') {
              queue.push(data);
              closeStream();
              return;
            }

            const { content }: StreamPackageJson = JSON.parse(data);

            queue.push(content);
          } catch (err) {
            closeStream();
            throw err;
          }
        });
      });
    },
  },
});

export const deepClone = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};
