export function GetStartedChatBubbleText() {
  return (
    <div className="flex w-full flex-grow flex-col justify-center p-6 text-center">
      <a
        className={'mb-3 inline-block text-blue-400'}
        target={'_blank'}
        href={'https://docs.seo.ai/a-20-how-to-use-the-chat'}
        rel="noreferrer"
      >
        Learn to use the chat
      </a>
      <img
        src={'/svg/chat-bubble.svg'}
        className={'mx-auto h-16 rotate-[5deg]'}
      />
    </div>
  );
}
