import { HelpCircle } from 'lucide-react';
import { Tooltip } from '../Tooltip';

type Props = {
  label: string;
  actions?: React.ReactNode;
  required?: boolean;
  hint?: string;
  size?: 'sm' | 'lg';
  tooltip?: string;
  variant?: 'default' | 'overlay';
};

export const InputLabel = ({
  label,
  hint,
  required,
  size = 'sm',
  tooltip,
  variant,
  actions,
}: Props) => {
  const getVariantStyling = () => {
    switch (variant) {
      case 'overlay':
        return 'absolute -top-2.5 left-2 z-50 pl-2 pr-6 text-sm font-normal text-primary-400';
      default:
        return 'text-primary-800';
    }
  };

  return (
    <p
      className={`mb-1 flex w-full items-end justify-between gap-1 font-bold ${getVariantStyling()}`}
    >
      <div
        className={`flex items-end gap-1 ${size === 'sm' ? '' : 'text-lg'} ${variant === 'overlay' ? 'bg-white px-1' : ''}`}
      >
        {label} {required ? <span className="text-red-400">*</span> : undefined}{' '}
        {hint && <span className="text-sm text-primary-400">{hint}</span>}
        {tooltip && (
          <div className="-mb-0.5">
            <Tooltip description={tooltip}>
              <HelpCircle size={14} className="stroke-black" />
            </Tooltip>
          </div>
        )}
      </div>

      {actions && (
        <div className={`${variant === 'overlay' ? 'bg-white' : ''} px-1`}>
          {actions}
        </div>
      )}
    </p>
  );
};
