'use client';

import React, { useContext, useEffect, useState } from 'react';

import { withRef } from '@udecode/cn';
import { type PlateEditor, ParagraphPlugin } from '@udecode/plate/react';
import { AIChatPlugin } from '@udecode/plate-ai/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { TablePlugin } from '@udecode/plate-table/react';
import {
  Heading2Icon,
  Heading3Icon,
  Heading4Icon,
  Image,
  ListIcon,
  ListOrdered,
  PilcrowIcon,
  Quote,
  SparklesIcon,
  Table,
} from 'lucide-react';

import {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmpty,
  InlineComboboxGroup,
  InlineComboboxGroupLabel,
  InlineComboboxInput,
  InlineComboboxItem,
} from './inline-combobox';
import { PlateElement } from './plate-element';
import { insertBlock } from '../lib/transforms';
import {
  BulletedListPlugin,
  NumberedListPlugin,
} from '@udecode/plate-list/react';
import { IsTableContext } from './table-element';
import { AddImageDialog } from './add-image-dialog';
import { ImagePlugin } from '@udecode/plate-media/react';
import { useDocumentStore } from '@/Pages/Document/stores';

type Group = {
  group: string;
  items: Item[];
};

interface Item {
  icon: React.ReactNode;

  onSelect: (
    editor: PlateEditor,
    value: string,
    setShowAddImageDialog: (value: boolean) => void,
  ) => void;
  description?: string;
  value: string;
  className?: string;
  focusEditor?: boolean;
  keywords?: string[];
  label?: string;
}

const groups: Group[] = [
  {
    group: 'AI',
    items: [
      {
        focusEditor: false,
        icon: <SparklesIcon />,
        value: 'Ask AI',
        description: 'Prompt or instruction for the AI',
        onSelect: (editor) => {
          editor.getApi(AIChatPlugin).aiChat.show();
        },
      },
    ],
  },
  {
    group: 'Basic blocks',
    items: [
      {
        icon: <PilcrowIcon />,
        keywords: ['paragraph'],
        label: 'Text',
        description: 'A paragraph of text',
        value: ParagraphPlugin.key,
      },
      {
        icon: <Heading2Icon />,
        keywords: ['subtitle', 'h2'],
        label: 'Heading 2',
        description: 'A section heading',
        value: HEADING_KEYS.h2,
      },
      {
        icon: <Heading3Icon />,
        keywords: ['subtitle', 'h3'],
        label: 'Heading 3',
        description: 'A subsection heading',
        value: HEADING_KEYS.h3,
      },
      {
        icon: <Heading4Icon />,
        keywords: ['subtitle', 'h4'],
        label: 'Heading 4',
        description: 'A subsection heading',
        value: HEADING_KEYS.h4,
      },
      {
        icon: <ListIcon />,
        keywords: ['unordered', 'ul', '-'],
        label: 'Bulleted list',
        description: 'A list of items',
        value: BulletedListPlugin.key,
      },
      {
        icon: <ListOrdered />,
        keywords: ['ordered', 'ol', '1'],
        label: 'Numbered list',
        description: 'A list of items with numbers',
        value: NumberedListPlugin.key,
      },
      {
        icon: <Table />,
        label: 'Table',
        description: 'A table of rows and columns',
        value: TablePlugin.key,
      },
      {
        icon: <Quote />,
        keywords: ['citation', 'blockquote', 'quote', '>'],
        label: 'Blockquote',
        description: 'A block of quoted text',
        value: BlockquotePlugin.key,
      },
      {
        icon: <Image />,
        keywords: ['image', 'picture', 'img'],
        label: 'Image',
        description: 'Insert an image link',
        value: ImagePlugin.key,
      },
    ].map((item) => ({
      ...item,
      onSelect: (editor, value, setShowAddImageDialog) => {
        if (item.value === ImagePlugin.key) {
          setShowAddImageDialog(true);
        } else {
          insertBlock(editor, value);
        }
      },
    })),
  },
];

export const SlashInputElement = withRef<typeof PlateElement>(
  ({ className, ...props }, ref) => {
    const { children, editor, element } = props;

    const setShowImageDialog = useDocumentStore(
      (state) => state.setShowImageDialog,
    );

    const isTable = useContext(IsTableContext);

    if (isTable) {
      return null;
    }

    return (
      <>
        <PlateElement
          ref={ref}
          as="span"
          className={className}
          data-slate-value={element.value}
          {...props}
        >
          <InlineCombobox element={element} trigger="/">
            <InlineComboboxInput />

            <InlineComboboxContent>
              <InlineComboboxEmpty>No results</InlineComboboxEmpty>

              {groups.map(({ group, items }) => (
                <InlineComboboxGroup key={group}>
                  <InlineComboboxGroupLabel>{group}</InlineComboboxGroupLabel>

                  {items.map(
                    ({
                      focusEditor,
                      icon,
                      keywords,
                      label,
                      value,
                      onSelect,
                      description,
                    }) => (
                      <InlineComboboxItem
                        key={value}
                        value={value}
                        onClick={() =>
                          onSelect(editor, value, setShowImageDialog)
                        }
                        label={label}
                        focusEditor={focusEditor}
                        group={group}
                        keywords={keywords}
                      >
                        {description ? (
                          <>
                            <div className="[&_svg]:text-subtle-foreground flex size-11 items-center justify-center rounded border border-foreground/15 bg-white [&_svg]:size-5">
                              {icon}
                            </div>
                            <div className="ml-3 flex flex-1 flex-col truncate">
                              <span>{label ?? value}</span>
                              <span className="truncate text-xs text-muted-foreground">
                                {description}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-subtle-foreground mr-2">
                              {icon}
                            </div>
                            {label ?? value}
                          </>
                        )}
                      </InlineComboboxItem>
                    ),
                  )}
                </InlineComboboxGroup>
              ))}
            </InlineComboboxContent>
          </InlineCombobox>

          {children}
        </PlateElement>
      </>
    );
  },
);
