import { FullScreenPageContainer } from '@/Components/FullScreenPageContainer';
import { Menus } from '@/Components/Menus';
import { useAppStore } from '../AppLoader/stores';
import {
  useMerchantCenterMerchantFeedGroupApply,
  useMerchantFeedGroupItemIndex,
  useMerchantFeedGroupShow,
} from '@/api/openapiComponents';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { OptimizationGroupDetails } from './components/OptimizationGroupDetails';
import { Button } from '@/Components/v2/Button';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { FeedStatusLabel } from './components/FeedStatusLabel';
import { ExternalLink } from 'lucide-react';
import * as Table from '@/Components/Table';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { MerchantFeedGroupResource } from '@/api/openapiSchemas';
import { useQueryClient } from '@tanstack/react-query';
import { FailedJobsDialog } from './components/FailedJobsDialog';

type Props = {
  feedId: string;
  optimizationGroupId: number;
};

export const ViewOptimizationGroup = ({
  feedId,
  optimizationGroupId,
}: Props) => {
  const appState = useAppStore();
  const { showSnackbar } = useSnackbar();
  const [showFailedJobsDialog, setShowFailedJobsDialog] = useState(false);
  const navigate = useNavigate({
    from: '/feed-seo/$feedId/view/$optimizationGroupId',
  });

  const search = useSearch({
    from: '/feed-seo/$feedId/view/$optimizationGroupId',
  });

  const client = useQueryClient();

  const [tabIndex, setTabIndex] = useState(0);

  const groupQuery = useMerchantFeedGroupShow({
    pathParams: {
      group: optimizationGroupId,
      merchantFeed: feedId,
      project: appState.currentProject!.id,
    },
  });

  useEffect(() => {
    if (groupQuery.data?.data) {
      const channel = window.Echo.private(
        `projects.${appState.currentProject!.id}`,
      );

      channel.listen(
        'MerchantFeedGroupUpdated',
        (event: MerchantFeedGroupResource) =>
          event.id === groupQuery.data.data.id &&
          client.setQueriesData(
            {
              predicate: (query) =>
                query.queryKey.includes('merchantFeedGroupShow'),
            },
            () => ({ data: event }),
          ),
      );

      return () => {
        channel.stopListening('MerchantFeedGroupUpdated');
      };
    }
  }, [groupQuery.isSuccess]);

  const showResults =
    groupQuery.data?.data.status === 'ready' ||
    groupQuery.data?.data.status === 'completed' ||
    groupQuery.data?.data.status === 'deployed';
  const groupItemsQuery = useMerchantFeedGroupItemIndex(
    {
      pathParams: {
        group: optimizationGroupId,
        merchantFeed: feedId,
        project: appState.currentProject!.id,
      },
      queryParams: {
        page: search.page,
        limit: 50,
      },
    },
    {
      enabled: showResults,
    },
  );

  const applyMutation = useMerchantCenterMerchantFeedGroupApply();

  return (
    <Menus>
      <FailedJobsDialog
        isOpen={showFailedJobsDialog}
        feedId={feedId}
        optimizationGroup={groupQuery.data?.data!}
        onClose={() => setShowFailedJobsDialog(false)}
        onRetry={() => groupQuery.refetch()}
      />
      <FullScreenPageContainer
        title={`View optimization group`}
        canGoBack
        subtitle={`${groupQuery.data?.data.name} | ${groupQuery.data?.data.items_count} products`}
        actions={
          <div className="flex items-center gap-4">
            <FeedStatusLabel group={groupQuery.data?.data} />
          </div>
        }
      >
        <InputDecoration label="Summary">
          <OptimizationGroupDetails
            group={groupQuery.data?.data}
            actions={{
              atStatus:
                groupQuery.data?.data.batch?.failed_jobs > 0 ? (
                  <div className="flex ">
                    {groupQuery.data?.data.batch?.failed_jobs} failed jobs
                    <Button
                      text="View failed jobs"
                      variant="outline"
                      onClick={() => setShowFailedJobsDialog(true)}
                    />
                  </div>
                ) : undefined,
              top: (
                <Button
                  text="Edit"
                  variant="ghost"
                  onClick={() =>
                    navigate({
                      to: '/feed-seo/$feedId/edit-group/$optimizationGroupId',
                      params: {
                        feedId,
                        optimizationGroupId: '' + optimizationGroupId!,
                      },
                    })
                  }
                />
              ),
              bottom:
                (groupQuery.data?.data.status === 'ready' ||
                  groupQuery.data?.data.status === 'failed') &&
                !applyMutation.isSuccess ? (
                  <Button
                    text={'Set live'}
                    color="secondary"
                    isLoading={applyMutation.isPending}
                    onClick={() =>
                      applyMutation.mutate(
                        {
                          pathParams: {
                            group: optimizationGroupId,
                            merchantFeed: feedId,
                            project: appState.currentProject!.id,
                          },
                        },
                        {
                          onSuccess: () => {
                            showSnackbar({
                              color: 'green',
                              message: 'Optimizations are deploying',
                            });
                            groupQuery.refetch();
                          },
                        },
                      )
                    }
                  />
                ) : null,
            }}
          />
        </InputDecoration>

        {showResults && (
          <div className="mt-4">
            <Tabs
              value={tabIndex}
              onChange={setTabIndex}
              tabs={
                groupQuery.data?.data.attributes?.map((attr) => ({
                  text: attr.display_name ?? '',
                })) ?? []
              }
            />
            <Table.Root
              items={groupItemsQuery.data?.data}
              isLoading={groupItemsQuery.isLoading}
              columns={[
                {
                  width: 2,
                  heading: 'ID',
                  render: (item) => item.id,
                },
                {
                  width: 30,
                  heading: `Current`,
                  render: (item) =>
                    item.item?.values?.find(
                      (val) =>
                        val.field ===
                        groupQuery.data?.data.attributes?.[tabIndex]?.name,
                    )?.value ?? (
                      <div className="text-primary-400">No value</div>
                    ),
                },
                {
                  heading: `Optimized`,
                  render: (item) =>
                    item.values?.find(
                      (val) =>
                        val.field ===
                        groupQuery.data?.data.attributes?.[tabIndex]?.name,
                    )?.value ?? <SkeletonLoader />,
                },
                {
                  width: 2,
                  render: (item) => (
                    <IconButton
                      icon={ExternalLink}
                      onClick={() =>
                        window.open(
                          item.item?.values?.find(
                            (value) => value.field === 'link',
                          )?.value,
                          '_blank',
                        )
                      }
                    />
                  ),
                },
              ]}
            >
              <Table.Footer>
                <Pagination
                  currentPage={search.page}
                  setCurrentPage={(page) =>
                    navigate({ search: { page }, replace: true })
                  }
                  lastPage={groupItemsQuery.data?.meta.last_page}
                  disabled={groupItemsQuery.isLoading}
                />
              </Table.Footer>
              <Table.NoContent>No items</Table.NoContent>
            </Table.Root>
          </div>
        )}
      </FullScreenPageContainer>
    </Menus>
  );
};
