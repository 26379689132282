import { FullScreenPageContainer } from '@/Components/FullScreenPageContainer';
import { Input } from '@/Components/v2/Input/Input';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useEffect, useState } from 'react';
import * as Table from '@/Components/Table';
import { Button } from '@/Components/v2/Button';
import { Plus, RefreshCcw, Trash2, X } from 'lucide-react';
import {
  useMerchantCenterMerchantFeedGroupNameSuggestion,
  useMerchantCenterMerchantFeedMatches,
  useMerchantFeedFieldIndex,
  useMerchantFeedGroupShow,
  useMerchantFeedGroupStore,
  useMerchantFeedGroupUpdate,
} from '@/api/openapiComponents';
import { useAppStore } from '../AppLoader/stores';
import { Select } from '@/Components/v2/Select';
import { CreationStepControls } from './components/CreationStepControls';
import { feedSeoSteps } from './util';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useDebounce } from '@/Hooks/useDebounce';
import { LoadingSpinner } from '@/Components/v2/SvgIcon';

type Props = {
  feedId: string;
  optimizationGroupId?: number;
};

type Filter = {
  field?: string | null;
  value?: string | null;
};

export const EditOptimizationGroup = ({
  feedId,
  optimizationGroupId,
}: Props) => {
  const appState = useAppStore();

  const navigate = useNavigate();
  const router = useRouter();

  const createGroupMutation = useMerchantFeedGroupStore();
  const updateGroupMutation = useMerchantFeedGroupUpdate();

  const groupNameSuggestionMutation =
    useMerchantCenterMerchantFeedGroupNameSuggestion();

  const groupQuery = useMerchantFeedGroupShow(
    {
      pathParams: {
        group: optimizationGroupId!,
        project: appState.currentProject!.id,
        merchantFeed: feedId!,
      },
    },
    {
      enabled: !!optimizationGroupId,
    },
  );
  const fieldsQuery = useMerchantFeedFieldIndex({
    pathParams: {
      merchantFeed: feedId!,
      project: appState.currentProject!.id,
    },
  });
  const matchingItemsMutation = useMerchantCenterMerchantFeedMatches();

  useEffect(() => {
    if (groupQuery.isSuccess) {
      setGroupName(groupQuery.data!.data.name);
      setFilters([
        ...(groupQuery.data!.data.filters?.length === 0
          ? [
              {
                field: '',
                value: '',
              },
            ]
          : []),
        ...(groupQuery.data!.data.filters ?? []).map((filter) => ({
          field: filter.field,
          value: filter.value,
        })),
      ]);
    }
  }, [groupQuery.isSuccess]);

  const [groupName, setGroupName] = useState('');
  const [filters, setFilters] = useState<Filter[]>([
    {
      field: '',
      value: '',
    },
  ]);

  const handleGetMatchingItems = () => {
    matchingItemsMutation.mutate({
      pathParams: {
        merchantFeed: feedId!,
        project: appState.currentProject!.id,
      },
      queryParams: {},
      body: {
        filters,
      },
    });
  };

  const handleNameSuggestion = (options?: { force: boolean }) => {
    if (groupName.length > 0 && !options?.force) return;
    groupNameSuggestionMutation.mutate(
      {
        pathParams: {
          merchantFeed: feedId!,
          project: appState.currentProject!.id,
        },
        queryParams: {},
        body: {
          filters,
        },
      },
      {
        onSuccess: (data) => {
          setGroupName(data.data.name);
        },
      },
    );
  };

  useDebounce(handleGetMatchingItems, [filters]);
  useEffect(handleGetMatchingItems, []);

  const isEditing = !!optimizationGroupId;

  const handleSubmit = () => {
    const onSuccess = (optimizationGroupId?: number) => {
      navigate({
        to: '/feed-seo/$feedId/edit-group/$optimizationGroupId',
        params: { feedId, optimizationGroupId: '' + optimizationGroupId },
        replace: true,
      }).then(() => {
        navigate({
          to: '/feed-seo/$feedId/choose-attributes/$optimizationGroupId',
          params: { feedId, optimizationGroupId: '' + optimizationGroupId },
          search: { new: '' + !isEditing },
        });
      });
    };

    if (isEditing) {
      updateGroupMutation.mutate(
        {
          pathParams: {
            group: optimizationGroupId!,
            project: appState.currentProject!.id,
            merchantFeed: feedId!,
          },
          body: {
            name: groupName,
            optimizable_attributes: groupQuery.data?.data.attributes?.map(
              (attr) => ({
                attribute_id: attr.id,
                additional_instructions: attr.additional_instructions ?? '',
              }),
            ),
            filters: filters.map((filter) => ({
              field: filter.field ?? null,
              value: filter.value!,
            })),
          },
        },
        {
          onSuccess: () => onSuccess(optimizationGroupId),
        },
      );
    } else {
      createGroupMutation.mutate(
        {
          pathParams: {
            project: appState.currentProject!.id,
            merchantFeed: feedId!,
          },
          body: {
            name: groupName,
            filters: filters.map((filter) => ({
              field: filter.field ?? null,
              value: filter.value!,
            })),
          },
        },
        {
          onSuccess: (data) => onSuccess(data.data.id),
        },
      );
    }
  };

  const errorHelper = new ErrorHelper(
    createGroupMutation.error ?? updateGroupMutation.error,
  );

  return (
    <>
      <FullScreenPageContainer
        title={`${isEditing ? 'Edit' : 'Create'} Optimization Group`}
        actions={
          <Button
            text="Close"
            prependIcon={X}
            variant="ghost"
            onClick={() => navigate({ to: '/feed-seo' })}
          />
        }
        controls={
          <CreationStepControls
            steps={feedSeoSteps}
            currentStep={0}
            onPrevious={() => router.history.back()}
            onNext={handleSubmit}
            error={errorHelper.message()}
            isLoading={
              createGroupMutation.isPending || updateGroupMutation.isPending
            }
          />
        }
      >
        <div className="mt-8">
          <InputDecoration
            label="Choose products you want to optimize"
            size="lg"
          >
            <Table.Root
              transparent
              items={filters}
              columns={[
                {
                  heading: 'Field',
                  render: (item, _, __, rowIndex) => (
                    <div className="max-w-lg">
                      <Select
                        options={[
                          { title: 'Any', value: '' },
                          ...(fieldsQuery.data?.data.map((field) => ({
                            title: field.value,
                            value: field.value,
                          })) ?? []),
                        ]}
                        value={item.field ?? ''}
                        onChange={(value) =>
                          setFilters(
                            filters.map((field, index) =>
                              index === rowIndex
                                ? { ...field, field: value as string }
                                : field,
                            ),
                          )
                        }
                      />
                    </div>
                  ),
                },
                {
                  render: () => <div>Contains</div>,
                },
                {
                  heading: 'Value',
                  expanded: true,
                  render: (item, _, __, rowIndex) => (
                    <Input
                      value={item.value ?? ''}
                      placeholder="Value of the condition"
                      onChange={(value) =>
                        setFilters(
                          filters.map((field, index) =>
                            index === rowIndex
                              ? { ...field, value: value }
                              : field,
                          ),
                        )
                      }
                    />
                  ),
                },
                {
                  render: (item, _, __, rowIndex) =>
                    filters.length > 1 ? (
                      <IconButton
                        icon={Trash2}
                        variant="ghost"
                        onClick={() =>
                          setFilters(
                            filters.filter((_, index) => index !== rowIndex),
                          )
                        }
                      />
                    ) : null,
                },
              ]}
            >
              <Table.Footer>
                <div className="-mt-4 flex w-full justify-between">
                  <Button
                    text="Add condition"
                    prependIcon={Plus}
                    variant="ghost"
                    onClick={() =>
                      setFilters((prev) => [
                        ...prev,
                        { field: undefined, value: '' },
                      ])
                    }
                  />
                </div>
              </Table.Footer>
            </Table.Root>
          </InputDecoration>
        </div>

        <div className="mb-8 min-h-[450px]">
          {matchingItemsMutation.data?.data ? (
            <div className="flex flex-col">
              <h1 className="font-bold">
                {matchingItemsMutation.data.meta.total} matches
              </h1>
              {matchingItemsMutation.data.data.slice(0, 20).map((result) => (
                <a
                  href={
                    result.values?.find((value) => value.field === 'link')
                      ?.value
                  }
                  target="_blank"
                  className="cursor-pointer hover:opacity-60"
                >
                  {result.item_id}: {result.name}
                </a>
              ))}
              {matchingItemsMutation.data.meta.total > 20 && (
                <div>
                  And {matchingItemsMutation.data.meta.total - 20} more...
                </div>
              )}
            </div>
          ) : filters.length > 0 &&
            filters.some((rule) => (rule.value?.length ?? 0) > 0) ? (
            <div className="flex flex-col gap-1">
              <h1 className="font-bold">Matches</h1>
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
              <SkeletonLoader height="2xs" />
            </div>
          ) : (
            <div className="flex flex-col">
              <h1 className="font-bold">Matches</h1>
            </div>
          )}
        </div>

        <InputDecoration label="Group name" required size="lg">
          <Input
            value={groupName}
            onFocus={() => handleNameSuggestion()}
            prependIcon={
              groupNameSuggestionMutation.isPending ? (
                <LoadingSpinner color="secondary" size="lg" />
              ) : undefined
            }
            appendIcon={
              <IconButton
                icon={RefreshCcw}
                onClick={() => handleNameSuggestion({ force: true })}
              />
            }
            placeholder="Provide a name for this group"
            onChange={(value) => setGroupName(value)}
            error={errorHelper.has('name')}
          />
        </InputDecoration>
      </FullScreenPageContainer>
    </>
  );
};
