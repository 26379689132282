import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { AiWriterFormState } from '../AiDraft';
import { Input } from '@/Components/v2/Input/Input';
import { AdditionalContextForm } from '@/Components/AdditionalContextForm';
import { useAnalyzePage } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useGetRandomPlaceHolder } from '@/data/placeholders';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { Button } from '@/Components/v2/Button';
import { ErrorAlert } from '@/Components/v2/Alert';
import { SettingsToggle } from '@/Pages/CreateNewContent/components/SettingsSwitch';
import { Stars } from 'lucide-react';

type Props = {
  isLoading?: boolean;
  errorHelper: ErrorHelper;
  formState: AiWriterFormState;
  setFormState: (state: AiWriterFormState) => void;
  onSubmit: () => void;
};

export const SingleForm = ({
  formState,
  setFormState,
  errorHelper,
  onSubmit,
  isLoading,
}: Props) => {
  const appState = useAppStore();

  const analyseUrlMutation = useAnalyzePage();

  const placeholder = useGetRandomPlaceHolder();

  const handleValueChange = (values: Partial<AiWriterFormState['single']>) => {
    setFormState({
      ...formState,
      single: {
        ...formState.single,
        ...values,
      },
    });
  };

  const handleImportBrief = () => {
    analyseUrlMutation.mutate(
      {
        body: {
          url: formState.single.importedUrl,
          content_format: 'text',
        },
      },
      {
        onSuccess: (data) => {
          handleValueChange({
            selectedBriefType: 'from-url',
            briefFromUrl: data.data.content,
          });
        },
      },
    );
  };

  return (
    <>
      <InputDecoration
        label="Target keyword"
        required
        description="The keyword you would like to rank on."
        readMoreLink="https://docs.seo.ai/target-keyword"
      >
        <Input
          disabled={isLoading}
          error={errorHelper.has('keyword')}
          value={formState.single.keyword}
          onChange={(value) => handleValueChange({ keyword: value })}
        />
      </InputDecoration>
      <InputDecoration
        label="H1 heading"
        description="The headline of your content. Leave blank to let AI generate."
      >
        <Input
          disabled={isLoading}
          error={errorHelper.has('title')}
          value={formState.single.title}
          onChange={(value) => handleValueChange({ title: value })}
        />
      </InputDecoration>
      <InputDecoration
        label="Add extra context"
        description="Is there any extra info you can provide the AI? (It's optional)"
      >
        <AdditionalContextForm
          briefLimit={appState.currentProject?.language?.brief_length ?? 0}
          isImportSuccess={analyseUrlMutation.isSuccess}
          isLoading={analyseUrlMutation.isPending || isLoading}
          brief={formState.single.brief}
          briefFromUrl={formState.single.briefFromUrl}
          url={formState.single.importedUrl}
          onBriefChange={(value) => handleValueChange({ brief: value })}
          onBriefFromUrlChange={(value) =>
            handleValueChange({ briefFromUrl: value })
          }
          onUrlChange={(value) => handleValueChange({ importedUrl: value })}
          onTabChange={(value) =>
            handleValueChange({
              selectedBriefType: value as 'from-url' | 'manual' | undefined,
            })
          }
          selectedTab={formState.single.selectedBriefType}
          onImport={handleImportBrief}
          placeholder={placeholder}
        />
      </InputDecoration>

      {(!appState.subscription!.usage.credits.generated_article.is_allowed ||
        !appState.subscription!.usage.credits.content.is_allowed) && (
        <ErrorAlert title="You have insufficient credits to generate an AI article" />
      )}
      <div className="mx-auto w-1/2">
        <Button
          fullWidth
          text={'Generate'}
          prependIcon={Stars}
          onClick={onSubmit}
          color="secondary"
          disabled={
            !appState.subscription!.usage.credits.generated_article
              .is_allowed ||
            !appState.subscription!.usage.credits.content.is_allowed
          }
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
