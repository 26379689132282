import {
  ArrowRightLeft,
  Copy,
  FilePlus,
  GripVertical,
  PlusSquare,
  RefreshCcw,
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { useDraggable } from '@dnd-kit/core';
import BeatLoader from '@/Components/BeatLoader';
import remarkGfm from 'remark-gfm';
import { Button } from '@/Components/v2/Button';
import { IconButton } from '@/Components/v2/IconButton/IconButton';
import { useCurrentEditorState } from '../../EditorStateProvider';
import { tracking } from '@/Services/tracking/Tracking';
import { deserializeSnippet } from '../../PlateEditor/lib/deserialize';
import { PlateEditor } from '@udecode/plate/react';
import { isEmpty } from 'slate';
import { useCreateDocument } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useNavigate } from '@tanstack/react-router';
import { hasher } from '@/Pages/AppLoader';
import { AnimatedEllipsis } from '@/Components/AnimatedEllipsis';

type AIMessageOverlayProps = {
  text: string;
  id?: number;
  toolCall?: string;
};
type Props = {
  allowDocumentCreation?: boolean;
  hideEditorActions?: boolean;
  hasSelection?: boolean;
  isLastAIMessage?: boolean;
  text: string;
  id: number;
  onCopy?: (value: string) => void;
  handleRefreshMessage: (id: number) => void;
};

export function AIMessageOverlay({
  text,
  id,
  toolCall,
}: AIMessageOverlayProps) {
  return (
    <div className="flex w-full flex-col pt-2 opacity-70">
      <p className="mb-4 px-3">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className="prose w-full max-w-none  text-lg"
          components={{
            a: ({ node, ...props }) => (
              <a
                {...props}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
              />
            ),
          }}
        >
          {text}
        </ReactMarkdown>
        <div className="mt-6 flex items-center gap-2">
          {id === 0 && (
            <BeatLoader
              className=""
              size={'0.5rem'}
              color="rgb(74 222 128 / 1)"
            />
          )}
          {toolCall} {toolCall && <AnimatedEllipsis />}
        </div>
      </p>
    </div>
  );
}

export function AIMessage({
  text,
  id,
  handleRefreshMessage,
  isLastAIMessage,
  hasSelection,
  hideEditorActions,
  allowDocumentCreation,
  onCopy,
}: Props) {
  const appState = useAppStore();
  const navigate = useNavigate();

  const { attributes, listeners, setNodeRef, setActivatorNodeRef } =
    useDraggable({
      data: {
        text,
        source: 'chat',
      },
      id,
    });

  const { currentEditor } = useCurrentEditorState();

  const createDocumentMutation = useCreateDocument();

  const handleInsertText = (markdownString: string) => {
    const editor = currentEditor as PlateEditor;
    if (editor) {
      const nodes = deserializeSnippet(markdownString);
      const block = editor.api.block();

      tracking.event('document_chat_inserted_content');
      if (block && isEmpty(editor, block?.[0])) {
        editor.tf.replaceNodes(nodes, { at: block?.[1] });
      } else {
        if (editor.selection) {
          const selectionBlock = editor.api.block({ highest: true });
          editor.tf.insertNodes(nodes, {
            at: selectionBlock?.[1],
            nextBlock: true,
          });
        } else {
          editor.tf.insertNodes(nodes);
        }
      }
    }
  };

  const handleReplaceText = (markdownString: string) => {
    if (currentEditor) {
      currentEditor.tf.deleteFragment();
      const nodes = deserializeSnippet(markdownString);
      currentEditor.tf.insertFragment(nodes);
      tracking.event('document_chat_replaced_content');
    }
  };

  const handleCreateDocument = (content: string) => {
    createDocumentMutation.mutate(
      {
        pathParams: {
          project: appState.currentProject!.id,
        },
        body: {
          content,
        },
      },
      {
        onSuccess: (data) => {
          navigate({
            to: '/documents/$documentId',
            params: { documentId: hasher.encode(data?.data.id) },
          });
        },
      },
    );
  };

  return (
    <div className="relative w-full">
      <div
        className="group flex w-full flex-col rounded-xl pt-6"
        ref={setNodeRef}
      >
        <p className="mb-4 px-3">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              a: ({ node, ...props }) => (
                <a
                  {...props}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline"
                />
              ),
            }}
            className="prose w-full max-w-none text-lg"
          >
            {text}
          </ReactMarkdown>
        </p>
        <div className="flex items-center justify-between gap-2 pb-2 pl-2">
          <div className="flex items-center gap-1">
            <div {...listeners} {...attributes}>
              {!hideEditorActions && (
                <Button
                  prependIcon={GripVertical}
                  text="Drag"
                  size="sm"
                  variant="ghost"
                  dense="also-horizontally"
                />
              )}
            </div>
          </div>
          <div className="flex items-center gap-6">
            {(allowDocumentCreation || isLastAIMessage || onCopy) && (
              <div className="flex">
                {allowDocumentCreation && (
                  <IconButton
                    icon={FilePlus}
                    tooltip={{
                      title: 'Create document with message as body',
                      side: 'bottom',
                    }}
                    isLoading={createDocumentMutation.isPending}
                    onClick={() => handleCreateDocument(text)}
                  />
                )}
                {onCopy && (
                  <IconButton
                    icon={Copy}
                    tooltip={{ title: 'Copy message', side: 'bottom' }}
                    onClick={() => onCopy(text)}
                  />
                )}
                {isLastAIMessage && (
                  <IconButton
                    icon={RefreshCcw}
                    tooltip={{ title: 'Refresh message', side: 'bottom' }}
                    onClick={() => {
                      handleRefreshMessage(id);
                    }}
                  />
                )}
              </div>
            )}
            {hasSelection && !hideEditorActions && (
              <Button
                prependIcon={ArrowRightLeft}
                text="Replace"
                variant="ghost"
                dense="also-horizontally"
                size="sm"
                onClick={() => handleReplaceText(text)}
              />
            )}
            {!hideEditorActions && (
              <Button
                prependIcon={PlusSquare}
                text="Insert"
                variant="ghost"
                dense="also-horizontally"
                size="sm"
                onClick={() => handleInsertText(text)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
