import {
  useMerchantCenterMerchantFeedGroupOptimize,
  useMerchantFeedGroupShow,
} from '@/api/openapiComponents';
import { FullScreenPageContainer } from '@/Components/FullScreenPageContainer';
import { InputDecoration } from '@/Components/v2/Input/InputDecoration';
import { useAppStore } from '../AppLoader/stores';
import { CreationStepControls } from './components/CreationStepControls';
import { feedSeoSteps } from './util';
import { Button } from '@/Components/v2/Button';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { OptimizationGroupDetails } from './components/OptimizationGroupDetails';
import { X } from 'lucide-react';
import { ErrorHelper } from '@/Services/ErrorHandling';

type Props = {
  feedId: string;
  optimizationGroupId: number;
};

export const FinalizeOptimizationGroup = ({
  feedId,
  optimizationGroupId,
}: Props) => {
  const appState = useAppStore();
  const navigate = useNavigate();
  const router = useRouter();

  const generateMutation = useMerchantCenterMerchantFeedGroupOptimize();

  const groupQuery = useMerchantFeedGroupShow({
    pathParams: {
      group: optimizationGroupId,
      merchantFeed: feedId,
      project: appState.currentProject!.id,
    },
  });

  const handleSubmit = (autoApply: boolean) => {
    generateMutation.mutate(
      {
        body: {
          auto_apply: autoApply,
        },
        pathParams: {
          group: optimizationGroupId,
          merchantFeed: feedId,
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          navigate({ to: '/feed-seo' });
        },
      },
    );
  };

  const errorHelper = new ErrorHelper(generateMutation.error);

  return (
    <FullScreenPageContainer
      title="Finalize Optimization Group"
      subtitle={`${groupQuery.data?.data.name} | ${groupQuery.data?.data.items_count} products`}
      size="md"
      actions={
        <Button
          text="Close"
          prependIcon={X}
          variant="ghost"
          onClick={() => navigate({ to: '/feed-seo' })}
        />
      }
      controls={
        <CreationStepControls
          currentStep={2}
          error={errorHelper.message()}
          onNext={() => handleSubmit(true)}
          onPrevious={() => router.history.back()}
          steps={feedSeoSteps}
          disabled={groupQuery.isLoading}
          additionalActions={
            <Button
              text="review before publish"
              variant="outline"
              color="secondary"
              isLoading={generateMutation.isPending}
              onClick={() => handleSubmit(false)}
            />
          }
          isLoading={generateMutation.isPending}
          nextText="Generate and set live"
        />
      }
    >
      <InputDecoration label="Summary">
        <OptimizationGroupDetails group={groupQuery.data?.data} />
      </InputDecoration>
    </FullScreenPageContainer>
  );
};
