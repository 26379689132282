import { Collapsable } from './Collapsable';
import React, { useEffect } from 'react';
import { TanStackSearchParams } from '@/types';
import { SidebarMenuItem } from './Sidebar/SidebarMenuItem';
import { useSidebarStore } from './Sidebar/stores';

type Props<T> = {
  title: string;
  icon: React.ReactNode;
  items: {
    name: string;
    href: string;
    search?: TanStackSearchParams<T>;
    current: boolean;
    disabled?: boolean;
    isBeta?: boolean;
    tooltip?: {
      text: string;
      navLink?: string;
      navLinkText?: string;
    };
    icon: any;
  }[];
};
export const SidebarCollapsable = <T,>({ title, icon, items }: Props<T>) => {
  const { openMenu, toggleMenu, menu } = useSidebarStore();

  useEffect(() => {
    const matchingItem = items.find((item) => {
      return (
        item.href === window.location.pathname ||
        window.location.pathname.startsWith(item.href)
      );
    });
    if (matchingItem) {
      openMenu(title);
    }
  }, []);

  return (
    <li key={title}>
      <Collapsable
        onClick={() => toggleMenu(title)}
        paddingX="sm"
        isOpen={menu === title}
        fullWidth
        prependIcon={icon}
        title={
          <p
            className={`group ml-2 flex text-base ${menu === title ? 'font-semibold' : 'font-semibold'} leading-6`}
          >
            {title}
          </p>
        }
      >
        {items.map((item, i, array) => {
          return (
            <SidebarMenuItem
              isSubItem
              item={item}
              key={item.name}
              isLast={i + 1 === array.length}
              isBeta={item.isBeta}
            />
          );
        })}
      </Collapsable>
    </li>
  );
};
