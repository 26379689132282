import { IconToggle } from '@/Components/IconToggle';
import { LucideGlobe } from 'lucide-react';
import { Select } from '@/Components/v2/Select';
import ToggleButton from '@/Components/ToggleButton';
import { AnalyticsTotalsData, GraphState } from '../hooks';
import ReactCountryFlag from 'react-country-flag';
import { useState } from 'react';
import { ChooseCountryDialog } from '@/Pages/Analytics/components/ChooseCountryDialog';
import { AnalyticsGranularity } from '@/api/openapiSchemas';
import { AnalyticsTotals } from './AnalyticsTotals';
import { AnalyticsRange, rangeDisplayNames } from '@/Pages/Analytics/utils';
import { useGetTopMerchantCustomerCountries } from '@/api/openapiComponents';
import { useAppStore } from '@/Pages/AppLoader/stores.ts';

type Props = {
  page?: string;
  totals: AnalyticsTotalsData;
  state: GraphState;
  onStateChange: (state: GraphState) => void;
};

export const AnalyticsFilters = ({ onStateChange, state, totals }: Props) => {
  const [showChooseCountryDialog, setShowChooseCountryDialog] = useState(false);

  const appState = useAppStore();

  const countries = useGetTopMerchantCustomerCountries({
    pathParams: {
      project: appState.currentProject!.id,
    }
  }, {
    enabled: showChooseCountryDialog
  })

  return (
    <div>
      <ChooseCountryDialog
        isLoading={countries.isLoading}
        data={countries.data?.data ?? []}
        isOpen={showChooseCountryDialog}
        onChooseCountry={(country) => onStateChange({ ...state, country })}
        onClose={() => setShowChooseCountryDialog(false)}
      />
      <div className="mb-2 mt-6 flex items-center justify-between">
        {/* Graph settings */}

        <AnalyticsTotals
          state={state}
          onStateChange={onStateChange}
          totals={totals}
        />

        <div className="flex items-center gap-2">
          <ToggleButton
            value={state.granularity}
            options={[
              {
                displayName: 'Day',
                value: 'day',
              },
              {
                displayName: 'Week',
                value: 'week',
              },
              {
                displayName: 'Month',
                value: 'month',
              },
            ]}
            onChange={(value) =>
              onStateChange({
                ...state,
                granularity: value as AnalyticsGranularity,
              })
            }
          />

          <IconToggle
            icon={
              state.country?.iso_3166_2 === 'xx' ? (
                <LucideGlobe width={23} height={18} />
              ) : (
                ((
                  <ReactCountryFlag
                    countryCode={state.country?.iso_3166_2}
                    svg
                    style={{
                      height: '18px',
                      width: '23px',
                    }}
                    className="border border-gray-100"
                  />
                ) as any)
              )
            }
            value={false}
            onClick={() => setShowChooseCountryDialog(true)}
          />

          <Select
            value={state.range}
            onChange={(value) =>
              onStateChange({
                ...state,
                range: value as AnalyticsRange,
              })
            }
            options={[
              {
                title: rangeDisplayNames.P1W,
                value: 'P1W',
              },
              {
                title: rangeDisplayNames.P2W,
                value: 'P2W',
              },
              {
                title: rangeDisplayNames.P4W,
                value: 'P4W',
              },
              {
                title: rangeDisplayNames.P3M,
                value: 'P3M',
              },
              {
                title: rangeDisplayNames.P6M,
                value: 'P6M',
              },
              {
                title: rangeDisplayNames.P1Y,
                value: 'P1Y',
              },
              {
                title: rangeDisplayNames.P16M,
                value: 'P16M',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
