import { cn, withRef } from '@udecode/cn';
import { withHOC } from '@udecode/plate/react';
import { Image, ImagePlugin, useMediaState } from '@udecode/plate-media/react';
import { ResizableProvider } from '@udecode/plate-resizable';

import { MediaPopover } from './media-popover';
import { PlateElement } from './plate-element';
import { Resizable } from './resizable';
import { createPortal } from 'react-dom';
import { ConfirmDialog } from '@/Components/ConfirmDialog';
import { useState } from 'react';

export const ImageElement = withHOC(
  ResizableProvider,
  withRef<typeof PlateElement>(
    ({ children, className, nodeProps, ...props }, ref) => {
      const { align = 'center', focused, readOnly, selected } = useMediaState();

      const [showWarningDialog, setShowWarningDialog] = useState(true);

      const isImageUrl =
        props.element?.url?.startsWith('http://') ||
        props.element?.url?.startsWith('https://');

      if (!isImageUrl) {
        return createPortal(
          <ConfirmDialog
            title="Warning"
            description={[
              "It's not possible to paste raw images into the editor. Use the insert image button instead.",
            ]}
            noCancel
            confirmText="OK"
            isOpen={showWarningDialog}
            onConfirm={() => setShowWarningDialog(false)}
          />,
          document.body,
        );
      }

      return (
        <MediaPopover plugin={ImagePlugin}>
          <PlateElement
            ref={ref}
            className={cn(className, 'py-2.5')}
            {...props}
          >
            <figure className="group relative m-0" contentEditable={false}>
              <Resizable
                align={align}
                options={{
                  align,
                  readOnly,
                }}
              >
                <Image
                  className={cn(
                    'block w-full max-w-full cursor-pointer object-cover px-0',
                    'rounded-sm',
                    focused && selected && 'ring-2 ring-ring ring-offset-2',
                  )}
                  alt=""
                  {...nodeProps}
                />
              </Resizable>
            </figure>

            {children}
          </PlateElement>
        </MediaPopover>
      );
    },
  ),
);
