import { FeatureItem } from '@/Pages/Account/components/FeatureItem';
import { Button } from '../../../Components/v2/Button';
import { ArrowRight } from 'lucide-react';
import { ReactNode, Fragment } from 'react';

type Props = {
  name: string;
  headline: string;
  headlineAppendix?: string;
  description: string;
  disabled?: boolean;
  isCurrent?: boolean;
  isTrialing?: boolean;
  bulletPoints: ({
    text: ReactNode;
    disabled?: boolean;
    unlimitedOver?: number;
  } | null)[];
  onClick: () => void;
  actionText: string;
  isLoading?: boolean;
  error?: string;
};

export const SubscriptionItem = ({
  headline,
  headlineAppendix,
  bulletPoints,
  description,
  disabled,
  name,
  isCurrent,
  isTrialing,
  onClick,
  actionText,
  isLoading,
  error,
}: Props) => {
  return (
    <div
      className={`relative flex w-full flex-col gap-2 rounded-2xl bg-gray-50 p-8 text-gray-700`}
    >
      <p>{name}</p>
      <p className="text-2xl font-bold">
        {headline}{' '}
        {headlineAppendix && (
          <span className="text-sm font-bold italic">{headlineAppendix}</span>
        )}
      </p>
      <div
        className={`${
          isCurrent ? '' : 'invisible'
        } absolute right-6 top-6 rounded-md bg-secondary-100 p-2 text-sm font-semibold text-secondary-400`}
      >
        CURRENT PLAN
      </div>
      <div className="relative mb-2 h-16 w-full">
        <p className="absolute left-0 right-0 top-0 mb-4  italic">
          {description}
        </p>
      </div>
      <div>
        <Button
          text={actionText}
          disabled={disabled || (isCurrent && !isTrialing)}
          fullWidth
          variant={'fill'}
          color={'secondary'}
          appendIcon={ArrowRight}
          onClick={onClick}
          isLoading={isLoading}
        />
        {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
      </div>
      <div className="mb-6 mt-6 flex flex-grow flex-col gap-4 text-gray-600">
        {bulletPoints.map((bulletPoint, key) => {
          return bulletPoint === null ? (
            <Fragment key={key}>&nbsp;</Fragment>
          ) : (
            <FeatureItem
              feature={bulletPoint.text}
              key={bulletPoint.text?.toString()}
              disabled={bulletPoint.disabled}
              isUnlimitedAfter={bulletPoint.unlimitedOver}
            />
          );
        })}
      </div>
    </div>
  );
};
