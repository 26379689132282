import * as React from 'react';

export const useScrollIntoView = (isChatOpen: boolean) => {
  const scrollToDiv = React.useRef<HTMLInputElement>(null);
  const scrollContainerDiv = React.useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = React.useState(false);

  React.useEffect(() => {
    if (scrollToDiv.current === null) return;

    const observer = new IntersectionObserver((ent) => {
      const isInView = ent[0].isIntersecting;
      setIsInView(isInView);
    });
    observer.observe(scrollToDiv.current);

    return () => {
      if (observer && scrollToDiv.current) {
        observer?.unobserve?.(scrollToDiv.current);
      }
    };
  }, [scrollToDiv, isChatOpen]);

  const handleScrollIntoView = () => {
    scrollContainerDiv.current?.scroll({
      top: scrollContainerDiv.current.scrollHeight,
      behavior: 'instant',
    });
  };

  return { handleScrollIntoView, isInView, scrollToDiv, scrollContainerDiv };
};
