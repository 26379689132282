import { Link } from '@tanstack/react-router';
import { Popover } from '../Menu/Popover';
import { TooltipProps } from './Tooltip';

export const TooltipPopover = ({
  children,
  align,
  description,
  asChild,
  link,
  side = 'right',
  navLinkText,
  navLinkParams,
  navLinkTarget,
  navLink,
  title,
}: Omit<TooltipProps, 'disabled'>) => (
  <Popover
    trigger={children}
    type="tooltip"
    asChild={asChild}
    align={align}
    side={side}
  >
    <div role="tooltip" className={`z-20 max-h-36 max-w-64 p-2`}>
      {title && (
        <p className={'text-sm font-bold text-gray-600'}>{title}</p>
      )}

      {description && (
        <p className={`text-sm leading-4 text-gray-600 ${title ? 'pt-1' : ''}`}>{description}</p>
      )}

      {navLink && (
        <div className="flex justify-between pt-3">
          <div className="flex items-center">
            <Link
              to={navLink}
              params={navLinkParams}
              target={navLinkTarget}
              className="text-sm font-bold text-indigo-700"
              rel="noreferrer"
            >
              {navLinkText ?? 'Learn more'}
            </Link>
          </div>
          <div className="flex items-center"></div>
        </div>
      )}

      {link && (
        <div className="flex justify-between pt-3">
          <div className="flex items-center">
            <a
              target="_blank"
              href={link.url}
              className="text-sm font-bold text-indigo-700"
              rel="noreferrer"
            >
              {link.text}
            </a>
          </div>
          <div className="flex items-center"></div>
        </div>
      )}
    </div>
  </Popover>
);
