import {
  useGetGoogleSearchConsole,
  useSetupGoogleSearchConsole,
  useSelectSite,
  useProjectsConnectionsDestroy,
  useCreateShopifyConnection,
  useGetGoogleMerchantCenter,
  useSetupGoogleMerchantCenter,
  useSelectAccount,
  SetupGoogleMerchantCenterResponse,
} from '@/api/openapiComponents';
import { queryKeyFn } from '@/api/openapiContext';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useAppStore } from '../AppLoader/stores';
import { useGoogleLogin } from '@react-oauth/google';
import { ProjectConnectionGoogleMerchantCenterResource } from '@/api/openapiSchemas.ts';
export const useConnectToGoogleSearchConsole = (projectId: number) => {
  const initialState = {
    isOpen: false,
    sites: [],
    selectedSite: undefined,
  };

  const [googleDialog, setGoogleDialog] = useState<{
    isOpen: boolean;
    sites: string[];
    selectedSite: string | undefined;
  }>(initialState);

  const client = useQueryClient();
  const variables = {
    pathParams: {
      project: projectId,
    },
  };

  const {
    data: googleSearchConsoleData,
    isLoading: isGettingGoogleSearchConsoleData,
    error: googleSearchConsoleError,
  } = useGetGoogleSearchConsole(variables, { retry: false });

  const {
    mutate: sendSetupGoogleSearchConsoleRequest,
    isPending: isSettingUpGoogleSearchConsole,
  } = useSetupGoogleSearchConsole({
    onSuccess: (data) => {
      setGoogleDialog({
        isOpen: true,
        sites: data.data.settings.available_sites,
        selectedSite: data.data.settings.site,
      });
    },
  });

  const {
    mutate: selectSiteForGoogleSearchConsole,
    isPending: isSelectingSiteForGoogleSearchConsole,
  } = useSelectSite({
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: queryKeyFn({
          path: '/projects/{project}/connection/google-search-console',
          operationId: 'getGoogleSearchConsole',
          variables,
        }),
      });
    },
  });

  const { mutate: disconnect } = useProjectsConnectionsDestroy({
    onSuccess: () => {
      client.resetQueries({
        queryKey: queryKeyFn({
          path: '/projects/{project}/connection/google-search-console',
          operationId: 'getGoogleSearchConsole',
          variables,
        }),
      });
    },
  });

  const setupGoogleConsole = useGoogleLogin({
    onSuccess: (codeResponse) => {
      sendSetupGoogleSearchConsoleRequest({
        pathParams: {
          project: projectId,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    ux_mode: 'popup',
  });

  return {
    googleDialog,
    setGoogleDialog,
    isGettingGoogleSearchConsoleData,
    setupGoogleConsole,
    isSettingUpGoogleSearchConsole,
    selectSiteForGoogleSearchConsole,
    isSelectingSiteForGoogleSearchConsole,
    googleSearchConsoleError,
    googleSearchConsoleData,
    disconnect,
    initialSearchConsoleState: initialState,
    hasNoAvailableSites:
      googleSearchConsoleData?.data.settings.available_sites.length === 0,
  };
};

export type GoogleMerchantDialogState = {
  isOpen: boolean;
  accounts:
    | ProjectConnectionGoogleMerchantCenterResource['settings']['accounts']
    | [];
  selectedAccount: string | undefined;
};

export const useConnectToGoogleMerchantCenter = (projectId: number) => {
  const initialMerchantCenterState = {
    isOpen: false,
    accounts: [],
    selectedAccount: undefined,
  };

  const [googleMerchantDialog, setGoogleMerchantDialog] =
    useState<GoogleMerchantDialogState>(initialMerchantCenterState);

  const client = useQueryClient();
  const variables = {
    pathParams: {
      project: projectId,
    },
  };

  const {
    data: googleMerchantCenterData,
    error: googleMerchantCenterError,
    isLoading: isGettingGoogleMerchantCenterData,
  } = useGetGoogleMerchantCenter(variables, { retry: false });

  const {
    mutate: sendSetupGoogleMerchantCenterRequest,
    isPending: isSettingUpGoogleMerchantCenter,
  } = useSetupGoogleMerchantCenter({
    onSuccess: (data: SetupGoogleMerchantCenterResponse) => {
      setGoogleMerchantDialog({
        isOpen: true,
        accounts: data.data.settings.accounts,
        selectedAccount: data.data.settings.account?.accountId,
      });
    },
  });

  const {
    mutate: selectAccountForGoogleMerchantCenter,
    isPending: isSelectingAccountForGoogleMerchantCenter,
  } = useSelectAccount({
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: queryKeyFn({
          path: '/projects/{project}/connection/google-merchant-center',
          operationId: 'getGoogleMerchantCenter',
          variables,
        }),
      });
    },
  });

  const { mutate: disconnectMerchantCenter } = useProjectsConnectionsDestroy({
    onSuccess: () => {
      client.resetQueries({
        queryKey: queryKeyFn({
          path: '/projects/{project}/connection/google-merchant-center',
          operationId: 'getGoogleMerchantCenter',
          variables,
        }),
      });
    },
  });

  const setupGoogleMerchantCenter = useGoogleLogin({
    onSuccess: (codeResponse) => {
      sendSetupGoogleMerchantCenterRequest({
        pathParams: {
          project: projectId,
        },
        body: {
          code: codeResponse.code,
        },
      });
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/content',
    ux_mode: 'popup',
  });

  return {
    googleMerchantDialog,
    googleMerchantCenterError,
    setGoogleMerchantDialog,
    isGettingGoogleMerchantCenterData,
    setupGoogleMerchantCenter,
    isSettingUpGoogleMerchantCenter,
    selectAccountForGoogleMerchantCenter,
    isSelectingAccountForGoogleMerchantCenter,
    googleMerchantCenterData,
    disconnectMerchantCenter,
    initialMerchantCenterState,
    hasNoAvailableAccounts:
      googleMerchantCenterData?.data.settings.accounts.length === 0,
  };
};
