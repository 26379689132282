import { Popover } from '@/Components/v2/Menu/Popover';
import { useLayoutEffect, useRef, useState } from 'react';

type Props = {
  content: string;
  configs: {
    name: string;
    maxWidth: number;
    fontClassnames: string;
  }[];
};

export const MetadataWidth = ({ content, configs }: Props) => {
  const hiddenRefs = useRef<HTMLDivElement[]>([]);

  const [widths, setWidths] = useState<number[]>([]);

  const defaultConfig = configs[0];
  const defaultWidth = widths[0];

  useLayoutEffect(() => {
    if (hiddenRefs.current) {
      setWidths(hiddenRefs.current.map((ref) => ref.offsetWidth));
    }
  }, [content]);

  const getTextColor = (width: number, maxWidth: number) => {
    if (width > maxWidth) {
      return 'text-red-800 bg-red-100 rounded-md px-2 ';
    }
    return 'text-primary';
  };

  return (
    <Popover
      type="hover"
      side="right"
      trigger={
        <div
          className={`relative ${getTextColor(defaultWidth, defaultConfig.maxWidth)}`}
        >
          {configs.map((config, index) => (
            <div
              className={`pointer-events-none absolute -left-[50rem] -right-[2000rem] flex h-0 opacity-0 ${config.fontClassnames} `}
            >
              <div ref={(el) => (hiddenRefs.current[index] = el!)}>
                {content}
              </div>
            </div>
          ))}
          {content.length} <span className="text-xs">CHARACTERS</span> /{' '}
          {defaultWidth} <span className="text-xs">PIXELS</span>
        </div>
      }
    >
      <div className="flex flex-col gap-2 p-2">
        <p className="font-semibold text-primary">DESCRIPTION LENGTH CHECKER</p>
        {configs.map((config, index) => (
          <div key={config.name} className="mt-4 flex flex-col gap-2">
            <p className="text-xs uppercase leading-3 text-primary-400">
              {config.name}
            </p>
            <p className={`${getTextColor(widths[index], config.maxWidth)}`}>
              <b>{widths[index]}</b> <span className="text-xs">PIXELS OF</span>{' '}
              <b>{configs[index].maxWidth}</b>{' '}
              <span className="text-xs">RECOMMENDED</span>
            </p>
          </div>
        ))}
      </div>
    </Popover>
  );
};
