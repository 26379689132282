import {
  useProjectChatMessageDestroy,
  useProjectChatMessageIndex,
  useProjectChatMessageStore,
} from '@/api/openapiComponents';
import { useQueryClient } from '@tanstack/react-query';
import { useStartMessageStreamMutation } from '@/Components/Utils/v2/api';
import { queryKeyFn } from '@/api/openapiContext';
import { useSnackbar } from '@/Components/v2/Snackbar';
import { useEffect, useState } from 'react';
import { useGetDraggableState } from '@/Hooks/useGetDraggableState';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useScrollIntoView } from '@/Pages/Document/components/Chat/hooks/useScrollIntoView';
import { InitialMessage } from '../ChatPage';
import { ChatLayout } from './ChatLayout';
import { Event, events } from '@/events';
import { useNavigate } from '@tanstack/react-router';
import copyToClipboard from 'copy-to-clipboard';
import { getInitialPlateJSValue, plateToHTML } from '@/Pages/Document/utils';
import serialize, {
  serializeToMarkdown,
} from '@/Pages/Document/components/PlateEditor/lib/serialize';
import { GetStartedChatBubbleText } from '@/Pages/Document/components/Chat/components/GetStartedChatBubble';

type Props = {
  chatId: number;
  disabled?: boolean;
  initialMessage?: InitialMessage;
  onClearInitialMessage: () => void;
};

export const ProjectChat = ({
  chatId,
  onClearInitialMessage,
  disabled,
  initialMessage,
}: Props) => {
  const client = useQueryClient();
  const appState = useAppStore();
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();
  const { draggableState } = useGetDraggableState();

  const [text, setText] = useState('');

  const variables = {
    pathParams: {
      project: appState.currentProject!.id,
      chat: chatId,
    },
  };

  const queryKey = queryKeyFn({
    path: '/projects/{project}/chats/{chat}/messages',
    operationId: 'projectChatMessageIndex',
    variables,
  });

  useEffect(() => {
    if (initialMessage) {
      handleSendMessage(initialMessage.message, initialMessage.images);
    }
  }, [initialMessage]);

  useEffect(() => {
    const handler = () => {
      navigate({ to: '/chat' });
    };

    events.subscribe(Event.ProjectChanged, handler);

    return () => {
      events.unsubscribe(Event.ProjectChanged, handler);
    };
  }, []);

  const { mutateAsync: sendMessageAsync, isPending: isSendingMessage } =
    useProjectChatMessageStore();
  const { data: chatMessages, isLoading } = useProjectChatMessageIndex(
    variables,
    { enabled: !initialMessage },
  );
  const deleteChatMessageMutation = useProjectChatMessageDestroy();
  // const { mutate: clearChat, isPending: isClearingChat } = useProjectChatClear(
  //   {
  //     onMutate: () => {
  //       client.setQueryData(queryKey, () => ({ data: [] }));
  //     },
  //     onSuccess: () => {
  //       client.invalidateQueries({ queryKey });
  //     },
  //   },
  // );

  const {
    handleStartStream,
    data: { isLoadingMessage, content, isStreamingMessage, toolCall },
    handleSetLoadingMessage,
  } = useStartMessageStreamMutation(
    { chatId, projectId: appState.currentProject!.id },
    queryKey,
  );

  const handleRefreshMessage = (id: number) => {
    handleSetLoadingMessage(true);
    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chat: chatId,
          message: id,
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          client.invalidateQueries({ queryKey: queryKey }).then(() => {
            handleStartStream();
          });
        },
      },
    );
  };

  const { handleScrollIntoView, isInView, scrollToDiv, scrollContainerDiv } =
    useScrollIntoView(true);

  const handleSendMessage = async (
    predefinedText?: string,
    images?: string[],
    insert = true,
  ) => {
    handleScrollIntoView();
    if (insert) {
      client.setQueriesData({ queryKey }, (prev) => {
        return {
          ...(prev ?? {}),
          data: [
            {
              content: predefinedText || text,
              display_type: 'MESSAGE',
              is_moderated: false,
              id: 0,
              role: 'user',
              images,
            },
            ...(prev?.data ?? []),
          ],
        };
      });
    }
    handleSetLoadingMessage(true);

    try {
      const message = await sendMessageAsync({
        pathParams: {
          project: appState.currentProject!.id,
          chat: chatId,
        },
        body: {
          content: predefinedText || text,
          display_type: 'MESSAGE',
          images,
        },
      });
      onClearInitialMessage();
      if (!predefinedText) {
        if (!text) return;
        setText('');
      }
      if (!message.data.is_moderated) {
        handleStartStream();
      } else {
        handleSetLoadingMessage(false);
        client.setQueriesData({ queryKey }, (prev) => {
          return {
            ...(prev ?? {}),
            data: [
              ...(prev?.data ?? []).map((m, index) => {
                if (index === 0) {
                  return {
                    ...m,
                    id: message.data.id,
                    is_moderated: true,
                  };
                }
                return m;
              }),
            ],
          };
        });
      }
    } catch (error: any) {
      showSnackbar({
        alignment: 'top',
        message: error.message,
        color: 'red',
      });
      handleSetLoadingMessage(false);
    }
  };

  const handleSubmitEditedMessage = (
    id: number,
    text: string,
    images?: string[],
  ) => {
    client.setQueriesData({ queryKey }, (prev) => {
      const messageIndex = prev.data.findIndex((message) => message.id === id);

      return {
        ...prev,
        data: [
          {
            ...prev.data[messageIndex],
            content: text,
          },
          ...prev.data.slice(messageIndex + 1),
        ],
      };
    });

    deleteChatMessageMutation.mutate(
      {
        pathParams: {
          chat: chatId,
          message: id,
          project: appState.currentProject!.id,
        },
      },
      {
        onSuccess: () => {
          handleSendMessage(text, images, false);
        },
      },
    );
  };

  return (
    <ChatLayout
      input={{
        onChange: setText,
        value: text,
      }}
      placeholder={<GetStartedChatBubbleText />}
      allowDocumentCreation
      currentToolCall={toolCall!}
      isDeletingMessage={deleteChatMessageMutation.isPending}
      isLoading={isLoading}
      isLoadingMessage={isLoadingMessage}
      isSendingMessage={isSendingMessage}
      isStreamingMessage={isStreamingMessage}
      streamedMessage={content!}
      onEditMessage={handleSubmitEditedMessage}
      onRefreshMessage={handleRefreshMessage}
      onSendMessage={handleSendMessage}
      onCopy={(value) => {
        copyToClipboard(' ', {
          onCopy: (dataTransfer) => {
            const data = dataTransfer as DataTransfer;
            if (!data) return;

            const html = plateToHTML(getInitialPlateJSValue(value));
            data.setData('text/html', html);
          },
        });
      }}
      scroll={{
        scrollContainerDiv,
        handleScrollIntoView,
        isInView,
        scrollToDiv,
      }}
      hideEditorActions
      chatMessages={chatMessages?.data}
      disabled={disabled}
    />
  );
};
