import {
  ParagraphPlugin,
  useEditorReadOnly,
  useEditorState,
} from '@udecode/plate/react';
import { BoldPlugin, ItalicPlugin } from '@udecode/plate-basic-marks/react';
import {
  BoldIcon,
  Image,
  ItalicIcon,
  QuoteIcon,
  WandSparklesIcon,
} from 'lucide-react';

import { AIToolbarButton } from './ai-toolbar-button';
import { RedoToolbarButton, UndoToolbarButton } from './history-toolbar-button';
import { LinkToolbarButton } from './link-toolbar-button';
import { MarkToolbarButton } from './mark-toolbar-button';
import { TableDropdownMenu } from './table-dropdown-menu';
import { ToolbarButton, ToolbarGroup } from './toolbar';
import { TurnIntoDropdownMenu } from './turn-into-dropdown-menu';
import { ListToolbarButton } from './list-toolbar-button';
import {
  BulletedListPlugin,
  NumberedListPlugin,
} from '@udecode/plate-list/react';
import { setBlockType } from '../lib/transforms';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { useDocumentStore } from '@/Pages/Document/stores';

export function FixedToolbarButtons() {
  const readOnly = useEditorReadOnly();

  const setShowImageDialog = useDocumentStore(
    (state) => state.setShowImageDialog,
  );

  const editor = useEditorState();

  const toggleBlockQuote = () => {
    const block = editor.api.block();

    setBlockType(
      editor,
      block?.[0].type === BlockquotePlugin.key
        ? ParagraphPlugin.key
        : BlockquotePlugin.key,
    );
  };

  return (
    <div className="flex w-full">
      {!readOnly && (
        <>
          <ToolbarGroup>
            <UndoToolbarButton />
            <RedoToolbarButton />
          </ToolbarGroup>
          <ToolbarGroup>
            <TurnIntoDropdownMenu />
          </ToolbarGroup>

          <ToolbarGroup>
            <MarkToolbarButton nodeType={BoldPlugin.key} tooltip="Bold (⌘+B)">
              <BoldIcon />
            </MarkToolbarButton>

            <MarkToolbarButton
              nodeType={ItalicPlugin.key}
              tooltip="Italic (⌘+I)"
            >
              <ItalicIcon />
            </MarkToolbarButton>
          </ToolbarGroup>
          <ToolbarGroup>
            <ListToolbarButton nodeType={BulletedListPlugin.key} />
            <ListToolbarButton nodeType={NumberedListPlugin.key} />
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarButton onClick={toggleBlockQuote}>
              <QuoteIcon />
            </ToolbarButton>
          </ToolbarGroup>

          <ToolbarGroup>
            <LinkToolbarButton />
          </ToolbarGroup>
          <ToolbarGroup>
            <TableDropdownMenu />
            <ToolbarButton onClick={() => setShowImageDialog(true)}>
              <Image />
            </ToolbarButton>
          </ToolbarGroup>
        </>
      )}
    </div>
  );
}
