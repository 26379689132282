import {
  useMerchantCenterMerchantFeedGroupRetryFailed,
  useMerchantFeedGroupFailedItemsIndex,
} from '@/api/openapiComponents';
import { Dialog } from '@/Components/Dialog';
import { useAppStore } from '@/Pages/AppLoader/stores';
import { useState } from 'react';
import * as Table from '@/Components/Table';
import Pagination from '@/Components/ProjectKeywords/Pagination';
import { Tabs } from '@/Components/v2/Tabs/Tabs';
import { MerchantFeedGroupResource } from '@/api/openapiSchemas';
import { SkeletonLoader } from '@/Components/v2/SkeletonLoader/SkeletonLoader';
import { ErrorHelper } from '@/Services/ErrorHandling';
import { ErrorAlert } from '@/Components/v2/Alert';

type Props = {
  isOpen: boolean;
  feedId: string;
  optimizationGroup?: MerchantFeedGroupResource;
  onClose: () => void;
  onRetry: () => void;
};

export const FailedJobsDialog = ({
  isOpen,
  feedId,
  optimizationGroup,
  onClose,
  onRetry,
}: Props) => {
  const appState = useAppStore();
  const [page, setPage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);

  const retryMutation = useMerchantCenterMerchantFeedGroupRetryFailed();
  const failedJobsQuery = useMerchantFeedGroupFailedItemsIndex(
    {
      pathParams: {
        group: optimizationGroup?.id,
        merchantFeed: feedId,
        project: appState.currentProject!.id,
      },
      queryParams: {
        page: page,
      },
    },
    {
      enabled: isOpen && optimizationGroup,
    },
  );

  const errorHelper = new ErrorHelper(retryMutation.error);

  return (
    <Dialog
      isOpen={isOpen}
      actions={[
        {
          text: 'Close',
          onClick: onClose,
          variant: 'outline',
        },
        {
          text: 'Run again',
          onClick: () =>
            retryMutation.mutate(
              {
                pathParams: {
                  group: optimizationGroup?.id,
                  merchantFeed: feedId,
                  project: appState.currentProject!.id,
                },
              },
              {
                onSuccess: () => onRetry(),
              },
            ),
          color: 'secondary',
        },
      ]}
    >
      {errorHelper.isError() && <ErrorAlert title={errorHelper.message()} />}
      <Tabs
        value={tabIndex}
        onChange={setTabIndex}
        tabs={
          optimizationGroup?.attributes?.map((attr) => ({
            text: attr.display_name ?? '',
          })) ?? []
        }
      />
      <Table.Root
        columns={[
          {
            heading: 'ID',
            render: (item) => item.id,
          },
          {
            width: 30,
            heading: `Current`,
            render: (item) =>
              item.item?.values?.find(
                (val) =>
                  val.field === optimizationGroup?.attributes?.[tabIndex]?.name,
              )?.value ?? <div className="text-primary-400">No value</div>,
          },
          {
            heading: `Optimized`,
            render: (item) =>
              item.values?.find(
                (val) =>
                  val.field === optimizationGroup?.attributes?.[tabIndex]?.name,
              )?.value ?? <SkeletonLoader />,
          },
        ]}
        items={failedJobsQuery.data?.data ?? []}
        isLoading={failedJobsQuery.isLoading}
      >
        <Table.Footer>
          <Pagination
            currentPage={page}
            lastPage={failedJobsQuery.data?.meta.last_page}
            setCurrentPage={setPage}
          />
        </Table.Footer>
      </Table.Root>
    </Dialog>
  );
};
